import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { toast } from "react-toastify";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { getValidInput } from "../../Uitls/Util";
import { styled } from "@material-ui/core/styles";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
import InfoIcon from "../../images/iconinfo.svg";
import Outline from "../../images/Outline.svg";
import Outlinegrey from "../../images/Outlinegrey.svg";

export default function TextAndButton({
  createFroalaInstance,
  froalaIndex,
  textColor,
  styles,
  ColorPicker,
  handleFontFamily,
  refFontColor,
  refTextBg,
  displayColorPickerFontColor,
  fontFamily,
  fontSize,
  //handleFontSizeChange,
  fontFamilyItems,
  fontSizeItems,
  handleFontWeights,
  fontWeight,
  fontWeightItems,
  AV,
  // characterSpacingConfig,
  // handleCharacterSpacing,
  // characterSpacing,
  letterSpacingItems,
  handleClickSwatchFontCOlor,
  LineHeight,
  // lineHeightConfig,
  // handleLineHeight,
  // lineHeightProp,
  lineHeightItems,
  froalaFontAlignments,
  froalaNumberList,
  //alignmentConfig,
  froalaFontStyles,
  //fontStyleConfig,
  setDisplayColorPickerFontColor,
  setDisplayColorPickerBorder,
  borderColor,
  handleChange,
  expanded,
  selectedComponent,
  layerCompData,
  setSelectedComponent,
  handleTextBgColor,
  // toggleScrollbar,
  setCheckedScroll,
  checkedScroll,
  localRightProps,
  setLocalRightProps,
  handleTextPropsOnchange,
  setFontSize,
  fontSizeConfig,
  setLineHeightProp,
  setCharacterSpacing,
  setLayerCompData,
  fontAlignmentonClick,
  listOnclick,
  fontStylesonClick,
  selectedFontId,
  setSelectedFontId,
  updateSelctedFontstyle,
}) {
  const { t } = useTranslation();

  // let locked = selectedComponent?.componentStyles?.lockBoolean;
  let locked =
    layerCompData[
      layerCompData.findIndex((selComp) => selComp.id === selectedComponent.id)
    ]?.componentStyles?.lockBoolean;

  const ScrollBar = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      background: selectedComponent?.componentStyles?.toggleScroll
        ? "white"
        : "",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  // const Accordion = styled((props) => (
  //   <MuiAccordion disableGutters elevation={0} square {...props} />
  // ))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  //   "&:before": {
  //     display: "none",
  //   },
  // }));

  // const AccordionSummary = styled((props) => (
  //   <MuiAccordionSummary
  //     expandIcon={<ArrowDropDownIcon sx={{ fontSize: "20px" }} />}
  //     {...props}
  //   />
  // ))(({ theme }) => ({
  //   backgroundColor:
  //     theme.palette.mode === "dark"
  //       ? "rgba(255, 255, 255, .05)"
  //       : "rgba(0, 0, 0, .03)",
  //   flexDirection: "row-reverse",
  //   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
  //     transform: "rotate(90deg)",
  //   },
  //   "& .MuiAccordionSummary-content": {
  //     marginLeft: theme.spacing(1),
  //   },
  // }));

  // const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  //   padding: theme.spacing(2),
  //   borderTop: "1px solid rgba(0, 0, 0, .125)",
  // }));

  // const [checkedAspect, setCheckedAspect] = React.useState(true);
  const ToggleScrollbar = (e) => {
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.toggleScroll = e.target.checked;
    let value =
      e.target.checked === false
        ? (tempSlected.componentStyles.expandAndClose = false)
        : "";
    setSelectedComponent(tempSlected);
  };

  // const updateSelctedFontstyle = (id) => {
  //   let tempSlected = { ...selectedComponent };
  //   const selectedIndex =
  //     tempSlected.componentStyles.selectedFontStyle?.indexOf(id);
  //   if (tempSlected.componentStyles.selectedFontStyle?.includes(id)) {
  //     tempSlected.componentStyles.selectedFontStyle.splice(selectedIndex, 1);
  //   } else {
  //     tempSlected.componentStyles.selectedFontStyle = [
  //       ...tempSlected.componentStyles.selectedFontStyle,
  //       id,
  //     ];
  //     setSelectedComponent(tempSlected);
  //   }
  // };

  const toggleFroalaAlignment = (id, type) => {
    let tempSlected = { ...selectedComponent };

    if (tempSlected?.componentStyles?.selectedId !== id) {
      tempSlected.componentStyles.selectedId = id;
      // let tempListtypeClick = !tempSlected.componentStyles.listTypeNumberClick;
      //  tempSlected.componentStyles.listTypeNumberClick = tempListtypeClick;
      setSelectedComponent(tempSlected);
      setAlignItem(type);
      let froalaText =
        createFroalaInstance.current[froalaIndex]?.editor?.html.get();
      if (
        froalaText.includes("text-align:left") ||
        froalaText.includes("text-align: left") ||
        froalaText.includes("text-align : left")
      ) {
        if (id === 3 || id === 4) {
          tempSlected.componentStyles.textAlign = "left";
        }
      } else if (
        froalaText.includes("text-align:right") ||
        froalaText.includes("text-align: right") ||
        froalaText.includes("text-align : right")
      ) {
        if (id === 3 || id === 4) {
          tempSlected.componentStyles.textAlign = "right";
        }
      } else if (
        froalaText.includes("text-align:center") ||
        froalaText.includes("text-align: center") ||
        froalaText.includes("text-align : center")
      ) {
        if (id === 3 || id === 4) {
          tempSlected.componentStyles.textAlign = "center";
        }
      }
      const tempLayercompData = [...layerCompData];

      if (tempLayercompData[froalaIndex]?.froalaText) {
        tempLayercompData[froalaIndex].froalaText = froalaText;
      }
    } else {
      setAlignItem("leftAlign");
      tempSlected.componentStyles.selectedId = "";
      tempSlected.componentStyles.textAlign = "left";
      setSelectedComponent(tempSlected);
    }
  };
  const toggleFroalaAlignmentNumberlist = (id, type) => {
    let tempSlected = { ...selectedComponent };
    if (tempSlected?.componentStyles?.selectedIdnumberList !== id) {
      tempSlected.componentStyles.selectedIdnumberList = id;
      // let tempListtypeClick = !tempSlected.componentStyles.listTypeNumberClick;
      //  tempSlected.componentStyles.listTypeNumberClick = tempListtypeClick;
      setSelectedComponent(tempSlected);
      setListType(type);
      let froalaText =
        createFroalaInstance.current[froalaIndex]?.editor?.html.get();
      if (
        froalaText.includes("text-align:left") ||
        froalaText.includes("text-align: left") ||
        froalaText.includes("text-align : left")
      ) {
        if (id === 0 || id === 1) {
          tempSlected.componentStyles.textAlign = "left";
        }
      } else if (
        froalaText.includes("text-align:right") ||
        froalaText.includes("text-align: right") ||
        froalaText.includes("text-align : right")
      ) {
        if (id === 0 || id === 1) {
          tempSlected.componentStyles.textAlign = "right";
        }
      } else if (
        froalaText.includes("text-align:center") ||
        froalaText.includes("text-align: center") ||
        froalaText.includes("text-align : center")
      ) {
        if (id === 0 || id === 1) {
          tempSlected.componentStyles.textAlign = "center";
        }
      }
      const tempLayercompData = [...layerCompData];

      if (tempLayercompData[froalaIndex]?.froalaText) {
        tempLayercompData[froalaIndex].froalaText = froalaText;
      }
    } else {
      setListType("");
      tempSlected.componentStyles.selectedIdnumberList = "";
      setSelectedComponent(tempSlected);
    }
  };
  const [openfontsizeDropdown, setOpenfontsizeDropdown] = React.useState(null);
  const open = Boolean(openfontsizeDropdown);
  const [alignItem, setAlignItem] = useState("leftAlign");
  const [listType, setListType] = useState("");

  const handleFontsizeDropdown = (event) => {
    setOpenfontsizeDropdown(event.currentTarget);
  };
  const handleClose = () => {
    setOpenfontsizeDropdown(null);
  };
  const [openletterspaceDropdown, setOpenletterspaceDropdown] =
    React.useState(null);
  const openletterSpacing = Boolean(openletterspaceDropdown);
  const handleletterSpaceDropdown = (event) => {
    setOpenletterspaceDropdown(event.currentTarget);
  };
  const handleCloseletterSpace = () => {
    setOpenletterspaceDropdown(null);
  };
  const [openlineheightDropdown, setOpenlineheightDropdown] =
    React.useState(null);
  const openlineHeight = Boolean(openlineheightDropdown);
  const handlelineHeightDropdown = (event) => {
    setOpenlineheightDropdown(event.currentTarget);
  };
  const handleCloselineHeight = () => {
    setOpenlineheightDropdown(null);
  };

  window.oncontextmenu = function () {
    handleCloselineHeight();
    handleCloseletterSpace();
    handleClose();
  };
  const validateInput = (value) => {
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(value)) {
      return;
    } else {
      value = re1.exec(value);
      if (value) {
        value = value[0];
      } else {
        value = "";
      }
    }
    let input = value !== "" ? value : "";
    return input;
  };

  const handletextfield = (e, blur) => {
    let value = getValidInput(e, blur);
    if (value === -1) return;

    let name = e.target.name;

    let updateValue = {
      ...localRightProps,
      componentStyles: {
        ...localRightProps.componentStyles,
        [name]: value,
      },
    };

    setLocalRightProps(updateValue);

    if (value[value.length - 1] === "." || value === "") return;
    let value1 = value; //Math.floor(value * 100) / 100;
    let tempLayercompData = [...layerCompData];

    if (name === "fontSize") {
      setFontSize(value1);
      //fontSizeConfig(value1, e.target.id);
    } else if (name === "letterSpacing") {
      setCharacterSpacing(value1);
    } else if (name === "lineHeight") {
      setLineHeightProp(value1);
    }

    tempLayercompData.forEach((entry, index) => {
      if (entry.id === updateValue.id) {
        tempLayercompData[index].componentStyles[e.target.name] = value1;
      }
    });
    setLayerCompData(tempLayercompData);
    if (blur === "blur") {
      layerCompData[layerCompData.length - 1].eachDrag = "no";
    } else {
      layerCompData[layerCompData.length - 1].eachDrag = "yes";
    }
    // let tempSelected = JSON.parse(JSON.stringify(selectedComponent));
    // tempSelected.componentStyles[e.target.name] = value;
    // setSelectedComponent(tempSelected);
  };

  const getCssClass = (itemId) => {
    if (
      itemId === 0 &&
      selectedComponent?.componentStyles?.boldClick &&
      selectedComponent?.componentStyles?.fontWeight === "bold"
    ) {
      return "target-box23";
    } else if (
      itemId === 0 &&
      !selectedComponent?.componentStyles?.boldClick &&
      selectedComponent?.componentStyles?.fontWeight === "normal"
    ) {
      return " ";
    } else {
      return selectedComponent?.componentStyles?.selectedFontStyle?.includes(
        itemId
      )
        ? "target-box23 muniraj"
        : "";
    }
  };

  return (
    <>
      {selectedComponent.componentType === "Text" ? (
        <hr className="hrtag" />
      ) : (
        ""
      )}
      <div className="accordion2 customAccordIcons1">
        <Accordion
          //expanded={expanded === "panel3"}
          // className="accordion"
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            aria-controls="panel3a-content"
            id="panel3a-header"
            expandIcon={
              expanded.panel3 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                <div className="text">
                  {t("editorRightPanel.propertiesTabcontent.text")}
                  {/* <img
                    src={InfoIcon}
                    alt=""
                    className="img-fluid pl-1 infoIcon2"
                  /> */}
                </div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ pointerEvents: locked && "none" }}>
              <div>
                <div className="propertyPanelToolbar">
                  {selectedComponent.componentType === "Text" ? (
                    <div className="enableScroll">
                      {t("editorRightPanel.propertiesTabcontent.enableScroll")}

                      <ScrollBar
                        checked={
                          selectedComponent?.componentStyles?.toggleScroll
                        }
                        onChange={(e) => {
                          ToggleScrollbar(e);
                          // !locked && ToggleAspectLock(e);
                        }}
                        className="toggleIcon "
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  <div
                    className={
                      selectedComponent.componentType === "Text"
                        ? "toolbarFonts"
                        : "toolbarFontsBut"
                    }
                  >
                    <div className="fontWeightProperty justify-content-between select_boxfont">
                      <p>Font</p>
                      <select
                        disabled={locked}
                        className="fontfamilyDropdown"
                        onChange={handleFontFamily}
                        // value={fontFamily}
                        value={selectedComponent?.componentStyles?.fontFamily}
                      >
                        {fontFamilyItems.map((fontFamilyItems, id) => {
                          return (
                            <option
                              key={id}
                              value={
                                fontFamilyItems.family
                                  ? fontFamilyItems.family
                                  : fontFamilyItems
                              }
                            >
                              {fontFamilyItems.family
                                ? fontFamilyItems.family
                                : fontFamilyItems}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div className="fontSizeProperty">
                      <div className="fontSizeItems">
                        <p className="noMargin">Size</p>
                        <div
                          className={
                            locked
                              ? "fontInputDisable d-flex"
                              : "fontInput d-flex"
                          }
                        >
                          <input
                            disabled={locked}
                            name="fontSize"
                            id="fontSizeIp"
                            className=" fontSizeDropdown"
                            //list="fontSizedata"
                            // onBlur={(e) => {
                            //   handleFontSizeChange(e, "blur");
                            // }}
                            onChange={(e) => handletextfield(e)}
                            onBlur={(e) => {
                              handletextfield(e, "blur");
                            }}
                            value={localRightProps?.componentStyles?.fontSize}
                            title=""
                          />
                          <KeyboardArrowDownIcon
                            id="fontSizeIcon"
                            // style={{ color: "#000000" }}
                            onClick={handleFontsizeDropdown}
                          />
                        </div>
                        <Menu
                          id="basic-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          anchorEl={openfontsizeDropdown}
                          open={locked ? false : open}
                          onClose={handleClose}
                          class="dropdownFontinput"
                          PaperProps={{
                            style: {
                              position: "absolute",
                              //  right: "28px",
                              width: "80px",
                              height: "150px",

                              border: "1px solid black",
                              borderRadius: "0px",
                            },
                          }}
                          //   TransitionComponent={Fade}
                        >
                          {fontSizeItems.map((fontSizeItems, id) => {
                            return (
                              <MenuItem
                                key={id}
                                value={fontSizeItems.fontSizeItem}
                                onClick={(e) => {
                                  handleTextPropsOnchange(
                                    fontSizeItems.fontSizeItem,
                                    "fontSize"
                                  );
                                  handleClose();
                                }}
                              >
                                {fontSizeItems.fontSizeItem}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                      </div>
                      <div className="fontWeightItems">
                        <div className="d-flex fontWeights weight select_boxfontweight ">
                          <p className="size noMargin" id="weight">
                            Weight
                          </p>
                          <select
                            disabled={locked}
                            className="fontWeightDropdown"
                            onChange={(e) =>
                              handleTextPropsOnchange(
                                e.target.value,
                                "fontWeight"
                              )
                            }
                            name="fontWeight"
                            value={fontWeight}
                          >
                            {fontWeightItems?.map((fontWeightItem, id) => {
                              return (
                                <option key={id} value={fontWeightItem}>
                                  {fontWeightItem}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="fontDecorators justify-content-between">
                      <div className=" spacingProperty">
                        <div className=" spacingPropertyItems">
                          <img
                            src={AV}
                            alt="vertical-spacing"
                            onClick={!locked}
                          />
                          <div
                            className={
                              locked
                                ? "fontInputDisable d-flex"
                                : "fontInput d-flex"
                            }
                          >
                            <input
                              // type="number"
                              disabled={locked}
                              name="letterSpacing"
                              className=" fontSizeDropdown"
                              //list="fontSizedata"
                              // onKeyDown={(e) =>
                              //   symbolsArr.includes(e.key) && e.preventDefault()
                              // }
                              onBlur={(e) => handletextfield(e, "blur")}
                              onChange={(e) => handletextfield(e)}
                              value={
                                localRightProps?.componentStyles?.letterSpacing
                              }
                              title=""
                            />
                            <KeyboardArrowDownIcon
                              id="fontSizeIcon"
                              onClick={handleletterSpaceDropdown}
                            />
                          </div>
                          <Menu
                            id="basic-menu"
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorEl={openletterspaceDropdown}
                            open={locked ? false : openletterSpacing}
                            onClose={handleCloseletterSpace}
                            class="dropdownFontinput "
                            PaperProps={{
                              style: {
                                position: "absolute",
                                //  right: "28px",
                                width: "80px",
                                height: "101px",
                                // left: "1290px !important",
                                border: "1px solid black",
                                borderRadius: "0px",
                              },
                            }}
                            //   TransitionComponent={Fade}
                          >
                            {letterSpacingItems.map(
                              (letterSpacingItems, id) => {
                                return (
                                  <MenuItem
                                    key={id}
                                    value={letterSpacingItems.letterSpacingItem}
                                    onClick={(e) => {
                                      handleTextPropsOnchange(
                                        letterSpacingItems.letterSpacingItem,
                                        "letterSpacing"
                                      );
                                      handleCloseletterSpace();
                                    }}
                                  >
                                    {letterSpacingItems.letterSpacingItem}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Menu>
                        </div>
                        <div className=" lineHeightItems">
                          <img
                            src={LineHeight}
                            alt="lineHeight"
                            onClick={!locked}
                          />
                          <div
                            className={
                              locked
                                ? "fontInputDisable d-flex"
                                : "fontInput d-flex"
                            }
                          >
                            <input
                              type="number"
                              disabled={locked}
                              name="lineHeight"
                              className=" fontSizeDropdown "
                              //list="fontSizedata"
                              style={{ width: "100%" }}
                              // onKeyDown={(e) =>
                              //   symbolsArr.includes(e.key) && e.preventDefault()
                              // }
                              onBlur={(e) => handletextfield(e, "blur")}
                              onChange={(e) => handletextfield(e)}
                              value={
                                localRightProps?.componentStyles?.lineHeight
                              }
                              title=""
                            />
                            <KeyboardArrowDownIcon
                              id="fontSizeIcon"
                              onClick={handlelineHeightDropdown}
                            />
                          </div>

                          <Menu
                            id="basic-menu"
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                            anchorEl={openlineheightDropdown}
                            open={locked ? false : openlineHeight}
                            onClose={handleCloselineHeight}
                            class="dropdownFontinput letterspacing"
                            PaperProps={{
                              style: {
                                position: "absolute",
                                //  right: "28px",
                                width: "80px",
                                height: "120px",
                                border: "1px solid black",
                                borderRadius: "0px",
                              },
                            }}
                            //   TransitionComponent={Fade}
                          >
                            {lineHeightItems.map((lineHeightItems, id) => {
                              return (
                                <MenuItem
                                  key={id}
                                  value={lineHeightItems.lineHeightItem}
                                  onClick={(e) => {
                                    handleTextPropsOnchange(
                                      lineHeightItems.lineHeightItem,
                                      "lineHeight"
                                    );
                                    handleCloselineHeight();
                                  }}
                                >
                                  {lineHeightItems.lineHeightItem}
                                </MenuItem>
                              );
                            })}
                          </Menu>
                          {/* <select
                            disabled={locked}
                            className="lineheightDropdown"
                            onChange={handleLineHeight}
                            value={lineHeightProp}
                          >
                            {lineHeightItems.map((lineHeightItems, id) => {
                              return (
                                <option
                                  key={id}
                                  value={lineHeightItems.lineHeightItem}
                                >
                                  {lineHeightItems.lineHeightItem}
                                </option>
                              );
                            })}
                          </select> */}
                        </div>
                      </div>
                    </div>
                    <div className="fontDecorators justify-content-between">
                      {froalaFontAlignments.map((items, id) => {
                        return (
                          <div
                            onClick={() => {
                              !locked && fontAlignmentonClick(items.type, id);

                              toggleFroalaAlignment(items.id, items.type);
                            }}
                            key={id}
                          >
                            {alignItem === items.type ? (
                              <img src={Outline} alt="" className="img-fluid" />
                            ) : (
                              <img
                                src={Outlinegrey}
                                alt=""
                                className="img-fluid"
                              />
                            )}
                            {alignItem === items.type ? items.img2 : items.img}
                          </div>
                        );
                      })}
                      {froalaNumberList.map((items, id) => {
                        return (
                          <div
                            onClick={() => {
                              !locked && listOnclick(items.type, id);
                              toggleFroalaAlignmentNumberlist(
                                items.id,
                                items.type
                              );
                            }}
                            key={id}
                          >
                            {listType === items.type ? (
                              <img src={Outline} alt="" className="img-fluid" />
                            ) : (
                              <img
                                src={Outlinegrey}
                                alt=""
                                className="img-fluid"
                              />
                            )}

                            {listType === items.type ? items.img2 : items.img}
                          </div>
                        );
                      })}
                    </div>
                    <div className="fontDecorators justify-content-between">
                      {froalaFontStyles.map((items, id) => {
                        return (
                          <div
                            onClick={() => {
                              !locked && fontStylesonClick(items.type, id);
                              updateSelctedFontstyle(items.id, items.type);
                            }}
                            key={id}
                          >
                            {selectedComponent?.componentStyles?.selectedFontStyle?.includes(
                              items.id
                            ) ? (
                              <img src={Outline} alt="" className="img-fluid" />
                            ) : (
                              <img
                                src={Outlinegrey}
                                alt=""
                                className="img-fluid"
                              />
                            )}

                            {selectedComponent?.componentStyles?.selectedFontStyle?.includes(
                              items.id
                            ) ||
                            (items.id === 1 &&
                              selectedComponent?.componentStyles?.boldClick &&
                              selectedComponent?.componentStyles?.fontWeight ===
                                "bold")
                              ? items.img2
                              : items.img}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="bg-fill"
                style={
                  selectedComponent?.componentType === "Text"
                    ? { marginTop: "4px" }
                    : { marginTop: "-23px" }
                }
              >
                <div className="swatch">
                  {
                    <img
                      alt=""
                      style={styles.textColor}
                      className="swatchBorder"
                      onClick={() => {
                        !locked && handleClickSwatchFontCOlor();
                      }}
                    ></img>
                  }
                </div>

                <div className="fill-color">
                  {t("editorRightPanel.propertiesTabcontent.fontColor")}
                </div>
              </div>
            </div>
            {selectedComponent.componentType === "Text" && (
              <div className="bg-fill" style={{ marginTop: "10px" }}>
                <div className="swatch">
                  {
                    <img
                      alt=""
                      style={styles.textBgColor}
                      className="swatchBorder"
                      onClick={() => {
                        !locked && handleTextBgColor();
                      }}
                    ></img>
                  }
                </div>

                <div className="fill-color">{t("Text Background Color")}</div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <hr className="hr" />
    </>
  );
}
