import React from "react";
// import { DebounceInput } from "react-debounce-input";

import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField } from "@material-ui/core";
import PaddingNotselected from "../../images/PaddingNotselected.svg";
import PaddingSelected from "../../images/PaddingSelected.svg";
import PaddingAllsidesblack from "../../images/PaddingAllsidesblack.svg";
import PaddingAllsidesblue from "../../images/PaddingAllsidesblue.svg";
import RadiusAllsidesblack from "../../images/RadiusAllsidesblack.svg";
import RadiusAllsidesblue from "../../images/RadiusAllsidesBlue.svg";
import RadiusonesideBlack from "../../images/RadiusonesideBlack.svg";
import RadiusonesideBlue from "../../images/RadiusonesideBlue.svg";
import rad1 from "../../images/rad1.svg";
import radiusGrey from "../../images/RadiusGrey.svg";
import rad2 from "../../images/rad2.svg";
import rad2Grey from "../../images/rad2Grey.svg";
import "../../styles/components/RightPanel.scss";
//import "../../styles/pages/Editor.scss";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import InfoIcon from "../../images/iconinfo.svg";

export default function ButtonAndShape({
  selectedComponent,
  styles,
  handleClickSwatch,
  displayColorPicker,
  ColorPicker,
  handleClose,
  handleButtonColorChange,
  buttonColor,
  setDisplayColorPicker,
  handleClickSwatchBorder,
  refBorder,
  handleCloseBorder,
  handleBorderColorChange,
  borderColor,
  setDisplayColorPickerBorder,
  changeBorderStyle,
  borderStyle,
  borderStyles,
  borderWidth,
  changeBorderWidth,
  refButtonColor,
  displayColorPickerBorder,
  paddingTRBL,
  radiusTRBL,
  commonPadding,
  commonRadius,
  handlePadding,

  handleRadius,
  changeRadius,
  changePadding,
  targetLink,
  changeTargetLink,

  handleChange,
  expanded,
  layerCompData,
  /*  cornerCount,
  setCornerCount,
  handleCornerCount, */
}) {
  const { t } = useTranslation();
  const showBorderDropdown =
    selectedComponent?.componentType === "Button"
      ? "borderDropdown"
      : "borderDropdownShape";
  // let locked = selectedComponent?.componentStyles?.lockBoolean;
  let locked =
    layerCompData[
      layerCompData.findIndex((selComp) => selComp.id === selectedComponent.id)
    ]?.componentStyles?.lockBoolean;

  return (
    <>
      <hr className="hrtag" />
      {/* <div>
        {!locked && displayColorPicker && (
          <div>
            <ColorPicker
              id="textColor"
              //  hideColorPicker={hideColorPicker}
              // handleColorChange={(e) => handleTextColorChange(e)}
              name="textColor"
              // color={
              //   colorPickerForComponents ? colorPickerForComponents : ""
              // }
              displayColorPicker={displayColorPicker}
              // displayColorPickerBgColor={displayColorPickerBgColor}
              // displayColorPickerBorder={displayColorPickerBorder}
              // displayColorPickerFontColor={displayColorPickerFontColor}
              // displayColorPickerScrollColor={
              //   displayColorPickerScrollColor
              // }
              // displaycolorpickerTextBg={displaycolorpickerTextBg}
              // colorPickerTextBgColorISI={colorPickerTextBgColorISI}
              // colorPickerFontColorISI={colorPickerFontColorISI}
              // collectColorOnChangeComplete={(e) =>
              //   collectColorOnChangeComplete(e)
              // }
            />
          </div>
        )}
      </div> */}
      <div className="accordion2 customAccordIcons1">
        <Accordion
          // expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              expanded.panel1 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                <div className="text">
                  {selectedComponent?.componentType === "Button"
                    ? t("editorRightPanel.propertiesTabcontent.button")
                    : t("editorRightPanel.propertiesTabcontent.shape")}
                  {/* <img
                    src={InfoIcon}
                    alt=""
                    className="img-fluid pl-1 infoIcon2"
                  /> */}
                </div>
              </div>
            </Typography>
          </AccordionSummary>

          {/*  <div className="text">
          {selectedComponent?.componentType === "Button"
            ? t("editorRightPanel.propertiesTabcontent.button")
            : t("editorRightPanel.propertiesTabcontent.shape")}
        </div> */}

          {/*    {selectedComponent?.textComponentType.addContent === "triangleShape" && (
        <div className="cornerCount">
          <div className="height-width corner-countDiv">
            <p>{t("editorRightPanel.propertiesTabcontent.cornerCount")}</p>
            // <DebounceInput
                                element={TextField}
                                debounceTimeout={500}
              className="standard-basic count"
              id="cornerCount"
              onChange={handleCornerCount}
              value={isNaN(cornerCount) ? 0 : Math.round(cornerCount)}
              name="cornerCount"
              size="small"
              inputProps={{
                style: {
                  width: "40px",
                  marginTop: "-14px",
                  marginBottom: "-19px",
                  fontSize: "11px",
                  color: "#928989",
                  textAlign: "center",
                },
              }}
            />
          </div>
        </div>
      )} */}
          <AccordionDetails className="accordion2-details">
            <div className="bg-fill">
              <div className="swatch">
                {
                  <div
                    style={styles.buttonColor}
                    className="swatchBorder"
                    onClick={() => {
                      !locked && handleClickSwatch();
                    }}
                  ></div>
                }
              </div>
              {/* <div>
                {!locked && displayColorPicker && (
                  <div ref={refButtonColor}>
                    <ColorPicker
                      id="textColor"
                      handleClose={handleClose}
                      handleColorChange={(e) => handleButtonColorChange(e)}
                      name="buttonColor"
                      color={buttonColor ? buttonColor : ""}
                      buttonColor={buttonColor ? buttonColor : ""}
                      setDisplayColorPicker={setDisplayColorPicker}
                    />
                  </div>
                )}
              </div> */}
              <div className="fill-color">
                {selectedComponent?.componentType === "Button"
                  ? t("editorRightPanel.propertiesTabcontent.buttonFill")
                  : t("editorRightPanel.propertiesTabcontent.shapeFill")}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <hr className="hr" />
        {/*   </div> */}
        <Accordion
          //expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={
              expanded.panel2 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                <div className="text">
                  BORDER
                  {/* <img
                    src={InfoIcon}
                    alt=""
                    className="img-fluid pl-1 infoIcon2"
                  /> */}
                </div>
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion2-details">
            <div className="borderFll">
              <div className="bg-fill">
                <div className="swatch">
                  {
                    <div
                      style={styles.borderColor}
                      className="swatchBorder"
                      onClick={() => {
                        !locked && handleClickSwatchBorder();
                      }}
                    ></div>
                  }
                </div>

                <div className="fill-color">
                  {t("editorRightPanel.propertiesTabcontent.border")}
                </div>
              </div>
              <div className="borderSizeType">
                <div className="fontSizeItems">
                  <p style={{ marginTop: "4px" }}>Size</p>
                  <TextField
                    // element={TextField}
                    // debounceTimeout={500}
                    disabled={locked}
                    className="textFieldCustom3"
                    //   value={borderWidth}
                    value={Number(borderWidth)}
                    onChange={changeBorderWidth}
                    onBlur={(e) => changeBorderWidth(e, "blur")}
                    size="small"
                    inputProps={{
                      style: {
                        width: "20px",
                        marginTop: "-14px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                        color: "#928989",
                        textAlign: "center",
                      },
                    }}
                  />{" "}
                </div>

                {selectedComponent.textComponentType.addContent !==
                  "triangleShape" &&
                  selectedComponent.textComponentType.addContent !==
                    "polygonShape" &&
                  selectedComponent.textComponentType.addContent !==
                    "hexagonShape" && (
                    <div
                      className={
                        selectedComponent?.componentType === "Button"
                          ? "fontWeightItems select_boxbordersize"
                          : "fontWeightItems select_boxbordershapesize"
                      }
                    >
                      <select
                        disabled={locked}
                        className={showBorderDropdown}
                        onChange={changeBorderStyle}
                        value={borderStyle}
                      >
                        {borderStyles.map((borderstyleItem, id) => {
                          return (
                            <option key={id} value={borderstyleItem}>
                              {borderstyleItem}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  )}
                {(selectedComponent.textComponentType.addContent ===
                  "triangleShape" ||
                  selectedComponent.textComponentType.addContent ===
                    "polygonShape" ||
                  selectedComponent.textComponentType.addContent ===
                    "hexagonShape") && (
                  <div className="fontWeightItems">
                    <div className=" borderstyleShape">
                      <TextField
                        // element={TextField}
                        // debounceTimeout={500}
                        disabled={locked}
                        className=""
                        name="shapeStyle"
                        //value={borderWidth}
                        value="Solid"
                        // onChange={changeBorderWidth}
                        size="small"
                        inputProps={{
                          style: {
                            // width: "35px",
                            marginTop: "-14px",
                            marginBottom: "-15px",
                            fontSize: "11px",
                            color: "black",
                            textAlign: "center",
                          },
                        }}
                      />{" "}
                    </div>
                  </div>
                )}
              </div>
              {(selectedComponent.componentType === "Button" ||
                selectedComponent.textComponentType.addContent ===
                  "rectangleShape") && (
                <div className="padding">
                  <div className="paddingRad justify-content-between">
                    <div className="rPImages">
                      <img
                        src={
                          commonPadding === true
                            ? PaddingSelected
                            : PaddingNotselected
                        }
                        alt="PaddingBox"
                        className="image-fluid"
                        // onClick={() => {
                        //   !locked && handlePadding("each");
                        // }}
                        onClick={() => {
                          !locked && handlePadding("common");
                        }}
                      />
                      <img
                        src={
                          commonPadding === false
                            ? PaddingAllsidesblue
                            : PaddingAllsidesblack
                        }
                        style={{ paddingLeft: "16px" }}
                        alt="PaddingEach pl-2"
                        // onClick={() => {
                        //   !locked && handlePadding("common");
                        // }}
                        onClick={() => {
                          !locked && handlePadding("each");
                        }}
                      />
                    </div>
                    <div className="padRadIps">
                      <div className={!commonPadding && "padRadDiv"}>
                        {!commonPadding && <div className="trbl">T</div>}
                        <TextField
                          // element={TextField}
                          // debounceTimeout={500}
                          disabled={locked}
                          className="textFieldCustom txtfld"
                          name="paddingTop"
                          value={paddingTRBL.paddingTop}
                          onChange={changePadding}
                          onBlur={(e) => changePadding(e, "blur")}
                          size="small"
                          inputProps={{
                            style: {
                              width: "20px",
                              marginTop: "-14px",

                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        />{" "}
                      </div>
                      {!commonPadding && (
                        <>
                          <div className="padRadDiv">
                            <div className="trbl">R</div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="paddingRight"
                              value={paddingTRBL.paddingRight}
                              onChange={changePadding}
                              onBlur={(e) => changePadding(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                          <div className="padRadDiv">
                            <div className="trbl">B</div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="paddingBottom"
                              value={paddingTRBL.paddingBottom}
                              onChange={changePadding}
                              onBlur={(e) => changePadding(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                          <div className="padRadDiv">
                            <div className="trbl">L</div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="paddingLeft"
                              value={paddingTRBL.paddingLeft}
                              onChange={changePadding}
                              onBlur={(e) => changePadding(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <p className="radiusLabel">Radius</p>
                  <div className="paddingRad justify-content-between mt-0">
                    <div className="rPImages">
                      <img
                        src={
                          !commonRadius
                            ? RadiusAllsidesblack
                            : RadiusAllsidesblue
                        }
                        alt="PaddingBox"
                        className="image-fluid"
                        // onClick={() => {
                        //   !locked && handleRadius("each");
                        // }}
                        onClick={() => {
                          !locked && handleRadius("common");
                        }}
                      />
                      <img
                        src={
                          commonRadius === true
                            ? RadiusonesideBlack
                            : RadiusonesideBlue
                        }
                        alt="PaddingEach pl-2"
                        style={{ paddingLeft: "16px" }}
                        // onClick={() => {
                        //   !locked && handleRadius("common");
                        // }}
                        onClick={() => {
                          !locked && handleRadius("each");
                        }}
                      />
                    </div>
                    <div className="padRadIps">
                      <div>
                        <TextField
                          // element={TextField}
                          // debounceTimeout={500}
                          disabled={locked}
                          className="textFieldCustom txtfld"
                          name="borderTopLeftRadius"
                          value={radiusTRBL.borderTopLeftRadius}
                          onChange={changeRadius}
                          onBlur={(e) => changeRadius(e, "blur")}
                          size="small"
                          inputProps={{
                            style: {
                              width: "20px",
                              marginTop: "-14px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        />{" "}
                      </div>
                      {!commonRadius && (
                        <>
                          <div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="borderTopRightRadius"
                              value={radiusTRBL.borderTopRightRadius}
                              onChange={changeRadius}
                              onBlur={(e) => changeRadius(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                          <div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="borderBottomLeftRadius"
                              value={radiusTRBL.borderBottomLeftRadius}
                              onChange={changeRadius}
                              onBlur={(e) => changeRadius(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                          <div>
                            <TextField
                              // element={TextField}
                              // debounceTimeout={500}
                              disabled={locked}
                              className="textFieldCustom txtfld"
                              name="borderBottomRightRadius"
                              value={radiusTRBL.borderBottomRightRadius}
                              onChange={changeRadius}
                              onBlur={(e) => changeRadius(e, "blur")}
                              size="small"
                              inputProps={{
                                style: {
                                  width: "20px",
                                  marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <hr className="hr" />
    </>
  );
}
