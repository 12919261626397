import React, { useContext } from "react";
import ValueContext from "../../ContextAPi/appContext";
import DropDownWithLabel from "../DropDownWithLabel.js";
import { useTranslation } from "react-i18next";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { TextField } from "@material-ui/core";
import { getValidInput } from "../../Uitls/Util";

function TextFieldInput({
  setGenericInfo,
  genericInfo,
  custom,
  assetType,
  canvasDimension,
  setCanvasDimension,
  setCanvasUpdated,
}) {
  //const appData = useContext(ValueContext);
  const { t } = useTranslation();

  const dimensions = ["Width", "Height"];
  const symbolsArr = ["e", "E", "+", "-", "."];

  const handleDimensions = (e, name, blur) => {
    let retVal = getValidInput(e, name + "Banner", blur);
    if (retVal === -1) return;

    let tempValue = {
      ...genericInfo,
      [e.target.name]: retVal,
    };
    setGenericInfo(tempValue);

    // if (blur !== "blur") return;
    if (setCanvasUpdated !== undefined) {
      if (blur === "blur") {
        setCanvasUpdated(true);
      } else {
        setCanvasUpdated(false);
      }
    }
    if (canvasDimension) {
      let tempgenricInfo = { ...canvasDimension };
      tempgenricInfo.width = tempValue?.Width;
      tempgenricInfo.height = tempValue?.Height;
      setCanvasDimension(tempgenricInfo);
    }
  };

  return (
    <>
      <div
        className={
          canvasDimension?.custom
            ? "height-widthcustomeditor heightWidthTemplate hWTemplate d-flex"
            : "d-flex heightWidthTemplate hWTemplate"
        }
      >
        {custom &&
          dimensions.map((dimensions, id) => {
            return (
              <div key={id} className=" d-flex">
                <p
                  className={
                    dimensions === "Height" ? "heightField d-flex" : "d-flex"
                  }
                >
                  {dimensions === "Width" ? "W" : "H"}
                </p>
                <TextField
                  // debounceTimeout={500}
                  // element={TextField}
                  // type="number"

                  className="standard-basic"
                  id={`${dimensions}Dimension`}
                  // onKeyDown={validateNumbers}
                  value={genericInfo[dimensions]}
                  onChange={(e) => handleDimensions(e, e.target.name)}
                  onKeyDown={(e) =>
                    symbolsArr.includes(e.key) && e.preventDefault()
                  }
                  autoComplete="off"
                  title={
                    `${dimensions}Dimension` === "WidthDimension"
                      ? "Width"
                      : "Height"
                    // assetType === "banner"
                    //   ? `${dimensions}Dimension` === "WidthDimension"
                    //     ? "WidthBanner"
                    //     : "HeightBanner"
                    //   : `${dimensions}Dimension` === "WidthDimension"
                    //   ? "WidthTemplate"
                    //   : "HeightTemplate"
                  }
                  // onPaste={(e) => preventPasteNegative(e)}
                  onBlur={(e) => handleDimensions(e, e.target.name, "blur")}
                  name={
                    `${dimensions}Dimension` === "WidthDimension"
                      ? "Width"
                      : "Height"
                  }
                  size="small"
                  inputProps={{
                    style: {
                      width: "59px",
                      position: "relative",
                      bottom: "6px",
                      height: "20px",
                      // marginTop: "-14px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                      color: "#928989",
                      textAlign: "center",
                    },
                  }}
                />
                {`${dimensions}Dimension` === "WidthDimension" ? (
                  <div className="xlabel">X</div>
                ) : (
                  ""
                )}
              </div>
            );
          })}
      </div>
    </>
  );
}

export default TextFieldInput;
