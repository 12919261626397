import React, { useState, useEffect } from "react";
import "../../styles/components/ToolBarLeftPanel.scss";
import { useTranslation } from "react-i18next";
import warning from "../../images/warning.svg";

import ClearText from "../../images/ClearText.svg";

import DragIcon from "../../images/drag_icon.svg";
import Lock from "../../images/LockWithCircleIcon.svg";
import UnLock from "../../images/UnLockWithCircleIcon.svg";
import hideIcon from "../../images/view--off.svg";
import { toast } from "react-toastify";
import EyeIcon from "../../images/view.svg";

import Tooltip from "@material-ui/core/Tooltip";
import "../../styles/components/AnimationTimeLine.scss";
// import { DebounceInput } from "react-debounce-input";
import { arrayMove } from "react-sortable-hoc";
// import Popover from "@material-ui/core/Popover";
// import Typography from "@material-ui/core/Typography";

import ButtonIcon from "../../images/ButtonNewIcon.svg";
import ContainerIcon from "../../images/container_icon.svg";
import ImageIcon from "../../images/ImageClicked.svg";
import Timage from "../../images/tTextIcon.svg";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

const Item = SortableElement(
  ({
    item,
    selec,
    toggle,
    toggleInput,
    indexValue,
    handleTextChange,
    setToggle,
    updateLayerNameToDefault,
    layerName,
    toggleLockComp,
    toggleHideComp,
    setSelectedComponent,
    toggleAnimatedBanner,
    setMultiSelectedComps,
  }) => {
    return (
      <div
        onClick={() => {
          setSelectedComponent(item);
          setMultiSelectedComps([]);
        }}
        key={item.id}
        id="addHeading"
        className={`addHeading1 mb-3 mr-3  row ${
          item.id === selec?.id && "addHeadingSelected"
        }`}
      >
        <div className="layer-dragImage1 col-md-1">
          {" "}
          <img
            src={DragIcon}
            alt="drag"
            draggable={false}
            className="img-fluid1"
          />
        </div>
        <div
          className="layer-textImage2 col-md-2" //draggable={false}
        >
          <img
            src={
              item.componentType === "Text"
                ? Timage
                : item.componentType === "Button"
                ? ButtonIcon
                : item.componentType === "Image"
                ? ImageIcon
                : ContainerIcon
            }
            alt="heading"
            draggable={false}
            className="img-fluid1 iconSize"
          />

          {/* <div className="layer-headline1 col-5"></div> */}
        </div>
        <Tooltip
          title={
            item?.componentStyles?.layerName?.length > 10
              ? item?.componentStyles?.layerName
              : ""
          }
          placement="top"
        >
          <div className="text-icon1 col-md-6">
            {toggle ? (
              <div
                className="ellipseHidden"
                onDoubleClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleInput(item.id);
                }}
              >
                {item?.componentStyles?.layerName ? (
                  item?.componentStyles?.layerName
                ) : (
                  <span className="ellipseHidden">{"Enter layer name"}</span>
                )}
              </div>
            ) : indexValue === item?.id ? (
              <input
                className="inputElem"
                // element="input"
                // debounceTimeout={500}
                style={{ width: "75px" }}
                type="text"
                value={layerName}
                onChange={(e) => {
                  handleTextChange(e, item.id);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setToggle(true);
                    updateLayerNameToDefault(e);
                  }
                }}
                onBlur={(e) => {
                  setToggle(true);
                  updateLayerNameToDefault(e);
                }}
                autoFocus
              />
            ) : (
              <div
                className="ellipseHidden"
                onDoubleClick={() => {
                  toggleInput(item.id);
                }}
              >
                {item?.componentStyles?.layerName}
                {/* {item?.componentStyles?.layerName ? (
                item?.componentStyles?.layerName
              ) : (
                <span>{t("Enter layer name")}</span>
              )} */}
              </div>
            )}
          </div>
        </Tooltip>
        <div className="lock-icon1 col-md-1">
          <img
            src={item?.componentStyles?.lockBoolean === false ? UnLock : Lock}
            alt="LockIcon"
            className=" img-fluid1 iconSize"
            onClick={(e) => {
              e.stopPropagation();
              item.id === selec.id && toggleLockComp(item);
            }}

            // draggable={false}
          />
        </div>
        <div className="eye-icon1 col-md-1">
          <img
            src={
              item?.componentStyles?.hideBoolean === false ? EyeIcon : hideIcon
            }
            alt="heading"
            className="img-fluid1 eyeIconSize"
            onClick={(e) => {
              e.stopPropagation();
              item.id === selec.id && toggleHideComp(item);
            }}
            // draggable={false}
          />
        </div>
      </div>
    );
  }
);

const ItemContainer = SortableContainer(
  ({
    items,
    selec,
    toggle,
    toggleInput,
    indexValue,
    handleTextChange,
    setToggle,
    updateLayerNameToDefault,
    layerName,
    toggleHideComp,
    toggleLockComp,
    setSelectedComponent,
    toggleAnimatedBanner,
    setMultiSelectedComps,
  }) => (
    <div>
      {items.map((item, index) => {
        return (
          <Item
            className="SortableList"
            key={`item-${item.id}`}
            item={item}
            index={index}
            selec={{ ...selec }}
            toggle={toggle}
            toggleInput={toggleInput}
            indexValue={indexValue}
            handleTextChange={handleTextChange}
            setToggle={setToggle}
            updateLayerNameToDefault={updateLayerNameToDefault}
            layerName={layerName}
            toggleHideComp={toggleHideComp}
            toggleLockComp={toggleLockComp}
            setSelectedComponent={setSelectedComponent}
            toggleAnimatedBanner={toggleAnimatedBanner}
            setMultiSelectedComps={setMultiSelectedComps}
          />
        );
      })}
    </div>
  )
);

const ExpandLayers = (props) => {
  const {
    handleCloseElement,
    layerCompData,
    setLayerCompData,
    selectedComponent,
    setSelectedComponent,
    toggleLockComp,
    toggleHideComp,
    toggleAnimatedBanner,
    playPause,
    setMultiSelectedComps,
  } = props;
  const { t } = useTranslation();
  const [toggle, setToggle] = useState(true);
  const [indexValue, setIndexValue] = useState(undefined);
  const chartCount = 13; //To Show layer tooltip only when it exceeds 13 characters

  const toggleInput = (index) => {
    setToggle(false);
    setIndexValue(index);
    setLayerName(selectedComponent.componentStyles.layerName);
  };
  const handleTextChange = (e, id) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(e.target.value)) return;
    setLayerName(e.target.value);
    // console.log(id, "id");
    // let tempSelected = [...layerCompData];

    // let x = tempSelected.findIndex((selComp) => selComp.id === id);
    // tempSelected[x].componentStyles.layerName = e.target.value;
    // setLayerCompData(tempSelected);

    // let tempSelected = { ...selectedComponent };

    // tempSelected.componentStyles.layerName = e.target.value;

    // setSelectedComponent(tempSelected);
  };
  const updateLayerNameToDefault = (e) => {
    let tempSelected = { ...selectedComponent };
    if (layerName === "" || layerName?.trim() === "") {
      tempSelected.componentStyles.layerName = selectedComponent.componentStyles
        .layerNameSaved
        ? selectedComponent.componentStyles.layerNameSaved
        : selectedComponent.componentStyles.layerNameUpdated;
      setLayerName(tempSelected.componentStyles.layerName);
    } else {
      tempSelected.componentStyles.layerName = e.target.value;
    }
    setSelectedComponent(tempSelected);
  };
  const [layerName, setLayerName] = useState(
    selectedComponent?.componentStyles?.layerName
  );

  const onReorder = ({ oldIndex, newIndex }) => {
    let swappedLayerCompData = arrayMove(layerCompData, oldIndex, newIndex);

    swappedLayerCompData.forEach((layerObj, index) => {
      swappedLayerCompData[index].componentStyles.zIndex =
        swappedLayerCompData.length - index;
    });
    setLayerCompData(swappedLayerCompData);
  };

  return (
    <>
      <div className="textWindow">
        <div className="textWindowHeader align-items-center d-flex justify-content-between">
          <div className=" titletextWindow d-flex">
            <span>{t("LAYERS")}</span>
            <img alt="" src={warning} className="img-fluid pl-2" />
          </div>

          <div className="pr-2">
            <img
              src={ClearText}
              alt=""
              className="clearTextIcon clearText"
              onClick={() => handleCloseElement("Layers")}
            />
          </div>
        </div>
        <div className="addTextscroll">
          {/* <div
            id="addHeading"
            className="addHeading mb-3 mr-3"
            style={{ pointerEvents: !playPause.animationCompleted && "none" }}
          >
            <span>{t("Heading")}</span>
          </div> */}
          <>
            <ItemContainer
              items={layerCompData}
              onSortEnd={onReorder}
              distance={1}
              selec={{ ...selectedComponent }}
              toggle={toggle}
              toggleInput={toggleInput}
              indexValue={indexValue}
              handleTextChange={handleTextChange}
              setToggle={setToggle}
              updateLayerNameToDefault={updateLayerNameToDefault}
              layerName={layerName}
              toggleHideComp={toggleHideComp}
              toggleLockComp={toggleLockComp}
              setSelectedComponent={setSelectedComponent}
              toggleAnimatedBanner={toggleAnimatedBanner}
              setMultiSelectedComps={setMultiSelectedComps}
            />
          </>
        </div>
      </div>
    </>
  );
};

export default ExpandLayers;
