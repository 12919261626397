import React, { useState, useEffect, useContext } from "react";
import TemplateLeftPanel from "./TemplateManagerLeftpanel";
import TemplateLandingPage from "./TemplateLandingPage";

import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import "../../styles/pages/TemplateManager.scss";
import "react-toastify/dist/ReactToastify.css";
import { template, banner } from "../../Uitls/constants";
import NextLogo from "../../images/NEXT-Banner.svg";
import BannerManager from "../Banners/BannerManager";
import ValueContext from "../../ContextAPi/appContext";

const LandingPage = (props) => {
  const appData = useContext(ValueContext);

  const [openCreateNewtemplate, setOpenCreateNewtemplate] = useState(false);
  const [openCloneTemplate, setOpenCloneTemplate] = useState(false);

  const [isloading, setIsloading] = useState(false);

  const [createTemplate, setCreateTemplate] = useState(true);
  // const [banner, setBanner] = useState(false);

  const [deleteId, setDeleteId] = useState(undefined);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [exportFormats, setExportFormats] = useState("");
  const [marketDataList, setmarketDataList] = useState([]);
  const [cardID, setCardID] = useState(null);

  const handleExportModal = (id) => {
    setCardID(id._id);
    setOpenExportModal(true);
  };
  const closeExportModal = () => {
    setOpenExportModal(false);
    setExportFormats("");
  };

  const toggleLandingPage = (page) => {
    appData.setBanner(page === "banner" ? true : false);
  };
  return (
    <>
      <div className="container-fluid templateLandingPage">
        <div className="row">
          <div className="col-md-2 tempManagrLeftPanel">
            <img src={NextLogo} alt="" className="nextLogoimage img-fluid" />
            <TemplateLeftPanel
              setBanner={appData.setBanner}
              banner={appData.banner}
              toggleLandingPage={toggleLandingPage}
              appData={appData}
            />
          </div>
          <div className="col-md-10 templateSearchResultBrdr">
            <TemplateLandingPage banner={appData.banner} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
