import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import "../../styles/components/Editor.scss";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import "../../styles/pages/TemplateManager.scss";
import DropDownWithLabelSizeTemplate from "../../components/DropdownWithLabelSizeTemplate.js";

import DropDownWithLabelTemplate from "../../components/DropDownWithLabelTemplate.js";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import TextFieldInputTemp from "../../components/CommonComponents/TextFieldInputTemp";

//import BannerSizeWithcustomTemp from "../BannerManager/BannerSizeWithcustomTemp";
import {
  getBrandsAndCampaigns,
  getNewBrandsAndcampaigns,
  getAssetdetails,
  getCognitoSession,
  getCognitoIndentityCredentials,
} from "../../services/apis";
import DropDownWithSearchTemplate from "../../components/DropDownWithSearchTemplate";
import { toast } from "react-toastify";
//Denovo related imports
//MArket Data returnlated imports and varaihle declaration
import {
  isoCountryTableName,
  awsRegion,
  nameValidation,
} from "../../Uitls/Util";
import errComponentToRender from "../../Uitls/apiErrorHandling";

import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
//import { PowerInputSharp } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CircularProgress } from "@material-ui/core";

import { templateSizes } from "../../Uitls/RightPanelToolBarItems";
// import nameValidation from "../../Uitls/Util";

var dynamo = require("dynamodb");

export default function CreateTemplateModal(props) {
  const history = useHistory();
  const { setShowNewTemplate, showNewTemplate, assetType } = props;
  const appData = useContext(ValueContext);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [preDefined, setPredefined] = useState(true);
  //  const [bannerNameInput,setBannerNameInput]=useState('');
  //   const [tags,setTags]=useState('');
  const [loader, setLoader] = useState(false);

  const [customTemp, setCustomTempSize] = React.useState(false);
  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    templateNameInput: "",
    //tagName: "",
    templateSize: "",
    Width: 0,
    Height: 0,
    templateType: "",
  });
  const closeCreateTempModal = (e) => {
    setShowNewTemplate(false);
    setLoader(false);
  };
  const handleFormReset = () => {
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
      templateNameInput: "",
      //tagName: "",
      templateSize: "",
      Width: 0,
      Height: 0,
      templateType: "",
    });
    setSelectedMarket("");
  };
  const { t } = useTranslation();
  React.useEffect(() => {
    setGenericInfo({
      ...genericInfo,
      campaign: genericInfo?.brand === "— Not Brand Specific —" ? "-" : "",
    });
  }, [genericInfo.brand]);

  React.useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
  }, []);

  /*   const handleGoToEditor = () => {
  
    openNewBannerModal(true);
  }; */
  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };

  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns]?.map((object) => {
      return object.brandName;
    });
  };
  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (brand) => {
    if (brand) {
      const campaigns = brandsAndCampaigns?.filter((object) => {
        return object.brandName === brand;
      })[0]?.campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns?.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };
  function validateForm() {
    return (
      genericInfo.templateNameInput &&
      genericInfo.templateNameInput.trim() !== "" &&
      genericInfo.brand?.length > 0 &&
      genericInfo.market?.length > 0 &&
      genericInfo.campaign?.length > 0 &&
      //genericInfo.tagName?.length > 0 &&
      genericInfo.templateType?.length > 0 &&
      (genericInfo.templateSize?.length > 0 ||
        (genericInfo.Width > 0 && genericInfo.Height > 0))
    );
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "templateNameInput") {
      if (e.target.value.length > 250) {
        toast.error(
          <div className="notificationText">
            {t("bannerManager.tempNameErr")}
          </div>,
          { autoClose: 1200, hideProgressBar: false }
        );
      }
    }

    setGenericInfo({ ...genericInfo, [name]: value });
    if (brandsAndCampaigns) {
      brandsAndCampaigns &&
        brandsAndCampaigns.map((brand) => {
          if (brand.brandName === value) {
            appData.setBrandId(brand.brandId);
          }
          brand.campaigns &&
            brand.campaigns.map((campaign) => {
              if (campaign.campaignName === value)
                appData.setCampaignId(campaign.campaignId);
            });
        });
    }
  };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleChangeInfoDataBlur = (e) => {
    if (
      e.target.value.length < 3 &&
      e.target.value.length !== 0 &&
      e.target.value.trim() !== ""
    ) {
      toast.error(
        <div className="notificationText">
          {t("bannerManager.tempNameMinErr")}
        </div>
      );
    }
  };

  const handleMarketChange = (market) => {
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({ ...genericInfo, market: market.name });
      appData.setMarketCode(market.code);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        market: market.name,
      });
    }
  };
  const handleGoToEditorScreen = (moduleInfo) => {
    if (!nameValidation(genericInfo.templateNameInput, "Template")) return;
    setLoader(true);
    appData.setActiveCards([]);

    var tempSizeRes = genericInfo.templateSize.split("X");
    var moduleInfo = {};
    moduleInfo.assetType = "TEMPLATE";
    moduleInfo.brandName = genericInfo.brand;
    moduleInfo.assetName = genericInfo.templateNameInput.trim();
    moduleInfo.campaign = genericInfo.campaign; //=== "" && "-";
    // genericInfo.brand === "— Not Brand Specific —"
    //   ? ""
    //   : genericInfo.campaign;
    moduleInfo.campaignId = appData?.campaignId; //=== "" && "-";
    moduleInfo.brandId = appData?.brandId;
    moduleInfo.marketCode = appData?.marketCode;
    moduleInfo.marketName = genericInfo.market;
    // moduleInfo.tagName = genericInfo.tagName;
    moduleInfo.width = preDefined ? tempSizeRes[0] : genericInfo.Width;
    moduleInfo.height = preDefined ? tempSizeRes[1] : genericInfo.Height;
    moduleInfo.comments = "";
    moduleInfo.description = "";
    moduleInfo.modules = [];
    //moduleInfo.customTemp = customTemp;
    moduleInfo.attributes = {
      customTemp: customTemp === true ? true : false,
    };
    moduleInfo.isAnimated =
      genericInfo.templateType === "Animated " ? true : false;
    appData.setCustomTempSize(customTemp === true ? true : false);
    // console.log("moduleInfo", moduleInfo);
    getAssetdetails(moduleInfo, (response, err, errorCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        if (err.response?.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS") {
          toast.error(
            <div className="notificationText">
              {t("apiErrors.errTempExist")}
            </div>
          );
        } else {
          toast.error(
            <div className="notificationText">{t("apiErrors.err500")}</div>
          );
        }
        setLoader(false);
      } /* else if (response?.data?.assetName.length > 255) {
              toast.error(
                <div className="notificationText">
                  {t("bannerManager.bnrNameErr")}
                </div>
              );
            }  */ else {
        setGenericInfo(response.data);
        // appData.updateGenericInfo(genericInfo);
        appData.updateGenericInfo({
          ...appData?.genericInfo,
          ...genericInfo,
        });

        appData.setAssetInfo({
          assetId: response.data.assetId,
          assetName: genericInfo.templateNameInput,
        });

        window.localStorage.setItem("assetId", response.data.assetId);
        //redirect
        history.push(`/banner-editor/${response?.data?.assetId}`);
        setLoader(false);
      }
    });
  };

  const templateType = ["Static ", "Animated "];
  // const [age, setAge] = React.useState("");

  // const handleChangee = (event) => {
  //   setAge(event.target.value);
  // };
  const handleChangeSizes = (e, id) => {
    //setPredefined(e.target.checked);

    let name = e.target.name;
    if (name === "preDefined") {
      setCustomTempSize(false);
      setPredefined(true);
      setGenericInfo({
        ...genericInfo,
        //tagName: "",
        Width: 0,
        Height: 0,
      });
    } else {
      setCustomTempSize(true);
      setPredefined(false);
      setGenericInfo({
        ...genericInfo,
        //tagName: "",
        templateSize: "",
      });
    }
  };
  const [age, setAge] = React.useState("");

  const handleChangeinput = (event) => {
    setAge(event.target.value);
  };
  const dropdownWidth = useRef(null);

  return (
    <>
      <Dialog
        disableBackdropClick
        open={showNewTemplate}
        onClose={closeCreateTempModal}
        className="newBannerModal tempBannerModal"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        {loader && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <DialogTitle id="form-dialog-title">
          <div
            className="baseOnModalHeading"
            style={{ fontFamily: "'Nunito Sans', sans-serif" }}
          >
            {t("Create New Template")}
          </div>
        </DialogTitle>
        <DialogContent className=" tempContent customTempScrollBar ">
          <div className="row newbannerDetails" ref={dropdownWidth}>
            <TextField
              id="standard-basic"
              variant="standard"
              label={t("Template Name")}
              name="templateNameInput"
              className="bannerNameTextfield templateNameTextfield"
              fullWidth={true}
              value={genericInfo.templateNameInput}
              onChange={handleChange}
              // onBlur={(e) => handleChangeInfoDataBlur(e)}
              autoComplete="off"
            />
            <div className="newInputFieldTemp">
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.brand, //t("uploadAssetLabel.brand"),
                  name: "brand",
                  label: t("bannerManager.brandName"),
                  items: getBrandsToPopulate(),
                  handleChange: handleChange,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>
            <DropDownWithSearchTemplate
              options={{
                value: selectedMarket,
                name: "market",
                label: t("bannerManager.market"),
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              menuWidth={
                dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                undefined
              }
              className="custonScrollBar"
            />
            <div className="newInputFieldTemp">
              <DropDownWithLabelTemplate
                options={{
                  value:
                    genericInfo?.brand === "— Not Brand Specific —"
                      ? "-"
                      : genericInfo.campaign || "",
                  name: "campaign",
                  label: t("bannerManager.campaign"),
                  items:
                    // genericInfo?.brand === "— Not Brand Specific —"
                    //   ? ["-"]
                    //   :
                    getCampaignListToPopulate(genericInfo.brand),
                  handleChange: handleChange,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>
            <div className="tempSizes">Template Size</div>

            <FormControl className="tempradioButns">
              <RadioGroup
                aria-label="rvcv"
                name="radio-buttons-group"
                className="rvcvRadiobuttonChecked rvcvMultipleradio"
                row
              >
                <FormControlLabel
                  name="preDefined"
                  control={
                    <Radio
                      checked={preDefined}
                      onChange={(e) => {
                        handleChangeSizes(e);
                      }}
                    />
                  }
                  label="Pre Defined"
                  className="tempSizeText"
                />
                <FormControlLabel
                  name="customTemp"
                  control={
                    <Radio
                      checked={customTemp}
                      onChange={(e) => {
                        handleChangeSizes(e);
                      }}
                    />
                  }
                  label="Custom"
                  className="tempSizeText"
                />
              </RadioGroup>
            </FormControl>
            <div className="newSizeInputFieldTemp chooseSize newInputFieldTemp">
              {preDefined && (
                <DropDownWithLabelSizeTemplate
                  //style={{ marginTop: "30px !important" }}
                  options={{
                    value: genericInfo.templateSize || "",
                    name: "templateSize",
                    label: t("Choose Size"),
                    items: templateSizes,
                    handleChange: handleChange,
                    className: "testBannersize",
                  }}
                />
              )}
            </div>
            {customTemp && (
              <TextFieldInputTemp
                assetType={assetType}
                custom={customTemp}
                setCustomSize={setCustomTempSize}
                //  handleDimensions={handleDimensions}
                genericInfo={genericInfo}
                setGenericInfo={setGenericInfo}
                templateSizes={templateSizes}
                handleChange={handleChange}
              />
            )}

            <div className="newInputFieldTemp">
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.templateType || "",
                  name: "templateType",
                  label: t("Template Type"),
                  items: templateType,
                  handleChange: handleChange,
                  className: "testBannersize",
                }}
              />
            </div>
          </div>{" "}
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={() => {
              closeCreateTempModal();
              handleFormReset();
            }}
            color="primary"
            className="cancelBtntemp"
          >
            {t("bannerManager.cancel")}
          </Button>
          <Button
            onClick={handleGoToEditorScreen}
            type="submit"
            className="btn btn-primary"
            style={{ fontFamily: "'Nunito Sans', sans-serif" }}
            disabled={!validateForm()}
            // disabled={false}
            autoFocus
          >
            {t("bannerManager.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
