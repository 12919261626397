import React, { useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import "../../styles/components/Editor.scss";
import "../../styles/components/EditorModal.scss";
import { CircularProgress } from "@material-ui/core";

import ErrComponentToRender from "../../Uitls/apiErrorHandling";
//import DropDownWithLabel from "../DropDownWithLabel.js";
import DropDownWithLabelTemplate from "../DropDownWithLabelTemplate.js";
import DropDownWithLabelSizeTemplate from "../DropdownWithLabelSizeTemplate.js";

import DropDownWithSearchTemplate from "../DropDownWithSearchTemplate.js";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import BannerSizeWithCustom from "../BannerManager/BannerSizeWithCustom";
import TextFieldInputTemp from "../CommonComponents/TextFieldInputTemp";

import {
  getBrandsAndCampaigns,
  getNewBrandsAndcampaigns,
  getAssetdetails,
  getCognitoSession,
  getCognitoIndentityCredentials,
} from "../../services/apis";
import DropDownWithSearch from "../../Uitls/DropDownWithSearch";
import { toast } from "react-toastify";
//Denovo related imports
//MArket Data returnlated imports and varaihle declaration
import {
  isoCountryTableName,
  awsRegion,
  nameValidation,
} from "../../Uitls/Util";
import errComponentToRender from "../../Uitls/apiErrorHandling";

import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
//import { PowerInputSharp } from "@material-ui/icons";

import { bannerSizes } from "../../Uitls/RightPanelToolBarItems";
var dynamo = require("dynamodb");

export default function NewBannerModal(props) {
  const history = useHistory();
  const {
    openNewBannerModal,
    showNewBanner,
    setShowNewBanner,
    assetType,
    preDefined,
    setPredefined,
    customTemp,
    setCustomBannerSize,
  } = props;
  const appData = useContext(ValueContext);
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  //  const [bannerNameInput,setBannerNameInput]=useState('');
  //   const [tags,setTags]=useState('');
  const [loader, setLoader] = useState(false);

  const [custom, setCustomSize] = React.useState(false);
  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    bannerNameInput: "",
    //tagName: "",
    bannerSize: "",
    Width: 0,
    Height: 0,
    bannerType: "",
  });
  const closeNewBannerModal = (e) => {
    setShowNewBanner(false);
    setLoader(false);
  };
  const handleFormReset = () => {
    setGenericInfo({
      brand: "",
      campaign: "",
      market: "",
      bannerNameInput: "",
      //  tagName: "",
      bannerSize: "",
      bannerType: "",
      width: 0,
      height: 0,
    });
    setSelectedMarket("");
  };
  const { t } = useTranslation();
  React.useEffect(() => {
    setGenericInfo({ ...genericInfo, campaign: "" });
  }, [genericInfo.brand]);

  React.useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
  }, []);

  /*   const handleGoToEditor = () => {
  
    openNewBannerModal(true);
  }; */
  //Fetch Brands and campaigns
  const fetchBrandsAndCampaigns = () => {
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          if (appData?.banner) {
            data.shift();
            setBrandsAndCampaigns(data);
          } else {
            setBrandsAndCampaigns(data);
          }
        }
      }
    });
  };

  const getBrandsToPopulate = () => {
    if (!brandsAndCampaigns || brandsAndCampaigns.length <= 0) {
      return [];
    }
    return [...brandsAndCampaigns].map((object) => {
      return object.brandName;
    });
  };
  //Returns Campaign list to populate in Drop down
  const getCampaignListToPopulate = (Brand) => {
    if (Brand) {
      const campaigns = brandsAndCampaigns.filter((object) => {
        return object.brandName === Brand;
      })[0].campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }
    return [];
  };
  function validateForm() {
    return (
      genericInfo.bannerNameInput &&
      genericInfo.bannerNameInput.trim() !== "" &&
      genericInfo.brand?.length > 0 &&
      genericInfo.market?.length > 0 &&
      genericInfo.campaign?.length > 0 &&
      //genericInfo.tagName?.length > 0 &&
      genericInfo.bannerType?.length > 0 &&
      (genericInfo.bannerSize?.length > 0 ||
        (genericInfo.Width > 0 && genericInfo.Height > 0))
    );
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGenericInfo({ ...genericInfo, [name]: value });
    if (name === "bannerNameInput") {
      if (e.target.value.length > 255) {
        toast.error(
          <div className="notificationText">
            {t("bannerManager.bnrNameErr")}
          </div>,
          { autoClose: 1200, hideProgressBar: false }
        );
      }
    }
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          appData.setBrandId(brand.brandId);
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            appData.setCampaignId(campaign.campaignId);
        });
      });
    }
  };

  // const fetchMarketData = () => {
  //   updateDynamicDBConfig(dynamo);
  //   var params = {
  //     TableName: isoCountryTableName,
  //   };
  //   getDyanamoDBInstance(dynamo).scan(params, (error, data) => {
  //     if (data) {
  //       let marketData = data.Items;
  //       if (marketData && marketData.length > 0) {
  //         marketData = [...marketData].map((market) => {
  //           let object = {
  //             code: market.countryCode["S"], //Object.values(market.countryCode)[0],
  //             shortName: market.ShortName["S"], //market.ShortName)[0],
  //             name: market.Name["S"],
  //           };
  //           object["displayName"] = `${object.code} - ${object.name}`;
  //           return object;
  //         });
  //         if (marketData && marketData.length > 0) {
  //           const sortCountryCode = marketData.sort(function (a, b) {
  //             if (a.code.toLowerCase() < b.code.toLowerCase()) {
  //               return -1;
  //             }
  //             if (a.code.toLowerCase() > b.code.toLowerCase()) {
  //               return 1;
  //             }
  //             return 0;
  //           });
  //           setMarketDataSource(sortCountryCode);
  //         }
  //       }
  //     }
  //   });
  // };

  const fetchMarketData = () => {
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleChangeInfoDataBlur = (e) => {
    if (
      e.target.value.length < 3 &&
      e.target.value.length !== 0 &&
      e.target.value.trim() !== ""
    ) {
      toast.error(
        <div className="notificationText">
          {t("bannerManager.bnrNameMinErr")}
        </div>
      );
    }
  };

  const handleMarketChange = (market) => {
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({ ...genericInfo, market: market.name });
      appData.setMarketCode(market.code);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        market: market.name,
      });
    }
  };
  const handleGoToEditorScreen = (moduleInfo) => {
    if (!nameValidation(genericInfo.bannerNameInput, "Banner")) return;

    setLoader(true);
    appData.setActiveCards([]);
    var bannerSizeRes = genericInfo.bannerSize.split("X");
    var moduleInfo = {};
    moduleInfo.assetType = "BANNER";
    moduleInfo.brandName = genericInfo.brand;
    moduleInfo.assetName = genericInfo.bannerNameInput.trim();
    moduleInfo.campaign = genericInfo.campaign;
    moduleInfo.campaignId = appData?.campaignId;
    moduleInfo.brandId = appData?.brandId;
    moduleInfo.marketCode = appData?.marketCode;
    moduleInfo.marketName = genericInfo.market;
    // moduleInfo.tagName = genericInfo.tagName;
    moduleInfo.width = bannerSizeRes[0] || genericInfo.Width;
    moduleInfo.height = bannerSizeRes[1] || genericInfo.Height;
    moduleInfo.comments = "";
    moduleInfo.description = "";
    moduleInfo.modules = [];
    moduleInfo.attributes = {
      custom: custom,
    };
    moduleInfo.attributes = {
      customTemp: customTemp === true ? true : false,
    };

    moduleInfo.isAnimated =
      genericInfo.bannerType === "Animated Banner" ? true : false;
    appData.setCustomTempSize(customTemp === true ? true : false);
    getAssetdetails(moduleInfo, (response, err, errorCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errorCode);
        if (err.response?.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS") {
          toast.error(
            <div className="notificationText">
              {t("apiErrors.errBannerExist")}
            </div>
          );
        } else {
          toast.error(
            <div className="notificationText">{t("apiErrors.err500")}</div>
          );
        }
        setLoader(false);
      } /* else if (response?.data?.assetName.length > 255) {
              toast.error(
                <div className="notificationText">
                  {t("bannerManager.bnrNameErr")}
                </div>
              );
            }  */ else {
        setGenericInfo(response.data);
        // appData.updateGenericInfo(genericInfo);
        appData.updateGenericInfo({
          ...appData?.genericInfo,
          ...genericInfo,
        });

        appData.setAssetInfo({
          assetId: response.data.assetId,
          assetName: genericInfo.bannerNameInput,
        });

        window.localStorage.setItem("assetId", response.data.assetId);
        //redirect
        appData.setVeevaModulesLinked(false);
        history.push(`/banner-editor/${response?.data?.assetId}`);
        //history.push("/banner-editor");
        setLoader(false);
      }
    });
  };

  const bannerType = ["Static Banner", "Animated Banner"];
  const handleChangeSizes = (e, id) => {
    //setPredefined(e.target.checked);

    let name = e.target.name;
    if (name === "preDefined") {
      setCustomBannerSize(false);
      setPredefined(true);
      setGenericInfo({
        ...genericInfo,
        //tagName: "",
        Width: 0,
        Height: 0,
      });
    } else {
      setCustomBannerSize(true);
      setPredefined(false);
      setGenericInfo({
        ...genericInfo,
        //tagName: "",
        bannerSize: "",
      });
    }
  };
  const dropdownWidth = useRef(null);

  return (
    <>
      <Dialog
        disableBackdropClick
        open={showNewBanner}
        onClose={closeNewBannerModal}
        className="newBannerModal tempBannerModal"
        aria-labelledby="form-dialog-title"
        overlaystyle={{ backgroundColor: "transparent" }}
      >
        {loader && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <DialogTitle id="form-dialog-title">
          <div className="baseOnModalHeading">{t("Create New Banner")}</div>
        </DialogTitle>
        <DialogContent className=" tempContent customTempScrollBar ">
          <div className="row newbannerDetails">
            <TextField
              variant="standard"
              label={t("bannerManager.bannerName")}
              name="bannerNameInput"
              className="bannerNameTextfield templateNameTextfield"
              fullWidth={true}
              value={genericInfo.bannerNameInput}
              onChange={handleChange}
              // onBlur={handleChangeInfoDataBlur}
              autoComplete="off"
            ></TextField>

            <div className="newInputFieldTemp" ref={dropdownWidth}>
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.brand, //t("uploadAssetLabel.brand"),
                  name: "brand",
                  label: t("bannerManager.brandName"),
                  items: getBrandsToPopulate(),
                  handleChange: handleChange,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>

            <DropDownWithSearchTemplate
              options={{
                value: selectedMarket,
                name: "market",
                label: t("bannerManager.market"),
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              className="custonScrollBar"
            />
            {/* <DropDownWithSearch
              options={{
                value: selectedMarket,
                name: "market",
                label: t("bannerManager.market"),
                items: marketDataSource,
                handleChange: handleMarketChange,
              }}
              className="custonScrollBar"
            /> */}
            <div className="newInputFieldTemp marketbanner">
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.campaign || "",
                  name: "campaign",
                  label: t("bannerManager.campaign"),
                  items: getCampaignListToPopulate(genericInfo.brand),
                  handleChange: handleChange,
                }}
                menuWidth={
                  dropdownWidth.current?.getBoundingClientRect().width * 0.95 ||
                  undefined
                }
              />
            </div>

            {/* <BannerSizeWithCustom
              bannerSize="bannerSize"
              handleChange={handleChange}
              bannerSizes={bannerSizes}
              genericInfo={genericInfo}
              setGenericInfo={setGenericInfo}
              custom={custom}
              setCustomSize={setCustomSize}
            />
            */}
            <div className="tempSizes">Banner Size</div>

            <FormControl className="tempradioButns newbannerRadio">
              <RadioGroup
                aria-label="rvcv"
                name="radio-buttons-group"
                className="rvcvRadiobuttonChecked rvcvMultipleradio"
                row
              >
                <FormControlLabel
                  name="preDefined"
                  control={
                    <Radio
                      checked={preDefined}
                      onChange={(e) => {
                        handleChangeSizes(e);
                      }}
                    />
                  }
                  label="Pre Defined"
                  className="tempSizeText"
                />
                <FormControlLabel
                  name="customTemp"
                  control={
                    <Radio
                      checked={customTemp}
                      onChange={(e) => {
                        handleChangeSizes(e);
                      }}
                    />
                  }
                  label="Custom"
                  className="tempSizeText"
                />
              </RadioGroup>
            </FormControl>
            <div className="newSizeInputFieldTemp chooseSize newInputFieldTemp">
              {preDefined && (
                <DropDownWithLabelSizeTemplate
                  //style={{ marginTop: "30px !important" }}
                  options={{
                    value: genericInfo.bannerSize || "",
                    name: "bannerSize",
                    label: t("Choose Size"),
                    items: bannerSizes,
                    handleChange: handleChange,
                    className: "testBannersize",
                  }}
                />
              )}
            </div>
            {customTemp && (
              <TextFieldInputTemp
                custom={customTemp}
                setCustomSize={setCustomBannerSize}
                //  handleDimensions={handleDimensions}
                genericInfo={genericInfo}
                setGenericInfo={setGenericInfo}
                //   templateSizes={templateSizes}
                handleChange={handleChange}
                assetType={assetType}
              />
            )}
            <div className="newInputFieldTemp bannerType">
              <DropDownWithLabelTemplate
                options={{
                  value: genericInfo.bannerType || "",
                  name: "bannerType",
                  label: t("bannerManager.bannerType"),
                  items: bannerType,
                  handleChange: handleChange,
                  className: "testBannersize",
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="modalActions">
          <Button
            onClick={() => {
              closeNewBannerModal();
              handleFormReset();
            }}
            color="primary"
            className="cancelBtnbnr"
            style={{ right: "213px !important" }}
          >
            {t("bannerManager.cancel")}
          </Button>
          <Button
            onClick={handleGoToEditorScreen}
            type="submit"
            className="btn btn-primary"
            disabled={!validateForm()}
            autoFocus
          >
            {t("bannerManager.proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
