import React, { useState, useRef, useEffect, useContext } from "react";
import EditorHeader from "../components/Editor/EditorHeader";
import EditorHeaderForTemplate from "../components/Editor/EditorHeaderForTemplate";
import { useHistory } from "react-router-dom";

import Drawer from "../components/Dashboard/Drawer";
import { Row, Col } from "react-bootstrap";
import LeftPanel from "../components/ToolbarLeftPanel/ToolbarLeftPanel";
import "../styles/pages/EditorMainPage.scss";
import FroalaEditor from "../Uitls/CommonFroalaEditor";
import RightPanel from "../components/RightPanel/RightPanel";
import AnimationTimeLine from "../components/AnimationTimeLinePanel/AnimationTimeLine";
import PasteContextMenuZindex from "../components/PasteContextmenu";
import TimelineBlue from "../images/LeftPanelIcons/Layerblue.svg";
import nounLayers from "../images/nounLayers.svg";
import nounLayersBlack from "../images/Layergrey.svg";
import MoveIcon from "../images/MoveIcon.svg";
import ValueContext from "../ContextAPi/appContext";
import ContextMenuZindex from "../components/zIndexContextMenu";
import { Rnd } from "react-rnd";
import grayFwdArrow from "../images/Group_10891.svg";
import { Scrollbars } from "rc-scrollbars";
import { arrayMove } from "react-sortable-hoc";
import { TestSidesTouching } from "./GridSnapping";
import { Prompt, useParams } from "react-router-dom";
import GuidLines from "./GuidLines";
import parse from "html-react-parser";
import infoIcon from "../images/infoIconrightpanel.svg";
import infoIconActive from "../images/CSBannerinfo2.svg";
import propsIcon from "../images/CSBannerProperties1.svg";
import propsIconActive from "../images/CSBannerProperties2.svg";
import { Redirect } from "react-router-dom";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

import {
  renderAppliedFonts,
  renderTheFontLink,
  getRelativeCoordinates,
} from "./RenderFonts";

import {
  sendSaveModuleIdHistory,
  getSaveAssets,
  searchModules,
  getVeevaCardsInleftcont,
  cardInfoForcardIds,
  getCoreClaimCategoriesforVeeva,
} from "../services/apis";
import ErrComponentToRender from "../Uitls/apiErrorHandling";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import {
  IMAGE,
  BUTTON,
  BLUEBUTTONWITHRAD,
  WHITEBUTTON,
  WHITEBUTTONWITHRAD,
  BUTTONWITHOUTBORDER,
  ADDHEADING,
  ADDSUBHEADING,
  ADDBODY,
  RECTANGLE,
  CIRCLE,
  TRIANGLE,
  POLYGON,
  HEXAGON,
  froalaFontStyles,
  froalaFontAlignments,
  froalaNumberList,
  addHeading,
  addSubHeading,
  addBody,
  button,
  rectangleShape,
  circleShape,
  triangleShape,
  polygonShape,
  hexagonShape,
  image,
  selectionDiv,
  objSides,
  BlueButtonWithRad,
  WhiteButton,
  WhiteButtonWithRad,
  ButtonWithoutBorder,
} from "../Uitls/ComponentType";

import { useDisplaycolorpicker } from "../components/RightPanel/DisplaycolorPicker.js";
// import { random } from "gsap/all";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";
import { keyPressAction } from "./KeyCodeEvents";

// import useExitPrompt from "../Uitls/useExitPrompt";
import {
  drawDiv,
  mousedrag,
  overLap,
  closeSelec,
  pasteMultiSelectedComps,
  deleteMultiSelectedComps,
} from "./multiCompSelect";

import { initBeforeUnLoad } from "./ExitPrompt/UseExitPropmpt";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
let noContextMenu = "";
let domCanvasPage = "";
const EditorMainPage = (props) => {
  let { bannerId } = useParams();

  //2776-canvasundo
  // const [disableDragger, setDisableDragger] = useState(true);
  const [isInfoPanel, setIsInfoPanel] = useState(false);
  const [froalatoolbarTag, setFroalatoolbarTag] = useState(true);
  const [popUpLeft, SetPopupLeft] = React.useState(-140);
  const [canvasUpdated, setCanvasUpdated] = useState(false);
  const [previousCanvasDimension, setPreviousCanvasDimension] = useState({});

  const [addedActionsList, setAddedActionsList] = useState([
    { layerCompData: [] },
  ]);

  const [addedAnmationActionList, setAddedAnmationActionList] = useState([
    { animationObject: {} },
  ]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [selectedPreviousId, setSelectedPreviousId] = useState("");
  const [listForReDo, setListForReDo] = useState([]);
  const [undoFlag, setundoFlag] = useState(true);
  const [undoCount, setUndoCount] = useState(0);
  const [openTimelinePopup, setOpenTimelinePopup] = useState({
    popupWindow: false,
    fromUnRedo: false,
  }); // tto popup display on each undo
  const [undoAnimationObjFlag, setUndoAnimationObjFlag] = useState(true);
  // const [redoFlag, setRedoFlag] = useState(false);
  const [refreshed, setRefreshed] = useState(false);
  const [layerLengthAtRefresh, setLayerLengthAtRefresh] = useState([]);
  const [refreshCompleted, setRefreshCompleted] = useState(0);
  const [animationObjAfterRefresh, setAnimationObjAfterRefresh] = useState({});
  const [MLRstatus, setMLRstatus] = useState("Not Submitted");

  // const [actionFlags, setActionFlags] = useState({
  //   layerFlag: false,
  //   animationFlag: false,
  // });
  const [tempLayerCompData, setTempLayerCompData] = useState([]);
  const [tempAnimationObject, setTempAnimationObject] = useState({});
  const [animationChanged, setAnimationChanged] = useState(false);
  const [popUpValuesEdited, setPopUpValuesEdited] = useState(false); //to hande undo(line  375 in popupwindowjs)
  const [damsID, setDamsId] = useState("_");
  const [marker, setMarker] = React.useState(0);
  //MultiSelect
  const [multiSelect, setMultiSelect] = useState(selectionDiv);
  const [multiSelectedComps, setMultiSelectedComps] = useState([]);
  const refMultiselected = useRef(multiSelectedComps);
  let showSelection = false;
  let Xstart;
  let Ystart;

  //const [drawXYValue, setDrawXYValue] = useState({ x: 0, y: 0 });
  //IMC-2776

  // const [googleFontsLink, setGoogleFontsLink] = useState("");

  //IMC3241
  const appData = useContext(ValueContext);

  const [bannerChanged, setBannerChanged] = useState(false);
  const [veevaModuleCardData, setVeevaModuleCardData] = useState([]);

  const [claimCatArray, setClaimCatArray] = useState([]);

  // const handlesetveevamoduleCard = (resp) => {
  //   setVeevaModuleCardData(resp);
  // };
  const refBannerChanged = useRef(bannerChanged);

  // window.onload = function () {
  //   initBeforeUnLoad(
  //     bannerChanged,
  //     appData?.genericInfo?.rightUpdaed,
  //     refBannerChanged.current
  //   );
  // };
  const history = useHistory();
  const justMultiSelcted = (oldValue, newValue) => {
    let returnval = false;
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      oldValue.forEach((value, index) => {
        if (
          (value?.multiSelected === true &&
            newValue[index]?.multiSelected === false) ||
          (value?.multiSelected === false &&
            newValue[index]?.multiSelected === true)
        )
          returnval = true;
        else returnval = false;
      });
    }
    return returnval;
  };

  // useEffect(() => {
  //   setBannerChanged(refBannerChanged.current);
  // }, [refBannerChanged.current]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  let summery = { addded: [], removed: [] };
  const send = debounce(() => {
    // console.log(
    //   `sending mutationsto UndoArray :  ${JSON.stringify({
    //     added: summery.addded[summery.addded.length - 1],
    //     removed: summery.removed[summery.removed.length - 1],
    //   })}`
    // );
    // console.log(
    //   attributeRecord[attributeRecord.length - 1],
    //   "sending mutationsto UndoArray attributeRecord"
    // );
  }, 200);
  let attributeRecord = [];
  const log = (mutations) => {
    for (let mutation of mutations) {
      if (mutation.type === "attributes") {
        // ;

        let oldValue = mutation.oldValue;
        let newValue = mutation.target.getAttribute(mutation.attributeName);
        oldValue = oldValue.startsWith("[") ? JSON.parse(oldValue) : oldValue;
        newValue = newValue.startsWith("[") ? JSON.parse(newValue) : newValue;

        console.log("oldValue:", oldValue);
        console.log("oldValueNew:", newValue);

        if (
          JSON.stringify(oldValue) === JSON.stringify(newValue) ||
          (newValue.includes("rgba") && !oldValue.includes("rgba")) ||
          (oldValue.length === 0 && newValue.length !== 0) ||
          justMultiSelcted(oldValue, newValue) === true
          // ||
          // refBannerChanged.current === false
        ) {
          //think here        ) {
          console.log("oldValue BannernotChanged");
          setBannerChanged(false);
          refBannerChanged.current = false;
          setLayerChangedOnPause(false);
        } else {
          console.log("oldValue bannerChanged");
          setBannerChanged(true);
          setLayerChangedOnPause(true);
          refBannerChanged.current = true;
          attributeRecord.push({
            oldValue: oldValue,
            newValue: newValue,
          });
        }
      }
      // console.log("mutations", mutation);
      for (let node of mutation.addedNodes) {
        if (!(node instanceof HTMLElement)) continue;
        summery = {
          ...summery,
          addded: [summery.addded[summery.addded.length - 1], node.outerHTML],
        };
      }
      for (let node of mutation.removedNodes) {
        if (!(node instanceof HTMLElement)) continue;
        summery = {
          ...summery,
          removed: [
            summery.removed[summery.removed.length - 1],
            node.outerHTML,
          ],
        };
      }
    }
    send();
  };
  const observeMutations = () => {
    let observer = new MutationObserver(log);
    observer.observe(contextMenuRef.current, {
      attributes: true,
      childList: true,
      attributeOldValue: true,
      // subtree: true,
      // attributeFilter: ["layerCompData"],
    });
  };
  useEffect(() => {
    // ;
    observeMutations();
  }, []);
  // useEffect(() => {
  //  setBannerChanged(appData?.genericInfo?.rightUpdaed);
  // }, [appData?.genericInfo?.rightUpdaed]);

  useEffect(() => {
    console.log("useEffect BannerChanged called");
    // setTimeout(() => {
    // initBeforeUnLoad(
    //   bannerChanged,
    //   appData?.genericInfo?.rightUpdaed,
    //   refBannerChanged.current
    // );
    // }, 100);
  }, [bannerChanged, refBannerChanged.current]);

  // const [bannerChanged, setBannerChanged] = useExitPrompt(
  //   false,
  //   appData?.genericInfo?.rightUpdaed,
  //   refBannerChanged.current
  // );
  // useEffect(() => {
  //   let url = window.location.origin;
  //   const editorLink = `${url}/banner-editor/${bannerId}`;

  //   if (url !== `${url}/banner-editor/${bannerId}`) {
  //     toast.error(
  //       <div className="notificationText">{t("Entered URL not found")}</div>
  //     );
  //   }
  // }, []);

  // const [bannerChanged, setBannerChanged] = useState(false);
  const [layerChangedOnPause, setLayerChangedOnPause] = useState(true);

  const contextMenuRef = useRef([]);
  const [bannerData, setBannerData] = useState([]);
  const [openTimeLine, setOpenTimeLine] = useState(false);
  const [getAtom, setAtom] = React.useState({
    eventDetails: "",
    type: "",
    data: "",
  });
  const { t } = useTranslation();
  const { refButtonColor, displayColorPicker, setDisplayColorPicker } =
    useDisplaycolorpicker(false);
  const [openTextWindow, setOpenTextWindow] = useState(false);
  const [openShapesWindow, setOpenShapesWindow] = useState(false);
  const [openModuleWindow, setOpenModuleWindow] = useState(false);
  const [openImageWindow, setOpenImageWindow] = useState(false);
  const [layerCompData, setLayerCompData] = useState([]);
  const tempLayer = React.useRef(layerCompData);

  const [canvasDimension, setCanvasDimension] = useState({
    width: 300,
    height: 250,
    scale: 1,
    zoomValue: 100,
    fps: 60,
    backgroundColor: "rgba(255,255,255, 0.9)",
    globalLink: "https://",
    checkGloballink: false,
    checkLoops: false,
    loopAnimation: 0,
    custom: false,
    customTemp: false,
  });
  const [layerId, setLayerId] = React.useState({
    eventDetails: "",
    type: "",
  });

  const [layerIndex, setLayerIndex] = useState(undefined);
  const [animationObject, setAnimationObject] = React.useState({});
  const animationObjRef = useRef(animationObject);
  const [dimensionsData, setDimensionsData] = useState({
    dimensions: {
      dimension: ["W", "H"],
      positions: ["X", "Y"],
      width: "true",
      height: "true",
      xPosition: "true",
      yPosition: "true",
    },
  });
  const [containerID, setContainerID] = useState(undefined);
  const [currentIdZindex, setCurrentIdZindex] = useState(undefined);
  //const [backgroundColor, setBackgroundColor] = useState("#fffff");
  const createFroalaInstance = useRef([]);
  const [froalaIndex, setFroalaIndex] = useState(undefined);
  const [selectedComponent, setSelectedComponent] = useState({});
  const [count, setCount] = useState(0);
  const compCountRef = useRef(count);
  const [isVisible, setVisibility] = useState(false);
  const [showPaste, setShowPaste] = useState(false);
  const [toggleSelected, setToggleSelected] = useState(false);
  const [copiedComponent, setCopiedComponent] = useState("");
  const copiedComponentRef = useRef(copiedComponent);
  const [countZindex, setCountZindex] = useState(0);
  const refCountZindex = useRef(countZindex);
  //const [triColor, setTriColor] = useState();
  const [checkedAspect, setCheckedAspect] = React.useState(false);
  const [checkedScroll, setCheckedScroll] = React.useState(false);
  const myStateRef = React.useRef(checkedAspect);
  const myCanvasRef = React.useRef(canvasDimension);
  const [modules, setModules] = useState([]);
  // const myStateScrollRef = React.useRef(checkedScroll);
  const tempSelect = React.useRef(selectedComponent);
  const scrollBarExpand = useRef([]);
  const [bgColorCanvas, setBgColorCanvas] = useState({
    r: "255",
    g: "255",
    b: "255",
    a: "1",
  });
  const [genericInfo, setGenericInfo] = useState({
    bannerSize: "",
    Width: "",
    Height: "",
  });
  //2792
  const [playPause, setPlayPause] = React.useState({
    playPause: true,
    animationCompleted: true,
  });
  const playPauseRef = React.useRef(playPause);

  //GridSnapping
  const [verticalLineFor, setVerticalLineFor] = useState(objSides);

  //2776 canvas undo
  const [addedCanvasActionsList, setAddedCanvasactionsList] = useState([]);
  const [canvasBgChange, setCanvasBgChange] = useState(false);

  var [isEditorOn, setIsEditorOn] = useState("notClickedYet");
  const [isDragged, setIsDragged] = useState({
    mouseDown: false,
    mouseMove: false,
    compDrag: false,
  });

  //3174

  //3341
  const [clickedTimes, setClickedTimes] = useState({ clicks: 0, index: null });

  const setLayersAndSelectedComp = (itemUndone, layerOrAnimation) => {
    let currentLayer = "";
    if (layerOrAnimation === "layerCompData")
      currentLayer = JSON.parse(JSON.stringify(itemUndone?.layerCompData));
    else currentLayer = JSON.parse(JSON.stringify(itemUndone?.animationObject));

    if (itemUndone?.selecetedComponent === "compdeleted") {
      setSelectedComponent("compdeleted");
      if (layerOrAnimation === "layerCompData") setLayerCompData(currentLayer);
      else setAnimationObject(currentLayer);
    } else if (layerOrAnimation === "layerCompData") {
      let filteredSelected = currentLayer.filter(
        (comp) => comp.id === itemUndone?.selecetedComponent?.id
      )[0];

      setSelectedComponent({
        ...filteredSelected,
      });

      setLayerCompData(currentLayer);
    } else setAnimationObject(currentLayer);
  };
  const handleRedo = () => {
    setundoFlag(true);
    setUndoAnimationObjFlag(true);

    let tempListForReDo = JSON.parse(JSON.stringify(listForReDo));
    let itemUndone = tempListForReDo[tempListForReDo.length - 1];

    let tempAddedActionList = JSON.parse(JSON.stringify(addedActionsList));
    let tempAddedAnimationActionList = JSON.parse(
      JSON.stringify(addedAnmationActionList)
    );
    let tempAddedCanvasActionsList = JSON.parse(
      JSON.stringify(addedCanvasActionsList)
    );

    if (itemUndone.hasOwnProperty("layerCompData")) {
      tempAddedActionList.push(tempListForReDo.pop());
      setAddedActionsList(tempAddedActionList);
      // setLayerCompData(itemUndone.layerCompData);
      // setSelectedComponent(itemUndone.selecetedComponent);
      setLayersAndSelectedComp(itemUndone, "layerCompData");
      // setOpenTimelinePopup(false); // 2776
      setOpenTimelinePopup({
        popupWindow: false,
        fromUnRedo: false,
      });
    }
    if (itemUndone.hasOwnProperty("animationObject")) {
      tempAddedAnimationActionList.push(tempListForReDo.pop());
      tempAddedActionList.push(tempListForReDo.pop());
      setAddedActionsList(tempAddedActionList);
      setAddedAnmationActionList(tempAddedAnimationActionList);
      setLayersAndSelectedComp(itemUndone, "animationObject");

      if (Object.values(itemUndone.animationObject).length !== 0) {
        setLayersAndSelectedComp(
          tempAddedActionList[tempAddedActionList.length - 1],
          "layerCompData"
        );

        // setLayerCompData(
        //   tempAddedActionList[tempAddedActionList.length - 1].layerCompData
        // );
        // setSelectedComponent(
        //   tempAddedActionList[tempAddedActionList.length - 1].selecetedComponent
        // );
        // setAnimationObject(itemUndone.animationObject);

        // let itemUndoAnimationApllied =
        //   itemUndone.selecetedComponent.animationApllied;

        let selTemp =
          tempAddedActionList[tempAddedActionList.length - 1]
            ?.selecetedComponent;
        if (selTemp?.keyFrames?.length > 0) {
          let markerPosn = isNaN(
            parseInt(itemUndone?.selecetedComponent?.animationApllied.marker)
          )
            ? 0
            : parseInt(itemUndone?.selecetedComponent?.animationApllied.marker);
          setMarker(markerPosn);
          SetPopupLeft(markerPosn - 140);
          pixelToTimeConverter(markerPosn);

          // setOpenTimelinePopup(true);
          setOpenTimelinePopup({
            popupWindow: true,
            fromUnRedo: true,
          });
        }
        if (
          tempAddedActionList[tempAddedActionList.length - 1]
            ?.selecetedComponent.animationApllied.keyFrameDeleted === "true"
        )
          setOpenTimelinePopup({
            popupWindow: false,
            fromUnRedo: true,
          });
        // 2776
      } else {
        setLayersAndSelectedComp(
          { layerCompData: [], selecetedComponent: "compdeleted" },
          "layerCompData"
        );
      }
    }
    if (itemUndone.hasOwnProperty("canvasDimension")) {
      tempAddedCanvasActionsList.push(tempListForReDo.pop());
      setAddedCanvasactionsList(tempAddedCanvasActionsList);
      setCanvasDimension(itemUndone.canvasDimension);
      // setOpenTimelinePopup(false); // 2776
      setOpenTimelinePopup({
        popupWindow: false,
        fromUnRedo: false,
      });
    }
    setListForReDo(tempListForReDo);
  };

  const handleCanvasUndo = () => {
    // setUndoCount((prevUndoCount) => ++prevUndoCount);
    setCanvasBgChange(false);
    let tempListForReDo = JSON.parse(JSON.stringify(listForReDo));
    let tempAddedCanvasActionsList = JSON.parse(
      JSON.stringify(addedCanvasActionsList)
    );
    tempListForReDo.push(tempAddedCanvasActionsList.pop()); //LIFO
    setAddedCanvasactionsList(tempAddedCanvasActionsList);
    setListForReDo(tempListForReDo);
    setCanvasDimension(
      tempAddedCanvasActionsList[tempAddedCanvasActionsList.length - 1]
        .canvasDimension
    );
    setBgColorCanvas(
      tempAddedCanvasActionsList[tempAddedCanvasActionsList.length - 1]
        .canvasDimension.backgroundColor
    );
  };
  // const prepareCanvasUndoArray = () => {
  //   setBannerChanged(true);
  //   // setLayerChangedOnPause(true);

  //   let tempAddedCanvasActionsList = JSON.parse(
  //     JSON.stringify(addedCanvasActionsList)
  //   );
  //   tempAddedCanvasActionsList.push({
  //     canvasDimension: JSON.parse(JSON.stringify(canvasDimension)),
  //   });

  //   setAddedCanvasactionsList(tempAddedCanvasActionsList);
  //   //setBgColorCanvas(canvasDimension.backgroundColor);
  // };
  // const pushaddedActionList = () => {
  //   let tempLayer = JSON.parse(JSON.stringify(layerCompData));
  //   tempLayer[tempLayer.length - 1].canvasEdited =
  //     !tempLayer[tempLayer.length - 1].canvasEdited;
  //   let tempAddedActionList = JSON.parse(JSON.stringify(addedActionsList));
  //   tempAddedActionList.push({
  //     layerCompData: tempLayer,
  //     selecetedComponent: { componentType: "default" },
  //   });
  //   setAddedActionsList(tempAddedActionList);
  // };

  useEffect(() => {
    playPauseRef.current = playPause;
  }, [playPause]);

  useEffect(() => {
    setCanvasScrollToggle({
      height: canvasDimension.height,
      width: canvasDimension.width,
    });
  }, [canvasDimension.height || canvasDimension.width]);
  // useEffect(
  //   () => {
  //     if (canvasUpdated) {
  //       // if (layerCompData.length > 0) pushaddedActionList();
  //       prepareCanvasUndoArray();
  //       setCanvasUpdated(false);
  //     } else if (addedCanvasActionsList.length === 0) {
  //       if (!sessionStorage.getItem("is_reloaded")) {
  //         // let type = performanceNavigation.type;
  //         setAddedCanvasactionsList([
  //           {
  //             canvasDimension: JSON.parse(JSON.stringify(canvasDimension)),
  //           },
  //         ]);
  //         sessionStorage.setItem("is_reloaded", true);
  //       } else {
  //         if (canvasDimension.refreshed) {
  //           setAddedCanvasactionsList([
  //             {
  //               canvasDimension: JSON.parse(JSON.stringify(canvasDimension)),
  //             },
  //           ]);
  //         }
  //       }
  //     }
  //   },
  //   [JSON.parse(JSON.stringify(canvasDimension))],
  //   canvasUpdated
  // );
  //2776 canvas undo

  //IMC-2776

  const ToggleAspectLock = (event) => {
    myStateRef.current = event.target.checked;
    setCheckedAspect(event.target.checked);
  };

  const [millSeconds, setMillSeconds] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);

  const [copiedKeyFrame, setCopiedKeyFrame] = useState("");
  const [popUpOptions, setPopUpOptions] = useState({
    ease: "NONE",
    opacity: 100,
    rotation: 0,
    scale: 100,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    skewAnimation: false,
  });
  const [linkedModulesType, setLinkedModulesType] = useState();
  const [expanded, setExpanded] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
    panel8: false,
    panel9: false,
    panel10: false,
    panel11: false,
  });
  const [toggleAnimatedBanner, setToggleAnimatedBanner] = useState(false);
  const [showBannertype, setShowBannertype] = useState("");
  const [showBannerName, setShowBannerName] = useState("");
  const [showAssettype, setShowAssettype] = useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    //setExpanded(isExpanded ? panel : false);

    switch (panel) {
      case "panel1":
        setExpanded({ ...expanded, panel1: !expanded.panel1 });
        break;
      case "panel2":
        setExpanded({ ...expanded, panel2: !expanded.panel2 });
        break;
      case "panel3":
        setExpanded({ ...expanded, panel3: !expanded.panel3 });
        break;
      case "panel4":
        setExpanded({ ...expanded, panel4: !expanded.panel4 });
        break;
      case "panel5":
        setExpanded({ ...expanded, panel5: !expanded.panel5 });
        break;
      case "panel6":
        setExpanded({ ...expanded, panel6: !expanded.panel6 });
        break;
      case "panel7":
        setExpanded({ ...expanded, panel7: !expanded.panel7 });
        break;
      case "panel8":
        setExpanded({ ...expanded, panel8: !expanded.panel8 });
        break;
      case "panel9":
        setExpanded({ ...expanded, panel9: !expanded.panel9 });
        break;
      case "panel10":
        setExpanded({ ...expanded, panel10: !expanded.panel10 });
        break;
      case "panel11":
        setExpanded({ ...expanded, panel11: !expanded.panel11 });
        break;
      default:
        break;
    }
  };

  const toggleLockComp = (itemSelected) => {
    let newLayer = [...layerCompData];
    let tempSelected = {
      ...itemSelected,
      componentStyles: {
        ...itemSelected.componentStyles,
        lockBoolean:
          itemSelected.componentStyles.lockBoolean === false ? true : false,
      },
    };
    setSelectedComponent(tempSelected);
    newLayer[
      layerCompData.findIndex((selComp) => selComp.id === itemSelected.id)
    ] = tempSelected;
    setLayerCompData(newLayer);
    // setBannerChanged(true);
  };

  const toggleHideComp = (itemSelected) => {
    let newLayer = [...layerCompData];
    let tempSelected = {
      ...itemSelected,
      // componentType: itemSelected.componentStyles.hideBoolean === false && "",
      componentStyles: {
        ...itemSelected.componentStyles,
        hideBoolean:
          itemSelected.componentStyles.hideBoolean === false ? true : false,
      },
    };
    setSelectedComponent(tempSelected);
    tempSelected.componentStyles.opacity = tempSelected.componentStyles
      .hideBoolean
      ? 0
      : 1;
    newLayer[
      layerCompData.findIndex((selComp) => selComp.id === itemSelected.id)
    ] = tempSelected;
    setLayerCompData(newLayer);
    // setBannerChanged(true);
  };

  const sendSelectedComponent = (e, index) => {
    if (playPauseRef.current.playPause && playPause.animationCompleted) {
      setVerticalLineFor({ ...objSides });
      console.log(e, "eeeee");
      // if (e.target?.parentElement?.id === selectedComponent.id)
      e.stopPropagation(); // ask keerthi before changing anything in this function
      if (index === "default") {
        setClickedTimes({ clicks: 0, index: null });

        setSelectedComponent({
          componentType: "default",
          componentStyles: { left: null, top: null },
        });
        setLayerId({
          eventDetails: "",
          type: "",
        });
        return;
      }
      if (layerCompData && index !== undefined) {
        let selectedObj = { ...layerCompData[index] };
        setSelectedComponent(selectedObj);
        let tempIndex = layerCompData.findIndex(
          (element) => element.id === selectedObj.id
        );

        setLayerIndex(tempIndex);
        if (layerIndex !== index) {
          setIsEditorOn("notClickedYet");
        }

        setLayerId({
          eventDetails: "",
          type: "",
        });
      }
    }
  };

  useEffect(() => {
    noContextMenu = document.querySelector(".canvasCol");
    noContextMenu.addEventListener("scroll", function () {
      setVisibility(false);
      setShowPaste(false);
    });
  });
  const updateSelctedFontstyle = (id, type) => {
    let tempSlected = { ...selectedComponent };
    if (tempSlected?.componentStyles?.selectedFontStyle) {
      const selectedIndex =
        tempSlected.componentStyles.selectedFontStyle?.indexOf(id);

      if (tempSlected.componentStyles.selectedFontStyle?.includes(id)) {
        tempSlected.componentStyles.selectedFontStyle.splice(selectedIndex, 1);
      } else {
        if (type === "bold" && !tempSlected.componentStyles.boldClick) {
          tempSlected.componentStyles.selectedFontStyle = [];
        } else {
          tempSlected.componentStyles.selectedFontStyle = [
            ...tempSlected.componentStyles.selectedFontStyle,
            id,
          ];

          setSelectedComponent(tempSlected);
        }
      }
    }
  };
  //const buttonRNDref = useRef(null);
  const [selectedFontId, setSelectedFontId] = useState();

  // const calcHeightofComp = () => {
  //   let heigthofComp = buttonRNDref?.current.getBoundingClientRect().height;
  //   console.log("heigthofComp", heigthofComp);
  // };
  // useEffect(() => {
  //   console.log("calculate", calcHeightofComp());
  // }, [selectedComponent]);

  const dropLayer = (ev, idvalue) => {
    ev.preventDefault();
    let coOrdinates = getRelativeCoordinates(ev);
    let constructObj = "";

    if (layerId.type !== "") {
      constructObj = componentType[layerId.type](
        {
          x: Math.floor(coOrdinates.x / canvasDimension.scale),

          y: Math.floor(coOrdinates.y / canvasDimension.scale),
        },
        ev,
        layerId.url
      );
      if (layerId.type === "Image") {
        return;
      }
    } else if (getAtom.type === "atomText" || getAtom.type === "Image") {
      if (getAtom.type === "atomText") {
        addBody.froalaText = getAtom.data;
        addBody.height = 50;

        addBody.fontSize = 10;
        let temp = getAtom.type === "atomText" ? "addBody" : getAtom.type;
        constructObj = componentType[temp](
          {
            x: Math.floor(coOrdinates.x / canvasDimension.scale),

            y: Math.floor(coOrdinates.y / canvasDimension.scale),
          },
          ev,
          addBody
        );
        setAtom({
          eventDetails: "",
          type: "",
          data: "",
        });
        addBody.froalaText = "Description";
        addBody.height = 50;
      } else {
        constructObj = componentType[getAtom.type](
          {
            x: Math.floor(coOrdinates.x / canvasDimension.scale),

            y: Math.floor(coOrdinates.y / canvasDimension.scale),
          },
          ev,
          getAtom.data
        );
        return;
      }
    } else {
      return;
    }

    constructObj.animationApllied = {
      keyFrameEdited: "false",
      keyFrameadded: "false",
      keyFrameDeleted: "false",
      componentAdded: "true",
      keyFramePasted: "false",
      marker: marker,
    };

    setLayerCompData([constructObj, ...layerCompData]);

    setSelectedComponent(constructObj);
    // setOpenTextWindow(false);
    //setOpenShapesWindow(false);
    setCount(count + 1);
    setLayerId({
      eventDetails: "",
      type: "",
    });
    setMultiSelectedComps([]);
    setVerticalLineFor({
      ...objSides,
    });
    setIsEditorOn("notClickedYet");
    setundoFlag(false);
    setUndoAnimationObjFlag(false);

    // if (constructObj?.componentStyles?.fontWeight === "bold") {
    //   updateSelctedFontstyle(selectedFontId);
    // }
  };

  const getHeightestZindex = () => {
    if (layerCompData.length > 0) {
      let data = [...layerCompData];

      data.sort((a, b) =>
        a.componentStyles.zIndex >= b.componentStyles.zIndex ? 1 : -1
      );
      return data[data.length - 1].componentStyles.zIndex + 1;
    }
    return 1;
  };
  const getMaxlayerNameCount = (type) => {
    const newLayerdata = layerSequence(type);
    if (newLayerdata.length <= 0) {
      return;
    }
    const layerCountdata = [];
    let newLayercount = newLayerdata?.forEach((item) => {
      layerCountdata.push(item.componentStyles.layerName);
    });
    let final = [];

    layerCountdata.forEach((item) => {
      const text = item?.split(type).join("");
      final.push(parseInt(text));
    });

    const newArray = final.filter((value) => !Number.isNaN(value));
    let maxNumber;
    if (newArray.length > 0) {
      maxNumber = Math.max(...newArray);
    } else {
      maxNumber = 0;
    }

    return maxNumber;
  };
  var componentType = {
    [ADDHEADING]: function (
      coOrdinates,
      ev,
      addHdng = addHeading,
      cnt = count
    ) {
      let cntZ = addHdng.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Text");

      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Text") + 1;
      } else {
        layerCnt = x.length + 1;
      }

      return {
        id: "Text" + cnt,
        componentType: "Text",
        textComponentType: {
          addContent: "AddHeading",
        },

        froalaText: addHdng.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: addHdng.width,
          height: addHdng.height,
          bottom: addHdng.height + coOrdinates.y,
          right: addHdng.width + coOrdinates.x,
          vMiddle: coOrdinates.x + addHdng.width / 2,
          hMiddle: coOrdinates.y + addHdng.height / 2,
          fontFamily: addHdng.fontFamily,
          fontLink: addHdng.fontLink || "",
          fontSize: addHdng.fontSize,

          textAlign: addHdng.textAlign,

          color: addHdng.color,
          listStyletype: addHdng.listStyletype,
          fontWeight: addHdng.fontWeight,
          fontWeightBold: addHdng.fontWeightBold,
          textDecUnderline: addHdng.textDecUnderline,
          letterSpacing: addHdng.letterSpacing,
          lineHeight: addHdng.lineHeight,
          fontStyle: addHdng.fontStyle,
          textTransform: addHdng.textTransform,
          textDecoration: addHdng.textDecoration,
          underlineClick: addHdng.underlineClick,
          strikeThroughClicked: addHdng.strikeThroughClicked,
          italicClick: addHdng.italicClick,
          boldClick: addHdng.boldClick,
          uppercaseClicked: addHdng.uppercaseClicked,
          zIndex: cntZ,
          icon: addHdng.icon,
          BGColor: "#6fbdf5",
          rotation: addHdng.rotation,
          // scaleAnm: addHdng.scaleAnm,
          // opacityAnm: addHdng.opacityAnm,
          ease: addHdng.ease,
          transform: addHdng.scale,
          targetLink: addHdng.targetLink,
          aspectLock: addHdng.aspectLock,

          cursor: addHdng.targetLink !== "https://" ? "pointer" : "default",
          lockBoolean: addHdng.lockBoolean,
          hideBoolean: addHdng.hideBoolean,
          opacity: addHdng.hideBoolean ? 0 : 1,
          toggleScroll: addHdng.toggleScroll,
          thumbHeight: addHdng.thumbHeight,
          thumbWidth: addHdng.thumbWidth,
          arrowWidth: addHdng.arrowWidth,
          arrowHeight: addHdng.arrowHeight,
          trackWidth: addHdng.trackWidth,
          trackHeight: addHdng.trackHeight,
          trackRadius: addHdng.trackRadius,
          thumbColor: addHdng.thumbColor,
          thumbRight: addHdng.thumbRight,
          trackRight: addHdng.trackRight,
          scrollThumbBg: addHdng.scrollThumbBg,
          scrollUpArrowbg: addHdng.scrollUpArrowbg,
          scrollDownArrowbg: addHdng.scrollDownArrowbg,
          textBgColor: addHdng.textBgColor,
          // textBgColorISI: addHdng.textBgColorISI,
          // fontColorISI: addHdng.fontColorISI,
          datafontSize: addHdng.datafontSize,
          selectedFontStyle: addHdng.selectedFontStyle,
          selectedId: addHdng.selectedId,
          selectedIdnumberList: addHdng.selectedIdnumberList,
          autoScroll: addHdng.autoScroll,
          expandAndClose: addHdng.expandAndClose,
          expandText: addHdng.expandText,
          expandTextUpdated: addHdng.expandText,
          closeTextUpdated: addHdng.closeText,
          closeText: addHdng.closeText,
          leftalignClick: addHdng.leftalignClick,
          rightAlignClick: addHdng.rightAlignClick,
          centerAlignClick: addHdng.centerAlignClick,
          listTypeNumberClick: addHdng.listTypeNumberClick,
          listTypediscClick: addHdng.listTypediscClick,
          expandClose: { ...addHdng.expandClose },
          layerName:
            addHdng.layerName === "Text"
              ? addHdng.layerName + layerCnt
              : addHdng.layerName,
          thumbRight: addHdng.thumbRight,
          layerNameUpdated:
            addHdng.layerName === "Text"
              ? addHdng.layerName + layerCnt
              : addHdng.layerName,
        },

        keyFrames: addHdng.keyFrames || [],
      };
    },
    [ADDSUBHEADING]: function (
      coOrdinates,
      ev,
      addSbHdng = addSubHeading,
      cnt = count
    ) {
      let cntZ = addSbHdng.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Text");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Text") + 1;
      } else {
        layerCnt = x.length + 1;
      }

      return {
        id: "Text" + cnt,
        componentType: "Text",
        textComponentType: {
          addContent: "AddSubHeading",
        },
        //  datafontSize: addSbHdng.fontSize,
        froalaText: addSbHdng.froalaText,

        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: addSbHdng.width,
          height: addSbHdng.height,
          bottom: coOrdinates.y + addSbHdng.height,
          right: addSbHdng.width + coOrdinates.x,
          vMiddle: coOrdinates.x + addSbHdng.width / 2,
          hMiddle: coOrdinates.y + addSbHdng.height / 2,
          fontFamily: addSbHdng.fontFamily,
          fontLink: addSbHdng.fontLink || "",
          fontSize: addSbHdng.fontSize,

          textAlign: addSbHdng.textAlign,
          color: addSbHdng.color,
          listStyletype: addSbHdng.listStyletype,
          fontWeight: addSbHdng.fontWeight,
          fontWeightBold: addSbHdng.fontWeightBold,
          textDecUnderline: addSbHdng.textDecUnderline,
          letterSpacing: addSbHdng.letterSpacing,
          lineHeight: addSbHdng.lineHeight,
          fontStyle: addSbHdng.fontStyle,
          textTransform: addSbHdng.textTransform,
          textDecoration: addSbHdng.textDecoration,
          underlineClick: addSbHdng.underlineClick,
          strikeThroughClicked: addSbHdng.strikeThroughClicked,
          italicClick: addSbHdng.italicClick,
          boldClick: addSbHdng.boldClick,
          uppercaseClicked: addSbHdng.uppercaseClicked,
          zIndex: cntZ,
          icon: addSbHdng.icon,
          BGColor: "#6fbdf5",
          rotation: addSbHdng.rotation,
          // scaleAnm: addSbHdng.scaleAnm,
          // opacityAnm: addSbHdng.opacityAnm,
          ease: addSbHdng.ease,
          aspectLock: addSbHdng.aspectLock,
          targetLink: addSbHdng.targetLink,
          cursor: addSbHdng.targetLink !== "https://" ? "pointer" : "default",
          lockBoolean: addSbHdng.lockBoolean,
          hideBoolean: addSbHdng.hideBoolean,
          opacity: addSbHdng.hideBoolean ? 0 : 1,
          toggleScroll: addSbHdng.toggleScroll,
          thumbHeight: addSbHdng.thumbHeight,
          thumbWidth: addSbHdng.thumbWidth,
          arrowWidth: addSbHdng.arrowWidth,
          arrowHeight: addSbHdng.arrowHeight,
          trackWidth: addSbHdng.trackWidth,
          trackHeight: addSbHdng.trackHeight,
          trackRadius: addSbHdng.trackRadius,
          thumbColor: addSbHdng.thumbColor,
          thumbRight: addSbHdng.thumbRight,
          trackRight: addSbHdng.trackRight,
          scrollThumbBg: addSbHdng.scrollThumbBg,
          scrollUpArrowbg: addSbHdng.scrollUpArrowbg,
          scrollDownArrowbg: addSbHdng.scrollDownArrowbg,
          textBgColor: addSbHdng.textBgColor,
          // textBgColorISI: addSbHdng.textBgColorISI,
          datafontSize: addSbHdng.datafontSize,
          selectedFontStyle: addSbHdng.selectedFontStyle,
          selectedId: addSbHdng.selectedId,
          selectedIdnumberList: addSbHdng.selectedIdnumberList,

          autoScroll: addSbHdng.autoScroll,
          expandAndClose: addSbHdng.expandAndClose,
          expandText: addSbHdng.expandText,
          closeText: addSbHdng.closeText,
          expandTextUpdated: addSbHdng.expandText,
          closeTextUpdated: addSbHdng.closeText,
          expandClose: { ...addSbHdng.expandClose },
          layerName:
            addSbHdng.layerName === "Text"
              ? addSbHdng.layerName + layerCnt
              : addSbHdng.layerName,
          thumbRight: addSbHdng.thumbRight,
          layerNameUpdated:
            addSbHdng.layerName === "Text"
              ? addSbHdng.layerName + layerCnt
              : addSbHdng.layerName,
        },

        keyFrames: addSbHdng.keyFrames || [],
      };
    },
    [ADDBODY]: function (coOrdinates, ev, addBdy = addBody, cnt = count) {
      let cntZ = addBdy.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Text");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Text") + 1;
      } else {
        layerCnt = x.length + 1;
      }

      return {
        id: "Text" + cnt,
        componentType: "Text",
        textComponentType: {
          addContent: "addBody",
        },
        // datafontSize: addBdy.fontSize,
        froalaText: addBdy.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: addBdy.width,
          height: addBdy.height,
          bottom: addBdy.height + coOrdinates.y,
          right: addBdy.width + coOrdinates.x,
          vMiddle: coOrdinates.x + addBdy.width / 2,
          hMiddle: coOrdinates.y + addBdy.height / 2,
          fontFamily: addBdy.fontFamily,
          fontLink: addBdy.fontLink || "",
          fontSize: addBdy.fontSize,

          textAlign: addBdy.textAlign,
          color: addBdy.color,
          listStyletype: addBdy.listStyletype,
          fontWeight: addBdy.fontWeight,
          fontWeightBold: addBdy.fontWeightBold,
          textDecUnderline: addBdy.textDecUnderline,
          letterSpacing: addBdy.letterSpacing,
          lineHeight: addBdy.lineHeight,
          fontStyle: addBdy.fontStyle,
          textTransform: addBdy.textTransform,
          textDecoration: addBdy.textDecoration,
          underlineClick: addBdy.underlineClick,
          strikeThroughClicked: addBdy.strikeThroughClicked,
          italicClick: addBdy.italicClick,
          boldClick: addBdy.boldClick,
          uppercaseClicked: addBdy.uppercaseClicked,
          zIndex: cntZ,
          icon: addBdy.icon,
          BGColor: "#6fbdf5",
          rotation: addBdy.rotation,
          // scaleAnm: addBdy.scaleAnm,
          // opacityAnm: addBdy.opacityAnm,
          ease: addBdy.ease,
          targetLink: addBdy.targetLink,
          aspectLock: addBdy.aspectLock,
          lockBoolean: addBdy.lockBoolean,
          hideBoolean: addBdy.hideBoolean,
          opacity: addBdy.hideBoolean ? 0 : 1,
          toggleScroll: addBdy.toggleScroll,
          thumbHeight: addBdy.thumbHeight,
          thumbWidth: addBdy.thumbWidth,
          arrowWidth: addBdy.arrowWidth,
          arrowHeight: addBdy.arrowHeight,
          trackWidth: addBdy.trackWidth,
          trackHeight: addBdy.trackHeight,
          trackRadius: addBdy.trackRadius,
          thumbColor: addBdy.thumbColor,
          scrollThumbBg: addBdy.scrollThumbBg,
          scrollUpArrowbg: addBdy.scrollUpArrowbg,
          scrollDownArrowbg: addBdy.scrollDownArrowbg,
          textBgColor: addBdy.textBgColor,
          datafontSize: addBdy.datafontSize,
          selectedFontStyle: addBdy.selectedFontStyle,
          selectedId: addBdy.selectedId,
          selectedIdnumberList: addBdy.selectedIdnumberList,

          autoScroll: addBdy.autoScroll,
          expandAndClose: addBdy.expandAndClose,
          expandText: addBdy.expandText,
          closeText: addBdy.closeText,
          thumbRight: addBdy.thumbRight,
          trackRight: addBdy.trackRight,
          expandTextUpdated: addBdy.expandText,
          closeTextUpdated: addBdy.closeText,
          expandClose: { ...addBdy.expandClose },
          layerName:
            addBdy.layerName === "Text"
              ? addBdy.layerName + layerCnt
              : addBdy.layerName,
          thumbRight: addBdy.thumbRight,
          layerNameUpdated:
            addBdy.layerName === "Text"
              ? addBdy.layerName + layerCnt
              : addBdy.layerName,
        },

        keyFrames: addBdy.keyFrames || [],
      };
    },
    [BUTTON]: function (coOrdinates, ev, but = button, cnt = count) {
      let cntZ = but.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Button");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Button") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "Button" + cnt,
        componentType: "Button",
        textComponentType: {
          addContent: "button",
        },
        //  datafontSize: but.fontSize,
        froalaText: but.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: but.width,
          height: but.height,
          bottom: coOrdinates.y + but.height,
          right: but.width + coOrdinates.x,
          vMiddle: coOrdinates.x + but.width / 2,
          hMiddle: coOrdinates.y + but.height / 2,
          fontFamily: but.fontFamily,
          fontLink: but.fontLink || "",
          fontSize: but.fontSize,
          textAlign: but.textAlign,
          color: but.color,
          listStyleType: but.listStyletype,
          fontWeight: but.fontWeight,
          fontWeightBold: but.fontWeightBold,
          textDecUnderline: but.textDecUnderline,
          letterSpacing: but.letterSpacing,
          lineHeight: but.lineHeight,
          fontStyle: but.fontStyle,
          textTransform: but.textTransform,
          textDecoration: but.textDecoration,
          underlineClick: but.underlineClick,
          strikeThroughClicked: but.strikeThroughClicked,
          italicClick: but.italicClick,
          boldClick: but.boldClick,
          uppercaseClicked: but.uppercaseClicked,
          zIndex: cntZ,
          backgroundColor: but.backgroundColor,
          border: but.border,
          targetLink: but.targetLink,
          paddingTop: but.paddingTop,
          paddingRight: but.paddingRight,
          paddingBottom: but.paddingBottom,
          paddingLeft: but.paddingLeft,
          borderTopLeftRadius: but.borderTopLeftRadius,
          borderTopRightRadius: but.borderTopRightRadius,
          borderBottomLeftRadius: but.borderBottomLeftRadius,
          borderBottomRightRadius: but.borderBottomRightRadius,
          icon: but.icon,
          BGColor: "#77d997",
          rotation: but.rotation,
          // scaleAnm: but.scaleAnm,
          // opacityAnm: but.opacityAnm,
          ease: but.ease,
          aspectLock: but.aspectLock,
          cursor: but.targetLink !== "https://" ? "pointer" : "default",
          lockBoolean: but.lockBoolean,
          hideBoolean: but.hideBoolean,
          opacity: but.hideBoolean ? 0 : 1,
          datafontSize: but.datafontSize,
          selectedFontStyle: but.selectedFontStyle,
          selectedId: but.selectedId,
          selectedIdnumberList: but.selectedIdnumberList,

          layerName:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
          layerNameUpdated:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
        },

        keyFrames: but.keyFrames || [],
      };
    },
    [BLUEBUTTONWITHRAD]: function (
      coOrdinates,
      ev,
      but = BlueButtonWithRad,
      cnt = count
    ) {
      let cntZ = but.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Button");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Button") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "Button" + cnt,
        componentType: "Button",
        textComponentType: {
          addContent: "Button",
        },
        //  datafontSize: but.fontSize,
        froalaText: but.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: but.width,
          height: but.height,
          bottom: coOrdinates.y + but.height,
          right: but.width + coOrdinates.x,
          vMiddle: coOrdinates.x + but.width / 2,
          hMiddle: coOrdinates.y + but.height / 2,
          fontFamily: but.fontFamily,
          fontLink: but.fontLink || "",
          fontSize: but.fontSize,
          textAlign: but.textAlign,
          color: but.color,
          listStyleType: but.listStyletype,
          fontWeight: but.fontWeight,
          fontWeightBold: but.fontWeightBold,
          textDecUnderline: but.textDecUnderline,
          letterSpacing: but.letterSpacing,
          lineHeight: but.lineHeight,
          fontStyle: but.fontStyle,
          textTransform: but.textTransform,
          textDecoration: but.textDecoration,
          underlineClick: but.underlineClick,
          strikeThroughClicked: but.strikeThroughClicked,
          italicClick: but.italicClick,
          boldClick: but.boldClick,
          uppercaseClicked: but.uppercaseClicked,
          zIndex: cntZ,
          backgroundColor: but.backgroundColor,
          border: but.border,
          targetLink: but.targetLink,
          paddingTop: but.paddingTop,
          paddingRight: but.paddingRight,
          paddingBottom: but.paddingBottom,
          paddingLeft: but.paddingLeft,
          borderTopLeftRadius: but.borderTopLeftRadius,
          borderTopRightRadius: but.borderTopRightRadius,
          borderBottomLeftRadius: but.borderBottomLeftRadius,
          borderBottomRightRadius: but.borderBottomRightRadius,
          icon: but.icon,
          BGColor: "#77d997",
          rotation: but.rotation,
          // scaleAnm: but.scaleAnm,
          // opacityAnm: but.opacityAnm,
          ease: but.ease,
          aspectLock: but.aspectLock,
          cursor: but.targetLink !== "http://" ? "pointer" : "default",
          lockBoolean: but.lockBoolean,
          hideBoolean: but.hideBoolean,
          opacity: but.hideBoolean ? 0 : 1,
          datafontSize: but.datafontSize,
          selectedFontStyle: but.selectedFontStyle,
          selectedId: but.selectedId,
          layerName:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
          layerNameUpdated:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
        },

        keyFrames: but.keyFrames || [],
      };
    },
    [WHITEBUTTON]: function (coOrdinates, ev, but = WhiteButton, cnt = count) {
      let cntZ = but.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Button");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Button") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "Button" + cnt,
        componentType: "Button",
        textComponentType: {
          addContent: "Button",
        },
        //  datafontSize: but.fontSize,
        froalaText: but.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: but.width,
          height: but.height,
          bottom: coOrdinates.y + but.height,
          right: but.width + coOrdinates.x,
          vMiddle: coOrdinates.x + but.width / 2,
          hMiddle: coOrdinates.y + but.height / 2,
          fontFamily: but.fontFamily,
          fontLink: but.fontLink || "",
          fontSize: but.fontSize,
          textAlign: but.textAlign,
          color: but.color,
          listStyleType: but.listStyletype,
          fontWeight: but.fontWeight,
          fontWeightBold: but.fontWeightBold,
          textDecUnderline: but.textDecUnderline,
          letterSpacing: but.letterSpacing,
          lineHeight: but.lineHeight,
          fontStyle: but.fontStyle,
          textTransform: but.textTransform,
          textDecoration: but.textDecoration,
          underlineClick: but.underlineClick,
          strikeThroughClicked: but.strikeThroughClicked,
          italicClick: but.italicClick,
          boldClick: but.boldClick,
          uppercaseClicked: but.uppercaseClicked,
          zIndex: cntZ,
          backgroundColor: but.backgroundColor,
          border: but.border,
          targetLink: but.targetLink,
          paddingTop: but.paddingTop,
          paddingRight: but.paddingRight,
          paddingBottom: but.paddingBottom,
          paddingLeft: but.paddingLeft,
          borderTopLeftRadius: but.borderTopLeftRadius,
          borderTopRightRadius: but.borderTopRightRadius,
          borderBottomLeftRadius: but.borderBottomLeftRadius,
          borderBottomRightRadius: but.borderBottomRightRadius,
          icon: but.icon,
          BGColor: "#77d997",
          rotation: but.rotation,
          // scaleAnm: but.scaleAnm,
          // opacityAnm: but.opacityAnm,
          ease: but.ease,
          aspectLock: but.aspectLock,
          cursor: but.targetLink !== "http://" ? "pointer" : "default",
          lockBoolean: but.lockBoolean,
          hideBoolean: but.hideBoolean,
          opacity: but.hideBoolean ? 0 : 1,
          datafontSize: but.datafontSize,
          selectedFontStyle: but.selectedFontStyle,
          selectedId: but.selectedId,
          layerName:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
          layerNameUpdated:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
        },

        keyFrames: but.keyFrames || [],
      };
    },
    [WHITEBUTTONWITHRAD]: function (
      coOrdinates,
      ev,
      but = WhiteButtonWithRad,

      cnt = count
    ) {
      let cntZ = but.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Button");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Button") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "Button" + cnt,
        componentType: "Button",
        textComponentType: {
          addContent: "Button",
        },
        //  datafontSize: but.fontSize,
        froalaText: but.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: but.width,
          height: but.height,
          bottom: coOrdinates.y + but.height,
          right: but.width + coOrdinates.x,
          vMiddle: coOrdinates.x + but.width / 2,
          hMiddle: coOrdinates.y + but.height / 2,
          fontFamily: but.fontFamily,
          fontLink: but.fontLink || "",
          fontSize: but.fontSize,
          textAlign: but.textAlign,
          color: but.color,
          listStyleType: but.listStyletype,
          fontWeight: but.fontWeight,
          fontWeightBold: but.fontWeightBold,
          textDecUnderline: but.textDecUnderline,
          letterSpacing: but.letterSpacing,
          lineHeight: but.lineHeight,
          fontStyle: but.fontStyle,
          textTransform: but.textTransform,
          textDecoration: but.textDecoration,
          underlineClick: but.underlineClick,
          strikeThroughClicked: but.strikeThroughClicked,
          italicClick: but.italicClick,
          boldClick: but.boldClick,
          uppercaseClicked: but.uppercaseClicked,
          zIndex: cntZ,
          backgroundColor: but.backgroundColor,
          border: but.border,
          targetLink: but.targetLink,
          paddingTop: but.paddingTop,
          paddingRight: but.paddingRight,
          paddingBottom: but.paddingBottom,
          paddingLeft: but.paddingLeft,
          borderTopLeftRadius: but.borderTopLeftRadius,
          borderTopRightRadius: but.borderTopRightRadius,
          borderBottomLeftRadius: but.borderBottomLeftRadius,
          borderBottomRightRadius: but.borderBottomRightRadius,
          icon: but.icon,
          BGColor: "#77d997",
          rotation: but.rotation,
          // scaleAnm: but.scaleAnm,
          // opacityAnm: but.opacityAnm,
          ease: but.ease,
          aspectLock: but.aspectLock,
          cursor: but.targetLink !== "http://" ? "pointer" : "default",
          lockBoolean: but.lockBoolean,
          hideBoolean: but.hideBoolean,
          opacity: but.hideBoolean ? 0 : 1,
          datafontSize: but.datafontSize,
          selectedFontStyle: but.selectedFontStyle,
          selectedId: but.selectedId,
          layerName:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
          layerNameUpdated:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
        },

        keyFrames: but.keyFrames || [],
      };
    },
    [BUTTONWITHOUTBORDER]: function (
      coOrdinates,
      ev,
      but = ButtonWithoutBorder,
      cnt = count
    ) {
      let cntZ = but.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Button");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Button") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "Button" + cnt,
        componentType: "Button",
        textComponentType: {
          addContent: "Button",
        },
        //  datafontSize: but.fontSize,
        froalaText: but.froalaText,
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: but.width,
          height: but.height,
          bottom: coOrdinates.y + but.height,
          right: but.width + coOrdinates.x,
          vMiddle: coOrdinates.x + but.width / 2,
          hMiddle: coOrdinates.y + but.height / 2,
          fontFamily: but.fontFamily,
          fontLink: but.fontLink || "",
          fontSize: but.fontSize,
          textAlign: but.textAlign,
          color: but.color,
          listStyleType: but.listStyletype,
          fontWeight: but.fontWeight,
          fontWeightBold: but.fontWeightBold,
          textDecUnderline: but.textDecUnderline,
          letterSpacing: but.letterSpacing,
          lineHeight: but.lineHeight,
          fontStyle: but.fontStyle,
          textTransform: but.textTransform,
          textDecoration: but.textDecoration,
          underlineClick: but.underlineClick,
          strikeThroughClicked: but.strikeThroughClicked,
          italicClick: but.italicClick,
          boldClick: but.boldClick,
          uppercaseClicked: but.uppercaseClicked,
          zIndex: cntZ,
          backgroundColor: but.backgroundColor,
          border: but.border,
          targetLink: but.targetLink,
          paddingTop: but.paddingTop,
          paddingRight: but.paddingRight,
          paddingBottom: but.paddingBottom,
          paddingLeft: but.paddingLeft,
          borderTopLeftRadius: but.borderTopLeftRadius,
          borderTopRightRadius: but.borderTopRightRadius,
          borderBottomLeftRadius: but.borderBottomLeftRadius,
          borderBottomRightRadius: but.borderBottomRightRadius,
          icon: but.icon,
          BGColor: "#77d997",
          rotation: but.rotation,
          // scaleAnm: but.scaleAnm,
          // opacityAnm: but.opacityAnm,
          ease: but.ease,
          aspectLock: but.aspectLock,
          cursor: but.targetLink !== "http://" ? "pointer" : "default",
          lockBoolean: but.lockBoolean,
          hideBoolean: but.hideBoolean,
          opacity: but.hideBoolean ? 0 : 1,
          datafontSize: but.datafontSize,
          selectedFontStyle: but.selectedFontStyle,
          selectedId: but.selectedId,
          layerName:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
          layerNameUpdated:
            but.layerName === "Button"
              ? but.layerName + layerCnt
              : but.layerName,
        },

        keyFrames: but.keyFrames || [],
      };
    },

    [IMAGE]: function (coOrdinates, ev, url) {
      var img = new Image();
      img.src = url;
      const subtractSize = 20; // This Value is used to make sure image dropped doesn't overflow the canvas dimension.
      let NewHeight = canvasDimension.height - subtractSize;
      let NewWidth = canvasDimension.width - subtractSize;
      let aspectRatio = 1;
      img.onload = function () {
        let imgWidth = img.width;
        let imgHeight = img.height;
        aspectRatio = imgWidth / imgHeight;
        if (canvasDimension.width < 310)
          if (
            canvasDimension.height >= imgHeight &&
            canvasDimension.width >= imgWidth
          ) {
            NewHeight = imgHeight;
            NewWidth = imgWidth;
          } else if (imgHeight > imgWidth) {
            NewWidth = (imgWidth / imgHeight) * NewHeight;
            if (NewWidth > canvasDimension.width) {
              NewWidth = canvasDimension.width - 50;
              NewHeight = (imgHeight / imgWidth) * NewWidth;
            } else if (NewHeight > canvasDimension.height) {
              NewHeight = NewHeight - 50;

              NewWidth = (imgWidth / imgHeight) * NewHeight;
            }
          } else if (imgWidth > imgHeight || imgHeight === imgWidth) {
            NewWidth = canvasDimension.width - 50;
            NewHeight = (imgHeight / imgWidth) * NewWidth;

            if (NewHeight > canvasDimension.height) {
              NewHeight = NewHeight - 50;
              NewWidth = (imgWidth / imgHeight) * NewHeight;
            }
          } else {
            NewHeight = imgHeight;
            NewWidth = imgWidth;
          }
        else {
          if (
            canvasDimension.height >= imgHeight &&
            canvasDimension.width >= imgWidth
          ) {
            NewHeight = imgHeight;
            NewWidth = imgWidth;
          } else if (imgHeight > imgWidth) {
            NewWidth = (imgWidth / imgHeight) * NewHeight;
            if (NewWidth > canvasDimension.width) {
              NewWidth = canvasDimension.width - 50;
              NewHeight = (imgHeight / imgWidth) * NewWidth;
            } else if (NewHeight > canvasDimension.height) {
              NewHeight = NewHeight - 50;

              NewWidth = (imgWidth / imgHeight) * NewHeight;
            }
          } else if (imgWidth > imgHeight || imgHeight === imgWidth) {
            NewWidth = 200;
            NewHeight = (imgHeight / imgWidth) * NewWidth;

            if (NewHeight > canvasDimension.height) {
              NewHeight = NewHeight - 50;
              NewWidth = (imgWidth / imgHeight) * NewHeight;
            }
          } else {
            NewHeight = imgHeight;
            NewWidth = imgWidth;
          }
        }

        imageOnCanvas(
          undefined,
          url,
          coOrdinates.x,
          coOrdinates.y,
          "fileName",
          image,
          NewWidth,
          NewHeight,
          count,
          aspectRatio
        );
      };
    },
    [RECTANGLE]: function (
      coOrdinates,
      ev,
      rectangle = rectangleShape,
      cnt = count
    ) {
      let cntZ = rectangle.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Shape");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Shape") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "rectangleShape" + cnt,
        componentType: "Shape",
        textComponentType: {
          addContent: "rectangleShape",
        },
        froalaText: "",
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          oldLeft: coOrdinates.x,
          oldRight: coOrdinates.y,
          width: rectangle.width,
          height: rectangle.height,
          bottom: coOrdinates.y + rectangle.height,
          right: rectangle.width + coOrdinates.x,
          vMiddle: coOrdinates.x + rectangle.width / 2,
          hMiddle: coOrdinates.y + rectangle.height / 2,
          zIndex: cntZ,
          backgroundColor: rectangle.backgroundColor,
          border: rectangle.border,
          paddingTop: rectangle.paddingTop,
          paddingRight: rectangle.paddingRight,
          paddingBottom: rectangle.paddingBottom,
          paddingLeft: rectangle.paddingLeft,
          borderTopLeftRadius: rectangle.borderTopLeftRadius,
          borderTopRightRadius: rectangle.borderTopRightRadius,
          borderBottomLeftRadius: rectangle.borderBottomLeftRadius,
          borderBottomRightRadius: rectangle.borderBottomRightRadius,
          icon: rectangle.icon,
          rotation: rectangle.rotation,
          aspectLock: rectangle.aspectLock,
          lockBoolean: rectangle.lockBoolean,
          hideBoolean: rectangle.hideBoolean,
          opacity: rectangle.hideBoolean ? 0 : 1,
          compOpacity: rectangle.compOpacity,
          layerName:
            rectangle.layerName === "Shape"
              ? rectangle.layerName + layerCnt
              : rectangle.layerName,
          layerNameUpdated:
            rectangle.layerName === "Shape"
              ? rectangle.layerName + layerCnt
              : rectangle.layerName,
        },

        keyFrames: rectangle.keyFrames || [],
      };
    },
    [CIRCLE]: function (coOrdinates, ev, circle = circleShape, cnt = count) {
      let cntZ = circle.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Shape");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Shape") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "circleShape" + cnt,
        componentType: "Shape",
        textComponentType: {
          addContent: "circleShape",
        },
        froalaText: "",
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: circle.width,
          height: circle.height,
          bottom: coOrdinates.y + circle.height,
          right: circle.width + coOrdinates.x,
          vMiddle: coOrdinates.x + circle.width / 2,
          hMiddle: coOrdinates.y + circle.height / 2,
          zIndex: cntZ,
          backgroundColor: circle.backgroundColor,
          border: circle.border,
          borderRadius: circle.borderRadius,
          icon: circle.icon,
          rotation: circle.rotation,
          // scaleAnm: circle.scaleAnm,
          // opacityAnm: circle.opacityAnm,
          aspectLock: circle.aspectLock,
          lockBoolean: circle.lockBoolean,
          hideBoolean: circle.hideBoolean,
          opacity: circle.hideBoolean ? 0 : 1,
          compOpacity: circle.compOpacity,
          layerName:
            circle.layerName === "Shape"
              ? circle.layerName + layerCnt
              : circle.layerName,
          layerNameUpdated:
            circle.layerName === "Shape"
              ? circle.layerName + layerCnt
              : circle.layerName,
        },

        keyFrames: circle.keyFrames || [],
      };
    },
    [TRIANGLE]: function (
      coOrdinates,
      ev,
      triangle = triangleShape,
      cnt = count
    ) {
      let cntZ = triangle.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Shape");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Shape") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "triangleShape" + cnt,
        componentType: "Shape",
        textComponentType: {
          addContent: "triangleShape",
        },
        froalaText: "",
        triImage: triangle.src,
        /* 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" viewBox="0 0 1280 70" stroke="red" stroke-width="1" ><g><path fill="white" d="M150 0 L75 200 L225 200 Z"/></g></svg>', */
        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: triangle.width,
          height: triangle.height,
          bottom: coOrdinates.y + triangle.height,
          right: triangle.width + triangle.x,
          vMiddle: coOrdinates.x + triangle.width / 2,
          hMiddle: coOrdinates.y + triangle.height / 2,
          zIndex: cntZ,
          backgroundColor: triangle.backgroundColor,
          border: triangle.border,
          borderWidth: triangle.borderWidth,
          borderStyle: triangle.borderStyle,
          icon: triangle.icon,
          rotation: triangle.rotation,
          // scaleAnm: triangle.scaleAnm,
          // opacityAnm: triangle.opacityAnm,
          aspectLock: triangle.aspectLock,
          lockBoolean: triangle.lockBoolean,
          hideBoolean: triangle.hideBoolean,
          opacity: triangle.hideBoolean ? 0 : 1,
          compOpacity: triangle.compOpacity,
          layerName:
            triangle.layerName === "Shape"
              ? triangle.layerName + layerCnt
              : triangle.layerName,
          layerNameUpdated:
            triangle.layerName === "Shape"
              ? triangle.layerName + layerCnt
              : triangle.layerName,
        },

        keyFrames: triangle.keyFrames || [],
      };
    },
    [POLYGON]: function (coOrdinates, ev, polygon = polygonShape, cnt = count) {
      let cntZ = polygon.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Shape");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Shape") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "polygonShape" + cnt,
        componentType: "Shape",
        textComponentType: {
          addContent: "polygonShape",
        },
        froalaText: "",

        pentImage: polygon.src,
        // "data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23ffffff%22%20%20viewBox%3D%220%200%2016%2016%22%20stroke%3D%22%23000000%22%20stroke-width%3D%221%22%3E%0A%20%20%3Cpath%20d%3D%22m8%200%208%206.5-3%209.5H3L0%206.5%208%200z%22%2F%3E%0A%3C%2Fsvg%3E%0A%0A",

        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: polygon.width,
          height: polygon.height,
          bottom: coOrdinates.y + polygon.height,
          right: polygon.width + polygon.x,
          vMiddle: coOrdinates.x + polygon.width / 2,
          hMiddle: coOrdinates.y + polygon.height / 2,
          zIndex: cntZ,
          border: polygon.border,
          backgroundColor: polygon.backgroundColor,
          icon: polygon.icon,
          rotation: polygon.rotation,
          // scaleAnm: polygon.scaleAnm,
          // opacityAnm: polygon.opacityAnm,
          aspectLock: polygon.aspectLock,
          lockBoolean: polygon.lockBoolean,
          hideBoolean: polygon.hideBoolean,
          opacity: polygon.hideBoolean ? 0 : 1,
          compOpacity: polygon.compOpacity,
          layerName:
            polygon.layerName === "Shape"
              ? polygon.layerName + layerCnt
              : polygon.layerName,
          layerNameUpdated:
            polygon.layerName === "Shape"
              ? polygon.layerName + layerCnt
              : polygon.layerName,
        },

        keyFrames: polygon.keyFrames || [],
      };
    },
    [HEXAGON]: function (coOrdinates, ev, hexa = hexagonShape, cnt = count) {
      let cntZ = hexa.zIndex || getHeightestZindex();
      setCountZindex(cntZ);
      let x = layerSequence("Shape");
      let layerCnt;
      if (x.length > 0) {
        layerCnt = getMaxlayerNameCount("Shape") + 1;
      } else {
        layerCnt = x.length + 1;
      }
      return {
        id: "hexagonShape" + cnt,
        componentType: "Shape",
        textComponentType: {
          addContent: "hexagonShape",
        },
        froalaText: "",
        hexImage: hexa.src,

        componentStyles: {
          left: coOrdinates.x,
          top: coOrdinates.y,
          width: hexa.width,
          height: hexa.height,
          bottom: coOrdinates.y + hexa.height,
          right: hexa.width + coOrdinates.x,
          vMiddle: coOrdinates.x + hexa.width / 2,
          hMiddle: coOrdinates.y + hexa.height / 2,
          zIndex: cntZ,
          border: hexa.border,
          backgroundColor: hexa.backgroundColor,
          icon: hexa.icon,
          rotation: hexa.rotation,
          // scaleAnm: hexa.scaleAnm,
          // opacityAnm: hexa.opacityAnm,
          aspectLock: hexa.aspectLock,
          lockBoolean: hexa.lockBoolean,
          hideBoolean: hexa.hideBoolean,
          opacity: hexa.hideBoolean ? 0 : 1,
          compOpacity: hexa.compOpacity,
          layerName:
            hexa.layerName === "Shape"
              ? hexa.layerName + layerCnt
              : hexa.layerName,
          layerNameUpdated:
            hexa.layerName === "Shape"
              ? hexa.layerName + layerCnt
              : hexa.layerName,
        },

        keyFrames: hexa.keyFrames || [],
      };
    },
    default: function () {
      return;
    },
    Conatiner: function () {
      return;
    },
  };

  function repeatXYUpdate(temp) {
    let tempElemLayers = [...tempLayer.current];
    if (tempElemLayers.length > 0) {
      let filetredLayer = tempElemLayers.filter(
        (layer) => layer.id === temp.id
      );
      filetredLayer[0].componentStyles.left = Number(
        parseFloat(+Number(temp.x).toFixed(2))
      );
      filetredLayer[0].componentStyles.top = Number(
        parseFloat(+Number(temp.y).toFixed(2))
      );

      //Math.round(temp.y / grid) * grid;
      filetredLayer[0].componentStyles.right =
        Number(filetredLayer[0].componentStyles.left) +
        Number(filetredLayer[0].componentStyles.width);
      filetredLayer[0].componentStyles.bottom =
        Number(filetredLayer[0].componentStyles.top) +
        filetredLayer[0].componentStyles.height;
      filetredLayer[0].componentStyles.vMiddle = Math.round(
        Number(
          filetredLayer[0].componentStyles.left +
            filetredLayer[0].componentStyles.width / 2
        )
      );
      filetredLayer[0].componentStyles.hMiddle = Math.round(
        filetredLayer[0].componentStyles.top +
          filetredLayer[0].componentStyles.height / 2
      );
      tempElemLayers[
        tempElemLayers.findIndex(
          (selComp) => selComp.id === tempSelect.current.id
        )
      ] = filetredLayer[0];
      tempElemLayers[tempElemLayers.length - 1].eachDrag = "yes";
      //setLayerCompData(tempElemLayers);
      setSelectedComponent({ ...filetredLayer[0] });
    }
  }

  function updateCompValues(temp, origin) {
    let grid = 5;
    isDragged.mouseMove = false;
    isDragged.compDrag = true;
    if (canvasDimension.zoomValue < 60) {
      console.log(canvasDimension);
      document.getElementsByTagName("body")[0].style.display = "none";
      let he = document.getElementsByTagName("body")[0].offsetHeight;
      document.getElementsByTagName("body")[0].style.display = "block";
    }
    if (temp.index !== undefined) {
      if (createFroalaInstance.current.length > 0) {
        if (createFroalaInstance.current[temp.index].hasOwnProperty("editor"))
          createFroalaInstance.current[temp.index].editor.el.blur();
      }
    }
    repeatXYUpdate(temp);
    // if (filetredLayer[0].componentStyles.height > 50) {
    //   domCanvasPage.scrollTo(
    //     Math.min(temp.x, temp.x - 100),
    //     Math.min(temp.y, temp.y - 100)
    //   );
    // } else {
    //   domCanvasPage.scrollTo(
    //     Math.min(temp.x, temp.x - 200),
    //     Math.min(temp.y, temp.y - 200)
    //   );
    // }
    // if (temp.y > 180) {
    //   domCanvasPage.scrollTop =
    //     temp.y - filetredLayer[0].componentStyles.height;
    // } else if (temp.y < 180) domCanvasPage.scrollTop = 0;
    if (!playPauseRef.current.animationCompleted) return;
    TestSidesTouching(setVerticalLineFor, tempLayer, tempSelect);
  }

  function callOnDragStop(temp) {
    isDragged.compDrag = false;

    if (temp.e !== undefined && temp.e.detail !== 2)
      isDragged.mouseMove = false;
    if (temp.index !== undefined) {
      if (createFroalaInstance.current.length > 0) {
        if (createFroalaInstance.current[temp.index]?.hasOwnProperty("editor"))
          createFroalaInstance.current[temp.index].editor.el.blur();
      }
    }

    // setundoFlag(false);
    let tempElemLayers = [...tempLayer.current];
    if (
      tempElemLayers.length > 0 &&
      temp.id === verticalLineFor.lastSelectedComp.id
    ) {
      repeatXYUpdate(temp);
      //  console.log("inside drag stopend");
      if (!playPauseRef.current.animationCompleted) return;
      TestSidesTouching(setVerticalLineFor, tempLayer, tempSelect);
    }

    // newDrag(verticalLineFor, verticalLineFor);
  }
  function updateFontOnResize(obj, undoSet) {
    if (layerCompData.length > 0 && obj.id) {
      //repeatXYUpdate(obj);
      let tempElemLayers = [...layerCompData];
      let filetredLayer = tempElemLayers.filter((layer) => layer.id === obj.id);
      filetredLayer[0].componentStyles.width =
        obj.width || filetredLayer[0].componentStyles.width;

      filetredLayer[0].componentStyles.height =
        obj.height || filetredLayer[0].componentStyles.height;

      filetredLayer[0].componentStyles.left =
        +Number(obj.x).toFixed(2) ||
        Number(filetredLayer[0].componentStyles.left);

      filetredLayer[0].componentStyles.top =
        +Number(obj.y).toFixed(2) ||
        Number(filetredLayer[0].componentStyles.top);
      filetredLayer[0].componentStyles.right =
        Number(filetredLayer[0].componentStyles.left) +
        Number(filetredLayer[0].componentStyles.width);

      filetredLayer[0].componentStyles.bottom =
        Number(filetredLayer[0].componentStyles.top) +
        filetredLayer[0].componentStyles.height;

      filetredLayer[0].componentStyles.vMiddle = Math.round(
        Number(
          filetredLayer[0].componentStyles.left +
            filetredLayer[0].componentStyles.width / 2
        )
      );
      filetredLayer[0].componentStyles.hMiddle = Math.round(
        filetredLayer[0].componentStyles.top +
          filetredLayer[0].componentStyles.height / 2
      );

      tempElemLayers[
        tempElemLayers.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ] = filetredLayer[0];
      tempElemLayers[tempElemLayers.length - 1].eachDrag = "yes";

      setSelectedComponent({ ...filetredLayer[0] });
      // setLayerCompData(tempElemLayers);
      // if (undoSet !== "undoSet") setundoFlag(true);

      setVerticalLineFor({ ...objSides });
    }
  }
  function callOnResizeStop(obj, undoSet) {
    return;
    // console.log("undoSet in resize is ...", undoSet);
    if (layerCompData.length > 0 && obj.id) {
      repeatXYUpdate(obj);

      // setLayerCompData(tempElemLayers);

      // if (undoSet !== "undoSet") setundoFlag(true);
      setVerticalLineFor({ ...objSides });
    }
  }
  // function updateFontOnResize(obj, undoSet) {
  //   if (layerCompData.length > 0 && obj.id) {
  //     repeatXYUpdate(obj);
  //     // setLayerCompData(tempElemLayers);
  //     // if (undoSet !== "undoSet") setundoFlag(true);

  //     setVerticalLineFor({ ...objSides });
  //   }
  // }
  function callOnResizeStop(obj, undoSet) {
    // console.log("undoSet in resize is ...", undoSet);
    if (layerCompData.length > 0 && obj.id) {
      repeatXYUpdate(obj);

      // setLayerCompData(tempElemLayers);

      // if (undoSet !== "undoSet") setundoFlag(true);
      setVerticalLineFor({ ...objSides });
    }
  }

  function imageOnCanvas(
    origin,
    url,
    x = 20,
    y = 30,
    filename,
    imag = image,
    width,
    height,
    cnt = count,
    aspectRatio
  ) {
    let cntZ = imag.zIndex || getHeightestZindex();
    setCountZindex(cntZ);
    let imagecnt = layerSequence("Image");
    let layerCnt;
    if (imagecnt.length > 0) {
      layerCnt = getMaxlayerNameCount("Image") + 1;
    } else {
      layerCnt = imagecnt.length + 1;
    }
    let Obj = {
      componentType: "Image",
      textComponentType: {
        addContent: "Image",
      },
      id: "Image" + cnt,
      componentStyles: {
        left: x,
        top: y,
        width: width || imag.width,
        height: height || imag.height,
        bottom: y + (height || imag.height),
        right: (width || imag.width) + x,
        vMiddle: Math.round(x + (width || imag.width) / 2),
        hMiddle: Math.round(y + (height || imag.height) / 2),

        zIndex: cntZ,
        background: url,
        backgroundRepeat: imag.backgroundRepeat,
        filename: filename,
        borderTopLeftRadius: imag.borderTopLeftRadius,
        borderTopRightRadius: imag.borderTopRightRadius,
        borderBottomLeftRadius: imag.borderBottomLeftRadius,
        borderBottomRightRadius: imag.borderBottomRightRadius,
        backgroundSize: imag.backgroundSize,
        border: imag.border,
        repeat: imag.repeat,
        targetLink: imag.targetLink,
        icon: imag.icon,
        backgroundPosition: imag.backgroundPosition,
        BGColor: "#f1c091",
        aspectRatio: aspectRatio || imag.aspectRatio,
        aspectLock: imag.aspectLock,
        lockBoolean: imag.lockBoolean,
        hideBoolean: imag.hideBoolean,
        opacity: imag.hideBoolean ? 0 : 1,
        compOpacity: imag.compOpacity,
        layerName:
          imag.layerName === "Image"
            ? imag.layerName + layerCnt
            : imag.layerName,
        layerNameUpdated:
          imag.layerName === "Image"
            ? imag.layerName + layerCnt
            : imag.layerName,
        //skewAnimation: imag.skewAnimation,
        //cursor: imag.targetLink !== "http://" ? "pointer" : "default",
      },
      keyFrames: imag.keyFrames || [],
    };
    setCount(count + 1);
    if (origin !== "fromGetAssets") {
      setLayerCompData([Obj, ...layerCompData]);
      setSelectedComponent(Obj);
    }

    setLayerId({
      eventDetails: "",
      type: "",
    });

    return Obj;
  }

  const allowDroplayer = (ev) => {
    ev.preventDefault();
    //ev.dataTransfer.dropEffect = "copy";
  };
  const handleTextChangeInLayerComp = (text, index, type) => {
    // let tempData = { ...selectedComponent };
    // tempData.froalaText = text;
    // setSelectedComponent(tempData);
    let data = [...layerCompData];
    data[index].froalaText = text;
    if (layerCompData[index].componentStyles.expandAndClose) {
      layerCompData[index].componentStyles.expandClose.expandHeight =
        document.getElementById("expandClose" + layerCompData[index].id)
          ?.clientHeight ||
        layerCompData[index].componentStyles.expandClose.expandHeight;
    }
    setLayerCompData(data);
  };
  const getLayerDetails = (id, type, url) => {
    setLayerId({ eventDetails: id, type: type, url: url });

    // if (type === IMAGE) {
    //   //setSelectedComponent({});
    // }
  };

  useEffect(() => {
    //  setCanvasScrollToggle(canvasDimension.height);
    getEditSaveAssets(bannerId);
    if (bannerId)
      sendSaveModuleIdHistory(
        {
          contentType: "Banner",
          contentId: [bannerId],
        },
        (response, err, errorCode) => {
          if (err) {
            const errCoponent = ErrComponentToRender(errorCode);
          } else {
          }
        }
      );
    document.addEventListener("keydown", captureKeyCodes, false);
  }, []);

  const captureKeyCodes = (event) => {
    if (
      tempSelect?.current?.componentStyles?.lockBoolean ||
      tempSelect?.current?.componentStyles?.hideBoolean ||
      !playPauseRef?.current?.animationCompleted
    )
      return;
    keyPressAction(event, tempSelect, updateCompValues, setVerticalLineFor, {
      count: compCountRef.current,
      setCount: setCount,
      setShowPaste: setShowPaste,
      setVisibility: setVisibility,
      layerCompData: [...tempLayer.current],
      setLayerCompData: setLayerCompData,
      copiedComponent: copiedComponentRef.current,
      countZindex: refCountZindex.current,
      multiSelectedComps: [...refMultiselected.current],
      setMultiSelectedComps: setMultiSelectedComps,
      setCopiedComponent: setCopiedComponent,
      componentType: componentType,
      imageOnCanvas: imageOnCanvas,
    });

    handleDeleteKey(event);
  };

  const NavigateToLandingPage = (errorMessage) => {
    toast.error(<div className="notificationText">{t(errorMessage)}</div>, {
      hideProgressBar: false,
      position: "top-right",
      autoClose: 800,
      transition: Bounce,
    });
    setTimeout(() => {
      history.push("/landingPage");
      setLoadingModules(false);
    }, 900);
    /////

    // toast.error(
    //   <div className="notificationText">{t("Asset Id not found")}</div>,
    //   {
    //     hideProgressBar: false,
    //     position: "top-right",
    //     autoClose: 800,
    //     transition: Bounce,
    //   }
    // );
    // setTimeout(() => {
    //   history.push("/landingPage");
    // }, 900);
  };
  function getEditSaveAssets(id) {
    setLoadingModules(true);
    getSaveAssets(id, async (response, err, errorCode) => {
      const data = await response.data;
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        setundoFlag(false);
        setUndoAnimationObjFlag(false);
        if (errorCode === 500) {
          NavigateToLandingPage("Asset Id not found");
          return;
        }
        // setRefreshed(false);
        setLoadingModules(false);
      } else {
        // appData.setAssetType(response.data.assetType);//DeleteNavigate
        if (
          response.data.lockStatus === "Locked" &&
          response.data.madeBy === "others"
        ) {
          NavigateToLandingPage(`${response.data.assetType} Expired`);
          return;
        } else if (response.data.assetStatus === "INACTIVE") {
          NavigateToLandingPage(`${response.data.assetType} is Deleted`);
          return;
        }
        setLoadingModules(false);
        if (response.data.assetpages.length > 0) renderAppliedFonts(response);
        let obj1 = {};
        let tempLayerCompData = [];
        let tempSelect = Object.assign({}, selectedComponent);
        obj1.assetName = data.assetName && data.assetName;
        obj1.campaignId = data.campaignId && data.campaignId;
        obj1.brandId = data.brandId && data.brandId;
        obj1.campaign = data.campaign && data.campaign;
        obj1.marketName = data.marketName && data.marketName;
        obj1.marketCode = data.marketCode && data.marketCode;
        // obj1.tagName = data.tagName && data.tagName;
        obj1.height = data.height && data.height.toString();
        obj1.width = data.width && data.width.toString();
        obj1.zIndex = data.zIndex && data.zIndex.toString();
        obj1.isHtmlEnable = data.isHtmlEnable;
        obj1.isPdfEnable = data.isPdfEnable;

        let canvasScale = data?.attributes?.canvasScale
          ? data?.attributes?.canvasScale
          : {
              divisions: 20,
              width: 910,
            };

        if (data?.attributes?.customTemp) {
          setGenericInfo({
            ...genericInfo,
            Height: data.height && data.height,
            Width: data.width && data.width,
          });
        } else {
          setGenericInfo({
            ...genericInfo,
            Height: 0,
            Width: 0,
          });
          setCanvasDimension({
            ...canvasDimension,
            height: data.height && data.height,
            width: data.width && data.width,
          });
        }
        setCanvasDimension({
          ...canvasDimension,
          height: data.height && data.height,
          width: data.width && data.width,
          fps: data?.attributes ? data.attributes.fps : 60,
          backgroundColor: data?.attributes?.backgroundColor
            ? data?.attributes?.backgroundColor
            : { r: 255, g: 255, b: 255, a: 0.9 },
          globalLink: data?.attributes?.globalLink || "https://",
          checkGloballink: data?.attributes?.checkGloballink || false,
          checkLoops: data?.attributes?.checkLoops,
          loopAnimation: data?.attributes?.loopAnimation || 0,
          refreshed: true,
          custom: data?.attributes?.custom,
          customTemp: data.attributes?.customTemp,
        });
        appData.setCustomTempSize(data.attributes?.customTemp);

        let linkedMod =
          data?.attributes && data?.attributes?.linkedModules?.linkedModules;
        setLinkedModulesType(linkedMod);
        if (linkedMod === "veevaModule") {
          const moduleId = data?.modules.map((module) => module.moduleId);
          setModules({ moduleId });
        } else {
          const filteredModules = data?.modules.filter(
            (module) => module.isParent === true
          );
          const moduleId = filteredModules.map((module) => module.moduleId);
          setModules({ moduleId });
        }
        setToggleAnimatedBanner(data.isAnimated);
        setShowBannertype(data.isAnimated === true ? "Animated" : "Static");
        setShowAssettype(data.assetType);
        setShowBannerName(data?.assetName);
        appData.setCampaignId(data.campaignId);
        setBannerData(data);
        appData.setBannersEditCardData({
          cards: data,
        });
        appData.setCardData({
          cards: [],
          search: "search",
        });

        appData.setAssetInfo({
          assetId: data.id,
          assetName: data.assetName,
        });

        let obj = {};
        if (data?.attributes?.linkedModules) {
          appData.setVeevaModulesLinked(true);
        }
        obj.brandName = data.brandName;
        obj.campaign = data.campaign;
        obj.market = data.marketName;
        appData.updateGenericInfo({
          ...appData?.genericInfo,
          brand: data.brandName,
          campaign: data.campaign,
          market: data.marketName,
          // tagName: data.tagName,
          canvasScale: canvasScale,
          searchedFields: data.attributes.searchExpression,
          searchFieldsTobeSaved:
            data?.attributes?.linkedModules?.linkedModules === "nextModule"
              ? data.attributes.searchExpression
              : {
                  moduleNameArray:
                    data?.attributes?.searchExpression?.moduleNameArray ||
                    data?.attributes?.searchExpression?.name,
                  brandName: data?.attributes?.searchExpression?.brandName,
                  claimCategoryArray:
                    data?.attributes?.searchExpression?.claimCategory ||
                    data?.attributes?.searchExpression?.claimCategoryArray,
                  assetNameArray: data?.attributes?.searchExpression?.assetName,
                },

          // searchedFields: {
          //   moduleNameArray: data?.attributes?.searchExpression?.name,
          //   assetNameArray: "",
          //   brandName: data.attributes?.searchExpression?.brandName,
          //   claimCategoryArray:
          //     data.attributes?.searchExpression?.claimCategory,
          //   coreClaimArray: "",
          //   cardTitle: [],
          //   errors: {},
          //   canReset: true,
          // },
          linkedData: {
            activeCards: {
              moduleId: response.data.modules.map((module) => module.moduleId),
            },
            values:
              data?.attributes?.linkedModules?.linkedModules === "nextModule"
                ? data?.attributes?.searchExpression
                : {
                    moduleNameArray:
                      data?.attributes?.searchExpression?.moduleNameArray ||
                      data?.attributes?.searchExpression?.name,
                    brandName: data?.attributes?.searchExpression?.brandName,
                    claimCategoryArray:
                      data?.attributes?.searchExpression?.claimCategory ||
                      data?.attributes?.searchExpression?.claimCategoryArray,
                    assetNameArray:
                      data?.attributes?.searchExpression?.assetName,
                  },
          },
        });
        setDamsId(
          data?.assetMlr[0]?.veevaDocNumber === undefined
            ? "_"
            : data?.assetMlr[0]?.veevaDocNumber
        );
        // appData.setActiveCards(response.data.modules);
        appData.setMarketCode(data.marketCode);
        setMLRstatus(
          data?.assetMlr[0]?.reviewStatus === undefined
            ? "Not Submitted"
            : data?.assetMlr[0]?.reviewStatus
        );
        appData.setBrandId(data.brandId);
        var formDataObj = [];

        if (
          response.data?.assetType === "Banner" &&
          response.data.modules.length &&
          response.data?.attributes?.linkedModules?.linkedModules !==
            "veevaModule"
        ) {
          for (let i = 0; i < data.modules.length; i++) {
            formDataObj.push(data.modules[i].moduleId);
          }

          appData.setFlagEditBanner(true);

          cardInfoForcardIds(
            JSON.stringify(formDataObj),
            async (response, err, errorCode) => {
              const dataSearch = await response.data;
              if (err) {
                const errCoponent = ErrComponentToRender(errorCode);

                appData.setCardData({
                  cards: [],
                  search: "search",
                });
              } else {
                // setcardsEditFLow(dataSearch);
                appData.setActiveCards(dataSearch);

                dataSearch.forEach((moduleRef, index) => {
                  let obj = data.modules.find(
                    (o) => o.code === moduleRef.moduleCode
                  );

                  moduleRef.record_index = obj.record_index;
                  moduleRef.isParent = obj.isParent;
                });
                dataSearch.sort((a, b) =>
                  a.record_index > b.record_index ? 1 : -1
                );
                // appData.setActiveCards(dataSearch);
              }
            }
          );
        }
        //else appData.setActiveCards([]);
        if (
          response.data?.assetType === "Banner" &&
          response.data.modules.length &&
          response.data?.attributes?.linkedModules?.linkedModules ===
            "veevaModule"
        )
          getVeevaModuleCardinfo(data, data?.modules);
        if (data.assetpages.length) {
          let elemCSSProp = {},
            compType = "",
            coOrdinates = {},
            constructObj = "",
            animationObj = {};
          data.assetpages[0].assetpagecomponent.forEach((element, index) => {
            elemCSSProp = Object.assign({}, element.components);
            coOrdinates.x = elemCSSProp.left;
            coOrdinates.y = elemCSSProp.top;

            if (
              element.componentType === "Text" ||
              element.componentType === "Shape"
            ) {
              compType = element.components.textComponentType.addContent;
            } else {
              compType = element.componentType;
            }

            if (compType === "Image") {
              constructObj = imageOnCanvas(
                "fromGetAssets",
                elemCSSProp.background,
                coOrdinates.x,
                coOrdinates.y,
                elemCSSProp.filename,
                elemCSSProp,
                elemCSSProp.width,
                elemCSSProp.height,
                index
              );
            } else {
              constructObj = componentType[compType](
                coOrdinates,
                null,
                elemCSSProp,
                index
              );
            }

            if (element.components.hasOwnProperty("animationObj")) {
              animationObj = {
                ...animationObj,
                [constructObj.id]: element.components.animationObj,
              };
              animationObj[constructObj.id].timeLine.id =
                "slider-TimeLine" + index;
            }
            tempLayerCompData = [...tempLayerCompData, constructObj];
            tempSelect = { ...constructObj };
          });
          if (tempLayerCompData.length > 0) {
            let temp = JSON.parse(JSON.stringify(tempLayerCompData));
            setLayerLengthAtRefresh(temp);
            setRefreshed(true);
          }

          // getVeevaModuleCardinfo(data, data?.modules);

          setLayerCompData(tempLayerCompData);
          setSelectedComponent(tempSelect);
          setCount(tempLayerCompData?.length);
          setAnimationObject(animationObj);
        }
      }
      //to avoid undo enable after refresh (was happening for scrollbar)
      setRefreshCompleted((prevRefreshCompleted) => ++prevRefreshCompleted);
    });
  }

  const layerSequence = (type) => {
    let result = tempLayer.current.filter((items, index) => {
      return items.componentType === type;
    });
    return result;
  };

  // const fetchAlltheModules = (claimCAtData, data) => {
  //   console.log(claimCAtData);
  //   let searchedClaimCat = claimCAtData.map((claim) => {
  //     if (claim.label === data?.attributes?.searchExpression?.claimCategory)
  //       return claim.name;
  //   });
  //   var formDataObj = {
  //     brandName: data?.brandName,
  //     name: data?.attributes?.searchExpression?.name,
  //     claimCategory: searchedClaimCat,
  //     campaign: "",
  //   };
  //   searchModules(
  //     JSON.stringify(formDataObj),
  //     async (response, err, errorCode) => {
  //       const dataSearch = await response.data;
  //       if (err) {
  //         const errCoponent = ErrComponentToRender(errorCode);
  //         appData.setCardData({
  //           cards: [],
  //           search: "search",
  //         });
  //         setLoadingModules(false);
  //       } else {
  //         appData.setCardData({
  //           cards: dataSearch,
  //           search: "search",
  //         });
  //         let canvasScale = data?.attributes?.canvasScale
  //           ? data?.attributes?.canvasScale
  //           : {
  //               divisions: 20,
  //               width: 910,
  //             };

  //         appData.updateGenericInfo({
  //           ...appData.genericInfo,
  //           brand: data.brandName,
  //           campaign: data.campaign,
  //           market: data.marketName,
  //           // tagName: data.tagName,
  //           canvasScale: canvasScale,
  //           searchedFields: data.attributes.searchExpression,
  //           linkedData: {
  //             activeCards: {
  //               moduleId: data.modules.map((module) => module.moduleId),
  //             },
  //             totalCards: dataSearch,
  //             values: {
  //               moduleNameArray: data.attributes.searchExpression.name,
  //               brandName: data.attributes.searchExpression.brandName,
  //               claimCategoryArray:
  //                 data.attributes.searchExpression.claimCategory,
  //               assetNameArray: data.attributes.searchExpression.assetName,
  //             },
  //           },
  //         });
  //         appData.setVeevaModulesLinked(true);
  //         setLoadingModules(false);
  //       }
  //     },
  //     linkedModulesType
  //   );
  // };
  const getVeevaModuleCardinfo = (dataAll, idsArr) => {
    // setLoadingModules(true);
    let veevaIds = [];
    idsArr.forEach((vModule) => {
      let arrOfids = vModule?.moduleId;
      veevaIds.push(arrOfids);
    });

    getVeevaCardsInleftcont(veevaIds, async (response, err, errorCode) => {
      if (err) {
        // const errCoponent = errComponentToRender(errorCode);
        console.log(err);
      } else {
        const data = await response.data;
        getCoreClaimCategoriesforVeeva((response, err, errorCode) => {
          if (err) {
            const errVeevaCoponent = ErrComponentToRender(errorCode);
            console.log(err);
            // categoriesErr(errVeevaCoponent);
          } else {
            setClaimCatArray(response.data);
            // fetchAlltheModules(response.data, dataAll);
          }
        });

        appData.setActiveCards(data);
        // setVeevaModuleCardData(data);
      }
    });
    //setOpenVeevaContentModal(false);
  };

  useEffect(() => {
    if (bannerId) getEditSaveAssets(bannerId);
    if (appData && appData?.genericInfo?.bannerSize) {
      let bannerWidthAandHeight = appData?.genericInfo?.bannerSize.split("X");
      setCanvasDimension({
        ...canvasDimension,
        width: parseInt(bannerWidthAandHeight[0]),
        height: parseInt(bannerWidthAandHeight[1]),
      });
    }
  }, []);
  const [canvasScrollToggle, setCanvasScrollToggle] = useState({
    height: canvasDimension.height,
    width: canvasDimension.width,
  });
  const handleZoomIn = () => {
    let canvasHeight =
      (canvasDimension.height / 100) * (canvasDimension.zoomValue + 10);
    let canvasWidth =
      (canvasDimension.width / 100) * (canvasDimension.zoomValue + 10);
    setCanvasScrollToggle({ height: canvasHeight, width: canvasWidth });
    if (canvasDimension.zoomValue <= 390) {
      setCanvasDimension({
        ...canvasDimension,
        scale: canvasDimension.scale + 0.1,
        zoomValue: canvasDimension.zoomValue + 10,
      });
      setCanvasUpdated(true);
    }
  };

  const handleZoomOut = () => {
    let canvasHeight =
      (canvasDimension.height / 100) * (canvasDimension.zoomValue - 10);
    let canvasWidth =
      (canvasDimension.width / 100) * (canvasDimension.zoomValue - 10);
    setCanvasScrollToggle({ height: canvasHeight, width: canvasWidth });
    if (canvasDimension.zoomValue > 30) {
      setCanvasDimension({
        ...canvasDimension,
        scale: canvasDimension.scale - 0.1,
        zoomValue: canvasDimension.zoomValue - 10,
      });
      setCanvasUpdated(true);
    }
  };

  const pixelToTimeConverter = (markerPosn) => {
    let sec = Math.trunc(markerPosn / 45);
    let milliSecs = Math.ceil((markerPosn / 45 - sec) * 10) * 10;
    if (milliSecs === 100) {
      sec = sec + 1;
      milliSecs = 0;
    }
    setSeconds(sec);
    setMillSeconds(milliSecs);
  };
  const openPopwithmarkerMoveAndTime = (
    markerPosn,
    tempAddedAnimationActionList
  ) => {
    setMarker(markerPosn);
    SetPopupLeft(markerPosn - 140);
    pixelToTimeConverter(markerPosn);
    if (tempAddedAnimationActionList !== undefined) {
      for (let compAnmObject in tempAddedAnimationActionList) {
        if (
          tempAddedAnimationActionList[
            compAnmObject
          ]?.animationObject?.hasOwnProperty(selectedComponent.id)
        ) {
          if (
            tempAddedAnimationActionList[compAnmObject]?.animationObject[
              selectedComponent.id
            ]?.animation?.length < 1
          )
            setOpenTimelinePopup({
              popupWindow: false,
              fromUnRedo: true,
            });
          else
            setOpenTimelinePopup({
              popupWindow: true,
              fromUnRedo: true,
            });
        }
      }
    } else
      setOpenTimelinePopup({
        popupWindow: true,
        fromUnRedo: true,
      });
  };
  useEffect(() => {
    tempLayer.current = layerCompData;
    tempSelect.current = selectedComponent;
    refMultiselected.current = multiSelectedComps;
    animationObjRef.current = animationObject;
    compCountRef.current = count;
    copiedComponentRef.current = copiedComponent;
    refCountZindex.current = countZindex;

    if (
      Object.entries(animationObject).length > 0 &&
      Object.entries(animationObject).length > layerCompData.length
    ) {
      let tempAnimationObj = {};
      for (props in animationObject) {
        layerCompData.forEach((layer) => {
          if (props === layer.id)
            tempAnimationObj[props] = animationObject[props];
        });
      }
      setAnimationObject(tempAnimationObj);
    }
  }, [
    layerCompData,
    selectedComponent,
    multiSelectedComps,
    animationObject,
    count,
    copiedComponent,
    countZindex,
  ]);

  const undoAnimation = (
    tempListForReDo,
    tempAddedActionList,
    selctdCompsAnimationApplied
  ) => {
    if (
      JSON.stringify(
        addedAnmationActionList[addedAnmationActionList?.length - 1]
          ?.animationObject
      ) ===
      JSON.stringify(
        addedAnmationActionList[addedAnmationActionList?.length - 2]
          ?.animationObject
      )
    )
      return;

    let tempAddedAnimationActionList = JSON.parse(
      JSON.stringify(addedAnmationActionList)
    );
    tempListForReDo.push(tempAddedAnimationActionList.pop());
    //close the popup when keyframe is deleted

    //close the popup when keyframe is deleted

    setAddedAnmationActionList(tempAddedAnimationActionList);
    setListForReDo(tempListForReDo);

    if (refreshed) {
      if (tempAddedAnimationActionList?.length === 0) {
        let tempAnimations = JSON.parse(JSON.stringify(animationObject));
        // tempAnimations.pop();
        setAnimationObject(tempAnimations);
        return;
      }
    }
    if (tempAddedAnimationActionList.length === 0) setAnimationObject({});
    else {
      let currentAnmObject = JSON.parse(
        JSON.stringify(
          tempAddedAnimationActionList[tempAddedAnimationActionList.length - 1]
            ?.animationObject
        )
      );
      setAnimationObject(currentAnmObject);
    }
    let markerPosn = 0;
    for (let val in selctdCompsAnimationApplied) {
      if (
        val !== "componentAdded" &&
        val !== "keyFramePasted" &&
        val !== "keyFrameadded" &&
        selctdCompsAnimationApplied[val] === "true"
      ) {
        markerPosn = isNaN(parseInt(selctdCompsAnimationApplied.marker))
          ? 0
          : parseInt(selctdCompsAnimationApplied.marker);
        openPopwithmarkerMoveAndTime(markerPosn);
      } else if (
        (val === "keyFrameadded" || val === "keyFramePasted") &&
        selctdCompsAnimationApplied[val] === "true"
      ) {
        let mP =
          tempAddedAnimationActionList[tempAddedAnimationActionList.length - 1]
            ?.selecetedComponent?.animationApllied?.marker;
        markerPosn = isNaN(parseInt(mP)) ? 0 : parseInt(mP);
        openPopwithmarkerMoveAndTime(markerPosn, tempAddedAnimationActionList);
      }
      // else setOpenTimelinePopup(false);
    }

    setAnimationChanged(false);
    // setCompDeleted(false);
  };
  const handleOnUndo = () => {
    // let x = undoCount;
    // setUndoCount((prevUndoCount) => ++prevUndoCount);
    setPopUpValuesEdited(false);
    // setOpenTimelinePopup(false);
    setOpenTimelinePopup({
      popupWindow: false,
      fromUnRedo: false,
    });
    setundoFlag(true);
    setUndoAnimationObjFlag(true);
    // if (
    //   layerCompData.length > 0 &&
    //   addedActionsList[
    //     addedActionsList.length - 1
    //   ].layerCompData[0].hasOwnProperty("canvasEdited")
    // )
    // handleCanvasUndo();
    let tempListForReDo = JSON.parse(JSON.stringify(listForReDo));
    let tempAddedActionList = JSON.parse(JSON.stringify(addedActionsList));

    tempListForReDo.push(tempAddedActionList.pop()); //LIFO
    setAddedActionsList(tempAddedActionList);
    setListForReDo(tempListForReDo);
    if (refreshed) {
      if (tempAddedActionList?.length === 0) {
        let tempLayers = JSON.parse(JSON.stringify(layerCompData));
        tempLayers.pop();
        setLayerCompData(tempLayers);
        setSelectedComponent("undefined");
        return;
      }
    }
    if (tempAddedActionList?.length === 0) {
      setLayerCompData([]);
      setSelectedComponent("undefined");
    } else {
      let currentLayer = JSON.parse(
        JSON.stringify(
          tempAddedActionList[tempAddedActionList.length - 1]?.layerCompData
        )
      );
      setLayerCompData(currentLayer);

      if (
        tempAddedActionList[tempAddedActionList.length - 1]
          ?.selecetedComponent === "compdeleted"
      )
        setSelectedComponent("compdeleted");
      else {
        let filteredSelected = currentLayer.filter(
          (comp) =>
            comp.id ===
            tempAddedActionList[tempAddedActionList.length - 1]
              ?.selecetedComponent.id
        )[0];
        if (filteredSelected === undefined) {
          filteredSelected = tempAddedActionList[
            tempAddedActionList.length - 1
          ]?.layerCompData?.filter(
            (comp) =>
              !tempListForReDo[
                tempListForReDo.length - 1
              ]?.layerCompData?.includes(comp)
          )[0];
        }
        setSelectedComponent(filteredSelected);
      }
    }

    if (toggleAnimatedBanner) {
      let index = tempListForReDo[
        tempListForReDo.length - 1
      ]?.layerCompData.findIndex((comp) => comp.id === selectedComponent?.id);

      if (index >= 0) {
        let selctdCompsAnimationApplied =
          tempListForReDo[tempListForReDo.length - 1]?.layerCompData[index]
            .animationApllied;
        for (let prop in selctdCompsAnimationApplied) {
          if (selctdCompsAnimationApplied[prop] === "true") {
            undoAnimation(
              tempListForReDo,
              tempAddedActionList,
              selctdCompsAnimationApplied
            );
            break;
          }
        }
      } else if (
        tempListForReDo[tempListForReDo.length - 1].selecetedComponent ===
        "compdeleted"
      )
        undoAnimation(tempListForReDo, tempAddedActionList);
    }
  };

  const prepareUndoArrayOnRefresh = (a, b, c) => {
    if (refreshCompleted < 2) return;
    let tempAddedActionList = [];
    // setBannerChanged(true);
    // setLayerChangedOnPause(true);
    // setRefreshed(false);
    if (
      addedActionsList.length === 1 &&
      Object.values(addedActionsList[0]).every((x) => x === "" || !x?.length)
    )
      tempAddedActionList = [
        {
          layerCompData: JSON.parse(JSON.stringify(tempLayerCompData)),
          selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
        },
        {
          layerCompData: JSON.parse(JSON.stringify(layerCompData)),
          selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
        },
      ];
    else {
      tempAddedActionList = JSON.parse(JSON.stringify(addedActionsList));
      tempAddedActionList.push({
        layerCompData: JSON.parse(JSON.stringify(layerCompData)),
        selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
      });
    }
    setAddedActionsList(tempAddedActionList);
    if (b !== undefined && b[c] === "true") {
      // {
      b[c] = "false";
      setundoFlag(true);
    }
  };
  const preapareUndoArray = (a, b, c) => {
    // setBannerChanged(true);
    // setLayerChangedOnPause(true);

    let x = animationObject;
    let tempAddedActionList = JSON.parse(JSON.stringify(addedActionsList));
    if (!tempAddedActionList.length) {
      tempAddedActionList = [
        {
          layerCompData: [],
          selectedComponent: "",
        },
      ];
    }

    let newAction = JSON.parse(
      JSON.stringify(tempAddedActionList[tempAddedActionList?.length - 1])
    );
    newAction.layerCompData = JSON.parse(JSON.stringify(layerCompData));
    newAction.selecetedComponent = JSON.parse(
      JSON.stringify(selectedComponent)
    );
    if (layerCompData?.length > 0) {
      if (
        tempAddedActionList?.length === 1 &&
        tempAddedActionList[0]?.layerCompData?.length === 0 &&
        tempAddedActionList[tempAddedActionList.length - 1]?.layerCompData
          ?.length === 0
      ) {
        tempAddedActionList[0] = newAction;
      } else if (
        JSON.stringify(tempAddedActionList[tempAddedActionList.length - 1]) !==
        JSON.stringify(newAction)
      ) {
        tempAddedActionList.push(newAction);
      }
    } else {
      tempAddedActionList.push(newAction);
    }
    setAddedActionsList(tempAddedActionList);
    if (b !== undefined && b[c] === "true") {
      //  {
      b[c] = "false";
      setundoFlag(true);
    }
  };

  const preapareUndoAnimationArray = () => {
    // setBannerChanged(true);
    // setLayerChangedOnPause(true);

    let tempAddedAnimationActionList = JSON.parse(
      JSON.stringify(addedAnmationActionList)
    );
    if (!tempAddedAnimationActionList?.length) {
      tempAddedAnimationActionList = [
        {
          animationObject: {},
          selectedComponent: "",
        },
      ];
    }
    let newAction = JSON.parse(
      JSON.stringify(
        tempAddedAnimationActionList[tempAddedAnimationActionList.length - 1]
      )
    );
    newAction.animationObject = JSON.parse(JSON.stringify(animationObject));
    newAction.selecetedComponent = JSON.parse(
      JSON.stringify(selectedComponent)
    );
    if (Object.values(animationObject)?.length > 0) {
      if (
        tempAddedAnimationActionList?.length === 1 &&
        Object.values(tempAddedAnimationActionList[0]?.animationObject)
          ?.length === 0 &&
        Object.values(
          tempAddedAnimationActionList[tempAddedAnimationActionList?.length - 1]
            ?.animationObject
        )?.length === 0
      ) {
        tempAddedAnimationActionList[0] = newAction;
      } else if (
        JSON.stringify(
          tempAddedAnimationActionList[tempAddedAnimationActionList?.length - 1]
        ) !== JSON.stringify(newAction)
      ) {
        tempAddedAnimationActionList.push(newAction);
      }
    } else {
      tempAddedAnimationActionList.push(newAction);
    }
    setAddedAnmationActionList(tempAddedAnimationActionList);
  };
  const prepareUndoAnimationArrayOnRefresh = () => {
    setRefreshed(false);
    let tempAddedAnimationActionList = [];
    if (
      addedAnmationActionList?.length === 1 &&
      Object.values(addedAnmationActionList[0]).every(
        (x) => x === "" || !Object.values(x)?.length
      )
    )
      tempAddedAnimationActionList = [
        {
          animationObject: JSON.parse(JSON.stringify(tempAnimationObject)),
          selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
        },
        {
          animationObject: JSON.parse(JSON.stringify(animationObject)),
          selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
        },
      ];
    else {
      tempAddedAnimationActionList = JSON.parse(
        JSON.stringify(addedAnmationActionList)
      );
      tempAddedAnimationActionList.push({
        animationObject: JSON.parse(JSON.stringify(animationObject)),
        selecetedComponent: JSON.parse(JSON.stringify(selectedComponent)),
      });
      // setBannerChanged(true);
      // setLayerChangedOnPause(true);
    }
    setAddedAnmationActionList(tempAddedAnimationActionList);
  };
  const handleAnimationChangesWithLayer = (
    CompAnmAppliedCurrent,
    propertyChanged
  ) => {
    if (selectedComponent !== "compdeleted") {
      for (let x in CompAnmAppliedCurrent) {
        if (CompAnmAppliedCurrent[x] === "true") {
          propertyChanged = x;
        }
      }
    }
    return propertyChanged;
  };

  // useEffect(() => {
  //   if (JSON.stringify(layerCompData) === JSON.stringify(tempLayerCompData)) {
  //     if (undoFlag) setundoFlag(false);
  //     return;
  //   }

  //   if (
  //     layerCompData.length > 0 &&
  //     layerCompData[layerCompData.length - 1]?.eachDrag !== "yes"
  //   ) {
  //     // if (tempLayerCompData.length === 0 || !undoFlag)
  //     setTempLayerCompData(JSON.parse(JSON.stringify(layerCompData)));
  //   }
  //   // if (layerCompData[layerCompData.length - 1]?.mulSelected !== "yes") {
  //   //   console.log("bannerChanged true");

  //   //   setBannerChanged(true);
  //   //   setLayerChangedOnPause(true);
  //   // } else {
  //   //   layerCompData[layerCompData.length - 1].mulSelected = "no";
  //   //   setBannerChanged(false);
  //   //   setLayerChangedOnPause(false);
  //   // }
  //   if (layerCompData[layerCompData.length - 1]?.mulSelected === "yes") {
  //     layerCompData[layerCompData.length - 1].mulSelected = "no";
  //     if (!bannerChanged) setBannerChanged(false);
  //     setLayerChangedOnPause(false);
  //   } else if (!refreshed) {
  //     if (layerCompData.length !== tempLayerCompData.length) {
  //       setBannerChanged(true);
  //     } else {
  //       let changeMultiSelect1 = [...layerCompData].map((layerComp) => {
  //         layerComp.mulSelected = null;
  //         return layerComp;
  //       });
  //       let changeMultiSelect2 = [...tempLayerCompData].map((layerComp) => {
  //         layerComp.mulSelected = null;
  //         return layerComp;
  //       });
  //       if (
  //         JSON.stringify(changeMultiSelect1) !==
  //         JSON.stringify(changeMultiSelect2)
  //       ) {
  //         setBannerChanged(true);
  //       }
  //     }
  //     setLayerChangedOnPause(true);
  //   }
  //   //

  //   let propertyChanged = false;
  //   // let currentLayers = JSON.parse(JSON.stringify(layerCompData));
  //   let CompAnmAppliedCurrent = {};

  //   if (
  //     toggleAnimatedBanner &&
  //     layerCompData[layerCompData.length - 1]?.eachDrag !== "yes"
  //   ) {
  //     CompAnmAppliedCurrent =
  //       layerCompData[
  //         layerCompData.findIndex((comp) => comp.id === selectedComponent.id)
  //       ]?.animationApllied;
  //     if (
  //       CompAnmAppliedCurrent !== undefined &&
  //       Object.values(CompAnmAppliedCurrent).some((val) => val === "true")
  //     ) {
  //       propertyChanged = handleAnimationChangesWithLayer(
  //         CompAnmAppliedCurrent,
  //         propertyChanged
  //       );
  //     }
  //   }
  //   if (!undoFlag && !refreshed) {
  //     if (
  //       (layerCompData.length > 0 || selectedComponent === "compdeleted") &&
  //       layerCompData[layerCompData.length - 1]?.eachDrag !== "yes"
  //     )
  //       preapareUndoArray(
  //         tempLayerCompData,
  //         CompAnmAppliedCurrent,
  //         propertyChanged
  //       );
  //   } else {
  //     setRefreshed(false);
  //     if (
  //       !undoFlag &&
  //       JSON.stringify(layerCompData) !== JSON.stringify(tempLayerCompData) &&
  //       (tempLayerCompData.length > 0 || selectedComponent === "compdeleted")
  //     ) {
  //       if (layerCompData[layerCompData.length - 1]?.eachDrag !== "yes")
  //         prepareUndoArrayOnRefresh(
  //           tempLayerCompData,
  //           CompAnmAppliedCurrent,
  //           propertyChanged
  //         );
  //     }
  //   }
  //   if (undoFlag) setundoFlag(false);
  // }, [JSON.stringify(layerCompData)]);

  // useEffect(() => {
  //   if (
  //     JSON.stringify(animationObject) === JSON.stringify(tempAnimationObject)
  //   ) {
  //     if (undoAnimationObjFlag) setUndoAnimationObjFlag(false);
  //     return;
  //   }
  //   if (Object.values(animationObject)?.length > 0) {
  //     setTempAnimationObject(JSON.parse(JSON.stringify(animationObject)));
  //   }
  //   if (!undoAnimationObjFlag && !refreshed) {
  //     preapareUndoAnimationArray();
  //     setAnimationChanged(true);
  //   } else {
  //     if (
  //       !undoAnimationObjFlag &&
  //       JSON.stringify(animationObject) !==
  //         JSON.stringify(tempAnimationObject) &&
  //       Object.values(tempAnimationObject).length > 0
  //     ) {
  //       prepareUndoAnimationArrayOnRefresh();
  //       setAnimationChanged(true);
  //     }
  //     setUndoAnimationObjFlag(false);
  //   }
  // }, [JSON.stringify(animationObject)]);

  const deleteComponentOnCanvas = (event) => {
    // setOpenTimelinePopup(false); // 2776
    if (
      !playPauseRef.current.animationCompleted ||
      selectedComponent?.componentStyles?.lockBoolean ||
      multiSelectedComps.length > 0
    )
      return;
    if (refMultiselected.current.length > 0) {
      deleteMultiSelectedComps(
        event,
        setOpenTimelinePopup,
        [...tempLayer.current],
        setLayerCompData,
        [...refMultiselected.current],
        setMultiSelectedComps,
        { ...animationObjRef.current },
        setAnimationObject,
        setSeconds,
        setMillSeconds,
        setSelectedComponent,
        setVisibility
      );
    } else {
      setOpenTimelinePopup({
        popupWindow: false,
        fromUnRedo: false,
      });
      let data = [...tempLayer.current];
      let selectCompObj = { ...tempSelect.current };

      if (data && selectCompObj) {
        const filteredComponents = data.filter(
          (item) => item.id !== selectCompObj.id
        );
        if (event !== "fromKey" && Object.values(animationObject).length > 0) {
          let tempAnimationObj = {};
          for (props in animationObject) {
            if (props !== selectCompObj.id)
              tempAnimationObj[props] = animationObject[props];
          }
          setAnimationObject(tempAnimationObj);
        }
        if (layerCompData.length === 1) {
          setMillSeconds(0);
          setSeconds(0);
        }
        // setCompDeleted(true);
        setSelectedComponent("compdeleted");
        setLayerCompData(filteredComponents);
        setVisibility(false);
      }
    }
  };

  const copyCanvasComponent = () => {
    if (multiSelectedComps.length > 0) setCopiedComponent(multiSelectedComps);
    else {
      let data = [...tempLayer.current];
      if (data && tempSelect) {
        let selectCompObj = { ...tempSelect.current };
        setCopiedComponent(selectCompObj);
      }
      setVisibility(false);
    }
  };

  const pasteComponentOnCanvas = (mm, imageOnCanvas) => {
    //console.log(mm);

    if (copiedComponent.length >= 1)
      pasteMultiSelectedComps(
        count,
        setCount,
        setShowPaste,
        setVisibility,
        layerCompData,
        setLayerCompData,
        copiedComponent,
        countZindex,
        multiSelectedComps,
        setMultiSelectedComps,
        mm,
        imageOnCanvas,
        setSelectedComponent,
        selectedComponent
      );
    else {
      if (copiedComponent) {
        pasteMultiSelectedComps(
          count,
          setCount,
          setShowPaste,
          setVisibility,
          layerCompData,
          setLayerCompData,
          [copiedComponent],
          countZindex,
          multiSelectedComps,
          setMultiSelectedComps,
          mm,
          imageOnCanvas,
          setSelectedComponent,
          selectedComponent
        );
        // let tempCopiedComponents = [copiedComponent];
        // ;
        // setSelectedComponent(tempCopiedComponents[0]);
        // setLayerCompData((layerCompData) => [
        //   ...layerCompData,
        //   tempCopiedComponents[0],
        // ]);
      }
    }
  };

  const handleDeleteKey = (event) => {
    let tem = animationObject;
    if (
      event.target.tagName === "INPUT" ||
      !playPauseRef.current.animationCompleted ||
      event.target.classList.contains("fr-view")
    )
      return;
    var mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

    if (mac && event.key === "Backspace") deleteComponentOnCanvas("fromKey");
    else if (event.key === "Delete") {
      deleteComponentOnCanvas("fromKey");
    }
  };
  useEffect(() => {
    domCanvasPage = document.getElementById("mainCanvasContainer");
    domCanvasPage.onclick = function () {
      setVisibility(false);
    };
    domCanvasPage.onclick = function () {
      setShowPaste(false);
    };

    layerCompData.forEach((obj) => {
      if (obj?.componentStyles?.toggleScroll === true) {
        let parentScrollBar = document.getElementById(obj.id);
        if (parentScrollBar) {
          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-thumb"
          )[1].style.backgroundImage = `url("${obj.componentStyles.scrollThumbBg} ")`;
          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-track-v"
          )[0].style.borderRadius = obj.componentStyles.trackRadius + "px";

          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-track-v"
          )[0].style.backgroundColor = obj.componentStyles.thumbColor;

          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-track-v"
          )[0].style.width = obj.componentStyles.trackWidth + "px";

          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-thumb"
          )[1].style.width = obj.componentStyles.thumbWidth + "px";

          // parentScrollBar.getElementsByClassName(
          //   "rc-scrollbars-thumb"
          // )[1].style.height = 30 + "px";
          // parentScrollBar.getElementsByClassName(
          //   "rc-scrollbars-thumb"
          // )[0].style.height = 30 + "px";

          if (obj.componentStyles.trackRight) {
            parentScrollBar.getElementsByClassName(
              "rc-scrollbars-thumb-v"
            )[0].style.right = obj.componentStyles.thumbRight + "px";
            parentScrollBar.getElementsByClassName(
              "rc-scrollbars-track-v"
            )[0].style.right = obj.componentStyles.trackRight + "px";
          }
        }
      }
    });
  });

  const updateZindexValue = (e, type) => {
    let getId = document.getElementById(containerID);
    let data = [...layerCompData];
    // data.sort((a, b) =>
    //   a.componentStyles.zIndex >= b.componentStyles.zIndex ? 1 : -1
    // );
    let indexSelected = data.findIndex(
      (ele) => JSON.stringify(ele) === JSON.stringify(selectedComponent)
    );

    if (getId) {
      let swappedLayerCompData = [...layerCompData];
      switch (type) {
        case "Back":
          if (indexSelected !== data.length - 1)
            if (data[indexSelected].componentStyles) {
              // data[indexSelected].componentStyles.zIndex = maxZindexValue + 1;
              swappedLayerCompData = arrayMove(
                layerCompData,
                indexSelected,
                layerCompData.length
              );
              swappedLayerCompData.forEach((layerObj, index) => {
                swappedLayerCompData[index].componentStyles.zIndex =
                  swappedLayerCompData.length - index;
              });
            }
          break;
        case "Front":
          if (indexSelected !== 0)
            if (data[indexSelected].componentStyles && indexSelected > 0) {
              // data[indexSelected].componentStyles.zIndex = minZindexValue - 1;
              swappedLayerCompData = arrayMove(layerCompData, indexSelected, 0);
              swappedLayerCompData.forEach((layerObj, index) => {
                swappedLayerCompData[index].componentStyles.zIndex =
                  swappedLayerCompData.length - index;
              });
            }
          break;
        case "Backward":
          if (indexSelected !== data.length - 1) {
            swappedLayerCompData = arrayMove(
              layerCompData,
              indexSelected,
              indexSelected + 1
            );
            swappedLayerCompData.forEach((layerObj, index) => {
              swappedLayerCompData[index].componentStyles.zIndex =
                swappedLayerCompData.length - index;
            });
          }
          // }
          break;
        case "Forward":
          if (indexSelected !== 0)
            if (data[indexSelected].componentStyles) {
              swappedLayerCompData = arrayMove(
                layerCompData,
                indexSelected,
                indexSelected - 1
              );
              swappedLayerCompData.forEach((layerObj, index) => {
                swappedLayerCompData[index].componentStyles.zIndex =
                  swappedLayerCompData.length - index;
              });
            }
          break;
        default:
          break;
      }
      setLayerCompData(swappedLayerCompData);

      // updateLayers(data);
      setVisibility(false);
    }
  };

  const froalaEditorHandleOnclciksAndDrag = (e, index) => {
    let refFroala = createFroalaInstance.current;
    if (refFroala.length > 0) {
      for (let x in refFroala) {
        if (refFroala[x] && refFroala[x].editorInitialized) {
          if (e.detail !== 2 && isEditorOn !== "dragged") {
            if (isEditorOn === "notClickedYet") {
              refFroala[x].editor.el.blur();
              setIsEditorOn("false");
            } else if (
              isEditorOn !== "notClickedYet" &&
              isEditorOn !== "dragged" &&
              refFroala[x].editor.selection.text() !== ""
            ) {
              refFroala[x].editor.selection.clear();
              refFroala[x].editor.markers.insertAtPoint(e);
            }
          } else if (e.detail === 2 || isEditorOn === "dragged") {
            if (Number(x) === index) {
              refFroala[x].editor.el.focus();
              setIsEditorOn("true");
            }
          }
        }
      }
    }
  };

  return (
    <>
      {/* {showAssettype === "Banner" && (
        <EditorHeader
          layerCompData={layerCompData}
          canvasDimension={canvasDimension}
          bgColorCanvas={canvasDimension.backgroundColor}
          animationObject={animationObject}
          selectedComponent={selectedComponent}
          popUpOptions={popUpOptions}
          setLayerCompData={setLayerCompData}
          renderAppliedFonts={renderAppliedFonts}
          toggleAnimatedBanner={toggleAnimatedBanner}
          handleOnUndo={handleOnUndo}
          addedActionsList={addedActionsList}
          refreshed={refreshed}
          layerLengthAtRefresh={layerLengthAtRefresh}
          getEditSaveAssets={getEditSaveAssets}
          setundoFlag={setundoFlag}
          // googleFontsLink={googleFontsLink}
          addedCanvasActionsList={addedCanvasActionsList}
          handleCanvasUndo={handleCanvasUndo}
          listForReDo={listForReDo}
          handleRedo={handleRedo}
          undoCount={undoCount}
          playPause={playPause}
          bannerChanged={bannerChanged}
          refBannerChanged={refBannerChanged}
          setBannerChanged={setBannerChanged}
          setRefreshed={setRefreshed}
        />
      )} */}
      {/* {showAssettype === "Template" && ( */}
      <EditorHeaderForTemplate
        setMLRstatus={setMLRstatus}
        setDamsId={setDamsId}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        layerCompData={layerCompData}
        canvasDimension={canvasDimension}
        bgColorCanvas={canvasDimension.backgroundColor}
        animationObject={animationObject}
        selectedComponent={selectedComponent}
        popUpOptions={popUpOptions}
        setLayerCompData={setLayerCompData}
        renderAppliedFonts={renderAppliedFonts}
        toggleAnimatedBanner={toggleAnimatedBanner}
        handleOnUndo={handleOnUndo}
        addedActionsList={addedActionsList}
        refreshed={refreshed}
        layerLengthAtRefresh={layerLengthAtRefresh}
        getEditSaveAssets={getEditSaveAssets}
        setundoFlag={setundoFlag}
        // googleFontsLink={googleFontsLink}
        addedCanvasActionsList={addedCanvasActionsList}
        handleCanvasUndo={handleCanvasUndo}
        listForReDo={listForReDo}
        handleRedo={handleRedo}
        undoCount={undoCount}
        playPause={playPause}
        bannerChanged={bannerChanged}
        refBannerChanged={refBannerChanged}
        setBannerChanged={setBannerChanged}
        setRefreshed={setRefreshed}
        showBannertype={showBannertype}
        showBannerName={showBannerName}
        setShowBannerName={setShowBannerName}
        showAssettype={showAssettype}
        linkedModulesType={linkedModulesType}
      />
      {/* )} */}
      <div className="container-fluid">
        {/* {showAssettype === "Banner" && (
          <div>
            <Drawer
              bannerChanged={bannerChanged}
              refBannerChanged={refBannerChanged}
              setBannerChanged={setBannerChanged}
              refreshed={refreshed}
              setRefreshed={setRefreshed}
              playPauseRef={playPauseRef}
              origin="EditorMainPage"
            />
          </div>
        )} */}
        <div className="editormainPage">
          <Row className="editorRow">
            {loadingModules && (
              <>
                <div className="modal-backdrop fade show"> </div>
                <CircularProgress size="30px" className="customLoader" />{" "}
              </>
            )}
            <Col className="col-1 col-md-1 leftHandSide">
              <LeftPanel
                getEditSaveAssets={getEditSaveAssets}
                bannerId={bannerId}
                setModules={setModules}
                modules={modules}
                showAssettype={showAssettype}
                openTimeLine={openTimeLine}
                openTextWindow={openTextWindow}
                setOpenTextWindow={setOpenTextWindow}
                openModuleWindow={openModuleWindow}
                setOpenModuleWindow={setOpenModuleWindow}
                layerCompData={layerCompData}
                setLayerCompData={setLayerCompData}
                getLayerDetails={getLayerDetails}
                layerId={layerId}
                openShapesWindow={openShapesWindow}
                setOpenShapesWindow={setOpenShapesWindow}
                setOpenTimeLine={setOpenTimeLine}
                sendAtomDetails2Canvas={setAtom}
                setOpenImageWindow={setOpenImageWindow}
                openImageWindow={openImageWindow}
                selectedComponent={selectedComponent}
                setSelectedComponent={setSelectedComponent}
                imageOnCanvas={imageOnCanvas}
                playPause={playPause}
                linkedModulesType={linkedModulesType}
                setLinkedModulesType={setLinkedModulesType}
                loadingModules={loadingModules}
                setLoadingModules={setLoadingModules}
                setVeevaModuleCardData={setVeevaModuleCardData}
                veevaModuleCardData={veevaModuleCardData}
                toggleAnimatedBanner={toggleAnimatedBanner}
                playPauseRef={playPauseRef}
                toggleHideComp={toggleHideComp}
                toggleLockComp={toggleLockComp}
                setMultiSelectedComps={setMultiSelectedComps}
              />
            </Col>

            <Col className=" col-8 col-md-8 editorCol ">
              {/* <div
                id={"drawSelectionDiv"}
                style={{
                  width: multiSelect.width + "px",
                  height: multiSelect.height + "px",
                  left: multiSelect.left + "px",
                  top: multiSelect.top + "px",
                  position: "absolute",
                  background: "rgba(0, 0, 0, 0.7)",
                  zIndex: 100,
                  opacity: 0.2,
                }}
              ></div> */}

              <div
                id={"mainCanvasContainer"}
                style={{
                  height: `${openTimeLine ? "50vh" : ""}`,
                  // overflowX:
                  //   canvasScrollToggle.width < 880 ? "hidden" : "scoll",
                }}
                onMouseDown={(e) => {
                  console.log(e.detail, "eDetailInOnMouseDown");
                  playPauseRef.current.animationCompleted &&
                    e.detail < 2 &&
                    drawDiv(
                      e,
                      showSelection,
                      Xstart,
                      Ystart,
                      setMultiSelect,
                      tempLayer,
                      multiSelect
                    );
                }}
                onMouseMove={(e) => {
                  !isDragged.mouseDown &&
                    playPauseRef.current.animationCompleted &&
                    mousedrag(
                      e,
                      tempLayer,
                      showSelection,
                      Xstart,
                      Ystart,
                      setMultiSelect,
                      setMultiSelectedComps,
                      multiSelect
                    );
                }}
                onMouseup={(e) => {
                  e.preventDefault();
                  playPauseRef.current.animationCompleted &&
                    closeSelec(showSelection);
                }}
                className={
                  `${
                    canvasScrollToggle.height < 45
                      ? "canvasUnset canvasCol-dup canvasCol mt-0"
                      : "canvasScroll canvasCol canvasCol-Expand mt-0 "
                  }` +
                  " " +
                  `${
                    canvasScrollToggle.width < 780
                      ? " Canvas-hide-scroll"
                      : "Canvas-X-scroll"
                  }`
                }
              >
                <div
                  id={"drawSelectionDiv"}
                  style={{
                    width: multiSelect.width + "px",
                    height: multiSelect.height + "px",
                    left: multiSelect.left + "px",
                    top: multiSelect.top + "px",
                    position: "absolute",
                    background: "rgba(0, 0, 0, 0.7)",
                    zIndex: 100,
                    opacity: 0.2,
                  }}
                  onMouseMove={(e) => {
                    !isDragged.mouseDown &&
                      playPauseRef.current.animationCompleted &&
                      mousedrag(
                        e,
                        tempLayer,
                        showSelection,
                        Xstart,
                        Ystart,
                        setMultiSelect,
                        setMultiSelectedComps
                      );
                  }}
                ></div>

                <div
                  id={"canvas-page"}
                  ref={contextMenuRef}
                  className="dragContentCanvas centerPanel"
                  style={{
                    width: canvasDimension.width + "px",
                    height: canvasDimension.height + "px",

                    transform: `scale(${canvasDimension.scale})`,
                    background: `rgba(${canvasDimension.backgroundColor?.r}, ${canvasDimension.backgroundColor?.g}, ${canvasDimension.backgroundColor?.b}, ${canvasDimension.backgroundColor?.a} )`,
                    transformOrigin: "0% 0%",
                  }}
                  onDrop={dropLayer}
                  onDragOver={allowDroplayer}
                  onClick={(e) => {
                    if (!(new Date().getTime() - multiSelect.timestamp)) {
                      setMultiSelectedComps([]);
                    }
                    setToggleSelected(false);
                    sendSelectedComponent(e, "default");
                    setVisibility(false);
                    setIsEditorOn("notClickedYet");
                    if (
                      createFroalaInstance?.current[froalaIndex]?.getEditor()
                        ?.selection
                    ) {
                      createFroalaInstance.current[froalaIndex]
                        .getEditor()
                        .selection.clear();
                    }
                  }}
                  value={JSON.stringify(layerCompData)}
                >
                  {selectedComponent?.componentStyles?.top ? (
                    <GuidLines verticalLineFor={verticalLineFor}></GuidLines>
                  ) : (
                    ""
                  )}
                  {layerCompData &&
                    layerCompData.map((items, index) => (
                      <div
                        onMouseDown={() => {
                          // setIsDragged({ mouseDown: true, mouseMove: false });
                          isDragged.mouseDown = true;
                          isDragged.mouseMove = false;
                        }}
                        onMouseMove={() => {
                          if (isDragged.mouseDown === true)
                            isDragged.mouseMove = true;
                        }}
                        onMouseUp={() => {
                          // setIsDragged({ ...isDragged, mouseUp: true });
                          isDragged.mouseDown = false;
                        }}
                        onClick={(e) => {
                          sendSelectedComponent(e, index);
                          setFroalaIndex(index);
                          setToggleSelected(true);
                          setVisibility(false);
                          //setDisableDragger(false);
                        }}
                        key={items.id + index}
                      >
                        {items?.componentType === "Text" && (
                          <Rnd
                            //ref={buttonRNDref}
                            resizeHandleClasses={{
                              topRight: "topAnchorRight",
                              topLeft: "topAnchorLeft",
                              bottomLeft: "bottomAnchorLeft",
                              bottomRight: "bottomAnchorRight",
                              top: "horizontalAnchorCenter",
                              bottom: "horizontalAnchorCenter",
                              left: "verticallAnchorCenter",
                              right: "verticallAnchorCenter",
                            }}
                            disableDragging={(() => {
                              return (
                                items?.componentStyles?.hideBoolean ||
                                items?.componentStyles?.lockBoolean ||
                                !playPauseRef.current.animationCompleted ||
                                isEditorOn === "true" ||
                                isEditorOn === "dragged"
                              );
                            })()}
                            enableResizing={
                              !items?.componentStyles?.hideBoolean &&
                              !items?.componentStyles?.lockBoolean &&
                              playPauseRef.current.animationCompleted &&
                              isEditorOn !== "true" &&
                              isEditorOn !== "dragged"
                            }
                            size={{
                              width: items.componentStyles.width,
                              height: items.componentStyles.height,
                            }}
                            scale={canvasDimension.scale}
                            // dragHandleClassName="moveIcon"
                            enableUserSelectHack={false}
                            position={{
                              x: items.componentStyles.left,
                              y: items.componentStyles.top,
                            }}
                            lockAspectRatio={items.componentStyles.aspectLock}
                            onDrag={(e, d) => {
                              updateCompValues({
                                x: d.x,
                                y: d.y,
                                id: items.id,
                                index: index,
                              });
                            }}
                            onDragStop={(e, d) => {
                              callOnDragStop({
                                e,
                                x: d.x,
                                y: d.y,
                                id: items.id,
                                index: index,
                              });
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                              updateFontOnResize({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            onResizeStop={(
                              e,
                              direction,
                              ref,
                              delta,
                              position
                            ) => {
                              callOnResizeStop({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            id={items.id}
                            key={index}
                            onClick={(e) => {
                              setMultiSelectedComps([]);
                              if (e.detail > 0) {
                                if (e.detail === 3) return;
                                if (isDragged.mouseMove && e.detail === 1) {
                                  isEditorOn = "dragged";
                                  // setIsEditorOn("dragged");
                                } else if (isEditorOn === "dragged") {
                                  isEditorOn = "true";
                                  // setIsEditorOn("true");
                                }
                                if (index !== layerIndex)
                                  isEditorOn = "notClickedYet";
                                froalaEditorHandleOnclciksAndDrag(e, index);
                              }
                              setFroalaIndex(index);
                              setContainerID(items.id);
                              setCurrentIdZindex(e.currentTarget.id);
                              //  setVisibility(true);
                            }}
                            className={
                              `${
                                selectedComponent.id === items.id
                                  ? "selectedComp div-dragresize-parent cs-container textCompNew"
                                  : "div-dragresize-parent cs-container textCompNew"
                              }` +
                              " " +
                              `${
                                multiSelectedComps.length &&
                                items.multiSelected === true
                                  ? "multipleSelection"
                                  : ""
                              }`
                            }
                            style={{
                              pointerEvents:
                                (!playPause.animationCompleted ||
                                  items.componentStyles.hideBoolean) &&
                                "none",
                              fontFamily: items.componentStyles.fontFamily,
                              //datafontSize:
                              //  items.componentStyles.datafontSize + "px",
                              fontSize: items.componentStyles.fontSize + "px",

                              fontWeight: items.componentStyles.boldClick
                                ? items.componentStyles?.fontWeightBold
                                : items.componentStyles?.fontWeight,

                              textAlign: items.componentStyles.textAlign,
                              color: items.componentStyles.color,
                              zIndex: items.componentStyles.zIndex,
                              //listStyleType:
                              // items.componentStyles.listStyletype,
                              letterSpacing:
                                items.componentStyles.letterSpacing,
                              lineHeight: items.componentStyles.lineHeight,
                              transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                              ease: items.componentStyles.ease,
                              // transform: items.componentStyles.scale,
                              aspectLock: items.componentStyles.aspectLock,
                              // opacity: items.componentStyles.opacity,
                              toggleScroll: items.componentStyles.toggleScroll,
                              backgroundColor:
                                items.componentStyles.textBgColor,
                              opacity: items.componentStyles.opacity,
                              fontStyle: items.componentStyles.fontStyle,

                              textTransform:
                                items.componentStyles?.textTransform,

                              //marginBottom: "8px",
                              textDecoration: items.componentStyles
                                ?.underlineClick
                                ? items.componentStyles?.strikeThroughClicked
                                  ? `${items.componentStyles?.textDecUnderline} ${items.componentStyles?.textDecoration} `
                                  : items.componentStyles?.textDecUnderline
                                : items.componentStyles?.textDecoration,
                            }}
                          >
                            {" "}
                            {items?.componentStyles.expandAndClose === true ? (
                              <div
                                id={"expandClose" + items.id}
                                ref={(e) =>
                                  (scrollBarExpand.current[index] = e)
                                }
                                style={{
                                  fontFamily:
                                    items.componentStyles?.expandClose
                                      .fontFamily,
                                  fontSize:
                                    items.componentStyles?.expandClose.fontSize,
                                  fontWeight: items.componentStyles.expandClose
                                    .boldClick
                                    ? items.componentStyles?.expandClose
                                        .fontWeightBold
                                    : items.componentStyles?.expandClose
                                        .fontWeight,
                                  textAlign: "right",
                                  color:
                                    items.componentStyles?.expandClose
                                      ?.fontColorISI,
                                  backgroundColor:
                                    items.componentStyles?.expandClose
                                      .textBgColorISI,
                                  letterSpacing:
                                    items.componentStyles?.expandClose
                                      .letterSpacing,
                                  lineHeight:
                                    items.componentStyles?.expandClose
                                      .lineHeight,
                                  fontStyle:
                                    items.componentStyles?.expandClose
                                      .fontStyle,
                                  textTransform:
                                    items.componentStyles?.expandClose
                                      .textTransform,
                                  //marginBottom: "8px",
                                  textDecoration: items.componentStyles
                                    ?.expandClose.underlineClick
                                    ? items.componentStyles?.expandClose
                                        .strikeThroughClicked
                                      ? `${items.componentStyles?.expandClose.textDecUnderline} ${items.componentStyles?.expandClose?.textDecoration} `
                                      : items.componentStyles?.expandClose
                                          ?.textDecUnderline
                                    : items.componentStyles?.expandClose
                                        ?.textDecoration,
                                  overflow: "hidden",
                                }}
                              >
                                + {items?.componentStyles.expandText}
                              </div>
                            ) : (
                              ""
                            )}
                            {items?.componentStyles.toggleScroll === true ? (
                              <>
                                <div
                                  id="upArrowButtons"
                                  style={{
                                    width: items.componentStyles.arrowWidth,
                                    height: items.componentStyles.arrowHeight,
                                    backgroundImage: `url("${items.componentStyles.scrollUpArrowbg}")`,
                                    backgroundSize:
                                      items.componentStyles.backgroundSize,
                                    backgroundPosition: `50% 50%`,
                                    left: `calc(100% - ${items.componentStyles.arrowWidth}px)`,
                                  }}
                                ></div>

                                <Scrollbars
                                  style={{
                                    width: "100%",
                                    height: `calc(${
                                      items.componentStyles.height -
                                      items.componentStyles.expandClose
                                        ?.expandHeight
                                    }px)`,
                                    top: `-${items.componentStyles.arrowHeight}px`,
                                    overflow: "hidden",
                                  }}
                                  universal={true}
                                  className="scrollBarText"
                                  renderTrackVertical={({
                                    style,
                                    ...props
                                  }) => (
                                    <div
                                      {...props}
                                      className={"rc-scrollbars-track-v"}
                                      style={{
                                        ...style,
                                        backgroundColor: "blue",
                                        bottom: "2px",
                                        top: `${
                                          items.componentStyles.arrowHeight + 2
                                        }px`,
                                        borderRadius: "3px",
                                        height: `calc( ${
                                          items.componentStyles.height -
                                          items.componentStyles.expandClose
                                            ?.expandHeight -
                                          items.componentStyles.arrowHeight *
                                            2 -
                                          4
                                        }px)`,
                                      }}
                                    />
                                  )}
                                  disableDefaultStyles={true}
                                  thumbSize={items.componentStyles.thumbHeight}
                                  thumbMinSize={30}
                                  // onMouseUp={(e) => {
                                  //   sendSelectedComponent(e, index);
                                  //   setFroalaIndex(index);
                                  //   setContainerID(items.id);
                                  //   setCurrentIdZindex(e.currentTarget.id);
                                  // }}
                                >
                                  {items.componentStyles.lockBoolean !==
                                  true ? (
                                    <FroalaEditor
                                      forwardedRef={(e) =>
                                        (createFroalaInstance.current[index] =
                                          e)
                                      }
                                      handleModelChange={(e) => {
                                        handleTextChangeInLayerComp(e, index);
                                      }}
                                      className=""
                                      defaultText={items.froalaText}
                                      tag="textarea"
                                      showToolBar={false}
                                      scrollBarStyleWidth={
                                        items.componentStyles.thumbRight * 2 +
                                        items.componentStyles.trackWidth +
                                        2
                                      }
                                    ></FroalaEditor>
                                  ) : (
                                    <div>{parse(items.froalaText || "")}</div>
                                  )}
                                </Scrollbars>

                                <div
                                  id="DownArrowButtons"
                                  style={{
                                    width: items.componentStyles.arrowWidth,
                                    height: items.componentStyles.arrowHeight,
                                    backgroundImage: `url("${items.componentStyles.scrollDownArrowbg}")`,
                                    backgroundSize:
                                      items.componentStyles.backgroundSize,
                                    backgroundPosition: `50% 50%`,
                                    left: `calc(100% - ${items.componentStyles.arrowWidth}px)`,
                                  }}
                                ></div>
                              </>
                            ) : (
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  overflow: "hidden",
                                }}
                                // onMouseUp={(e) => {
                                //   sendSelectedComponent(e, index);
                                //   setFroalaIndex(index);
                                //   setContainerID(items.id);
                                //   setCurrentIdZindex(e.currentTarget.id);
                                // }}
                              >
                                {items.componentStyles.lockBoolean !== true ? (
                                  <FroalaEditor
                                    forwardedRef={(e) =>
                                      (createFroalaInstance.current[index] = e)
                                    }
                                    handleModelChange={(e) => {
                                      handleTextChangeInLayerComp(e, index);
                                    }}
                                    className=""
                                    defaultText={items.froalaText}
                                    tag="textarea"
                                    showToolBar={false}
                                  ></FroalaEditor>
                                ) : (
                                  <div>{parse(items.froalaText || "")}</div>
                                )}
                              </div>
                            )}
                          </Rnd>
                        )}

                        {items?.componentType === "Button" && (
                          <Rnd
                            resizeHandleClasses={{
                              topRight: "topAnchorRight",
                              topLeft: "topAnchorLeft",
                              bottomLeft: "bottomAnchorLeft",
                              bottomRight: "bottomAnchorRight",
                              top: "horizontalAnchorCenter",
                              bottom: "horizontalAnchorCenter",
                              left: "verticallAnchorCenter",
                              right: "verticallAnchorCenter",
                            }}
                            disableDragging={(() => {
                              return (
                                items?.componentStyles?.hideBoolean ||
                                items?.componentStyles?.lockBoolean ||
                                !playPauseRef.current.animationCompleted ||
                                isEditorOn === "true" ||
                                isEditorOn === "dragged"
                              );
                            })()}
                            enableResizing={
                              !items?.componentStyles?.hideBoolean &&
                              !items?.componentStyles?.lockBoolean &&
                              playPauseRef.current.animationCompleted &&
                              isEditorOn !== "true" &&
                              isEditorOn !== "dragged"
                            }
                            size={{
                              width: items.componentStyles.width,
                              height: items.componentStyles.height,
                            }}
                            scale={canvasDimension.scale}
                            position={{
                              x: items.componentStyles.left,
                              y: items.componentStyles.top,
                            }}
                            enableUserSelectHack={false}
                            lockAspectRatio={items.componentStyles.aspectLock}
                            onDrag={(e, d) => {
                              updateCompValues({
                                x: d.x,
                                y: d.y,
                                id: items.id,
                                index: index,
                              });
                            }}
                            onDragStop={(e, d) => {
                              callOnDragStop({
                                e,
                                x: d.x,
                                y: d.y,
                                id: items.id,
                                index: index,
                              });
                            }}
                            onResizeStop={(
                              e,
                              direction,
                              ref,
                              delta,
                              position
                            ) => {
                              callOnResizeStop({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                              updateFontOnResize({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            key={index}
                            id={items.id}
                            className={
                              `${
                                selectedComponent.id === items.id
                                  ? "selectedComp div-dragresize-parent cs-container button-container buttonResize"
                                  : "div-dragresize-parent cs-container buttonResize"
                              }` +
                              " " +
                              `${
                                multiSelectedComps.length &&
                                items.multiSelected === true
                                  ? "multipleSelection"
                                  : ""
                              }`
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setMultiSelectedComps([]);
                              setDisplayColorPicker(false);

                              if (e.detail > 0) {
                                if (e.detail === 3) return;
                                if (isDragged.mouseMove && e.detail === 1) {
                                  isEditorOn = "dragged";
                                  // setIsEditorOn("dragged");
                                } else if (isEditorOn === "dragged") {
                                  isEditorOn = "true";
                                  // setIsEditorOn("true");
                                }
                                if (index !== layerIndex)
                                  isEditorOn = "notClickedYet";
                                froalaEditorHandleOnclciksAndDrag(e, index);
                              }

                              setFroalaIndex(index);
                              setContainerID(items.id);

                              setCurrentIdZindex(e.currentTarget.id);
                              setDisplayColorPicker(false);
                              setVisibility(false);
                            }}
                            tabIndex="0"
                            onKeyDown={(e) => handleDeleteKey(e)}
                            // data-fontSize={items.datafontSize + "px"}
                            style={{
                              pointerEvents:
                                (!playPause.animationCompleted ||
                                  items.componentStyles.hideBoolean) &&
                                "none",
                              fontFamily: items.componentStyles.fontFamily,
                              // datafontSize:
                              //   items.componentStyles.datafontSize + "px",
                              fontSize: items.componentStyles.fontSize + "px",
                              fontWeight: items.componentStyles.boldClick
                                ? items.componentStyles?.fontWeightBold
                                : items.componentStyles?.fontWeight,

                              textAlign: items.componentStyles.textAlign,
                              color: items.componentStyles.color,
                              zIndex: items.componentStyles.zIndex,
                              letterSpacing:
                                items.componentStyles.letterSpacing,
                              lineHeight: items.componentStyles.lineHeight,
                              targetLink: items.componentStyles.targetLink,
                              transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                              ease: items.componentStyles.ease,
                              // transform: items.componentStyles.scale,

                              opacity: items.componentStyles.opacity,
                              fontStyle: items.componentStyles.fontStyle,

                              textTransform:
                                items.componentStyles?.textTransform,

                              //marginBottom: "8px",
                              textDecoration: items.componentStyles
                                ?.underlineClick
                                ? items.componentStyles?.strikeThroughClicked
                                  ? `${items.componentStyles?.textDecUnderline} ${items.componentStyles?.textDecoration} `
                                  : items.componentStyles?.textDecUnderline
                                : items.componentStyles?.textDecoration,
                            }}
                          >
                            <div
                              style={{
                                overflow: "hidden",
                                width: "100%",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  overflow: "hidden",
                                  border: items.componentStyles.border,
                                  borderTopLeftRadius:
                                    items.componentStyles.borderTopLeftRadius,
                                  borderTopRightRadius:
                                    items.componentStyles.borderTopRightRadius,
                                  borderBottomLeftRadius:
                                    items.componentStyles
                                      .borderBottomLeftRadius,
                                  borderBottomRightRadius:
                                    items.componentStyles
                                      .borderBottomRightRadius,
                                  paddingTop: items.componentStyles.paddingTop,
                                  paddingRight:
                                    items.componentStyles.paddingRight,
                                  paddingBottom:
                                    items.componentStyles.paddingBottom,
                                  paddingLeft:
                                    items.componentStyles.paddingLeft,
                                  backgroundColor:
                                    items.componentStyles.backgroundColor,
                                }}
                                // onMouseUp={(e) => {
                                //   sendSelectedComponent(e, index);
                                //   setFroalaIndex(index);
                                //   setContainerID(items.id);
                                //   setCurrentIdZindex(e.currentTarget.id);
                                // }}
                              >
                                {items.componentStyles.lockBoolean !== true ? (
                                  <FroalaEditor
                                    forwardedRef={(e) =>
                                      (createFroalaInstance.current[index] = e)
                                    }
                                    handleModelChange={(e) => {
                                      handleTextChangeInLayerComp(e, index);
                                    }}
                                    className=""
                                    defaultText={items.froalaText}
                                    tag="textarea"
                                    showToolBar={false}
                                  ></FroalaEditor>
                                ) : (
                                  <div>{parse(items.froalaText || "")}</div>
                                )}
                              </div>
                            </div>
                          </Rnd>
                        )}

                        {items?.componentType === "Image" && (
                          <Rnd
                            resizeHandleClasses={{
                              topRight: "topAnchorRight",
                              topLeft: "topAnchorLeft",
                              bottomLeft: "bottomAnchorLeft",
                              bottomRight: "bottomAnchorRight",
                              top: "horizontalAnchorCenter",
                              bottom: "horizontalAnchorCenter",
                              left: "verticallAnchorCenter",
                              right: "verticallAnchorCenter",
                            }}
                            disableDragging={
                              items?.componentStyles?.hideBoolean ||
                              items?.componentStyles?.lockBoolean ||
                              !playPauseRef.current.animationCompleted
                            }
                            enableResizing={
                              !items?.componentStyles?.hideBoolean &&
                              !items?.componentStyles?.lockBoolean &&
                              playPauseRef.current.animationCompleted
                            }
                            size={{
                              width: items.componentStyles.width,
                              height: items.componentStyles.height,
                            }}
                            // scale={canvasDimension.scale}
                            position={{
                              x: items.componentStyles.left,
                              y: items.componentStyles.top,
                            }}
                            lockAspectRatio={items.componentStyles.aspectLock}
                            onDrag={(e, d) => {
                              updateCompValues({
                                x: d.x,
                                y: d.y,
                                id: items.id,
                              });
                            }}
                            onDragStop={(e, d) => {
                              callOnDragStop({
                                x: d.x,
                                y: d.y,
                                id: items.id,
                              });
                            }}
                            onResizeStop={(
                              e,
                              direction,
                              ref,
                              delta,
                              position
                            ) => {
                              callOnResizeStop({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            onResize={(e, direction, ref, delta, position) => {
                              // updateFontOnResize(
                              //   items.id,
                              //   ref.offsetWidth,
                              //   ref.offsetHeight
                              // );
                              updateFontOnResize({
                                id: items.id,
                                width: ref.offsetWidth,
                                height: ref.offsetHeight,
                                ...position,
                              });
                            }}
                            className={
                              `${
                                selectedComponent.id === items.id
                                  ? "selectedComp div-dragresize-parent cs-container "
                                  : "div-dragresize-parent cs-container"
                              }` +
                              " " +
                              `${
                                multiSelectedComps.length &&
                                items.multiSelected === true
                                  ? "multipleSelection"
                                  : ""
                              }`
                            }
                            // className="div-dragresize-parent cs-container"
                            data-aspect={items.componentStyles.aspectRatio}
                            style={{
                              pointerEvents:
                                (!playPause.animationCompleted ||
                                  items.componentStyles.hideBoolean) &&
                                "none",
                              // transform: items.componentStyles.scale,
                              transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                              zIndex: items.componentStyles.zIndex,
                              opacity: items.componentStyles.opacity,
                              backgroundSize: `${items.componentStyles.width}px ${items.componentStyles.height}px`,
                            }}
                            key={index}
                            id={items.id}
                            onClick={(e) => {
                              setMultiSelectedComps([]);

                              sendSelectedComponent(e, index);
                              setFroalaIndex(index);
                              setContainerID(items.id);
                              setCurrentIdZindex(e.currentTarget.id);
                              setDisplayColorPicker(false);
                              setVisibility(false);
                            }}
                            tabIndex="0"
                            onKeyDown={(e) => handleDeleteKey(e)}
                          >
                            {/* <img
                              src={items.componentStyles.background}
                              alt={"ded"}
                              style={{
                                height: "100%",
                                width: "100%",
                              }}
                            /> */}

                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                backgroundImage: `url(${items.componentStyles.background})`,
                                //   zIndex: items.componentStyles.zIndex,
                                backgroundRepeat:
                                  items.componentStyles.backgroundRepeat,
                                borderTopLeftRadius:
                                  items.componentStyles.borderTopLeftRadius,
                                borderTopRightRadius:
                                  items.componentStyles.borderTopRightRadius,
                                borderBottomLeftRadius:
                                  items.componentStyles.borderBottomLeftRadius,
                                borderBottomRightRadius:
                                  items.componentStyles.borderBottomRightRadius,
                                border: items.componentStyles.border,
                                //  transform: `rotate(${items.componentStyles.rotation}) scale(${canvasDimension.scale})`,

                                backgroundSize: "inherit",

                                ease: items.componentStyles.ease,
                                //  opacity: items?.componentStyles?.opacity,
                                // toggleAnimatedBanner === true
                                //   ? items?.componentStyles?.opacity
                                //   : items?.componentStyles?.compOpacity / 100,
                                display: items?.componentStyles?.hideBoolean
                                  ? "none"
                                  : "",
                              }}
                              onMouseUp={(e) => {
                                isDragged.mouseDown = false;
                                sendSelectedComponent(e, index);
                                setFroalaIndex(index);
                                setContainerID(items.id);
                                setCurrentIdZindex(e.currentTarget.id);
                              }}
                            ></div>
                          </Rnd>
                        )}
                        {items?.componentType === "Shape" && (
                          <div>
                            {items.textComponentType.addContent ===
                              "rectangleShape" && (
                              <Rnd
                                resizeHandleClasses={{
                                  topRight: "topAnchorRight",
                                  topLeft: "topAnchorLeft",
                                  bottomLeft: "bottomAnchorLeft",
                                  bottomRight: "bottomAnchorRight",
                                  top: "horizontalAnchorCenter",
                                  bottom: "horizontalAnchorCenter",
                                  left: "verticallAnchorCenter",
                                  right: "verticallAnchorCenter",
                                }}
                                disableDragging={
                                  items?.componentStyles?.hideBoolean ||
                                  items?.componentStyles?.lockBoolean ||
                                  !playPauseRef.current.animationCompleted
                                }
                                enableResizing={
                                  !items?.componentStyles?.hideBoolean &&
                                  !items?.componentStyles?.lockBoolean &&
                                  playPauseRef.current.animationCompleted
                                }
                                size={{
                                  width: items.componentStyles.width,
                                  height: items.componentStyles.height,
                                }}
                                scale={canvasDimension.scale}
                                position={{
                                  x: items.componentStyles.left,
                                  y: items.componentStyles.top,
                                }}
                                lockAspectRatio={
                                  items.componentStyles.aspectLock
                                }
                                onDrag={(e, d) => {
                                  updateCompValues({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onDragStop={(e, d) => {
                                  callOnDragStop({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onResizeStop={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  callOnResizeStop({
                                    id: items.id,
                                    width: ref.offsetWidth,
                                    height: ref.offsetHeight,
                                    ...position,
                                  });
                                }}
                                onResize={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  updateFontOnResize({
                                    id: items.id,
                                    width: ref.offsetWidth,
                                    height: ref.offsetHeight,
                                    ...position,
                                  });
                                }}
                                key={index}
                                id={items.id}
                                onClick={(e) => {
                                  setMultiSelectedComps([]);

                                  sendSelectedComponent(e, index);
                                  setFroalaIndex(index);
                                  setContainerID(items.id);
                                  setCurrentIdZindex(e.currentTarget.id);
                                  setVisibility(false);
                                }}
                                tabIndex="0"
                                onKeyDown={(e) => handleDeleteKey(e)}
                                className={
                                  `${
                                    selectedComponent.id === items.id
                                      ? `${
                                          !selectedComponent.componentStyles
                                            ?.hideBoolean && "selectedComp"
                                        } div-dragresize-parent cs-container`
                                      : "div-dragresize-parent cs-container"
                                  }` +
                                  " " +
                                  `${
                                    multiSelectedComps.length &&
                                    items.multiSelected === true
                                      ? "multipleSelection"
                                      : ""
                                  }`
                                }
                                style={{
                                  pointerEvents:
                                    (!playPause.animationCompleted ||
                                      items.componentStyles.hideBoolean) &&
                                    "none",
                                  zIndex: items.componentStyles.zIndex,
                                  //   opacity: items?.componentStyles?.opacity,
                                  transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                }}
                                // className="div-dragresize-parent cs-container"..
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    // opacity:
                                    //   toggleAnimatedBanner === true
                                    //     ? items?.componentStyles?.opacity
                                    //     : items?.componentStyles?.compOpacity /
                                    //       100,
                                    opacity:
                                      toggleAnimatedBanner === true ||
                                      items?.componentStyles?.hideBoolean ===
                                        true
                                        ? items?.componentStyles?.opacity
                                        : items?.componentStyles?.compOpacity /
                                          100,
                                    pointerEvents: items?.componentStyles
                                      ?.hideBoolean
                                      ? "none"
                                      : "",
                                    backgroundColor:
                                      items.componentStyles.backgroundColor,
                                    border: items.componentStyles.border,
                                    paddingTop:
                                      items.componentStyles.paddingTop,
                                    paddingRight:
                                      items.componentStyles.paddingRight,
                                    paddingBottom:
                                      items.componentStyles.paddingBottom,
                                    paddingLeft:
                                      items.componentStyles.paddingLeft,
                                    borderTopLeftRadius:
                                      items.componentStyles.borderTopLeftRadius,
                                    borderTopRightRadius:
                                      items.componentStyles
                                        .borderTopRightRadius,
                                    borderBottomLeftRadius:
                                      items.componentStyles
                                        .borderBottomLeftRadius,
                                    borderBottomRightRadius:
                                      items.componentStyles
                                        .borderBottomRightRadius,
                                    transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                    // transform: items.componentStyles.scale,
                                    aspectLock:
                                      items.componentStyles.aspectLock,
                                  }}
                                  onMouseUp={(e) => {
                                    isDragged.mouseDown = false;
                                    sendSelectedComponent(e, index);
                                    setFroalaIndex(index);
                                    setContainerID(items.id);
                                    setCurrentIdZindex(e.currentTarget.id);
                                  }}
                                >
                                  <div className="shapesOnCanvas"></div>
                                </div>
                              </Rnd>
                            )}
                            {items.textComponentType.addContent ===
                              "circleShape" && (
                              <Rnd
                                resizeHandleClasses={{
                                  topRight: "topAnchorRight",
                                  topLeft: "topAnchorLeft",
                                  bottomLeft: "bottomAnchorLeft",
                                  bottomRight: "bottomAnchorRight",
                                  top: "horizontalAnchorCenter",
                                  bottom: "horizontalAnchorCenter",
                                  left: "verticallAnchorCenter",
                                  right: "verticallAnchorCenter",
                                }}
                                disableDragging={
                                  items?.componentStyles?.hideBoolean ||
                                  items?.componentStyles?.lockBoolean ||
                                  !playPauseRef.current.animationCompleted
                                }
                                enableResizing={
                                  !items?.componentStyles?.hideBoolean &&
                                  !items?.componentStyles?.lockBoolean &&
                                  playPauseRef.current.animationCompleted
                                }
                                size={{
                                  width: items.componentStyles.width,
                                  height: items.componentStyles.height,
                                }}
                                scale={canvasDimension.scale}
                                position={{
                                  x: items.componentStyles.left,
                                  y: items.componentStyles.top,
                                }}
                                lockAspectRatio={
                                  items.componentStyles.aspectLock
                                }
                                onDrag={(e, d) => {
                                  updateCompValues({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onDragStop={(e, d) => {
                                  callOnDragStop({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onResize={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  updateFontOnResize({
                                    id: items.id,
                                    width: ref.offsetWidth,
                                    height: ref.offsetHeight,
                                    ...position,
                                  });
                                }}
                                key={index}
                                id={items.id}
                                className={
                                  `${
                                    selectedComponent.id === items.id
                                      ? "selectedComp div-dragresize-parent cs-container "
                                      : "div-dragresize-parent cs-container"
                                  }` +
                                  " " +
                                  `${
                                    multiSelectedComps.length &&
                                    items.multiSelected === true
                                      ? "multipleSelection"
                                      : ""
                                  }`
                                }
                                onClick={(e) => {
                                  setMultiSelectedComps([]);

                                  sendSelectedComponent(e, index);
                                  setFroalaIndex(index);
                                  setContainerID(items.id);
                                  setCurrentIdZindex(e.currentTarget.id);
                                  setVisibility(false);
                                }}
                                tabIndex="0"
                                onKeyDown={(e) => handleDeleteKey(e)}
                                style={{
                                  // opacity:
                                  //   items.id === selectedComponent.id
                                  //     ? 1
                                  //     : items.componentStyles.opacity,
                                  opacity:
                                    toggleAnimatedBanner === true ||
                                    items?.componentStyles?.hideBoolean === true
                                      ? items?.componentStyles?.opacity
                                      : items?.componentStyles?.compOpacity /
                                        100,
                                  zIndex: items.componentStyles.zIndex,

                                  // opacity: items?.componentStyles?.opacity,
                                  transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius:
                                      items.componentStyles.borderRadius,
                                    border: items.componentStyles.border,
                                    backgroundColor:
                                      items.componentStyles.backgroundColor,

                                    visibility:
                                      items.id === selectedComponent.id &&
                                      items.componentStyles.opacity === 0
                                        ? "hidden"
                                        : "visible",
                                    // display:
                                    //   !playPause.animationCompleted ||
                                    //   items.componentStyles.hideBoolean
                                    //     ? "none"
                                    //     : "block",

                                    /*  backgroundColor:
                                        items.componentStyles.backgroundColor, */
                                    paddingTop:
                                      items.componentStyles.paddingTop,
                                    // zIndex: items.componentStyles.zIndex,
                                    /*  borderRadius:
                                        items.componentStyles.borderRadius,
                                      border: items.componentStyles.border, */
                                    transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                    ease: items.componentStyles.ease,
                                    // opacity:
                                    //   items.id === selectedComponent.id
                                    //     ? 1
                                    //     : items.componentStyles.opacity,
                                    // transform: items.componentStyles.scale,
                                    opacity:
                                      items.id === selectedComponent.id
                                        ? 1
                                        : toggleAnimatedBanner === true ||
                                          items?.componentStyles
                                            ?.hideBoolean === true
                                        ? items?.componentStyles?.opacity
                                        : items?.componentStyles?.compOpacity /
                                          100,
                                  }}
                                  onMouseUp={(e) => {
                                    isDragged.mouseDown = false;
                                    sendSelectedComponent(e, index);
                                    setFroalaIndex(index);
                                    setContainerID(items.id);
                                    setCurrentIdZindex(e.currentTarget.id);
                                  }}
                                >
                                  <div className="shapesOnCanvas"></div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    height: "100%",

                                    /*  backgroundColor:
                                        items.componentStyles.backgroundColor, */
                                    paddingTop:
                                      items.componentStyles.paddingTop,
                                    // zIndex: items.componentStyles.zIndex,
                                    /*  borderRadius:
                                        items.componentStyles.borderRadius,
                                      border: items.componentStyles.border, */
                                    transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                    ease: items.componentStyles.ease,
                                    // transform: items.componentStyles.scale,
                                    // opacity:
                                    //   toggleAnimatedBanner === true ||
                                    //   items?.componentStyles?.hideBoolean ===
                                    //     true
                                    //     ? items?.componentStyles?.opacity
                                    //     : items?.componentStyles?.compOpacity /
                                    //       100,
                                  }}
                                ></div>
                              </Rnd>
                            )}
                            {(items?.textComponentType?.addContent ===
                              "polygonShape" ||
                              items?.textComponentType?.addContent ===
                                "triangleShape" ||
                              items?.textComponentType?.addContent ===
                                "hexagonShape") && (
                              <Rnd
                                resizeHandleClasses={{
                                  topRight: "topAnchorRight",
                                  topLeft: "topAnchorLeft",
                                  bottomLeft: "bottomAnchorLeft",
                                  bottomRight: "bottomAnchorRight",
                                  top: "horizontalAnchorCenter",
                                  bottom: "horizontalAnchorCenter",
                                  left: "verticallAnchorCenter",
                                  right: "verticallAnchorCenter",
                                }}
                                disableDragging={
                                  items?.componentStyles?.hideBoolean ||
                                  items?.componentStyles?.lockBoolean ||
                                  !playPauseRef.current.animationCompleted
                                }
                                enableResizing={
                                  !items?.componentStyles?.hideBoolean &&
                                  !items?.componentStyles?.lockBoolean &&
                                  playPauseRef.current.animationCompleted
                                }
                                size={{
                                  width: items.componentStyles.width,
                                  height: items.componentStyles.height,
                                }}
                                // scale={canvasDimension.scale}
                                position={{
                                  x: items.componentStyles.left,
                                  y: items.componentStyles.top,
                                }}
                                onDrag={(e, d) => {
                                  updateCompValues({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onDragStop={(e, d) => {
                                  updateCompValues({
                                    x: d.x,
                                    y: d.y,
                                    id: items.id,
                                  });
                                }}
                                onResize={(
                                  e,
                                  direction,
                                  ref,
                                  delta,
                                  position
                                ) => {
                                  updateFontOnResize({
                                    id: items.id,
                                    width: ref.offsetWidth,
                                    height: ref.offsetHeight,
                                    ...position,
                                  });
                                }}
                                key={index}
                                id={items.id}
                                className={
                                  `${
                                    selectedComponent.id === items.id
                                      ? "selectedComp div-dragresize-parent cs-container "
                                      : "div-dragresize-parent cs-container"
                                  }` +
                                  " " +
                                  `${
                                    multiSelectedComps.length &&
                                    items.multiSelected === true
                                      ? "multipleSelection"
                                      : ""
                                  }`
                                }
                                onClick={(e) => {
                                  setMultiSelectedComps([]);
                                  sendSelectedComponent(e, index);
                                  setFroalaIndex(index);
                                  setContainerID(items.id);
                                  setCurrentIdZindex(e.currentTarget.id);
                                  setVisibility(false);
                                }}
                                tabIndex="0"
                                onKeyDown={(e) => handleDeleteKey(e)}
                                style={{
                                  pointerEvents:
                                    (!playPause.animationCompleted ||
                                      items.componentStyles.hideBoolean) &&
                                    "none",
                                  zIndex: items.componentStyles.zIndex,
                                  opacity:
                                    items?.componentStyles?.hideBoolean ===
                                      true && 0,
                                  transform: `rotate(${items.componentStyles.rotation}) scale(${items.componentStyles.scale})`,
                                }}
                                // className="div-dragresize-parent cs-container"
                              >
                                <div
                                  className="shapesOnCanvas"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    // transform: `rotate(${items.componentStyles.rotation}) scale(${canvasDimension.scale})`,
                                    // left: items.componentStyles.left,
                                    // top: items.componentStyles.top,
                                    // zIndex: items.componentStyles.zIndex,
                                    ease: items.componentStyles.ease,
                                    // width: items.componentStyles.width,
                                    // height: items.componentStyles.height,
                                    transform: items.componentStyles.scale,
                                    aspectLock:
                                      items.componentStyles.aspectLock,
                                    // opacity:
                                    //   toggleAnimatedBanner === true ||
                                    //   items?.componentStyles?.hideBoolean ===
                                    //     true
                                    //     ? items?.componentStyles?.opacity
                                    //     : items?.componentStyles?.compOpacity /
                                    //       100,
                                  }}
                                  onMouseUp={(e) => {
                                    isDragged.mouseDown = false;
                                    sendSelectedComponent(e, index);
                                    setFroalaIndex(index);
                                    setContainerID(items.id);
                                    setCurrentIdZindex(e.currentTarget.id);
                                  }}
                                >
                                  {items?.textComponentType?.addContent ===
                                    "triangleShape" && (
                                    <img
                                      className="triangleImage"
                                      src={
                                        items?.textComponentType?.addContent ===
                                          "triangleShape" && items.triImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                        opacity:
                                          items?.componentStyles
                                            ?.hideBoolean === true
                                            ? items?.componentStyles?.opacity
                                            : items?.componentStyles
                                                ?.compOpacity / 100,
                                      }}
                                      alt=""
                                    />
                                  )}
                                  {items?.textComponentType?.addContent ===
                                    "polygonShape" && (
                                    <img
                                      className="pentagonImage"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                        opacity:
                                          items?.componentStyles
                                            ?.hideBoolean === true
                                            ? items?.componentStyles?.opacity
                                            : items?.componentStyles
                                                ?.compOpacity / 100,
                                        //border: items.componentStyles.border,
                                      }}
                                      src={
                                        items?.textComponentType?.addContent ===
                                          "polygonShape" && items.pentImage
                                      }
                                      alt=""
                                    />
                                  )}
                                  {items?.textComponentType?.addContent ===
                                    "hexagonShape" && (
                                    <img
                                      className="img-fluid"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        pointerEvents: "none",
                                        opacity:
                                          items?.componentStyles
                                            ?.hideBoolean === true
                                            ? items?.componentStyles?.opacity
                                            : items?.componentStyles
                                                ?.compOpacity / 100,
                                      }}
                                      src={
                                        items?.textComponentType?.addContent ===
                                          "hexagonShape" && items.hexImage
                                      }
                                      alt=""
                                    />
                                  )}
                                </div>
                              </Rnd>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
              <ContextMenuZindex
                outerRef={contextMenuRef}
                updateZindexValue={updateZindexValue}
                selectedComponent={selectedComponent}
                deleteComponentOnCanvas={deleteComponentOnCanvas}
                layerCompData={layerCompData}
                isVisible={isVisible}
                setVisibility={setVisibility}
                copiedComponent={copiedComponent}
                copyCanvasComponent={copyCanvasComponent}
                pasteComponentOnCanvas={pasteComponentOnCanvas}
                setShowPaste={setShowPaste}
                toggleLockComp={toggleLockComp}
                toggleHideComp={toggleHideComp}
                playPause={playPause}
                multiSelectedComps={multiSelectedComps}
                mm={componentType}
                imageOnCanvas={imageOnCanvas}
              />
              {copiedComponent && (
                <PasteContextMenuZindex
                  outerRefPaste={contextMenuRef}
                  selectedComponent={selectedComponent}
                  layerCompData={layerCompData}
                  showPaste={showPaste}
                  setShowPaste={setShowPaste}
                  pasteComponentOnCanvas={pasteComponentOnCanvas}
                  setVisibility={setVisibility}
                  mm={componentType}
                  imageOnCanvas={imageOnCanvas}
                />
              )}

              <>
                <div className="timeLinePanel">
                  {/* <div
                    className={
                      openTimeLine
                        ? " timelineiconbg timeLineImg"
                        : "timeLineImg"
                    }
                  >
                    <img
                      id="timeline-img"
                      alt="timeline-img"
                      className="timelineImg"
                      onClick={() => {
                        playPauseRef.current.animationCompleted &&
                          setOpenTimeLine(!openTimeLine);
                      }}
                      src={
                        toggleAnimatedBanner
                          ? openTimeLine === true
                            ? TimelineBlue
                            : nounLayersBlack
                          : openTimeLine === true
                          ? TimelineBlue
                          : nounLayersBlack
                      }
                    ></img> */}
                  {/* </div> */}
                  {/* <div className="timelineText">
                    <p
                      className={
                        openTimeLine === true
                          ? "sideBarTextonClick"
                          : "sideBarTypeNotOnclik"
                      }
                    >
                      Timeline
                    </p>
                  </div> */}
                  {/* <img
                    src={grayFwdArrow}
                    alt="forwardArrow2"
                    className="timeLinearrow img-fluid"
                  /> */}
                </div>
                {openTimeLine ? (
                  <AnimationTimeLine
                    layerCompData={layerCompData}
                    setVerticalLineFor={setVerticalLineFor}
                    animationObject={animationObject}
                    setAnimationObject={setAnimationObject}
                    millSeconds={millSeconds}
                    seconds={seconds}
                    setMillSeconds={setMillSeconds}
                    setSeconds={setSeconds}
                    selectedComponent={selectedComponent}
                    setSelectedComponent={setSelectedComponent}
                    setLayerCompData={setLayerCompData}
                    copiedKeyFrame={copiedKeyFrame}
                    setCopiedKeyFrame={setCopiedKeyFrame}
                    popUpOptions={popUpOptions}
                    setPopUpOptions={setPopUpOptions}
                    toggleLockComp={toggleLockComp}
                    toggleHideComp={toggleHideComp}
                    // fps={canvasDimension.fps}
                    toggleAnimatedBanner={toggleAnimatedBanner}
                    canvasDimension={canvasDimension}
                    setundoFlag={setundoFlag}
                    openTimelinePopup={openTimelinePopup}
                    setOpenTimelinePopup={setOpenTimelinePopup}
                    popUpValuesEdited={popUpValuesEdited}
                    setPopUpValuesEdited={setPopUpValuesEdited}
                    playPause={playPause}
                    setPlayPause={setPlayPause}
                    playPauseRef={playPauseRef}
                    marker={marker}
                    setMarker={setMarker}
                    popUpLeft={popUpLeft}
                    SetPopupLeft={SetPopupLeft}
                    refFroala={createFroalaInstance.current}
                    clickedTimes={clickedTimes}
                    froalaIndex={froalaIndex}
                    isEditorOn={isEditorOn}
                    setIsEditorOn={setIsEditorOn}
                    bannerChanged={bannerChanged}
                    setBannerChanged={setBannerChanged}
                    layerChangedOnPause={layerChangedOnPause}
                    setLayerChangedOnPause={setLayerChangedOnPause}
                    setMultiSelectedComps={setMultiSelectedComps}
                  />
                ) : (
                  <></>
                )}
              </>
            </Col>
            <Col className=" col-3 col-md-3 rightHandside">
              <div className="rightpn">
                <RightPanel
                  bannerId={bannerId}
                  setMLRstatus={setMLRstatus}
                  MLRstatus={MLRstatus}
                  damsID={damsID}
                  isInfoPanel={isInfoPanel}
                  showAssettype={showAssettype}
                  selectedFontId={selectedFontId}
                  setSelectedFontId={setSelectedFontId}
                  updateSelctedFontstyle={updateSelctedFontstyle}
                  setFroalatoolbarTag={setFroalatoolbarTag}
                  genericInfo={genericInfo}
                  setGenericInfo={setGenericInfo}
                  myCanvasRef={myCanvasRef}
                  animationObject={animationObject}
                  setAnimationObject={setAnimationObject}
                  dimensionsData={dimensionsData}
                  selectedComponent={selectedComponent}
                  setSelectedComponent={setSelectedComponent}
                  layerCompData={layerCompData}
                  setLayerCompData={setLayerCompData}
                  layerIndex={layerIndex}
                  createFroalaInstance={createFroalaInstance}
                  froalaIndex={froalaIndex}
                  froalaFontAlignments={froalaFontAlignments}
                  froalaNumberList={froalaNumberList}
                  froalaFontStyles={froalaFontStyles}
                  imageOnCanvas={imageOnCanvas}
                  layerId={layerId}
                  canvasDimension={canvasDimension}
                  setCanvasDimension={setCanvasDimension}
                  toggleSelected={toggleSelected}
                  //backgroundColor={backgroundColor}
                  //setBackgroundColor={setBackgroundColor}
                  checkedAspect={checkedAspect}
                  ToggleAspectLock={ToggleAspectLock}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  handleChange={handleChange}
                  setCheckedScroll={setCheckedScroll}
                  checkedScroll={checkedScroll}
                  renderTheFontLink={renderTheFontLink}
                  showBannertype={showBannertype}
                  toggleAnimatedBanner={toggleAnimatedBanner}
                  setSelectedPreviousId={setSelectedPreviousId}
                  getEditSaveAssets={getEditSaveAssets}
                  setShowBannerName={setShowBannerName}
                  setundoFlag={setundoFlag}
                  canvasUpdated={canvasUpdated}
                  setCanvasUpdated={setCanvasUpdated}
                  previousCanvasDimension={previousCanvasDimension}
                  setPreviousCanvasDimension={setPreviousCanvasDimension}
                  bgColorCanvas={bgColorCanvas}
                  setBgColorCanvas={setBgColorCanvas}
                  canvasBgChange={canvasBgChange}
                  setCanvasBgChange={setCanvasBgChange}
                  setOpenTimelinePopup={setOpenTimelinePopup}
                  scrollBarExpand={scrollBarExpand}
                  playPause={playPause}
                  playPauseRef={playPauseRef}
                  setClickedTimes={setClickedTimes}
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EditorMainPage;
