import React, {
  useEffect,
  useState,
  usePrevious,
  createRef,
  useRef,
} from "react";
import { DebounceInput } from "react-debounce-input";

import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField, InputAdornment } from "@material-ui/core";
import "../../styles/components/PopupWindow.scss";
import CloseIcon from "../../images/iconclose.svg";
import Delete from "../../images/Delete.svg";
import InfoIcon from "../../images/iconinfo.svg";
import Paste from "../../images/Paste.svg";
import pasteBlue from "../../images/pasteBlue.svg";
import Copy from "../../images/Copy.svg";
import Slider from "@material-ui/core/Slider";
import { toast } from "react-toastify";
import { SettingsInputHdmiOutlined } from "@material-ui/icons";
import { OutsideClickHandler } from "../../Uitls/Util";
export default function PopupWindow({
  layerCompData,
  setLayerCompData,
  handleTimelinePopup,
  marker,
  markerTime,
  selectedComponent,
  setSelectedComponent,
  setPopUpOptions,
  popUpOptions,
  animationObject,
  setAnimationObject,
  copiedKeyFrame,
  setCopiedKeyFrame,
  setEdited,
  setundoFlag,
  popUpValuesEdited,
  setPopUpValuesEdited,
  openTimelinePopup,
  setOpenTimelinePopup,
  handleChangeTimelineProps,
  saveAnmObjWithedit,
}) {
  const animationPopUpRef = useRef(null);

  const { t } = useTranslation();
  //const easingOptions = ["NONE", "EASE IN", "EASE OUT"];
  const easingOptions = ["none", "power1.in", "power1.out"];
  const [hoverRotateIcon, setHoverRotateIcon] = useState(false);
  const [hoverScaleIcon, setHoverScaleIcon] = useState(false);
  const [hoverOpacityIcon, setHoverOpacityIcon] = useState(false);

  const onHoverRotateIcon = () => {
    setHoverRotateIcon(true);
  };
  const onHoverScaleIcon = () => {
    setHoverScaleIcon(true);
  };
  const onLeaveScaleIcon = () => {
    setHoverScaleIcon(false);
  };

  const onHoverOpcaityIcon = () => {
    setHoverOpacityIcon(true);
  };
  const onLeaveOpcaityIcon = () => {
    setHoverOpacityIcon(false);
  };

  const onLeaveRotateicon = () => {
    setHoverRotateIcon(false);
  };

  OutsideClickHandler(
    animationPopUpRef,
    () => {
      // setOpenTimelinePopup(false);
      setEdited(false);
      setOpenTimelinePopup({
        popupWindow: false,
        fromUnRedo: false,
      });
    },
    openTimelinePopup,
    selectedComponent.id
  );

  const chanageOpacityOnmouseUp = (e, newValue) => {
    let event1 = {
      target: {
        name: "opacity",
        value: newValue / 100,
      },
    };
    setPopUpValuesEdited(true);
    saveAnmObjWithedit(event1);
  };
  const handleChangeOpacity = (event, newValue) => {
    let popUpvalues = { ...popUpOptions };
    popUpvalues.opacity = newValue;
    setPopUpOptions({ ...popUpvalues, clicked: true });
    // let event1 = {
    //   target: {
    //     name: "opacity",
    //     value: newValue / 100,
    //   },
    // };
    // setundoFlag(true);
    // layerCompData.forEach((entry, index) => {
    //   if (entry.id === selectedComponent.id)
    //     layerCompData[index].componentStyles.opacityAnm = newValue / 100;
    // });
    // setPopUpValuesEdited(true);
    // saveAnmObjWithedit(event1);
  };
  const changeScaleOnMouseUp = (event, newValue) => {
    let event1 = {
      target: {
        name: "transform",
        value: newValue / 100,
      },
    };
    setPopUpValuesEdited(true);
    saveAnmObjWithedit(event1);
  };
  const handleChangeScale = (event, newValue) => {
    let popUpvalues = { ...popUpOptions };
    popUpvalues.scale = newValue;
    setPopUpOptions({ ...popUpvalues, clicked: true });

    // let event1 = {
    //   target: {
    //     name: "transform",
    //     value: newValue / 100,
    //   },
    // };
    // if (selectedComponent.componentStyles[name] === Number(evennt1.target.value))
    //   return;
    // setundoFlag(true);
    // layerCompData.forEach((entry, index) => {
    //   if (entry.id === selectedComponent.id)
    //     layerCompData[index].componentStyles.scaleAnm = newValue / 100;
    // });
    // setPopUpValuesEdited(true);

    // saveAnmObjWithedit(event1);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleTimelinePopup, true);
    document
      .getElementById("marker")
      .addEventListener("mousedown", handleTimelinePopup, true);

    return () => {
      document.removeEventListener("scroll", handleTimelinePopup, true);
      document
        .getElementById("marker")
        .removeEventListener("mousedown", handleTimelinePopup, true);
    };
  }, []);
  const copyKeyFrames = () => {
    let n = markerTime / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

    let selectedIndex = animationObject[
      selectedComponent.id
    ]?.animation?.findIndex(
      (elem) =>
        keyFrameRange <= elem.end + 0.1 && keyFrameRange >= elem.end - 0.1
    );

    if (selectedIndex >= 0) {
      let tempAnmObj = {
        ...animationObject,
      };
      let anmAry = tempAnmObj[selectedComponent.id]?.animation;
      let keyFrameToBeCopied = anmAry.slice(selectedIndex, selectedIndex + 1);
      setCopiedKeyFrame(keyFrameToBeCopied[0]);
    } else
      toast.error(
        <div className="notificationText">{t("No KeyFrame is selected")}</div>
      );
  };
  const pasteKeyFrame = () => {
    if (!selectedComponent.hasOwnProperty("id")) {
      toast.error(
        <div className="notificationText">
          {t("Please select the component")}
        </div>
      );
      return;
    }
    if (copiedKeyFrame === "") {
      toast.error(
        <div className="notificationText">{t("No Key frame is copied")}</div>
      );
      return;
    }

    let n = markerTime / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

    let tempLayer = [...layerCompData];
    let tempAnmObj = {
      ...animationObject,
    };
    let anmAry = tempAnmObj[selectedComponent.id]?.animation;
    // if(( anmAry.findIndex((anm) => anm.end === keyFrameRange)) !== -1){
    //   toast.error(
    //     <div className="notificationText">
    //       {t("Key Frame exists !")}
    //     </div>
    //   );
    //   return;
    // }
    let tempCopiedKeyFrame = { ...copiedKeyFrame };
    tempCopiedKeyFrame.x = selectedComponent.componentStyles.left;
    tempCopiedKeyFrame.y = selectedComponent.componentStyles?.top;
    tempCopiedKeyFrame.width = selectedComponent.componentStyles.width;
    tempCopiedKeyFrame.height = selectedComponent.componentStyles.height;

    tempCopiedKeyFrame.end = keyFrameRange;
    let isKeyFramePresent = anmAry.findIndex(
      (anm) => keyFrameRange <= anm.end + 0.1 && keyFrameRange >= anm.end - 0.1
    );

    if (isKeyFramePresent !== -1) {
      tempCopiedKeyFrame.delay = anmAry[isKeyFramePresent].delay;
      tempCopiedKeyFrame.end = anmAry[isKeyFramePresent].end;
      tempCopiedKeyFrame.duration = anmAry[isKeyFramePresent].duration;
      anmAry[isKeyFramePresent] = tempCopiedKeyFrame;
    } else {
      anmAry.push(tempCopiedKeyFrame);
      anmAry.sort((a, b) => (a.end >= b.end ? 1 : -1));
      let pastedIndex = anmAry.findIndex((anm) => anm.end === keyFrameRange);
      if (anmAry.length > 1) {
        if (pastedIndex === 0) {
          anmAry[pastedIndex].delay = keyFrameRange;
          anmAry[pastedIndex].end = keyFrameRange;
          anmAry[pastedIndex].duration = 0;
          if (anmAry.length >= 1) {
            anmAry[pastedIndex + 1].delay = keyFrameRange;
            anmAry[pastedIndex + 1].duration =
              anmAry[pastedIndex + 1].end - anmAry[pastedIndex + 1].delay;
          }
        } else if (pastedIndex === anmAry.length - 1) {
          anmAry[pastedIndex].delay = anmAry[pastedIndex - 1].end;
          anmAry[pastedIndex].duration =
            anmAry[pastedIndex].end - anmAry[pastedIndex].delay;
        } else {
          anmAry[pastedIndex].delay = anmAry[pastedIndex - 1].end;
          anmAry[pastedIndex + 1].delay = anmAry[pastedIndex].end;
          anmAry[pastedIndex].duration =
            anmAry[pastedIndex].end - anmAry[pastedIndex].delay;
          anmAry[pastedIndex + 1].duration =
            anmAry[pastedIndex + 1].end - anmAry[pastedIndex + 1].delay;
        }
      }
    }
    let popUpvalues = {
      ...popUpOptions,
      left: tempCopiedKeyFrame.x,
      top: tempCopiedKeyFrame.y,
      width: tempCopiedKeyFrame.width,
      height: tempCopiedKeyFrame.height,
      ease: tempCopiedKeyFrame.ease,
      skewAnimation: tempCopiedKeyFrame.skewAnimation,
      opacity: Math.round(tempCopiedKeyFrame.opacity * 100),
      rotation: tempCopiedKeyFrame.rotation,
      scale: Math.round(tempCopiedKeyFrame.transform * 100),
    };
    setPopUpOptions(popUpvalues);
    setAnimationObject(tempAnmObj);
    if (isKeyFramePresent === -1)
      tempLayer.forEach((layer) => {
        if (layer.id === selectedComponent.id) {
          layer.keyFrames.push(markerTime);

          layer.keyFrames.sort(function (a, b) {
            return a - b;
          });
          layer.animationApllied = {
            keyFrameEdited: "false",
            keyFrameadded: "false",
            keyFrameDeleted: "false",
            componentAdded: "false",
            keyFramePasted: "true",
            marker: markerTime,
          };
        }
      });

    setLayerCompData(tempLayer);
  };
  const deleteKeyFrame = () => {
    if (animationObject[selectedComponent.id]?.animation?.length === 0) return;

    let n = markerTime / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

    let selectedIndex = animationObject[
      selectedComponent.id
    ]?.animation?.findIndex(
      (elem) =>
        keyFrameRange <= elem.end + 0.1 && keyFrameRange >= elem.end - 0.1
    );
    let tempAnmObj = {
      ...animationObject,
    };
    if (selectedIndex >= 0) {
      let anmAry = tempAnmObj[selectedComponent.id]?.animation;
      let anmAryAfterDelete = [];
      for (let i = 0; i < anmAry.length; i++) {
        if (i !== selectedIndex) anmAryAfterDelete.push(anmAry[i]);
      }
      if (selectedIndex === 0 && anmAry.length !== 1) {
        anmAryAfterDelete[0].delay = anmAryAfterDelete[0].end;
        anmAryAfterDelete[0].duration = 0;
      } else if (selectedIndex !== anmAry.length - 1) {
        anmAryAfterDelete[selectedIndex].delay =
          anmAryAfterDelete[selectedIndex - 1].end;
        anmAryAfterDelete[selectedIndex].duration =
          anmAryAfterDelete[selectedIndex].end -
          anmAryAfterDelete[selectedIndex].delay;
      } else if (selectedIndex === anmAry.length - 1 && selectedIndex !== 0) {
        let tempLayer = [...layerCompData];
        tempLayer.forEach((layer, index) => {
          if (layer.id === selectedComponent.id) {
            tempLayer[index].componentStyles.left = parseInt(
              anmAry[selectedIndex - 1].x
            );
            tempLayer[index].componentStyles.top = parseInt(
              anmAry[selectedIndex - 1].y
            );
            tempLayer[index].componentStyles.width = parseInt(
              anmAry[selectedIndex - 1].width
            );
            tempLayer[index].componentStyles.height = parseInt(
              anmAry[selectedIndex - 1].height
            );
          }
        });
      }
      tempAnmObj[selectedComponent.id].animation = [...anmAryAfterDelete];
      let tempLayer = [...layerCompData];
      tempLayer.forEach((layer, indexLayer) => {
        if (layer.id === selectedComponent.id) {
          layer.keyFrames = layer.keyFrames.filter(
            (keyFrame, index) => index !== selectedIndex
          );
          tempLayer[indexLayer].animationApllied = {
            keyFrameEdited: "false",
            keyFrameadded: "false",
            keyFrameDeleted: "true",
            componentAdded: "false",
            keyFramePasted: "false",
            marker: markerTime,
          };
        }
      });

      setLayerCompData(tempLayer);
      setAnimationObject(tempAnmObj);
      handleTimelinePopup(true);
    }
  };
  // const saveAnmObjWithedit = (e) => {
  //   let name = e.target.name;

  //   name = name === "left" ? "x" : name === "top" ? "y" : name;
  //   let n = markerTime / 45;
  //   let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

  //   let selectedIndex = animationObject[
  //     selectedComponent.id
  //   ]?.animation?.findIndex(
  //     (elem) =>
  //       keyFrameRange <= elem.end + 0.1 && keyFrameRange >= elem.end - 0.1
  //   );
  //   let tempAnmObj = {
  //     ...animationObject,
  //   };
  //   if (selectedIndex >= 0) {
  //     setundoFlag(false);
  //     let tempLayer = [...layerCompData];
  //     // tempLayer[
  //     //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
  //     // ].componentStyles[name + "edited"] = e.target.value;
  //     tempLayer.forEach((entry, index) => {
  //       if (entry.id === selectedComponent.id) {
  //         tempLayer[index].animationApllied = {
  //           keyFrameEdited: true,
  //           keyFrameadded: false,
  //           keyFrameDeleted: false,
  //           componentAdded: false,
  //           keyFramePasted: false,
  //         };
  //       }
  //     });

  //     setLayerCompData(tempLayer);
  //     setPopUpValuesEdited(true);
  //     tempAnmObj[selectedComponent.id].animation[selectedIndex][name] =
  //       e.target.value;
  //     setAnimationObject(tempAnmObj);
  //     setEdited(true);
  //   }
  // };
  const getPrevKeyFrameValues = () => {
    let popUpvalues = {};

    let selComp = animationObject[selectedComponent.id]?.animation?.filter(
      (elem) => {
        let n =
          // openTimelinePopup.fromUnRedo
          //   ? selectedComponent?.animationApllied.marker / 45 :
          markerTime / 45;
        let keyFrameRange =
          parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;
        if (
          keyFrameRange <= elem.end + 0.1 &&
          keyFrameRange >= elem.end - 0.1

          // elem.end >= parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10 &&
          // elem.end - 0.2 <=
          //   parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10
        )
          return elem;
      }
    );
    if (selComp?.length > 0) {
      popUpvalues = {
        ...popUpOptions,
        left: selComp[0].x,
        top: selComp[0].y,
        width: selComp[0].width,
        height: selComp[0].height,
        ease: selComp[0].ease,
        skewAnimation: selComp[0].skewAnimation,
        opacity: Math.round(selComp[0].opacity * 100),
        rotation: selComp[0].rotation,
        scale: Math.round(selComp[0].transform * 100),
      };
    } else if (selectedComponent.componentStyles) {
      popUpvalues = {
        ...popUpOptions,
        left: selectedComponent.componentStyles.left,
        top: selectedComponent.componentStyles.top,
        width: selectedComponent.componentStyles.width,
        height: selectedComponent.componentStyles.height,
        ease: "NONE",
        skewAnimation: false,
        opacity: 100,
        rotation: 0,
        scale: 100,
      };
    }
    setPopUpOptions(popUpvalues);
  };
  useEffect(() => {
    if (!popUpValuesEdited) getPrevKeyFrameValues();
  }, [selectedComponent]);

  // const handleChangeTimelineProps = (e, blur) => {
  //   if (e.target.value.length > 4) {
  //     toast.error(
  //       <div className="notificationText">
  //         Value can not be more than 4 digits
  //       </div>
  //     );
  //     return;
  //   }
  //   let name = e.target.name;

  //   let popUpvalues = {
  //     ...popUpOptions,
  //     [name]:
  //       isNaN(e.target.value) || e.target.value === "" ? 0 : e.target.value,
  //   };
  //   setPopUpOptions({ ...popUpvalues, clicked: true });
  //   if (blur !== "blur") return;
  //   if (
  //     selectedComponent.componentStyles[name] === Number(e.target.value) ||
  //     selectedComponent.componentStyles[name] === e.target.value
  //   )
  //     return;

  //   if (
  //     name === "left" ||
  //     name === "top" ||
  //     name === "width" ||
  //     name === "height"
  //   ) {
  //     setundoFlag(true);

  //     layerCompData.forEach((entry, index) => {
  //       if (entry.id === selectedComponent.id)
  //         layerCompData[index].componentStyles[name] = parseInt(e.target.value);
  //     });

  //     setPopUpValuesEdited(true);
  //   }

  //   if (name === "skewAnimation") {
  //     let skewChecked = {
  //       ...popUpOptions,
  //       [name]: e.target.checked,
  //     };
  //     // setPopUpOptions({ ...skewChecked, clicked: true });
  //   }
  //   // if (name === "width" || name === "height") {
  //   //   layerCompData.forEach((entry, index) => {
  //   //     if (entry.id === selectedComponent.id)
  //   //       layerCompData[index].componentStyles[name] = parseInt(e.target.value);
  //   //   });
  //   //   setundoFlag(true);
  //   // }
  //   if (name === "rotation") {
  //     // if (e.target.value > 360) {
  //     //   toast.error(
  //     //     <div className="notificationText">
  //     //       Rotation value can not be more than 360 degree
  //     //     </div>
  //     //   );
  //     // } else
  //     if (e.target.value < -360) {
  //       toast.error(
  //         <div className="notificationText">
  //           Rotation value can not be less than -360 degree
  //         </div>
  //       );
  //     } else {
  //       setundoFlag(true);
  //       layerCompData.forEach((entry, index) => {
  //         if (entry.id === selectedComponent.id)
  //           layerCompData[index].componentStyles[name] = isNaN(e.target.value)
  //             ? 0
  //             : e.target.value;
  //       });
  //       setPopUpValuesEdited(true);
  //       // setLayerCompData(tempElemLayers);
  //       // let popUpvalues = {
  //       //   ...popUpOptions,
  //       //   [name]:
  //       //     e.target.value !== "-" && isNaN(e.target.value)
  //       //       ? 0
  //       //       : e.target.value,
  //       // };
  //       // setPopUpOptions({ ...popUpvalues, clicked: true });
  //     }
  //   }

  //   saveAnmObjWithedit(e);
  // };

  const handlEasingOps = (e) => {
    // let sendEaseOption =
    //   e.target.value === "EASE OUT"
    //     ? "Power1.easeOut"
    //     : e.target.value === "EASE IN"
    //     ? "Power1.easeIn"
    //     : "none";
    setPopUpOptions({
      ...popUpOptions,
      ease: e.target.value,
      clicked: true,
    });
    let tempElemLayers = [...layerCompData];
    tempElemLayers.forEach((entry, index) => {
      if (entry.id === selectedComponent.id)
        tempElemLayers[index].componentStyles.ease = e.target.value;
    });
    setundoFlag(true);
    setLayerCompData(tempElemLayers);
    saveAnmObjWithedit(e);
  };
  const toggleSkewCheck = (e) => {
    if (selectedComponent) {
      let tempSlected = { ...selectedComponent };
      tempSlected.componentStyles.skewAnimation = e.target.checked;
      setSelectedComponent(tempSlected);
    }
  };

  return (
    <div
      className="timeline-menu"
      style={{ left: marker }}
      ref={animationPopUpRef}
      id="popUp-MainContainer"
    >
      <div className="popUpHeding">
        <div className="timeline-heading">{t("timelineMenu.titleText")}</div>
        <div className="pasteimg">
          {copiedKeyFrame ? (
            <img
              src={pasteBlue}
              alt="paste"
              onClick={() => pasteKeyFrame()}
              className="img-fluid"
            />
          ) : (
            <img src={Paste} alt="paste" className="img-fluid" />
          )}
        </div>
        <div className="copyImg">
          <img
            src={Copy}
            alt="copy"
            onClick={() => copyKeyFrames()}
            className="img-fluid"
          />
        </div>
        <div className="deleteImg">
          <img
            src={Delete}
            alt="copy"
            onClick={(e) => deleteKeyFrame(e)}
            className="img-fluid"
          />
        </div>
        <div className="closeIconImg">
          <img
            src={CloseIcon}
            alt="copy"
            className="img-fluid"
            onClick={handleTimelinePopup}
          />
        </div>
      </div>
      <hr className="hrtagPopUp" />
      <div className="timeline-div" id="timeLine-inPopUp">
        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.easing")}</div>
          <div className="timelineProps-textfield">
            <select
              name="ease"
              className="easeInDropdown"
              onChange={(e) => handlEasingOps(e)}
              value={popUpOptions.ease}
            >
              {easingOptions.map((easingOption, id) => {
                return (
                  <option key={id} value={easingOption}>
                    {easingOption === "power1.in"
                      ? "EASE IN"
                      : easingOption === "power1.out"
                      ? "EASE OUT"
                      : "NONE"}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.x")}</div>
          <div className="timelineProps-textfield xCoordinate">
            <TextField
              // element={TextField}
              // debounceTimeout={500}
              disabled={selectedComponent?.componentStyles?.lockBoolean}
              className="standard-basic"
              id="xCoord"
              value={popUpOptions.left}
              name="left"
              size="small"
              autoComplete="off"
              inputProps={{
                style: {
                  marginTop: "-20px",
                  marginBottom: "-15px",
                  marginLeft: "10px",
                  fontSize: "11px",
                  // color: "#928989",
                },
              }}
              onChange={(e) => handleChangeTimelineProps(e)}
              onBlur={(e) => handleChangeTimelineProps(e, "blur")}
            />
          </div>
        </div>
        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.y")}</div>
          <div className="timelineProps-textfield xCoordinate">
            <TextField
              // element={TextField}
              // debounceTimeout={500}
              disabled={selectedComponent?.componentStyles?.lockBoolean}
              className="standard-basic"
              id="top"
              value={popUpOptions.top}
              name="top"
              size="small"
              autoComplete="off"
              inputProps={{
                style: {
                  marginTop: "-20px",
                  marginBottom: "-15px",
                  marginLeft: "10px",
                  fontSize: "11px",
                  // color: "#928989",
                },
              }}
              onChange={(e) => handleChangeTimelineProps(e)}
              onBlur={(e) => handleChangeTimelineProps(e, "blur")}
            />
          </div>
        </div>
        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.width")}</div>
          <div className="timelineProps-textfield xCoordinate">
            <TextField
              // element={TextField}
              // debounceTimeout={500}
              disabled={selectedComponent?.componentStyles?.lockBoolean}
              className="standard-basic"
              id="width"
              value={popUpOptions.width}
              name="width"
              size="small"
              autoComplete="off"
              inputProps={{
                style: {
                  marginTop: "-20px",
                  marginBottom: "-15px",
                  marginLeft: "10px",
                  fontSize: "11px",
                  // color: "#928989",
                },
              }}
              onChange={(e) => handleChangeTimelineProps(e)}
              onBlur={(e) => handleChangeTimelineProps(e, "blur")}
            />
          </div>
        </div>
        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.height")}</div>
          <div className="timelineProps-textfield xCoordinate">
            <TextField
              // element={TextField}
              // debounceTimeout={500}
              disabled={selectedComponent?.componentStyles?.lockBoolean}
              className="standard-basic"
              id="height"
              value={popUpOptions.height}
              name="height"
              size="small"
              autoComplete="off"
              inputProps={{
                style: {
                  marginTop: "-20px",
                  marginBottom: "-15px",
                  marginLeft: "10px",
                  fontSize: "11px",
                  // color: "#928989",
                },
              }}
              onChange={(e) => handleChangeTimelineProps(e)}
              onBlur={(e) => handleChangeTimelineProps(e, "blur")}
            />
          </div>
        </div>
        <div className="timeline-menu--option timelineProps-time opacitySlider">
          <div className="timelineProps opacitySliderDiv">
            {t("timelineMenu.opacity")}{" "}
          </div>
          <img
            onMouseOver={onHoverOpcaityIcon}
            onMouseOut={onLeaveOpcaityIcon}
            src={InfoIcon}
            alt="info"
            className="img-fluid infoImgOp"
          />
          <div className="timelineProps-textfield sliderRange">
            <Slider
              value={
                isNaN(popUpOptions.opacity)
                  ? 0
                  : Math.round(popUpOptions.opacity)
              }
              onChange={handleChangeOpacity}
              onChangeCommitted={chanageOpacityOnmouseUp}
              aria-labelledby="continuous-slider"
              defaultValue={10}
            />
            <div className="opacityPercent">{popUpOptions.opacity}%</div>
          </div>
        </div>

        <div className="timeline-menu--option timelineProps-time opacitySlider">
          <div className="timelineProps opacitySliderDiv">
            {t("timelineMenu.scale")}{" "}
          </div>
          <img
            src={InfoIcon}
            alt="info"
            className="img-fluid infoImgScale"
            onMouseOver={onHoverScaleIcon}
            onMouseOut={onLeaveScaleIcon}
          />
          <div className="timelineProps-textfield sliderRange">
            <Slider
              value={
                isNaN(popUpOptions.scale) ? 0 : Math.round(popUpOptions.scale)
              }
              onChange={handleChangeScale}
              onChangeCommitted={changeScaleOnMouseUp}
              aria-labelledby="continuous-slider"
              defaultValue={10}
              min={0}
              max={400}
              step={10}
            />
            <div className="opacityPercent">{popUpOptions.scale}%</div>
          </div>
        </div>

        <div className="timeline-menu--option timelineProps-time">
          <div className="timelineProps">{t("timelineMenu.rotate")} </div>

          <img
            src={InfoIcon}
            alt="info"
            className=" infoImgRotate img-fluid"
            onMouseOver={onHoverRotateIcon}
            onMouseOut={onLeaveRotateicon}
          />

          <div className="timelineProps-textfield propDiv">
            <TextField
              // element={TextField}
              // debounceTimeout={500}
              //type="number"
              className="standard-basic"
              id="rotation"
              value={popUpOptions.rotation}
              name="rotation"
              size="small"
              autoComplete="off"
              inputProps={{
                style: {
                  //width: "40px",
                  marginTop: "-20px",
                  marginBottom: "-15px",
                  fontSize: "11px",
                  color: "#928989",
                  marginLeft: "10px",
                  // textAlign: "center",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="degree">
                    degree
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleChangeTimelineProps(e)}
              onBlur={(e) => handleChangeTimelineProps(e, "blur")}
            />
          </div>
        </div>
        {/* <div className="timeline-menu--option timelineProps-time skewLabel">
          <label for="vehicle1">Skew</label>
          <input
            name="skewAnimation"
            checked={popUpOptions?.skewAnimation}
            //  checked={selectedComponent?.componentStyles.skewAnimation}
            type="checkbox"
            className="skewCheckbox"
            onClick={(e) => handleChangeTimelineProps(e)}
            
          />
        </div> */}
        {hoverOpacityIcon && (
          <div className="opacityHover">{t("timelineMenu.opacityInfo")}</div>
        )}
        {hoverRotateIcon && (
          <div className="rotationHover">{t("timelineMenu.rotateText")}</div>
        )}
        {hoverScaleIcon && (
          <div className="scaleHover">{t("timelineMenu.zoom")}</div>
        )}
      </div>
    </div>
  );
}
