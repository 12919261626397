import React, { useState, useEffect, useContext } from "react";
import { scroll } from "../../Uitls/ScrollDiv";

import Canvas from "./Canvas";
import PopupWindow from "../TimelineComps/PopupWindow";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ValueContext from "../../ContextAPi/appContext";
import { getValidInput } from "../../Uitls/Util";

//import { resizeWdith } from "./DragandResize";

// export default function Layers({

const incrementalSecondsCTA = 68; //clientHeight of CTA
function Layers({
  layerCompData,
  setLayerCompData,
  millSeconds,
  seconds,
  setMillSeconds,
  setSeconds,
  selectedComponent,
  setSelectedComponent,
  marker,
  setMarker,
  animationObject,
  setAnimationObject,
  popUpOptions,
  setPopUpOptions,
  copiedKeyFrame,
  setCopiedKeyFrame,
  setEdited,
  openTimelinePopup,
  setOpenTimelinePopup,
  toggleAnimatedBanner,
  setundoFlag,
  popUpValuesEdited,
  setPopUpValuesEdited,
  playPause,
  popUpLeft,
  SetPopupLeft,
  bannerChanged,
  setBannerChanged,
  setLayerChangedOnPause,
}) {
  const { t } = useTranslation();

  // const [popUpLeft, SetPopupLeft] = React.useState(-140);//moved to editorMainPage //half of Pop up window with is 140
  // const [openTimelinePopup, setOpenTimelinePopup] = useState(false);
  const [popUpName, setPopUpName] = useState(false);

  const [totalDuration, setTotalDuration] = useState({
    divisions: 20,
    width: 910,
  });
  const appData = useContext(ValueContext); // to save scale

  const myDurationRef = React.useRef(totalDuration);

  const twentyPlusSeconds = () => {
    let tempduration = {
      ...totalDuration,
      divisions: totalDuration.divisions + 10, // 10 secs
      width: totalDuration.width + 449.5, //width
    };
    // myDurationRef.current = tempduration;
    setTotalDuration(tempduration);
    appData.updateGenericInfo({
      ...appData?.genericInfo,
      canvasScale: tempduration,
    });
    setBannerChanged(true);
    setLayerChangedOnPause(true);
  };

  useEffect(() => {
    if (
      !openTimelinePopup.popupWindow &&
      Object.values(selectedComponent).length &&
      selectedComponent.hasOwnProperty("id")
    ) {
      if (
        selectedComponent?.componentStyles[popUpName] !==
        popUpOptions[popUpName]
      ) {
        let event1 = {
          target: {
            name: popUpName,
            value: popUpOptions[popUpName],
          },
        };
        handleChangeTimelineProps(event1, "blur");
      }
    }
  }, [openTimelinePopup.popupWindow]);
  const saveAnmObjWithedit = (e) => {
    let name = e.target.name;

    name = name === "left" ? "x" : name === "top" ? "y" : name;
    let n = marker / 45;
    let keyFrameRange = parseInt(n) + Math.ceil((n - Math.floor(n)) * 10) / 10;

    let selectedIndex = animationObject[
      selectedComponent.id
    ]?.animation?.findIndex(
      (elem) =>
        keyFrameRange <= elem.end + 0.1 && keyFrameRange >= elem.end - 0.1
    );
    let tempAnmObj = {
      ...animationObject,
    };
    if (selectedIndex >= 0) {
      setundoFlag(false);
      let tempLayer = [...layerCompData];
      // tempLayer[
      //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
      // ].componentStyles[name + "edited"] = e.target.value;
      tempLayer.forEach((entry, index) => {
        if (entry.id === selectedComponent.id) {
          tempLayer[index].animationApllied = {
            keyFrameEdited: "true",
            keyFrameadded: "false",
            keyFrameDeleted: "false",
            componentAdded: "false",
            keyFramePasted: "false",
            marker: marker,
          };
        }
      });
      setEdited(true);

      setLayerCompData(tempLayer);
      setPopUpValuesEdited(true);
      tempAnmObj[selectedComponent.id].animation[selectedIndex][name] =
        e.target.value;
      setAnimationObject(tempAnmObj);
    }
  };
  // const getValidInput = (e, blur) => {
  //   let value = e.target.value;
  //   if (value.length > 4 && parseInt(value) > 9999) {
  //     toast.error(
  //       <div className="notificationText">
  //         Value can not be more than 4 digits
  //       </div>
  //     );
  //     return -1;
  //   }
  //   if (e.target.name === "rotation" && value === "-") return value;
  //   if (isNaN(value)) return -1;
  //   let nulVal = blur === "blur" ? 0 : "";
  //   return value !== "" ? parseInt(value) : nulVal;
  // };
  const handleChangeTimelineProps = (e, blur) => {
    let retVal = getValidInput(e, blur);

    if (retVal === -1) return;

    let name = e.target.name;

    if (
      name === "left" ||
      name === "top" ||
      name === "width" ||
      name === "height"
    ) {
      if (blur === "blur") {
        if (!!Number(retVal) || retVal === "") {
          retVal = Number(retVal);
        }
        layerCompData[layerCompData.length - 1].eachDrag = "no";
      } else {
        layerCompData[layerCompData.length - 1].eachDrag = "yes";
      }
      setundoFlag(true);
      // let tempLayer = [...layerCompData];

      // tempLayer.forEach((entry, index) => {
      //   if (entry.id === selectedComponent.id)
      //     // layerCompData[index].componentStyles[name] = retVal;
      //     entry.componentStyles[name] = retVal;
      // });
      // setLayerCompData(tempLayer);
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles[name] = retVal || 0;

      setSelectedComponent(tempSelected);
      setPopUpValuesEdited(true);
    } else {
      if (!!Number(retVal)) {
        retVal = Number(retVal);
      }
    }
    //  else if (blur !== "blur") return;

    let popUpvalues = {
      ...popUpOptions,
      [name]: retVal,
    };
    setPopUpOptions({ ...popUpvalues, clicked: true });
    setPopUpName(name);

    // if (
    //   selectedComponent.componentStyles[name] === Number(e.target.value) ||
    //   selectedComponent.componentStyles[name] === e.target.value
    // )
    //   return;

    if (
      name === "left" ||
      name === "top" ||
      name === "width" ||
      name === "height"
    ) {
      // setundoFlag(true);
      // let tempLayer = [...layerCompData];
      // tempLayer.forEach((entry, index) => {
      //   if (entry.id === selectedComponent.id)
      //     // layerCompData[index].componentStyles[name] = retVal;
      //     entry.componentStyles[name] = retVal;
      // });
      // setLayerCompData(tempLayer);
      // setPopUpValuesEdited(true);
    }

    if (name === "skewAnimation") {
      let skewChecked = {
        ...popUpOptions,
        [name]: e.target.checked,
      };
      // setPopUpOptions({ ...skewChecked, clicked: true });
    }

    if (name === "rotation") {
      //;
      // if (e.target.value > 360) {
      //   toast.error(
      //     <div className="notificationText">
      //       Rotation value can not be more than 360 degree
      //     </div>
      //   );
      // } else
      // if (!!(Number(retVal) % 1)) retVal = parseInt(retVal);
      let valueRotation = e.target.value;
      if (valueRotation < -360 || valueRotation > 360) {
        toast.error(
          <div className="notificationText">
            Rotation value should be in a range between -360 degree to +360
            degree.!
          </div>
        );

        if (parseInt(valueRotation) > 360) {
          valueRotation = 360;
          e.target.value = 360;
        } else if (parseInt(valueRotation) < -360) {
          valueRotation = -360;
          e.target.value = -360;
        }
        let tempLayer = [...layerCompData];
        // tempLayer[
        //   tempLayer.findIndex((selComp) => selComp.id === selectedComponent.id)
        // ].componentStyles[name + "edited"] = e.target.value;
        tempLayer.forEach((entry, index) => {
          if (entry.id === selectedComponent.id) {
            // layerCompData[index].componentStyles[name] = valueRotation;
            entry.componentStyles[name] = valueRotation;
          }
        });
        setLayerCompData(tempLayer);

        let popUpvalues = {
          ...popUpOptions,
          [name]: valueRotation,
        };

        setPopUpOptions({ ...popUpvalues, clicked: true });

        // setLayerCompData(tempLayer);
      } else {
        setundoFlag(true);
        layerCompData.forEach((entry, index) => {
          if (entry.id === selectedComponent.id)
            layerCompData[index].componentStyles[name] = valueRotation;
          //retVal;
        });
        setPopUpValuesEdited(true);
      }
    }
    saveAnmObjWithedit(e);
  };
  const handleTimelinePopup = (e) => {
    if (e?.target?.id) return;

    if (!selectedComponent.hasOwnProperty("id")) {
      toast.error(
        <div className="notificationText">{t("No Component selected!")}</div>
      );
      return;
    }
    setPopUpValuesEdited(false);
    setTimeout(() => {
      setEdited(false);
    }, 1000);
    let elem = document.getElementById("layerCol");
    SetPopupLeft(marker - 140 - elem.scrollLeft);
    // setOpenTimelinePopup(!openTimelinePopup);
    setOpenTimelinePopup({
      popupWindow: !openTimelinePopup.popupWindow,
      fromUnRedo: false,
    });
  };

  useEffect(() => {
    dragAndCalculate();
  }, []);

  useEffect(() => {
    console.log(appData?.genericInfo?.canvasScale);
    if (appData?.genericInfo?.canvasScale !== undefined)
      setTotalDuration({ ...appData?.genericInfo?.canvasScale });
    // myDurationRef.current = appData?.genericInfo?.canvasScale;
  }, [appData.updateGenericInfo]);
  useEffect(() => {
    //console.log("setMyrefAfterTwentyPlus", totalDuration);

    myDurationRef.current = totalDuration;
    // console.log("setMyrefAfterTwentyPlus", myDurationRef.current);
  }, [totalDuration]);
  let sLeft = 0;

  function dragAndCalculate() {
    console.log("here");
    let elem = document.getElementById("layerCol");

    let posLeft = 0;

    function dragTimeTracker() {
      document
        .getElementById("marker")
        .addEventListener("mousedown", mouseDown, false);
    }

    dragTimeTracker();

    function mouseUp(e) {
      e.preventDefault();
      if (!document.getElementById("marker")) return;
      if (!playPause?.playPause) setMarker(sLeft);
      let domVal = 551;
      let checkScrollValue =
        domVal -
        (document.getElementById("marker")?.getBoundingClientRect()?.left -
          document.getElementById("layerCol")?.getBoundingClientRect()?.left);

      if (checkScrollValue < 100) {
        let x = elem.scrollLeft;
        elem.scrollLeft = x + 30;
      }
      document
        .getElementById("marker")
        .addEventListener("mousedown", mouseDown, true);
      document
        .getElementById("timeline-scale")
        .removeEventListener("mousemove", divMove, false);
      // document
      //   .getElementById("timeline-scale")
      //   .removeEventListener("mouseup", mouseUp, true);
    }

    function mouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      posLeft = e.clientX;
      document
        .getElementById("timeline-scale")
        .addEventListener("mousemove", divMove, false);
      document
        //.getElementById("timeline-scale")
        .addEventListener("mouseup", mouseUp, true);
    }
    let scale = document
      .getElementById("timeline-scale")
      .getBoundingClientRect();

    let popUpParent = document
      .getElementById("time-line")
      .getBoundingClientRect();

    function divMove(e) {
      // console.log(e);
      if (openTimelinePopup === true) {
        // setOpenTimelinePopup(!openTimelinePopup);
        setOpenTimelinePopup({
          popupWindow: !openTimelinePopup.popupWindow,
          fromUnRedo: false,
        });
      }
      let valueTime =
        Math.floor(Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft) /
        45;
      let valueLeft = Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft;
      if (valueLeft >= 0 && valueTime <= myDurationRef.current.divisions) {
        sLeft = Math.round(
          Math.round(e.clientX) - scale.left - 16 + elem.scrollLeft
        );

        setMarker(sLeft);

        ////Calculate seconds and Milliseconds
        // console.log(sLeft, elem.scrollLeft, "timeline-scale");
        // sLeft + scale.left - 548 - elem.scrollLeft;
        SetPopupLeft(sLeft - 140 - elem.scrollLeft);
        let xMill = sLeft / 45;
        let secsToSet = 0;
        if ((xMill + 1) % Math.floor(xMill + 1) > 0.9)
          secsToSet = Math.round(xMill);
        else secsToSet = Math.floor(sLeft / 45);
        setSeconds(secsToSet < 0 ? 0 : secsToSet);

        if (xMill) {
          if (xMill < 1) xMill = parseInt((xMill % 1) * 100);
          else xMill = Math.round(parseInt((xMill % parseInt(xMill)) * 100));
          let stepValue = xMill % 10;

          xMill += 10;
          xMill = xMill > 100 ? 0 : xMill - stepValue;
          if (sLeft < myDurationRef.current.width - 10) {
            setMillSeconds(xMill);
          } else setMillSeconds(0);
        } else {
          setMillSeconds(0);
        }
        //setMillSeconds(Math.round(xMill));
      } else if (marker >= 0 && valueLeft < 0) {
        setMarker(0); // This is Hack not  a good fix
        setMillSeconds(0);
        setSeconds(0);
        SetPopupLeft(-140);
      } else if (valueTime > myDurationRef.current.divisions) {
        setMarker(myDurationRef.current.width - 10); // This is Hack not  a good fix
        setMillSeconds(0);
        setSeconds(myDurationRef.current.divisions);
        SetPopupLeft(myDurationRef.current.width - 140);
      }

      let domVal = 556;
      // document.getElementById("layerCol").clientWidth;
      // let leftScrollBar =
      //   document.getElementById("layerCol").getBoundingClientRect().left -
      //   document.getElementById("marker").getBoundingClientRect().left;
      // let checkScrollValue =
      //   domVal -
      //   (document.getElementById("marker")?.getBoundingClientRect().left -
      //     document.getElementById("layerCol")?.getBoundingClientRect().left);
      // console.clear();
      // console.log(domVal - checkScrollValue, "checkScrollValue");

      // if (checkScrollValue < 250) {
      //   let x = elem.scrollLeft;
      //   elem.scrollLeft = x + 30;
      // } else if (checkScrollValue - sLeft > 100) {
      //   elem.scrollTo(0, 0);
      // }
    }
  }

  useEffect(() => {
    layerCompData.map((value, index) => {
      if (!animationObject[value.id]) {
        resizeWdith("slider-TimeLine" + index, "ghostPane" + index);

        let temp;

        temp = Object.assign(animationObject, {
          [value.id]: {
            timeLine: { id: "slider-TimeLine" + index, left: 0 },
            animation: [],
          },
        });

        setAnimationObject(temp);
      }
    });
    if (layerCompData.length > 3) scroll();
    if (selectedComponent === "compdeleted" && layerCompData.length === 0) {
      setTotalDuration({ divisions: 20, width: 910 });
      setMarker(0);
      setSeconds(0);
      setMillSeconds(0);
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        canvasScale: { divisions: 20, width: 910 },
        rightUpdaed: true,
      });
      // myDurationRef.current = { divisions: 20, width: 910 };
      // setBannerChanged(true);
      // setLayerChangedOnPause(true);
    }

    // scrollbar initialization
  }, [layerCompData]);

  // useEffect(() => {
  //   // ssb.scrollbar("headPain");
  // }, []);

  function resizeWdith(pane1, ghostpane1) {
    return;
    // Minimum resizable area
    // var minWidth = 60;
    // var minHeight = 40;

    // // Thresholds
    // var FULLSCREEN_MARGINS = -5;
    // var MARGINS = 4;

    // // End of what's configurable.
    // var clicked = null;
    // var onRightEdge, onBottomEdge, onLeftEdge, onTopEdge;

    // var rightScreenEdge, bottomScreenEdge;

    // var preSnapped;

    // var b, x, y;

    // var redraw = false;

    // var pane = document.getElementById(pane1);
    // var ghostpane = document.getElementById(ghostpane1);
    // var ParentID = document.getElementById("layerCol");
    // function setBounds(element, x, y, w, h) {
    //   //console.log(element, x, "sssssss");
    //   if (element.style) {
    //     element.style.left = x + "px";
    //     // element.style.top = y + "px";
    //     element.style.top = 0 + "px";
    //     element.style.width = w + "px";
    //     element.style.height = "100%";
    //   }
    // }
    // let left = 2;
    // function hintHide() {
    //   left = pane.offsetLeft;
    //   setBounds(ghostpane, left, b.top, b.width, b.height);
    // }

    // // Mouse events
    // pane.addEventListener("mousedown", onMouseDown);
    // document.addEventListener("mousemove", onMove);
    // document.addEventListener("mouseup", onUp);

    // function onMouseDown(e) {
    //   onDown(e);
    //   e.preventDefault();
    // }

    // function onDown(e) {
    //   calc(e);

    //   var isResizing = onRightEdge || onBottomEdge || onTopEdge || onLeftEdge;

    //   clicked = {
    //     x: x,
    //     y: y,
    //     cx: e.clientX,
    //     cy: e.clientY,
    //     w: b.width,
    //     h: b.height,
    //     isResizing: isResizing,
    //     isMoving: !isResizing && canMove(),
    //     onTopEdge: onTopEdge,
    //     onLeftEdge: onLeftEdge,
    //     onRightEdge: onRightEdge,
    //     onBottomEdge: onBottomEdge,
    //   };
    // }

    // function canMove() {
    //   return;
    //   return x > 0 && x < b.width && y > 0 && y < b.height && y < 30;
    // }

    // function calc(e) {
    //   let parentID = document.getElementById("parentID");
    //   if (parentID) {
    //     let ss = parentID.getBoundingClientRect().left;
    //     b = pane.getBoundingClientRect();
    //     x = e.clientX - b.left;
    //     y = e.clientY - b.top;
    //     onTopEdge = y < MARGINS;
    //     onLeftEdge = x < MARGINS;
    //     onRightEdge = x >= b.width - MARGINS;
    //     onBottomEdge = y >= b.height - MARGINS;

    //     rightScreenEdge = window.innerWidth - MARGINS;
    //     bottomScreenEdge = window.innerHeight - MARGINS;
    //   }
    // }

    // var e;

    // function onMove(ee) {
    //   calc(ee);

    //   e = ee;

    //   redraw = true;
    // }

    // function animate() {
    //   requestAnimationFrame(animate);

    //   if (!redraw) return;

    //   redraw = false;

    //   let getAnimationObj = Object.keys(animationObject).filter(
    //     (obj, index) => {
    //       return animationObject[obj].timeLine.id === pane1;
    //     }
    //   );

    //   if (clicked && clicked.isResizing) {
    //     if (clicked.onRightEdge) {
    //       pane.style.width = Math.max(x, minWidth) + "px";

    //       let updateResizeValues = animationObject[getAnimationObj[0]];
    //       updateResizeValues.timeLine.width = Math.max(x, minWidth);
    //       let temp = Object.assign(animationObject, {
    //         [getAnimationObj[0]]: { ...updateResizeValues },
    //       });
    //       setAnimationObject(temp);
    //     }
    //     if (clicked.onBottomEdge) {
    //       return;

    //       pane.style.height = 100 + "px";
    //     }
    //     if (clicked.onLeftEdge) {
    //       // pane.style.height = Math.max(y, minHeight) + "px";

    //       var currentWidth = Math.max(
    //         clicked.cx - e.clientX + clicked.w,
    //         minWidth
    //       );
    //       let id = selectedComponent.id;

    //       if (currentWidth > minWidth) {
    //         let updateResizeValues = animationObject[getAnimationObj[0]];

    //         pane.style.width = currentWidth + "px";
    //         updateResizeValues.timeLine.width = currentWidth;
    //         var offsetRight = e.clientX - ParentID.getBoundingClientRect().left;
    //         if (offsetRight > 0) {
    //           pane.style.left = offsetRight + "px";

    //           updateResizeValues.timeLine.left = offsetRight;

    //           let temp = Object.assign(animationObject, {
    //             [getAnimationObj[0]]: { ...updateResizeValues },
    //           });
    //           setAnimationObject(temp);
    //         } else {
    //           // console.log(getAnimationObj, animationObject, "getAnimationObj");
    //         }
    //       }
    //     }

    //     if (clicked.onTopEdge) {
    //       return;
    //       var currentHeight = Math.max(
    //         clicked.cy - e.clientY + clicked.h,
    //         minHeight
    //       );
    //       if (currentHeight > minHeight) {
    //         pane.style.height = currentHeight + "px";
    //         pane.style.top = e.clientY + "px";
    //       }
    //     }
    //     hintHide();

    //     return;
    //   }

    //   if (clicked && clicked.isMoving) {
    //     if (
    //       b.top < FULLSCREEN_MARGINS ||
    //       b.left < FULLSCREEN_MARGINS ||
    //       b.right > window.innerWidth - FULLSCREEN_MARGINS ||
    //       b.bottom > window.innerHeight - FULLSCREEN_MARGINS
    //     ) {
    //       // hintFull();

    //       setBounds(ghostpane, 0, 0, window.innerWidth, window.innerHeight);
    //       ghostpane.style.opacity = 0;
    //     } else if (b.top < MARGINS) {
    //       // hintTop();
    //       setBounds(ghostpane, 0, 0, window.innerWidth, window.innerHeight / 2);
    //       ghostpane.style.opacity = 0;
    //     } else if (b.left < MARGINS) {
    //       // hintLeft();
    //       setBounds(ghostpane, 0, 0, window.innerWidth / 2, window.innerHeight);
    //       ghostpane.style.opacity = 0;
    //     } else if (b.right > rightScreenEdge) {
    //       // hintRight();
    //       setBounds(
    //         ghostpane,
    //         window.innerWidth / 2,
    //         0,
    //         window.innerWidth / 2,
    //         window.innerHeight
    //       );
    //       ghostpane.style.opacity = 0;
    //     } else if (b.bottom > bottomScreenEdge) {
    //       // hintBottom();
    //       setBounds(
    //         ghostpane,
    //         0,
    //         window.innerHeight / 2,
    //         window.innerWidth,
    //         window.innerWidth / 2
    //       );
    //       ghostpane.style.opacity = 0;
    //     } else {
    //       hintHide();
    //     }

    //     if (preSnapped) {
    //       setBounds(
    //         pane,
    //         e.clientX - preSnapped.width / 2,
    //         e.clientY - Math.min(clicked.y, preSnapped.height),
    //         preSnapped.width
    //         //  preSnapped.height
    //       );
    //       return;
    //     }

    //     // moving
    //     pane.style.top = e.clientY - clicked.y + "px";
    //     pane.style.left = e.clientX - clicked.x + "px";

    //     return;
    //   }

    //   // This code executes when mouse moves without clicking

    //   // style cursor
    //   if ((onRightEdge && onBottomEdge) || (onLeftEdge && onTopEdge)) {
    //     pane.style.cursor = "nwse-resize";
    //   } else if ((onRightEdge && onTopEdge) || (onBottomEdge && onLeftEdge)) {
    //     pane.style.cursor = "nesw-resize";
    //   } else if (onRightEdge || onLeftEdge) {
    //     pane.style.cursor = "ew-resize";
    //   } else if (onBottomEdge || onTopEdge) {
    //     pane.style.cursor = "ns-resize";
    //   } else if (canMove()) {
    //     pane.style.cursor = "move";
    //   } else {
    //     pane.style.cursor = "default";
    //   }
    // }

    // animate();

    // function onUp(e) {
    //   calc(e);

    //   if (clicked && clicked.isMoving) {
    //     // Snap
    //     var snapped = {
    //       width: b.width,
    //       height: b.height,
    //     };

    //     if (
    //       b.top < FULLSCREEN_MARGINS ||
    //       b.left < FULLSCREEN_MARGINS ||
    //       b.right > window.innerWidth - FULLSCREEN_MARGINS ||
    //       b.bottom > window.innerHeight - FULLSCREEN_MARGINS
    //     ) {
    //       // hintFull();
    //       setBounds(pane, 0, 0, window.innerWidth, window.innerHeight);
    //       preSnapped = snapped;
    //     } else if (b.top < MARGINS) {
    //       // hintTop();
    //       setBounds(pane, 0, 0, window.innerWidth, window.innerHeight / 2);
    //       preSnapped = snapped;
    //     } else if (b.left < MARGINS) {
    //       // hintLeft();
    //       setBounds(pane, 0, 0, window.innerWidth / 2, window.innerHeight);
    //       preSnapped = snapped;
    //     } else if (b.right > rightScreenEdge) {
    //       // hintRight();
    //       setBounds(
    //         pane,
    //         window.innerWidth / 2,
    //         0,
    //         window.innerWidth / 2,
    //         window.innerHeight
    //       );
    //       preSnapped = snapped;
    //     } else if (b.bottom > bottomScreenEdge) {
    //       // hintBottom();
    //       setBounds(
    //         pane,
    //         0,
    //         window.innerHeight / 2,
    //         window.innerWidth,
    //         window.innerWidth / 2
    //       );
    //       preSnapped = snapped;
    //     } else {
    //       preSnapped = null;
    //     }
    //     hintHide();
    //   }

    //   clicked = null;
    // }
  }

  return (
    <>
      {/* {console.log("inside Layers")} */}

      <div className="layerCol custonHorizontalScrollBar" id="layerCol">
        <div
          // ref={ref}
          className="marker"
          id="marker"
          style={{ left: marker, marginTop: 4 }}
        >
          <div
            className="marker-head"
            onClick={playPause?.animationCompleted && handleTimelinePopup}
          ></div>

          <div className="marker-tail"></div>
        </div>
        <div className="scale-IncrementalButton">
          <Canvas totalDuration={totalDuration} />
          <button
            disabled={totalDuration.divisions === 90 ? true : false}
            type="button"
            className="btn btn-link"
            onClick={twentyPlusSeconds}
          >
            +10 Sec
          </button>
        </div>
        <div
          className="animationScroll"
          id="parentID"
          style={{
            width: totalDuration.width + 68,
          }}
        >
          {/* <div id="scroll">
            <span id="anchor"></span>
          </div> */}
          <div
            className="animate-row custonVertScrollBar"
            id="scrollDiv"
            draggable={false}
            style={{ overflowY: "scroll", overflowX: "hidden" }}
          >
            {/* <div
              className="row"
              // className=" col-md-12 animation-timelineContent pd-0"
              // id="parentID"
            > */}
            {layerCompData?.map((item, index) => {
              return (
                <div
                  className=" col-md-12 animation-timelineContent pd-0 container"
                  // key={index}
                >
                  {item?.keyFrames?.map((key, index) => {
                    return (
                      <div
                        style={{ left: key + 5 + "px" }}
                        className="key-drops"
                      ></div>
                    );
                  })}
                  <div
                    id={"slider-TimeLine" + index}
                    className="resize-timeLine slider-TimeLine"
                    style={{ backgroundColor: item?.componentStyles?.BGColor }}
                  ></div>

                  <div id={"ghostPane" + index} className="ghostPane"></div>
                </div>
              );
            })}
          </div>
          {/* </div> */}
        </div>
      </div>

      {openTimelinePopup.popupWindow && (
        <PopupWindow
          layerCompData={layerCompData}
          setLayerCompData={setLayerCompData}
          handleTimelinePopup={handleTimelinePopup}
          marker={popUpLeft}
          markerTime={marker}
          selectedComponent={selectedComponent}
          SetPopupLeft={SetPopupLeft}
          setSelectedComponent={setSelectedComponent}
          popUpOptions={popUpOptions}
          setPopUpOptions={setPopUpOptions}
          animationObject={animationObject}
          setAnimationObject={setAnimationObject}
          copiedKeyFrame={copiedKeyFrame}
          setCopiedKeyFrame={setCopiedKeyFrame}
          setEdited={setEdited}
          setundoFlag={setundoFlag}
          popUpValuesEdited={popUpValuesEdited}
          setPopUpValuesEdited={setPopUpValuesEdited}
          openTimelinePopup={openTimelinePopup}
          setOpenTimelinePopup={setOpenTimelinePopup}
          handleChangeTimelineProps={handleChangeTimelineProps}
          saveAnmObjWithedit={saveAnmObjWithedit}
        />
      )}
    </>
  );
}

// const forwardRefDiv = forwardRef(Layers);
// export default forwardRefDiv;

export default Layers;
