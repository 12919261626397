import React, { useEffect, useContext, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import DropDownWithLabelSizeTemplate from "../DropdownWithLabelSizeTemplate.js";

import ColorPicker from "../ColorPicker";
import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField } from "@material-ui/core";
import ValueContext from "../../ContextAPi/appContext";
import InfoIcon from "../../images/iconinfo.svg";
import { toast } from "react-toastify";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import TextFieldInput from "../CommonComponents/TextFieldInput";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
export default function GlobalSetting({
  handleChange,
  showComponentType,
  expanded,
  handleDimensions,
  bgColorCanvas,
  styles,
  handleBannerSize,
  canvasDimension,
  setCanvasDimension,
  showAssettype,
  handleBgColorChange,
  bannerSizes,
  handleClickSwatchBgColor,
  displayColorPickerBgColor,
  setDisplayColorPickerBgColor,
  selectedComponent,
  setSelectedComponent,
  refBgColor,
  handleCloseBg,
  showBannertype,
  setCanvasUpdated,
  toggleAnimatedBanner,
  setOpenTimelinePopup,
  genericInfo,
  setGenericInfo,
  playPause,
}) {
  const appData = useContext(ValueContext);
  const min = 1;
  const max = 5;
  const { t } = useTranslation();
  const [fpsInfoIcon, setFpsInfoIcon] = useState(false);
  const [globalLink, setGlobalLink] = useState(canvasDimension.globalLink);

  useEffect(() => {
    setGlobalLink(canvasDimension.globalLink);
  }, [canvasDimension.globalLink]);

  const handleChangeGlobalLink = (event) => {
    let checked = event.target.checked;
    if (checked) {
      setCanvasDimension({
        ...canvasDimension,
        checkGloballink: checked,
      });
    } else {
      setCanvasDimension({
        ...canvasDimension,
        checkGloballink: false,
      });
    }
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
    setCanvasUpdated(true);
  };

  const handleChangeLoopAniamtion = (event) => {
    let checked = event.target.checked;
    if (checked) {
      setCanvasDimension({
        ...canvasDimension,
        [event.target.name]: checked,
      });
    } else {
      setCanvasDimension({
        ...canvasDimension,

        checkLoops: false,
      });
    }
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
  };

  const handleGloballinkValue = (e, blur) => {
    let name = e.target.name;
    setGlobalLink(e.target.value);
    if (blur !== "blur") return;
    setCanvasDimension({
      ...canvasDimension,
      [name]: e.target.value,
      //loopAnimation: e.target.value,
    });
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
    setCanvasUpdated(true);
  };

  const handleFps = (val) => {
    setCanvasDimension({ ...canvasDimension, fps: parseInt(val) });
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
  };
  const validateFps = () => {
    let inputVal =
      canvasDimension.fps < 35
        ? 35
        : canvasDimension.fps > 99
        ? 99
        : canvasDimension.fps;
    if (canvasDimension.fps < 35) {
      toast.error(
        <div className="notificationText">
          FPS value can not be less than 35
        </div>
      );
    } else if (canvasDimension.fps > 99) {
      toast.error(
        <div className="notificationText">
          FPS value can not be greater than 99
        </div>
      );
    }
    setCanvasUpdated(true);
    setCanvasDimension({ ...canvasDimension, fps: inputVal });
  };

  const onHoverfpsIcon = () => {
    setFpsInfoIcon(true);
  };
  const onLeaveFpsIcon = () => {
    setFpsInfoIcon(false);
  };
  // useEffect(() => {
  //   setCustomSize(canvasDimension.custom);
  // }, [canvasDimension.custom]);

  const handleChangeCustomeType = (e, blur) => {
    if (e.target.checked) {
      setGenericInfo({
        ...genericInfo,
        bannerSize: "",
        Width:
          genericInfo.Width === 0 ? canvasDimension.width : genericInfo.Width,
        Height:
          genericInfo.Height === 0
            ? canvasDimension.height
            : genericInfo.Height,
      });
      if (genericInfo.Width === 0) {
        setCanvasDimension({
          ...canvasDimension,
          custom: e.target.checked,
          width: canvasDimension.width,
          height: canvasDimension.height,
          oldWidth: canvasDimension.width,
          oldHeight: canvasDimension.height,
        });
        appData.setCustomTempSize(e.target.checked);
      } else {
        setCanvasDimension({
          ...canvasDimension,
          custom: e.target.checked,
          width: genericInfo.Width,
          height: genericInfo.Height,
          oldWidth: canvasDimension.width,
          oldHeight: canvasDimension.height,
        });
        appData.setCustomTempSize(e.target.checked);
      }
    } else {
      // setGenericInfo({
      //   ...genericInfo,
      //   Width: canvasDimension.width,
      //   Height: canvasDimension.height,
      // });
      setCanvasDimension({
        ...canvasDimension,

        width:
          canvasDimension.oldWidth === undefined
            ? 300
            : canvasDimension.oldWidth,
        height:
          canvasDimension.oldHeight === undefined
            ? 250
            : canvasDimension.oldHeight,

        custom: false,
      });
      appData.setCustomTempSize(false);
    }
    // appData.setCustomTempSize(false);
    // if (blur !== "blur") return;
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: true });
    setCanvasUpdated(true);
  };

  return (
    <div
      className="globalSettings"
      style={{ pointerEvents: !playPause.animationCompleted && "none" }}
    >
      <div className="accordion2 customAccordIcons1">
        <Accordion
          //expanded={expanded === "panel3"}
          // className="accordion"
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            aria-controls="panel7a-content"
            id="panel7a-header"
            expandIcon={
              expanded.panel7 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
          >
            <Typography className="accord2-summary">
              <div className="textSettingbanner d-flex">
                {showComponentType}
                {/* <img
                  src={InfoIcon}
                  alt=""
                  className="img-fluid pl-1 infoIcon2"
                /> */}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="bannerSizediv">
              {/* <div className="bannerSizeLabel">
            {showAssettype === "Banner"
              ? t("editorRightPanel.propertiesTabcontent.bannerSize")
              : "TEMPLATE SIZE"}
          </div> */}
              <div className="bannerSizeeditor">
                <div className="select_box">
                  {appData?.customTempSize ? (
                    <select
                      name="bannerWidthandHeight"
                      className={
                        appData?.customTempSize
                          ? "bannerSizeDropdown2 enableCustom"
                          : "bannerSizeDropdown2"
                      }
                      value="Presets"
                      disabled={appData?.customTempSize}
                    >
                      <option value="Presets">Presets</option>
                    </select>
                  ) : (
                    // <DropDownWithLabelSizeTemplate
                    //   //style={{ marginTop: "30px !important" }}
                    //   options={{
                    //     value: `${canvasDimension.width}X${canvasDimension.height}`,
                    //     name: "bannerSize",
                    //     // label: t("Choose Size"),
                    //     items: bannerSizes,
                    //     handleChange: handleBannerSize,
                    //     className: "testBannersize",
                    //   }}
                    // />
                    <select
                      name="bannerWidthandHeight"
                      className={
                        appData?.customTempSize
                          ? "bannerSizeDropdown2 enableCustom"
                          : "bannerSizeDropdown2"
                      }
                      onChange={handleBannerSize}
                      value={`${canvasDimension.width}X${canvasDimension.height}`}
                      disabled={appData?.customTempSize}
                    >
                      {bannerSizes.map((bannerSize, id) => {
                        return (
                          <option key={id} value={bannerSize}>
                            {bannerSize}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </div>
              </div>
            </div>
            <div className="bannerSizecustom-editor">
              <FormGroup
                aria-label="format"
                name="format"
                className="customDimension-editor"
                // style={{
                //   height: props.toggleAnimatedBanner ? "430px" : "350px ",
                // }}
              >
                <FormControlLabel
                  id="customDimension"
                  value=""
                  control={
                    <Checkbox
                      checked={appData?.customTempSize} //{canvasDimension?.custom}
                      onChange={(e) => handleChangeCustomeType(e)}
                      name="customDimensions"
                    />
                  }
                  label={"Custom Size"}
                />
              </FormGroup>
              <TextFieldInput
                genericInfo={genericInfo}
                setGenericInfo={setGenericInfo}
                custom={appData?.customTempSize}
                canvasDimension={canvasDimension}
                setCanvasDimension={setCanvasDimension}
                setCanvasUpdated={setCanvasUpdated}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <hr className="hrtag" />
        <Accordion
          //expanded={expanded === "panel3"}
          // className="accordion"
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            aria-controls="panel8a-content"
            id="panel8a-header"
            expandIcon={
              expanded.panel8 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
          >
            <Typography className="accord2-summary">
              <div className="bgColorDiv">
                {" "}
                {t("editorRightPanel.propertiesTabcontent.backgroundColor")}
                {/* <img
                  src={InfoIcon}
                  alt=""
                  className="img-fluid pl-1 infoIcon2"
                /> */}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="bgColorPicker">
              <div className="bg-fillGlobal">
                <div className="swatch">
                  {
                    <div
                      style={styles.backgroundColor}
                      className="swatchBorder"
                      onClick={() => handleClickSwatchBgColor("fromGlobal")}
                    ></div>
                  }
                </div>

                <div className="fill-color">
                  {t("editorRightPanel.propertiesTabcontent.fill")}
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      {toggleAnimatedBanner && (
        <>
          <hr className="hrtag" />
          <Accordion
            //expanded={expanded === "panel3"}
            // className="accordion"
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              aria-controls="panel9a-content"
              id="panel9a-header"
              expandIcon={
                expanded.panel9 === true ? (
                  <img src={DownwardArrow} alt="" className="img-fluid" />
                ) : (
                  <img src={SideArrow} alt="" className="img-fluid" />
                )
              }
            >
              <Typography className="accord2-summary">
                <div className="bgColorDiv">
                  {" "}
                  {t("FRAME RATE")}
                  {/* <img
                    src={InfoIcon}
                    alt=""
                    className="img-fluid pl-1 infoIcon2"
                  /> */}
                </div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {fpsInfoIcon && (
                <div className="fpsHover ml-2">
                  {t("FPS value can not be less than 35 and more than 99")}
                </div>
              )}
              <div className="fpsDiv">
                <div className="fpsLabel">
                  {t("Enter Fps")}
                  <img
                    onMouseOver={onHoverfpsIcon}
                    onMouseOut={onLeaveFpsIcon}
                    src={InfoIcon}
                    alt="info"
                    className="img-fluid infoImgFps"
                  />
                </div>
                {/* <TextField
          style={{
           
          }}
        ></TextField> */}
                <TextField
                  //element={TextField}
                  //debounceTimeout={500}
                  //title=""
                  className="standard-basic"
                  onChange={(e) => handleFps(e.target.value)}
                  onBlur={validateFps}
                  value={
                    isNaN(canvasDimension.fps)
                      ? 0
                      : Math.floor(canvasDimension.fps)
                  }
                  type="text"
                  inputProps={{
                    style: {
                      width: "120px",
                      height: "4px",
                      position: "relative",
                      bottom: "9px",
                      // height: "20px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                      color: "#928989",
                      textAlign: "center",
                      inputMode: "numeric",
                      // borderBottom: "solid 0.5px #c5c5c5",
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <hr className="hrtag" />
      <Accordion
        //expanded={expanded === "panel3"}
        // className="accordion"
        onChange={handleChange("panel10")}
      >
        <AccordionSummary
          aria-controls="panel10a-content"
          id="panel10a-header"
          expandIcon={
            expanded.panel10 === true ? (
              <img src={DownwardArrow} alt="" className="img-fluid" />
            ) : (
              <img src={SideArrow} alt="" className="img-fluid" />
            )
          }
        >
          <Typography>
            <div className="bgColorDiv" style={{ marginLeft: "16px" }}>
              {" "}
              {t("GLOBAL LINK")}
              {/* <img src={InfoIcon} alt="" className="img-fluid pl-1 infoIcon2" /> */}
            </div>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {/* <FormControl component="fieldset">
          <FormGroup
            aria-label="format"
            name="format"
            className="globalLinkcheck"
          >
            <FormControlLabel
              value="GlobalLink"
              control={
                <Checkbox
                  checked={canvasDimension?.checkGloballink}
                  onChange={handleChangeGlobalLink}
                  name="globalLink"
                />
              }
              label={t("Enter Global Link")}
            />
          </FormGroup>
        </FormControl> */}

            <div className="globalLinkdiv">
              <div className="globalLinkLabel">{t("Link")}</div>
              <TextField
                className="custonScrollBar textFieldCustom3 linkButton2"
                id="standard-basic"
                defaultValue="https://"
                value={globalLink}
                onChange={(e) => handleGloballinkValue(e)}
                onBlur={(e) => handleGloballinkValue(e, "blur")}
                name="globalLink"
                //  disabled={!canvasDimension?.checkGloballink}
                //  onBlur={changeTargetLinkOnBlur}
                inputProps={{
                  style: {
                    width: "100%",
                    marginTop: "-14px",
                    marginBottom: "-8px",
                    fontSize: "12px",
                    color: "#000000",
                    fontFamily: "Nunito Sans",
                    paddingLeft: "11px",
                    fontStyle: "italic",
                  },
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {toggleAnimatedBanner && (
        <>
          <hr className="hrtag" />
          <Accordion
            //expanded={expanded === "panel3"}
            // className="accordion"
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              aria-controls="panel11a-content"
              id="panel11a-header"
              expandIcon={
                expanded.panel11 === true ? (
                  <img src={DownwardArrow} alt="" className="img-fluid" />
                ) : (
                  <img src={SideArrow} alt="" className="img-fluid" />
                )
              }
            >
              <Typography>
                <div className="bgColorDiv">
                  {" "}
                  {"LOOP ANIMATION"}
                  {/* <img
                    src={InfoIcon}
                    alt=""
                    className="img-fluid  pl-2 infoIcon2"
                  /> */}
                </div>
              </Typography>
              {/* <div>
            <FormControl component="fieldset">
              <FormGroup
                aria-label="format"
                name="format"
                className="globalLinkcheck"
              >
                <FormControlLabel
                  value="CheckLoop"
                  control={
                    <Checkbox
                      checked={canvasDimension?.checkLoops}
                      onChange={handleChangeLoopAniamtion}
                      name="checkLoops"
                    />
                  }
                  label="Enable Looping"
                />
              </FormGroup>
            </FormControl>
          </div> */}
            </AccordionSummary>
            <AccordionDetails>
              <div className="globalLinkdiv">
                <div className="numberOfAnimation pl-0">
                  {"Number of loops"}
                </div>
                <TextField
                  className="custonScrollBar textFieldCustom3 "
                  id="standard-basic"
                  type="number"
                  defaultValue={1}
                  value={canvasDimension?.loopAnimation}
                  //disabled={!canvasDimension?.checkLoops}
                  onBlur={(e) => {
                    let value = parseInt(e.target.value, 10);
                    if (isNaN(value)) value = 1;
                    setCanvasDimension({
                      ...canvasDimension,
                      [e.target.name]: value,
                      //loopAnimation: e.target.value,
                    });
                  }}
                  onChange={(e) => {
                    let value = parseInt(e.target.value, 10);

                    if (value > max) value = max;
                    if (value < min) value = min;
                    setCanvasDimension({
                      ...canvasDimension,
                      [e.target.name]: value,
                      //loopAnimation: e.target.value,
                    });
                  }}
                  //  onChange={(e) => handleGloballinkValue(e)}
                  name="loopAnimation"
                  //  onBlur={changeTargetLinkOnBlur}
                  inputProps={{
                    style: {
                      marginTop: "-14px",
                      marginBottom: "-15px",
                      fontSize: "12px",
                      color: "#666666",
                      fontFamily: "Nunito Sans",
                      paddingLeft: "11px",
                    },
                  }}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </div>
  );
}
