import React from "react";
import LeftAlignBlack from "../images/LeftAlignBlack.svg";
import Bold from "../images/BoldBlack.svg";
import CenterAlignBlack from "../images/CenterAlignBlack.svg";
import Bullet from "../images/Bulletblack.svg";
import BulletBlue from "../images/BulletBlue.svg";
import Italic from "../images/ItalicBlack.svg";
import ItalicBlue from "../images/ItalicBlue.svg";
//import NumberList from "../images/EditorAnimation/NumberList.svg";
import BoldBlue from "../images/BoldBlue.svg";
import StrikeBlue from "../images/StrikeBlue.svg";
import UnderlineBlue from "../images/UnderlineBlue.svg";
import TTBlue from "../images/TTBlue.svg";
import Strikeout from "../images/StrikeBlack.svg";
import Underline from "../images/UnderlineBlack.svg";
import UpperCase from "../images/TTBlack.svg";
import "../styles/components/RightPanel.scss";
import ButtonIcon from "../images/Button.svg";
import ContainerIcon from "../images/container_icon.svg";
import ImageIcon from "../images/image_icon.svg";
import Timage from "../images/T2.svg";
import LeftAlignBlue from "../images/LeftAlignBlue.svg";
import CenterAlignBlue from "../images/CenterAlignBlue.svg";
import RightAlignBlue from "../images/RightAlignBlue.svg";
import RightAlignBlack from "../images/RightAlignBlack.svg";
import NumberlistBlack from "../images/NumberlistBlack.svg";
import NumberlistBlue from "../images/NumberlistBlue.svg";

export const TEXT = "Text";

export const IMAGE = "Image";

export const BUTTON = "Button";
export const BLUEBUTTONWITHRAD = "BlueButtonWithRad";
export const WHITEBUTTON = "WhiteButton";
export const WHITEBUTTONWITHRAD = "WhiteButtonWithRad";
export const BUTTONWITHOUTBORDER = "ButtonWithoutBorder";

export const ADDHEADING = "AddHeading";

export const ADDSUBHEADING = "AddSubHeading";

export const ADDBODY = "addBody";

export const SHAPE = "Shape";

export const RECTANGLE = "rectangleShape";

export const CIRCLE = "circleShape";

export const TRIANGLE = "triangleShape";
export const POLYGON = "polygonShape";
export const HEXAGON = "hexagonShape";

export const objSides = {
  left: false,
  right: false,
  top: false,
  bottom: false,
  leftid: false,
  endleftid: false,
  rightid: false,
  topid: false,
  bottomid: false,
  endtopid: false,
  endleftid: false,
  endrightid: false,
  endbottomid: false,

  vMiddle: false,
  hMiddle: false,
  vMiddleid: false,
  hMiddleid: false,

  endvMiddleid: false,
  endhMiddleid: false,

  // leftValue:false,
  rightValue: false,
  bottomValue: false,
  lastSelectedComp: false,
};

export const addHeading = {
  width: 142,
  height: 39,
  // height: 48,
  fontFamily: "Nunito Sans",
  fontSize: 29,
  textAlign: "left",
  color: "#000000",
  fontWeight: "bold",
  fontWeightBold: "bold",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  uClick: false,
  sClicked: false,
  iClick: false,
  bClick: false,
  upperClicked: false,
  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  // zIndex: 4,
  icon: Timage,
  rotation: 0,
  ease: "none",
  // froalaText: "HeadLine",
  froalaText: "HeadLine",
  scale: 1,
  aspectLock: false,
  targetLink: "https://",

  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  toggleScroll: false,
  thumbHeight: 30,
  thumbWidth: 6,
  arrowWidth: 10,
  arrowHeight: 10,
  trackRight: 1.8,
  trackWidth: 6,
  trackHeight: 5,
  trackRadius: 0,
  thumbColor: "#ada9a8",
  scrollThumbBg: "",
  scrollUpArrowbg: "",
  scrollDownArrowbg: "",
  textBgColor: "#ffffff00", //"rgba(255,255,255,0)",//imc3144 issue fixed
  datafontSize: 29,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Text",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
  thumbRight: 0,
  autoScroll: false,
  expandAndClose: false,
  expandText: "Expand",
  expandTextSaved: "",
  closeText: "Close",
  closeTextSaved: "",
  leftalignClick: false,
  rightAlignClick: false,
  centerAlignClick: false,
  listTypeNumberClick: false,
  listTypediscClick: false,
  expandClose: {
    fontFamily: "Nunito Sans",
    fontSize: 9,
    fontColorISI: "#000000",
    fontWeight: "bold",
    fontWeightBold: "bold",
    textDecUnderline: "",
    textBgColorISI: "#ffffff00", //"rgba(255,255,255,0)",
    letterSpacing: 0.2,
    lineHeight: 1.25,
    fontStyle: "",
    textTransform: "",
    textDecoration: "",
    selectedFontStyle: [],
    expandHeight: 0,
    underlineClick: false,
    strikeThroughClicked: false,
    italicClick: false,
    boldClick: true,
    uppercaseClicked: false,
  },
};

export const selectionDiv = {
  width: 1,
  height: 1,
  left: 0,
  top: 0,
  pointerX: 0,
  pointerY: 0,
  timestamp: 0,
};
export const addSubHeading = {
  width: 120,
  height: 24,

  // height: 38,
  fontFamily: "Nunito Sans",
  fontSize: 18,
  // fontWeight: "Normal",
  textAlign: "left",
  color: "#000000",

  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  // zIndex: 4,
  icon: Timage,
  rotation: 0,
  ease: "none",
  froalaText: "Sub-headline", //"<span style='font-size:18px;'>Sub-headline</span>",
  scale: 1,
  aspectLock: false,
  targetLink: "https://",
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  toggleScroll: false,
  thumbHeight: 30,
  thumbWidth: 6,
  arrowWidth: 10,
  arrowHeight: 10,
  trackWidth: 6,
  trackHeight: 5,
  trackRight: 1.8,

  trackRadius: 0,
  thumbColor: "#ada9a8",
  scrollThumbBg: "",
  scrollUpArrowbg: "",
  scrollDownArrowbg: "",
  textBgColor: "#ffffff00", //"rgba(255,255,255,0)",
  datafontSize: 18,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Text",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
  thumbRight: 0,
  autoScroll: false,
  expandAndClose: false,
  expandText: "Expand",
  expandTextSaved: "",
  closeText: "Close",
  closeTextSaved: "",
  expandClose: {
    fontFamily: "Nunito Sans",
    fontSize: 9,
    fontColorISI: "#000000",
    fontWeight: "normal",
    fontWeightBold: "normal",
    textDecUnderline: "",
    textBgColorISI: "#ffffff00", //"rgba(255,255,255,0)",
    letterSpacing: 0.2,
    lineHeight: 1.25,
    fontStyle: "",
    textTransform: "",
    textDecoration: "",
    selectedFontStyle: [],
    expandHeight: 0,
    underlineClick: false,
    strikeThroughClicked: false,
    italicClick: false,
    boldClick: true,
    uppercaseClicked: false,
  },
};
export const addBody = {
  width: 85,
  height: 20,
  //height: 32,
  fontFamily: "Nunito Sans",
  fontSize: 14,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "left",
  color: "#000000",

  // zIndex: 4,
  icon: Timage,
  rotation: 0,
  ease: "none",
  froalaText: "Description", //"<span style='font-size:14px;'>Description</span>",
  scale: 1,
  aspectLock: false,
  targetLink: "https://",
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  toggleScroll: false,
  thumbHeight: 30,
  thumbWidth: 6,
  arrowWidth: 10,
  arrowHeight: 10,
  trackWidth: 6,
  trackHeight: 5,
  trackRight: 1.8,

  trackRadius: 0,
  thumbColor: "#ada9a8",
  scrollThumbBg: "",
  scrollUpArrowbg: "",
  scrollDownArrowbg: "",
  textBgColor: "#ffffff00", //"rgba(255,255,255,0)",
  datafontSize: 14,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Text",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
  thumbRight: 0,
  autoScroll: false,
  expandAndClose: false,
  expandText: "Expand",
  expandTextSaved: "",
  closeText: "Close",
  closeTextSaved: "",
  expandClose: {
    fontFamily: "Nunito Sans",
    fontSize: 9,
    fontColorISI: "#000000",
    fontWeight: "normal",
    fontWeightBold: "normal",
    textDecUnderline: "none",
    textBgColorISI: "#ffffff00", //"rgba(255,255,255,0)",
    letterSpacing: 0.2,
    lineHeight: 1.25,
    fontStyle: "none",
    textDecoration: "none",
    selectedFontStyle: [],
    expandHeight: 19,
    underlineClick: false,
    strikeThroughClicked: false,
    italicClick: false,
    boldClick: true,
    uppercaseClicked: false,
  },
};
export const button = {
  width: 70,
  height: 30,
  fontFamily: "Nunito Sans",
  fontSize: 10,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "center",
  color: "#FFFFFF",
  // zIndex: 5,
  backgroundColor: "#034ea2",
  border: "solid 1px #034ea2",
  targetLink: "https://",
  paddingTop: 8,
  paddingRight: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  icon: ButtonIcon,
  rotation: 0,
  ease: "none",
  froalaText: "Click Here", //"<span  style='font-size:10px;'>Click me!</span>",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  datafontSize: 10,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Button",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const BlueButtonWithRad = {
  width: 70,
  height: 30,
  fontFamily: "Arial",
  fontSize: 10,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "center",
  color: "#FFFFFF",
  // zIndex: 5,
  backgroundColor: "#034ea2",
  borderRadius: "4px",
  border: "solid 1px #034ea2",
  targetLink: "https://",
  paddingTop: 8,
  paddingRight: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",

  icon: ButtonIcon,
  rotation: 0,
  ease: "none",
  froalaText: "Click Here", //"<span  style='font-size:10px;'>Click me!</span>",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  datafontSize: 10,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Button",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const WhiteButton = {
  width: 70,
  height: 30,
  fontFamily: "Arial",
  fontSize: 10,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "center",
  color: "#034ea2",
  // zIndex: 5,
  border: "solid 1px #034ea2",
  backgroundColor: "#fff",

  targetLink: "https://",
  paddingTop: 8,
  paddingRight: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  icon: ButtonIcon,
  rotation: 0,
  ease: "none",
  froalaText: "Click Here", //"<span  style='font-size:10px;'>Click me!</span>",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  datafontSize: 10,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Button",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const WhiteButtonWithRad = {
  width: 70,
  height: 30,
  fontFamily: "Arial",
  fontSize: 10,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",
  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "center",
  color: "#034ea2",
  // zIndex: 5,
  backgroundColor: "#fff",
  borderRadius: "4px",
  // border: "0px solid #000000",
  targetLink: "https://",
  paddingTop: 8,
  paddingRight: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  icon: ButtonIcon,
  rotation: 0,
  ease: "none",
  froalaText: "Click Here", //"<span  style='font-size:10px;'>Click me!</span>",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  datafontSize: 10,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Button",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
  border: "solid 1px #034ea2",
};
export const ButtonWithoutBorder = {
  width: 70,
  height: 30,
  fontFamily: "Arial",
  fontSize: 10,
  fontWeight: "normal",
  fontWeightBold: "normal",
  textDecUnderline: "",
  letterSpacing: 0.2,
  lineHeight: 1.25,
  fontStyle: "",
  textTransform: "",
  textDecoration: "",

  underlineClick: false,
  strikeThroughClicked: false,
  italicClick: false,
  boldClick: true,
  uppercaseClicked: false,
  // zIndex: 4,
  listStyleType: "none",
  textAlign: "center",
  color: "#034ea2",
  // zIndex: 5,
  backgroundColor: "#fff",
  targetLink: "https://",
  paddingTop: 8,
  paddingRight: 8,
  paddingBottom: 8,
  paddingLeft: 8,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,

  icon: ButtonIcon,
  rotation: 0,
  ease: "none",
  froalaText: "Click Here", //"<span  style='font-size:10px;'>Click me!</span>",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  datafontSize: 10,
  selectedFontStyle: [],
  selectedId: "",
  selectedIdnumberList: "",
  layerName: "Button",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
  border: "solid 0px #ffffff",
};
export const rectangleShape = {
  width: 50,
  height: 50,
  oldLeft: 0,
  oldTop: 0,
  // zIndex: 1,
  backgroundColor: "#ffffff",
  border: "solid 1px #000000",
  paddingTop: 0,
  paddingRight: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  icon: ContainerIcon,
  rotation: 0,
  ease: "none",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  compOpacity: 100,
  layerName: "Shape",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const circleShape = {
  width: 50,
  height: 50,
  // zIndex: 1,
  backgroundColor: "#ffffff",
  border: "solid 1px #000000",
  borderRadius: "50%",
  icon: ContainerIcon,
  rotation: 0,
  ease: "none",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  compOpacity: 100,
  layerName: "Shape",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const triangleShape = {
  width: 50,
  height: 50,
  // zIndex: 1,
  backgroundColor: "#ffffff",
  border: "solid 1px #000000",
  icon: ContainerIcon,
  rotation: 0,
  src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20class%3D%22svg-triangle%22%20width%3D%27100%27%20height%3D%27100%27%3E%0A%20%20%20%20%3Cpath%20d%3D%22M%2050%2C5%2095%2C97.5%205%2C97.5%20z%22%20fill%3D%22%23ffffff%22%20data-color-group%3D%220%22%20stroke%3D%22%23000000%22%20stroke-width%3D%222%22%2F%3E%0A%20%20%3C%2Fsvg%3E%0A",
  // new src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20id%3D%22Layer%5f1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20400%20305%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveAspectRatio%3D%22none%22%3E%3Ctitle%3EShapes%3C%2Ftitle%3E%3Cpolygon%20points%3D%22195%2C140%20245%2C210%20140%2C210%22%20fill%3D%22%23ffffff%22%20data-color-group%3D%220%22%20stroke%3D%22%23000000%22%20stroke-width%3D%221%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E",
  //old src: "data:image/svg+xml;charset=UTF-8,%3Csvg%20id%3D%22Layer%5f1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20400%20305%22%20width%3D%22100%25%22%20height%3D%22100%25%22%20preserveAspectRatio%3D%22none%22%3E%3Ctitle%3EShapes%3C%2Ftitle%3E%3Cpolygon%20points%3D%22400%20-0.5%200%20-0.5%200%20-0.5%20200%20304.5%20400%20-0.5%20400%20-0.5%20400%20-0.5%20400%20-0.5%22%20fill%3D%22%23ffffff%22%20data-color-group%3D%220%22%20stroke%3D%22%23000000%22%20stroke-width%3D%221%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E",
  ease: "none",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  compOpacity: 100,
  layerName: "Shape",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const polygonShape = {
  width: 50,
  height: 55,
  // zIndex: 1,
  backgroundColor: "#ffffff",
  border: "solid 1px #000000",
  icon: ContainerIcon,
  rotation: 0,
  src: "data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%23ffffff%22%20%20viewBox%3D%220%200%2016%2016%22%20stroke%3D%22%23000000%22%20stroke-width%3D%220.3%22%3E%0A%20%20%3Cpath%20d%3D%22m8%200%208%206.5-3%209.5H3L0%206.5%208%200z%22%2F%3E%0A%3C%2Fsvg%3E%0A%0A",
  ease: "none",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  compOpacity: 100,
  layerName: "Shape",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const hexagonShape = {
  width: 50,
  height: 60,
  // zIndex: 1,
  backgroundColor: "#ffffff",
  border: "solid 1px #000000",
  icon: ContainerIcon,
  rotation: 0,
  src: "data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%23000000%22%20stroke-width%3D%220.3%22%20viewBox%3D%220%200%2016%2016%22%3E%0A%20%20%3Cpath%20fill-rule%3D%22evenodd%22%20d%3D%22M8.5.134a1%201%200%200%200-1%200l-6%203.577a1%201%200%200%200-.5.866v6.846a1%201%200%200%200%20.5.866l6%203.577a1%201%200%200%200%201%200l6-3.577a1%201%200%200%200%20.5-.866V4.577a1%201%200%200%200-.5-.866L8.5.134z%22%2F%3E%0A%3C%2Fsvg%3E%0A%0A",
  ease: "none",
  scale: 1,
  aspectLock: false,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  compOpacity: 100,
  layerName: "Shape",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};
export const image = {
  width: 200,
  height: 150,
  // zIndex: 3,
  backgroundRepeat: "no-repeat",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  border: "solid 0px #000000",
  repeat: false,
  targetLink: "https://",
  icon: ImageIcon,
  backgroundSize: "contain",
  rotation: 0,
  ease: "none",
  backgroundPosition: "50% 50%",
  transition: "none 0s ease 0s",
  scale: 1,
  aspectRatio: 1,
  aspectLock: true,
  lockBoolean: false,
  hideBoolean: false,
  opacity: 1,
  skewAnimation: false,
  compOpacity: 100,
  layerName: "Image",
  // scaleAnm: 1,
  // opacityAnm: 1,
  layerNameSaved: "",
};

/* const toggleShowItalic = () => {
  var element = document.querySelector(".fontStyleitalic");
  element.classList.toggle("photo-caption-show");
}; */

export const froalaFontStyles = [
  {
    img: (
      <img
        src={Underline}
        alt="underline"
        className="fontStyles fontStyleUnderline"
      />
    ),
    img2: (
      <img
        src={UnderlineBlue}
        alt="bold"
        className="fontStyles fontStyleBold "
      />
    ),
    type: "underline",
    id: 0,
  },
  {
    img: <img src={Bold} alt="bold" className="fontStyles fontStyleBold " />,
    img2: (
      <img src={BoldBlue} alt="bold" className="fontStyles fontStyleBold " />
    ),
    type: "bold",
    id: 1,
  },
  {
    img: (
      <img
        src={Italic}
        alt="italic"
        className="fontStyles fontStyleitalic"
        //onClick={toggleShowItalic}
      />
    ),
    img2: (
      <img src={ItalicBlue} alt="bold" className="fontStyles fontStyleBold " />
    ),
    type: "italic",
    id: 2,
  },

  {
    img: (
      <img
        src={UpperCase}
        alt="uppercase"
        className="fontStyles fontStyleUppercase"
      />
    ),
    img2: <img src={TTBlue} alt="bold" className="fontStyles fontStyleBold " />,
    type: "uppercase",
    id: 3,
  },
  {
    img: (
      <img
        src={Strikeout}
        alt="strikeout"
        className="fontStyles fontStyleStrikeout"
      />
    ),
    img2: (
      <img
        src={StrikeBlue}
        alt="strikeout"
        className="fontStyles fontStyleStrikeout"
      />
    ),
    type: "strikeout",
    id: 4,
  },
];
export const froalaFontAlignments = [
  {
    img: <img src={LeftAlignBlack} alt="alignLeft" className="fontStyles" />,
    img2: <img src={LeftAlignBlue} alt="alignLeft" className="fontStyles" />,
    type: "leftAlign",
    id: 0,
  },
  {
    img: <img src={CenterAlignBlack} alt="alignLeft" className="fontStyles" />,

    img2: (
      <img src={CenterAlignBlue} alt="centerAlign" className="fontStyles" />
    ),
    type: "centerAlign",
    id: 1,
  },
  {
    img: <img src={RightAlignBlack} alt="rigtAlign" className="fontStyles " />,
    img2: <img src={RightAlignBlue} alt="centerAlign" className="fontStyles" />,
    type: "rightAlign",
    id: 2,
  },
];

export const froalaNumberList = [
  {
    img: <img src={Bullet} alt="bullet" className="fontStyles" />,
    img2: <img src={BulletBlue} alt="bullet" className="fontStyles" />,
    type: "bullets",
    id: 0,
  },
  {
    img: <img src={NumberlistBlack} alt="numberList" className="fontStyles" />,
    img2: <img src={NumberlistBlue} alt="numberList" className="fontStyles" />,
    type: "numberList",
    id: 1,
  },
];
export let defaultComp = {
  IMAGE,
  BUTTON,
  ADDHEADING,
  ADDSUBHEADING,
  ADDBODY,
  RECTANGLE,
  CIRCLE,
  TRIANGLE,
  POLYGON,
  HEXAGON,
  froalaFontStyles,
  froalaFontAlignments,
  addHeading,
  addSubHeading,
  addBody,
  button,
  rectangleShape,
  circleShape,
  triangleShape,
  polygonShape,
  hexagonShape,
  image,
  selectionDiv,
  objSides,
};
