import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "../Uitls";
import ValueContext from "../ContextAPi/appContext";

function UnAuthorizeModal(props) {
  const appData = useContext(ValueContext);

  const { t } = useTranslation();
  const history = useHistory();
  const onLogout = () => {
    // console.log("autoLogout");

    // appData.updateGenericInfo({ ...appData?.genericInfo, showPrompt: false });

    logout();
    history.push("/");
  };
  useEffect(() => {
    setTimeout(() => {
      // console.log("autoLogout");
      // appData.updateGenericInfo({ ...appData?.genericInfo, showPrompt: false });
      logout();
      console.log("autoLogout");
      // history.push("/");
      // logout();
    }, 6000);
  });
  return (
    <>
      <p className="notificationHeading">IMC</p>
      <p className="notificationText">{t("apiErrors.err403")}</p>
      <button onClick={onLogout} className="btn btn-link pl-0">
        {t("generalText.loginAgain")}
      </button>
    </>
  );
}

export default UnAuthorizeModal;
