import React, { useState, createRef, useContext, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import iconupload from "../../images/iconupload.svg";
import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import "../../styles/components/ScrollBar.scss";
import { TextField } from "@material-ui/core";
import { toast } from "react-toastify";
import InfoIcon from "../../images/iconinfo.svg";
import Bold from "../../images/BoldBlack.svg";

import Italic from "../../images/ItalicBlack.svg";
import ItalicBlue from "../../images/ItalicBlue.svg";

//import NumberList from "../images/EditorAnimation/NumberList.svg";
import BoldBlue from "../../images/BoldBlue.svg";
import StrikeBlue from "../../images/StrikeBlue.svg";
import UnderlineBlue from "../../images/UnderlineBlue.svg";
import TTBlue from "../../images/TTBlue.svg";
import Strikeout from "../../images/StrikeBlack.svg";
import Underline from "../../images/UnderlineBlack.svg";
import UpperCase from "../../images/TTBlack.svg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import WebFont from "webfontloader";
import Switch from "@material-ui/core/Switch";
import Dropzone from "react-dropzone";
import { browseImages } from "../../services/apis";
import ValueContext from "../../ContextAPi/appContext";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import AV from "../../images/EditorAnimation/AV.svg";
import Outline from "../../images/Outline.svg";
import Outlinegrey from "../../images/Outlinegrey.svg";
import LineHeight from "../../images/EditorAnimation/LineHeight.svg";
import Diamond1 from "../../images/Diamond (1).svg";
import Diamond2 from "../../images/Diamond (2).svg";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getValidInput } from "../../Uitls/Util";
import { log } from "dynamodb";
export default function ScrollBarArrow({
  handleChange,
  expanded,
  setDisplayColorPickerScrollColor,
  displayColorPickerScrollColor,
  refScrollColor,
  ColorPicker,
  styles,
  handleClickSwatchScroll,
  handleCloseScroll,
  scrollColor,
  scrollbarArrow,
  setScrollbarArrow,
  setScrollDimensionData,
  scrollDimensionData,
  handleScrollColorChange,
  selectedComponent,
  setSelectedComponent,
  refFontColorISI,
  colorPickerFontColorISI,
  colorPickerTextBgColorISI,
  refTextBgColorISI,
  handleClickFontColorISI,

  handleClicktextBgColorISI,
  locked,
  layerCompData,
  setLayerCompData,
  boldClick,
  setBoldClick,
  scrollBarExpand,
  froalaIndex,
}) {
  const { t } = useTranslation();
  const dropzoneReference = createRef();
  const [scrollDimensions, setScrollDimensions] = useState({
    thumbHeight: 30,
    thumbWidth: 6,
    arrowWidth: 10,
    arrowHeight: 10,
    trackWidth: 6,
    trackHeight: 5,
    trackRadius: 0,
  });
  console.log("styles", styles.fontColorISI, styles.textBgColorISI);
  const [expandEnable, setExpandEnable] = useState(true);
  const [closeEnable, setCloseEnable] = useState(false);
  //const [uppercaseClicked, setUppercaseClicked] = useState(false);

  // const [boldClick, setBoldClick] = useState(false);
  //const [italicClick, setItalicClick] = useState(false);
  const [toggleExpand, setToggleExpand] = useState(true);
  const [toggleClose, setToggleClose] = useState(true);
  const [indexValue, setIndexValue] = useState("");
  const [closeIndexValue, setCloseIndexValue] = useState(undefined);
  const [expandText, setExpandText] = useState(
    selectedComponent?.componentStyles?.expandText
  );
  const [scrollRightProps, setScrollRightProps] = useState({});
  const [closeText, setCloseText] = useState(
    selectedComponent?.componentStyles?.closeText
  );
  useEffect(() => {
    setScrollRightProps(selectedComponent);
  }, [selectedComponent]);

  const toggleExpandInput = (expand) => {
    setToggleExpand(false);
    setToggleClose(true);

    setIndexValue(expand);
    setExpandText(selectedComponent.componentStyles.expandText);
  };
  const toggleCloseInput = (close) => {
    setToggleClose(false);
    setToggleExpand(true);
    setCloseIndexValue(close);
    setCloseText(selectedComponent.componentStyles.closeText);
  };
  const AutoScroll = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      // background: selectedComponent?.componentStyles?.autoScroll ? "white" : "",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  // thumbHeight: 30,
  // thumbWidth: 3,
  const [lineHeightItems, setLineHeightItems] = useState([1, 1.25, 2, 3, 4, 5]);
  const [fontFamilyExpandClose, setFontFamilyExpandClose] = useState([
    "Arial",
    "Georgia",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ]);
  const [fontSizeExpandClose, setFontSizeExpandClose] = useState([
    9, 10, 12, 14, 18, 24,
  ]);
  const [fontWeightExpandclose, setFontWeightExpandclose] = useState([
    "normal",
    "bold",
  ]);
  const [letterSpacingEXpandclose, setLetterSpacingEXpandclose] = useState([
    0.2, 1, 2, 3, 4,
  ]);
  const [froalaFontStylesExCl, setFroalaFontStylesExCl] = useState([
    {
      img: (
        <img
          src={Underline}
          alt="underline"
          className="fontStyles fontStyleUnderline"
        />
      ),
      img2: (
        <img
          src={UnderlineBlue}
          alt="bold"
          className="fontStyles fontStyleBold "
        />
      ),
      type: "underline",
      id: 0,
    },
    {
      img: <img src={Bold} alt="bold" className="fontStyles fontStyleBold " />,
      img2: (
        <img src={BoldBlue} alt="bold" className="fontStyles fontStyleBold " />
      ),
      type: "bold",
      id: 1,
    },
    {
      img: (
        <img
          src={Italic}
          alt="italic"
          className="fontStyles fontStyleitalic"
          //onClick={toggleShowItalic}
        />
      ),
      img2: (
        <img
          src={ItalicBlue}
          alt="bold"
          className="fontStyles fontStyleBold "
        />
      ),
      type: "italic",
      id: 2,
    },

    {
      img: (
        <img
          src={UpperCase}
          alt="uppercase"
          className="fontStyles fontStyleUppercase"
        />
      ),
      img2: (
        <img src={TTBlue} alt="bold" className="fontStyles fontStyleBold " />
      ),
      type: "uppercase",
      id: 3,
    },
    {
      img: (
        <img
          src={Strikeout}
          alt="strikeout"
          className="fontStyles fontStyleStrikeout"
        />
      ),
      img2: (
        <img
          src={StrikeBlue}
          alt="strikeout"
          className="fontStyles fontStyleStrikeout"
        />
      ),
      type: "strikeout",
      id: 4,
    },
  ]);
  const [openfontsizeDropdown, setOpenfontsizeDropdown] = React.useState(null);
  const open = Boolean(openfontsizeDropdown);
  const handleFontsizeDropdown = (event) => {
    setOpenfontsizeDropdown(event.currentTarget);
  };
  const handleClose = () => {
    setOpenfontsizeDropdown(null);
  };
  const [openletterspaceDropdown, setOpenletterspaceDropdown] =
    React.useState(null);
  const openletterSpacing = Boolean(openletterspaceDropdown);
  const handleletterSpaceDropdown = (event) => {
    setOpenletterspaceDropdown(event.currentTarget);
  };
  const handleCloseletterSpace = () => {
    setOpenletterspaceDropdown(null);
  };
  const [openlineheightDropdown, setOpenlineheightDropdown] =
    React.useState(null);
  const openlineHeight = Boolean(openlineheightDropdown);
  const handlelineHeightDropdown = (event) => {
    setOpenlineheightDropdown(event.currentTarget);
  };
  const handleCloselineHeight = () => {
    setOpenlineheightDropdown(null);
  };

  window.oncontextmenu = function () {
    handleCloselineHeight();
    handleCloseletterSpace();
    handleClose();
  };
  const handleExpandCloseInput = (e) => {
    if (e.currentTarget.id === "expand") {
      setExpandEnable(true);
      setCloseEnable(false);
    } else if (e.currentTarget.id === "close") {
      setCloseEnable(true);
      setExpandEnable(false);
    }
  };
  const handleScrollDimensions = (e, blur) => {
    let name = e.target.name;

    let tempScrollDimensions = {
      ...scrollDimensions,
      [name]: e.target.value,
    };
    setScrollDimensions(tempScrollDimensions);
    if (blur !== "blur") return;
    let dimensionValue = { ...selectedComponent };
    dimensionValue.componentStyles[name] = parseInt(e.target.value);
    let parentScrollBar = document.getElementById(dimensionValue.id);
    if (parentScrollBar) {
      if (name === "thumbWidth") {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb-v"
        )[0].style.width = parseInt(e.target.value) + "px";
      } else if (name === "trackWidth") {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.width = parseInt(e.target.value) + "px";
      } else if (name === "trackRadius") {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.borderRadius = parseInt(e.target.value) + "px";
      }
      if (
        dimensionValue.componentStyles.thumbWidth >
        dimensionValue.componentStyles.trackWidth
      ) {
        let rightAlingArrow = 0;

        let rightAling =
          (dimensionValue.componentStyles.thumbWidth -
            dimensionValue.componentStyles.trackWidth) /
          2;
        if (
          dimensionValue.componentStyles.arrowWidth >
          dimensionValue.componentStyles.thumbWidth
        ) {
          rightAlingArrow =
            (dimensionValue.componentStyles.arrowWidth -
              dimensionValue.componentStyles.thumbWidth) /
            2;
          // rightAling = rightAlingArrow;
        }
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb-v"
        )[0].style.right = rightAling + "px";
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.right = rightAling + rightAlingArrow + "px";
        dimensionValue.componentStyles.thumbRight = rightAling;
        dimensionValue.componentStyles.trackRight =
          rightAling + rightAlingArrow;
      } else if (
        dimensionValue.componentStyles.thumbWidth <
          dimensionValue.componentStyles.trackWidth ||
        dimensionValue.componentStyles.thumbWidth ===
          dimensionValue.componentStyles.trackWidth
      ) {
        let rightAling = 0;

        if (
          dimensionValue.componentStyles.arrowWidth >
          dimensionValue.componentStyles.trackWidth
        ) {
          rightAling =
            (dimensionValue.componentStyles.arrowWidth -
              dimensionValue.componentStyles.trackWidth) /
            2;
        }
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb-v"
        )[0].style.right = 0 + "px";
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.right = rightAling + "px";
        dimensionValue.componentStyles.trackRight = rightAling;
        dimensionValue.componentStyles.thumbRight = 0;
      }
      dimensionValue.componentStyles.textHeight =
        parentScrollBar.getElementsByClassName("froalaContent")[0].offsetHeight;
      setSelectedComponent(dimensionValue);
    }
  };
  const onDropThumbImage = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      toast.error(
        <div className="notificationText">
          {" "}
          {t("imageWindowTabs.scrollToastErrMsg")}
        </div>
      );
    } else {
      let formData = new FormData();

      accepted.forEach((uploadFile) => {
        formData.append("fileName", uploadFile);
      });

      browseImages(formData, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          toast.error(<div className="notificationText">{errCoponent}</div>);
        } else {
          let collectImages = { ...selectedComponent };
          collectImages.componentStyles.scrollThumbBg =
            response.data.discription;
          let parentScrollBar = document.getElementById(collectImages.id);

          parentScrollBar.getElementsByClassName(
            "rc-scrollbars-thumb"
          )[1].style.backgroundImage = `url("${response.data.discription} ")`;

          setSelectedComponent(collectImages);
        }
      });
    }
  };

  const clientHeightchange = () => {
    let data = [...layerCompData];
    data.forEach((entry, index) => {
      if (entry.id === selectedComponent.id) {
        if (data[index].componentStyles.expandAndClose) {
          data[index].componentStyles.expandClose.expandHeight =
            document.getElementById("expandClose" + data[index]?.id)
              ?.clientHeight ||
            data[index].componentStyles.expandClose.expandHeight;
          console.log("dimensionValue ", data[index]);
          setLayerCompData(data);
        } else {
          if (data[index].componentStyles.expandClose) {
            data[index].componentStyles.expandClose.expandHeight = 0;
          }
        }
      }
    });
  };
  useEffect(() => {
    clientHeightchange();
  }, [
    selectedComponent.componentStyles?.expandAndClose,
    selectedComponent.componentStyles?.expandClose?.fontSize,
    selectedComponent.componentStyles?.expandClose?.lineHeight,
  ]);

  useEffect(() => {
    let dimensionValue = { ...selectedComponent };
    let parentScrollBar = document.getElementById(dimensionValue.id);
    if (parentScrollBar) {
      //ifcheck is the fix for IMC-3002 redo bug
      if (
        parentScrollBar.getElementsByClassName("rc-scrollbars-thumb").length >=
        2
      ) {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb"
        )[1].style.backgroundImage = `url("${selectedComponent.componentStyles.scrollThumbBg} ")`;
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb"
        )[1].style.width = selectedComponent.componentStyles.thumbWidth + "px";
      }
      if (
        parentScrollBar.getElementsByClassName("rc-scrollbars-track-v")
          .length >= 1
      ) {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.borderRadius =
          selectedComponent.componentStyles.trackRadius + "px";

        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.backgroundColor =
          selectedComponent.componentStyles.thumbColor;

        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.width = selectedComponent.componentStyles.trackWidth + "px";

        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-track-v"
        )[0].style.right = selectedComponent.componentStyles.trackRight + "px";
      }
      if (
        parentScrollBar.getElementsByClassName("rc-scrollbars-thumb-v")
          .length >= 1
      ) {
        parentScrollBar.getElementsByClassName(
          "rc-scrollbars-thumb-v"
        )[0].style.right = selectedComponent.componentStyles?.thumbRight + "px";
      }
      dimensionValue.componentStyles.textHeight =
        parentScrollBar?.getElementsByClassName(
          "froalaContent"
        )[0]?.offsetHeight;
    }

    //ExpandHeight for scrollbar
    if (dimensionValue.componentStyles.expandAndClose)
      dimensionValue.componentStyles.expandClose.expandHeight =
        document.getElementById("expandClose" + dimensionValue.id)
          ?.clientHeight ||
        dimensionValue.componentStyles.expandClose.expandHeight;

    if (
      dimensionValue.componentStyles.trackRight &&
      parentScrollBar.getElementsByClassName("rc-scrollbars-thumb").length >= 2
    ) {
      parentScrollBar.getElementsByClassName(
        "rc-scrollbars-thumb-v"
      )[0].style.right = dimensionValue.componentStyles.thumbRight + "px";
      parentScrollBar.getElementsByClassName(
        "rc-scrollbars-track-v"
      )[0].style.right = dimensionValue.componentStyles.trackRight + "px";
    }
    const tempScrollDimensions = {
      thumbHeight: selectedComponent.componentStyles.thumbHeight,
      thumbWidth: selectedComponent.componentStyles.thumbWidth,
      arrowWidth: selectedComponent.componentStyles.arrowWidth,
      arrowHeight: selectedComponent.componentStyles.arrowHeight,
      trackWidth: selectedComponent.componentStyles.trackWidth,
      trackHeight: selectedComponent.componentStyles.trackHeight,
      trackRadius: selectedComponent.componentStyles.trackRadius,
    };

    setScrollDimensions(tempScrollDimensions);
    // setSelectedComponent(dimensionValue);
  }, [selectedComponent]);

  // useEffect(() => {
  //   let parentScrollBar = document.getElementById(selectedComponent.id);
  //   parentScrollBar.getElementsByClassName(
  //     "rc-scrollbars-thumb"
  //   )[0].style.height = 30 + "px";
  //   ;
  // });

  const onDropUpArrowImage = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      toast.error(
        <div className="notificationText">
          {" "}
          {t("imageWindowTabs.scrollToastErrMsg")}
        </div>
      );
    } else {
      let formData = new FormData();

      accepted.forEach((uploadFile) => {
        formData.append("fileName", uploadFile);
      });

      browseImages(formData, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          toast.error(<div className="notificationText">{errCoponent}</div>);
        } else {
          let collectImages = { ...selectedComponent };
          collectImages.componentStyles.scrollUpArrowbg =
            response.data.discription;
          setSelectedComponent(collectImages);
        }
      });
    }
  };
  const onDropDownArrowImage = (accepted, rejected) => {
    if (Object.keys(rejected).length !== 0) {
      toast.error(
        <div className="notificationText">
          {" "}
          {t("imageWindowTabs.scrollToastErrMsg")}
        </div>
      );
    } else {
      let formData = new FormData();

      accepted.forEach((uploadFile) => {
        formData.append("fileName", uploadFile);
      });

      browseImages(formData, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          toast.error(<div className="notificationText">{errCoponent}</div>);
        } else {
          let collectImages = { ...selectedComponent };
          collectImages.componentStyles.scrollDownArrowbg =
            response.data.discription;
          setSelectedComponent(collectImages);
        }
      });
    }
  };
  const deleteThumbBg = () => {
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.scrollThumbBg = "";
      setSelectedComponent(tempSelected);
      if (document.getElementsByClassName("rc-scrollbars-thumb")[1]) {
        document.getElementsByClassName(
          "rc-scrollbars-thumb"
        )[1].style.backgroundImage = "";
      }
    }
  };
  const deleteUpArrowBg = () => {
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.scrollUpArrowbg = "";
      setSelectedComponent(tempSelected);
    }
  };
  const deleteDownArrowBg = () => {
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.scrollDownArrowbg = "";

      setSelectedComponent(tempSelected);
    }
  };

  const ToggleAutoScrollbar = (e) => {
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.autoScroll = e.target.checked;
    setSelectedComponent(tempSlected);
  };
  const toggleExpandAndclose = (e) => {
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.expandAndClose = e.target.checked;
    setSelectedComponent(tempSlected);
  };
  const handleFontFamilyISI = (e) => {
    let tempSelected = JSON.parse(JSON.stringify(selectedComponent));
    if (tempSelected.componentStyles.expandClose) {
      tempSelected.componentStyles.expandClose.fontFamily = e.target.value;
      setSelectedComponent(tempSelected);
      let tempLayercompData = [...layerCompData];
      tempLayercompData.forEach((entry, index) => {
        if (entry.id === tempSelected.id) {
          tempLayercompData[index] = { ...tempSelected };
        }
      });
      setLayerCompData(tempLayercompData);
    }
  };

  const handleScrollTextPropsOnchange = (value, compPropname) => {
    if (selectedComponent) {
      let tempSelected = JSON.parse(JSON.stringify(selectedComponent));
      if (compPropname === "lineHeight") {
        tempSelected.componentStyles.expandClose.lineHeight = value;
      } else if (compPropname === "fontSize") {
        tempSelected.componentStyles.expandClose.fontSize = value;
      } else if (compPropname === "fontWeight") {
        tempSelected.componentStyles.expandClose.fontWeight = value;
        tempSelected.componentStyles.expandClose.fontWeightBold = value;
      } else if (compPropname === "letterSpacing") {
        tempSelected.componentStyles.expandClose.letterSpacing = value;
      }
      setScrollRightProps(tempSelected);
      setSelectedComponent(tempSelected);
      let tempLayercompData = [...layerCompData];
      tempLayercompData.forEach((entry, index) => {
        if (entry.id === tempSelected.id) {
          tempLayercompData[index] = { ...tempSelected };
        }
      });
      setLayerCompData(tempLayercompData);
    }
  };
  const validateInput = (value) => {
    if (value.length >= 3 && value > 999) {
      return -1;
    }
    var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
    var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
    if (re.test(value)) {
      return;
    } else {
      value = re1.exec(value);
      if (value) {
        value = value[0];
      } else {
        value = "";
      }
    }
    let input = value !== "" ? value : "";
    return input;
  };

  const handletextfield = (e, blur) => {
    let value = getValidInput(e, blur);
    if (value === -1) return;

    let name = e.target.name;

    let updateValue = {
      ...scrollRightProps,
      componentStyles: {
        ...scrollRightProps.componentStyles,
        expandClose: {
          ...scrollRightProps.componentStyles.expandClose,
          [name]: value,
        },
      },
    };

    setScrollRightProps(updateValue);

    if (value[value.length - 1] === "." || value === "") return;
    let value1 = value; //Math.floor(value * 100) / 100;

    let tempLayercompData = [...layerCompData];
    tempLayercompData.forEach((entry, index) => {
      if (entry.id === updateValue.id) {
        tempLayercompData[index].componentStyles.expandClose[name] = value1;
      }
    });
    if (blur === "blur") {
      layerCompData[layerCompData.length - 1].eachDrag = "no";
    } else {
      layerCompData[layerCompData.length - 1].eachDrag = "yes";
    }
    let tempSelected = JSON.parse(JSON.stringify(selectedComponent));
    tempSelected.componentStyles.expandClose[e.target.name] = value1;
    setSelectedComponent(tempSelected);
    return;
  };

  const fontStyleConfigISI = (type, id) => {
    if (selectedComponent) {
      let tempSelected = { ...selectedComponent };
      //let tempSelected = JSON.parse(JSON.stringify(selectedComponent));
      switch (type) {
        case "bold":
          let clickBold = !tempSelected.componentStyles.expandClose.boldClick;

          tempSelected.componentStyles.expandClose.boldClick = clickBold;
          if (clickBold === true) {
            tempSelected.componentStyles.expandClose.fontWeightBold = "bold";
            tempSelected.componentStyles.expandClose.fontWeight = "bold";
          } else {
            tempSelected.componentStyles.expandClose.fontWeightBold = "normal";
            tempSelected.componentStyles.expandClose.fontWeight = "normal";
            tempSelected.componentStyles.expandClose.boldClick = false;
          }

          break;
        case "italic":
          let clickitalic =
            !tempSelected.componentStyles.expandClose.italicClick;

          tempSelected.componentStyles.expandClose.italicClick = clickitalic;
          if (clickitalic) {
            tempSelected.componentStyles.expandClose.fontStyle = "italic";
          } else {
            tempSelected.componentStyles.expandClose.fontStyle = "unset";
          }
          break;
        case "underline":
          let clickUnderline =
            !tempSelected.componentStyles.expandClose.underlineClick;

          tempSelected.componentStyles.expandClose.underlineClick =
            clickUnderline;
          if (clickUnderline === true) {
            return (tempSelected.componentStyles.expandClose.textDecUnderline =
              "underline");
          } else {
            tempSelected.componentStyles.expandClose.textDecUnderline = "unset";
          }

          break;
        case "uppercase":
          let clickUppercase =
            !tempSelected.componentStyles.expandClose.uppercaseClicked;
          tempSelected.componentStyles.expandClose.uppercaseClicked =
            clickUppercase;

          if (clickUppercase === true) {
            return (tempSelected.componentStyles.expandClose.textTransform =
              "uppercase");
          } else {
            tempSelected.componentStyles.expandClose.textTransform = "unset";
          }
          break;
        case "strikeout":
          let clickStrikeThrough =
            !tempSelected.componentStyles.expandClose.strikeThroughClicked;

          tempSelected.componentStyles.expandClose.strikeThroughClicked =
            clickStrikeThrough;
          if (clickStrikeThrough === true) {
            return (tempSelected.componentStyles.expandClose.textDecoration =
              "line-through");
          } else {
            tempSelected.componentStyles.expandClose.textDecoration = "unset";
          }

          break;
        default:
          break;
      }
      setSelectedComponent(tempSelected);
      let tempLayercompData = [...layerCompData];
      tempLayercompData.forEach((entry, index) => {
        if (entry.id === tempSelected.id) {
          tempLayercompData[index] = { ...tempSelected };
        }
      });
      setLayerCompData(tempLayercompData);
    }
  };

  const updateSelctedFontstyleISI = (id, type) => {
    let tempSlected = { ...selectedComponent };
    //  let tempSlected = JSON.parse(JSON.stringify(selectedComponent));
    const selectedIndex =
      tempSlected.componentStyles.expandClose.selectedFontStyle?.indexOf(id);
    if (
      tempSlected.componentStyles.expandClose.selectedFontStyle?.includes(id)
    ) {
      tempSlected.componentStyles.expandClose.selectedFontStyle.splice(
        selectedIndex,
        1
      );
    } else {
      if (
        type === "bold" &&
        !tempSlected.componentStyles.expandClose.boldClick
      ) {
        tempSlected.componentStyles.expandClose.selectedFontStyle = [];
      } else {
        tempSlected.componentStyles.expandClose.selectedFontStyle = [
          ...tempSlected?.componentStyles?.expandClose?.selectedFontStyle,
          id,
        ];
        setSelectedComponent(tempSlected);
        let tempLayercompData = [...layerCompData];
        tempLayercompData.forEach((entry, index) => {
          if (entry.id === tempSlected.id) {
            tempLayercompData[index] = { ...tempSlected };
          }
        });
        setLayerCompData(tempLayercompData);
      }
    }
  };

  const handleChangeExpandText = (e, id) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(e.target.value)) return;
    setExpandText(e.target.value);
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.expandText = e.target.value;
    setSelectedComponent(tempSlected);
    let tempLayercompData = [...layerCompData];
    tempLayercompData.forEach((entry, index) => {
      if (entry.id === tempSlected.id)
        tempLayercompData[index] = { ...tempSlected };
    });
    setLayerCompData(tempLayercompData);
  };

  const handleChangeCloseText = (e) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(e.target.value)) return;
    setCloseText(e.target.value);
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.closeText = e.target.value;
    setSelectedComponent(tempSlected);
    let tempLayercompData = [...layerCompData];
    tempLayercompData.forEach((entry, index) => {
      if (entry.id === tempSlected.id)
        tempLayercompData[index] = { ...tempSlected };
    });
    setLayerCompData(tempLayercompData);
  };

  const updateExpandNameToDefault = (e) => {
    let tempSelected = { ...selectedComponent };

    if (expandText === "" || expandText?.trim() === "") {
      tempSelected.componentStyles.expandText = selectedComponent
        .componentStyles.expandTextSaved
        ? selectedComponent.componentStyles.expandTextSaved
        : selectedComponent.componentStyles.expandTextUpdated;
      setExpandText(tempSelected.componentStyles.expandText);
    } else {
      tempSelected.componentStyles.expandText = e.target.value;
    }
    setSelectedComponent(tempSelected);
  };
  const updateCloseNameToDefault = (e) => {
    let tempSelected = { ...selectedComponent };
    if (closeText === "" || closeText?.trim() === "") {
      tempSelected.componentStyles.closeText = selectedComponent.componentStyles
        .closeTextSaved
        ? selectedComponent.componentStyles.closeTextSaved
        : selectedComponent.componentStyles.closeTextUpdated;
      setExpandText(tempSelected.componentStyles.closeText);
    } else {
      tempSelected.componentStyles.closeText = e.target.value;
    }
    setSelectedComponent(tempSelected);
  };
  const getCssClass = (itemId) => {
    if (
      itemId === 0 &&
      selectedComponent?.componentStyles?.expandClose.boldClick &&
      selectedComponent?.componentStyles?.expandClose.fontWeight === "bold"
    ) {
      return "targeting-box";
    } else if (
      itemId === 0 &&
      !selectedComponent?.componentStyles?.expandClose.boldClick &&
      selectedComponent?.componentStyles?.expandClose.fontWeight === "normal"
    ) {
      return " ";
    } else {
      return selectedComponent?.componentStyles?.expandClose.selectedFontStyle?.includes(
        itemId
      )
        ? "targeting-box muniraj"
        : "";
    }
  };
  //console.log(selectedComponent, "selectedComp styles");
  return (
    <>
      <div className="accordionScroll customAccordIcons1">
        <Accordion onChange={handleChange("panel6")}>
          <AccordionSummary
            expandIcon={
              expanded.panel6 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                {t("editorRightPanel.propertiesTabcontent.scrollBar")}
                {/* <img
                  src={InfoIcon}
                  alt=""
                  className="img-fluid pl-1 infoIcon2"
                /> */}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className="accordion2-details scrollContent"
            style={{ pointerEvents: locked && "none" }}
          >
            <div className="enableAutoScroll">
              <FormControl component="fieldset" id="autoScrollDiv">
                <FormGroup
                  aria-label="format"
                  name="format"
                  //  className="globalLinkcheck"
                >
                  <FormControlLabel
                    value="AutoScroll"
                    id="autoScrolllabel"
                    control={
                      <Checkbox
                        checked={selectedComponent?.componentStyles?.autoScroll}
                        onChange={(e) => {
                          ToggleAutoScrollbar(e);
                        }}
                        className="toggleIcon "
                        name="autoScroll"
                      />
                    }
                    label={t(
                      "editorRightPanel.propertiesTabcontent.enableAutoScroll"
                    )}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div
              className="enableExpandandclose justify-content-between"
              style={{ pointerEvents: locked && "none" }}
            >
              <p>{t("editorRightPanel.propertiesTabcontent.expandAndClose")}</p>

              <AutoScroll
                checked={selectedComponent?.componentStyles?.expandAndClose}
                onChange={(e) => {
                  toggleExpandAndclose(e);
                }}
                className="toggleExpand"
              />
            </div>
            {selectedComponent.componentStyles.expandAndClose && (
              <>
                <div className="expandcloseEditor">
                  <div
                    className={
                      toggleExpand
                        ? "expandButton  d-flex"
                        : " expandButtonClick  d-flex"
                    }
                    id="expand"
                    onClick={(e) => handleExpandCloseInput(e)}
                  >
                    <img
                      src={expandEnable ? Diamond1 : Diamond2}
                      alt="expand"
                      className="editableText"
                    />
                    {/* <span>Expand</span> */}
                    {toggleExpand ? (
                      <span
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleExpandInput("expand");
                        }}
                      >
                        {selectedComponent.componentStyles.expandText}
                      </span>
                    ) : indexValue === "expand" ? (
                      <input
                        type="text"
                        value={expandText}
                        onChange={(e) => {
                          handleChangeExpandText(e, "expand");
                        }}
                        onBlur={(e) => {
                          setToggleExpand(true);
                          updateExpandNameToDefault(e);
                        }}
                        className="expandAndcloseInput"
                        title=""
                      />
                    ) : (
                      <span
                        onDoubleClick={() => {
                          toggleExpandInput("expand");
                        }}
                      >
                        {selectedComponent.componentStyles.expandText ? (
                          selectedComponent.componentStyles.expandText
                        ) : (
                          <span>{t("Enter text")}</span>
                        )}
                      </span>
                    )}
                  </div>
                  <div
                    id="close"
                    className={
                      toggleClose
                        ? "closeButton  d-flex"
                        : "closeButtonClick  d-flex"
                    }
                    onClick={(e) => handleExpandCloseInput(e)}
                  >
                    <img
                      src={closeEnable ? Diamond1 : Diamond2}
                      alt="expand"
                      className="editableText"
                    />
                    {/* <span>Close</span> */}
                    {toggleClose ? (
                      <span
                        onDoubleClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleCloseInput("close");
                        }}
                      >
                        {selectedComponent.componentStyles.closeText}
                      </span>
                    ) : closeIndexValue === "close" ? (
                      <input
                        type="text"
                        value={selectedComponent.componentStyles.closeText}
                        onChange={(e) => handleChangeCloseText(e)}
                        onBlur={(e) => {
                          setToggleClose(true);
                          updateCloseNameToDefault(e);
                        }}
                        className="expandAndcloseInput"
                        title=""
                      />
                    ) : (
                      <span
                        onDoubleClick={() => {
                          toggleCloseInput("close");
                        }}
                      >
                        {selectedComponent.componentStyles.closeText ? (
                          selectedComponent.componentStyles.closeText
                        ) : (
                          <span>{t("Enter text")}</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <hr className="hrTag" />
                <div className="expandTextproperties ">
                  <div className="expandfontfamilyprop justify-content-between select_boxfont ">
                    <p>Font</p>
                    <select
                      disabled={locked}
                      className="fontfamilyDropdown"
                      onChange={(e) => handleFontFamilyISI(e)}
                      value={
                        selectedComponent?.componentStyles?.expandClose
                          .fontFamily
                      }
                    >
                      {fontFamilyExpandClose.map((fontFamilyItems, id) => {
                        return (
                          <option
                            key={id}
                            value={
                              fontFamilyItems
                              // fontFamilyItems.family
                              //   ? fontFamilyItems.family
                              //   : fontFamilyItems
                            }
                          >
                            {fontFamilyItems}
                            {/* {fontFamilyItems.family
                        ? fontFamilyItems.family
                        : fontFamilyItems} */}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="fontSizeProperty">
                    <div className="fontSizeItems">
                      <p className="noMargin">Size</p>
                      <div
                        className={
                          locked
                            ? "fontInputDisable d-flex"
                            : "fontInput d-flex"
                        }
                      >
                        <input
                          // type="number"
                          disabled={locked}
                          name="fontSize"
                          className=" fontSizeDropdown"
                          //list="fontSizedata"
                          style={{ width: "100%" }}
                          // onBlur={(e) => {
                          //   handleFontSizeISI(e);
                          // }}
                          onBlur={(e) => handletextfield(e, "blur")}
                          // onKeyDown={(e) =>
                          //   symbolsArr.includes(e.key) && e.preventDefault()
                          // }
                          onChange={(e) => handletextfield(e)}
                          value={
                            scrollRightProps?.componentStyles?.expandClose
                              .fontSize
                          }
                          title=""
                        />
                        <KeyboardArrowDownIcon
                          id="fontSizeIcon"
                          style={{ color: "#000000" }}
                          onClick={handleFontsizeDropdown}
                        />
                      </div>
                      <Menu
                        id="basic-menu"
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorEl={openfontsizeDropdown}
                        open={locked ? false : open}
                        onClose={handleClose}
                        class="dropdownFontinput"
                        PaperProps={{
                          style: {
                            position: "absolute",
                            //  right: "28px",
                            width: "80px",
                            height: "119px",

                            border: "1px solid black",
                            borderRadius: "0px",
                          },
                        }}
                        //   TransitionComponent={Fade}
                      >
                        {fontSizeExpandClose.map((fontSizeItems, id) => {
                          return (
                            <MenuItem
                              key={id}
                              value={fontSizeItems}
                              onClick={(e) => {
                                handleScrollTextPropsOnchange(
                                  fontSizeItems,
                                  "fontSize"
                                );
                                handleClose();
                              }}
                            >
                              {fontSizeItems}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>

                    <div className="fontWeightItems ">
                      <div className="d-flex select_boxfontweight">
                        <p className="size noMargin" id="weight">
                          Weight
                        </p>
                        <select
                          disabled={locked}
                          className="fontWeightDropdown"
                          onChange={(e) =>
                            handleScrollTextPropsOnchange(
                              e.target.value,
                              "fontWeight"
                            )
                          }
                          value={
                            selectedComponent?.componentStyles?.expandClose
                              .fontWeight
                          }
                        >
                          {fontWeightExpandclose?.map((fontWeightItem, id) => {
                            return (
                              <option key={id} value={fontWeightItem}>
                                {fontWeightItem}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="fontDecorators justify-content-between">
                    <div className=" spacingProperty">
                      <div className=" spacingPropertyItems">
                        <img
                          src={AV}
                          alt="vertical-spacing"
                          // onClick={(e) => {
                          //   !locked && handleCharacterSpacingISI();
                          // }}
                        />
                        <div
                          className={
                            locked
                              ? "fontInputDisable d-flex"
                              : "fontInput d-flex"
                          }
                        >
                          <input
                            // type="number"
                            disabled={locked}
                            name="letterSpacing"
                            className=" fontSizeDropdown"
                            //list="fontSizedata"
                            // onKeyDown={(e) =>
                            //   symbolsArr.includes(e.key) && e.preventDefault()
                            // }
                            //  onBlur={(e) => handleCharacterSpacingISI(e)}
                            onBlur={(e) => handletextfield(e, "blur")}
                            onChange={(e) => handletextfield(e)}
                            value={
                              scrollRightProps?.componentStyles?.expandClose
                                .letterSpacing
                            }
                            title=""
                          />
                          <KeyboardArrowDownIcon
                            id="fontSizeIcon"
                            onClick={handleletterSpaceDropdown}
                          />
                        </div>
                        <Menu
                          id="basic-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          anchorEl={openletterspaceDropdown}
                          open={locked ? false : openletterSpacing}
                          onClose={handleCloseletterSpace}
                          class="dropdownFontinput"
                          PaperProps={{
                            style: {
                              position: "absolute",
                              //  right: "28px",
                              width: "80px",
                              height: "101px",

                              border: "1px solid black",
                              borderRadius: "0px",
                            },
                          }}
                          //   TransitionComponent={Fade}
                        >
                          {letterSpacingEXpandclose.map(
                            (letterSpacingItems, id) => {
                              return (
                                <MenuItem
                                  key={id}
                                  value={letterSpacingItems}
                                  onClick={(e) => {
                                    handleScrollTextPropsOnchange(
                                      letterSpacingItems,
                                      "letterSpacing"
                                    );
                                    handleCloseletterSpace();
                                  }}
                                >
                                  {letterSpacingItems}
                                </MenuItem>
                              );
                            }
                          )}
                        </Menu>
                      </div>
                      <div className=" lineHeightItems">
                        <img
                          src={LineHeight}
                          alt="lineHeight"
                          // onClick={(e) => {
                          //   !locked && lineHeightConfig();
                          // }}
                        />
                        <div
                          className={
                            locked
                              ? "fontInputDisable d-flex"
                              : "fontInput d-flex"
                          }
                        >
                          <input
                            //  type="number"
                            disabled={locked}
                            name="lineHeight"
                            className=" fontSizeDropdown "
                            //list="fontSizedata"
                            // onKeyDown={(e) =>
                            //   symbolsArr.includes(e.key) && e.preventDefault()
                            // }
                            //   onBlur={(e) => handleLineHeightISI(e)}
                            onBlur={(e) => handletextfield(e, "blur")}
                            onChange={(e) => {
                              handletextfield(e);
                            }}
                            value={
                              scrollRightProps?.componentStyles?.expandClose
                                .lineHeight
                            }
                            title=""
                          />
                          <KeyboardArrowDownIcon
                            id="fontSizeIcon"
                            onClick={handlelineHeightDropdown}
                          />
                        </div>

                        <Menu
                          id="basic-menu"
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          anchorEl={openlineheightDropdown}
                          open={locked ? false : openlineHeight}
                          onClose={handleCloselineHeight}
                          class="dropdownFontinput letterspacing"
                          PaperProps={{
                            style: {
                              position: "absolute",
                              //  right: "28px",
                              width: "80px",
                              height: "120px",
                              border: "1px solid black",
                              borderRadius: "0px",
                            },
                          }}
                          //   TransitionComponent={Fade}
                        >
                          {lineHeightItems.map((lineHeightItems, id) => {
                            return (
                              <MenuItem
                                key={id}
                                value={lineHeightItems}
                                onClick={(e) => {
                                  handleScrollTextPropsOnchange(
                                    lineHeightItems,
                                    "lineHeight"
                                  );
                                  handleCloselineHeight();
                                }}
                              >
                                {lineHeightItems}
                              </MenuItem>
                            );
                          })}
                        </Menu>
                        {/* <select
                          disabled={locked}
                          className="lineheightDropdown"
                          onChange={(e) => handleLineHeightISI(e)}
                          value={
                            selectedComponent?.componentStyles?.expandClose
                              .lineHeight
                          }
                        >
                          {lineHeightItems.map((lineHeightItems, id) => {
                            return (
                              <option key={id} value={lineHeightItems}>
                                {lineHeightItems}
                              </option>
                            );
                          })}
                        </select> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="fontDecorators justify-content-between"
                    style={{ pointerEvents: locked && "none" }}
                  >
                    {/* {froalaFontStylesExCl.map((items, id) => {
                      return (
                        <div
                          onClick={() => {
                            !locked && fontStyleConfigISI(items.type, id);
                            updateSelctedFontstyleISI(items.id);
                          }}
                          key={id}
                        >
                          {items.img}
                          <div
                            // className={
                            // selectedComponent?.componentStyles?.expandClose.selectedFontStyle?.includes(
                            //   items.id
                            // )
                            //   ? "targeting-box"
                            //   : ""

                            //}
                            className={getCssClass(items.id)}
                          ></div>
                        </div>
                      );
                    })} */}

                    {froalaFontStylesExCl.map((items, id) => {
                      return (
                        <div
                          onClick={() => {
                            !locked && fontStyleConfigISI(items.type, id);
                            updateSelctedFontstyleISI(items.id, items.type);
                          }}
                          key={id}
                        >
                          {selectedComponent?.componentStyles.expandClose.selectedFontStyle?.includes(
                            items.id
                          ) ? (
                            <img src={Outline} alt="" className="img-fluid" />
                          ) : (
                            <img
                              src={Outlinegrey}
                              alt=""
                              className="img-fluid"
                            />
                          )}

                          {selectedComponent?.componentStyles.expandClose.selectedFontStyle?.includes(
                            items.id
                          ) ||
                          (items.id === 1 &&
                            selectedComponent?.componentStyles?.expandClose
                              .boldClick &&
                            selectedComponent?.componentStyles?.expandClose
                              .fontWeight === "bold")
                            ? items.img2
                            : items.img}
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className=" d-flex"
                    style={
                      selectedComponent?.componentType === "Text"
                        ? { marginTop: "4px" }
                        : { marginTop: "-23px" }
                    }
                  >
                    <div className="swatch">
                      {
                        <img
                          alt=""
                          style={styles?.fontColorISI}
                          className="swatchBorder"
                          onClick={() => {
                            !locked && handleClickFontColorISI();
                          }}
                        ></img>
                      }
                    </div>

                    <div className="fill-color">
                      {t("editorRightPanel.propertiesTabcontent.fontColor")}
                    </div>
                  </div>
                  {selectedComponent.componentType === "Text" && (
                    <div className=" d-flex" style={{ marginTop: "10px" }}>
                      <div className="swatch">
                        {
                          <img
                            alt=""
                            style={styles.textBgColorISI}
                            className="swatchBorder"
                            onClick={() => {
                              !locked && handleClicktextBgColorISI();
                            }}
                          ></img>
                        }
                      </div>

                      <div className="fill-color">
                        {t("Text Background Color")}
                      </div>
                    </div>
                  )}
                </div>
                <hr
                  className="hrTag"
                  style={{ position: "relative", top: "42px" }}
                />
              </>
            )}
            <div
              className={
                selectedComponent.componentStyles.expandAndClose
                  ? "thumbdiv"
                  : "thumbDivChecked"
              }
            >
              <div className="thumbScroll">{t("THUMB")}</div>
              <div className="scrollImageUpload">
                {selectedComponent?.componentStyles?.scrollThumbBg !== "" ? (
                  <>
                    <img
                      src={selectedComponent?.componentStyles?.scrollThumbBg}
                      className="uploadScrollimg2"
                      alt="UploadScrollImage"
                    />
                    <span class="deleteThumb" onClick={deleteThumbBg}>
                      Delete
                    </span>
                  </>
                ) : (
                  <>
                    <Dropzone
                      ref={dropzoneReference}
                      multiple={true}
                      accept=".jpeg,.png,.jpg,.gif,.svg"
                      onDrop={(accepted, rejected) =>
                        onDropThumbImage(accepted, rejected)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} style={{ marginTop: "6px" }}>
                          <input {...getInputProps()} />

                          <img
                            src={iconupload}
                            className="uploadScrollimg"
                            alt="UploadScrollImage"
                          />
                          <span className="image-textNewscroll">
                            Drag & drop image here
                          </span>
                          <div>
                            <span className="ordiv">Or</span>
                            <span className="image-textNew2scroll">
                              {" "}
                              click to browse
                            </span>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </>
                )}
                {/* <div>Upload</div> */}
              </div>
              <div className="dimensionFieldsWH">
                <div className="widthAndHeightScroll ">
                  <>
                    {scrollDimensionData.dimensions.dimension.map(
                      (dimensions, id) => {
                        return (
                          <div key={id} className="height-width">
                            <p>{dimensions}</p>
                            <TextField
                              title=""
                              className="standard-basic"
                              id={`${dimensions}Dimension`}
                              onChange={(e) => handleScrollDimensions(e)}
                              onBlur={(e) => handleScrollDimensions(e, "blur")}
                              value={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? isNaN(scrollDimensions.thumbWidth)
                                    ? 0
                                    : Math.round(scrollDimensions.thumbWidth)
                                  : isNaN(scrollDimensions.thumbHeight)
                                  ? 0
                                  : Math.round(scrollDimensions.thumbHeight)
                              }
                              name={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? "thumbWidth"
                                  : "thumbHeight"
                              }
                              size="small"
                              inputProps={{
                                style: {
                                  width: "40px",
                                  position: "relative",
                                  bottom: "6px",
                                  height: "20px",
                                  // marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                </div>
              </div>
            </div>
            <hr className="hrTag" />
            <div className="">
              <div className="trackScroll">{t("TRACK")}</div>
              <div className="dimensionFieldsWH">
                <div className="widthAndHeightScroll">
                  <>
                    {scrollDimensionData.dimensions.track.map(
                      (dimensions, id) => {
                        return (
                          <div
                            key={id}
                            className="height-width"
                            style={{ marginLeft: "-11px" }}
                          >
                            <p>{dimensions}</p>
                            <TextField
                              title=""
                              className="standard-basic"
                              id={`${dimensions}Dimension`}
                              onChange={(e) => handleScrollDimensions(e)}
                              onBlur={(e) => handleScrollDimensions(e, "blur")}
                              value={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? isNaN(scrollDimensions?.trackWidth)
                                    ? 0
                                    : Math.round(scrollDimensions?.trackWidth)
                                  : isNaN(scrollDimensions?.trackHeight)
                                  ? 0
                                  : Math.round(scrollDimensions?.trackHeight)
                              }
                              name={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? "trackWidth"
                                  : "trackHeight"
                              }
                              size="small"
                              inputProps={{
                                style: {
                                  width: "40px",
                                  position: "relative",
                                  bottom: "6px",
                                  height: "20px",
                                  // marginTop: "-14px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                </div>
              </div>
            </div>
            <div className="scrollFill">
              <div className="scrollFillsub">
                <div className="swatch">
                  {
                    <div
                      style={styles.scrollColor}
                      className="swatchBorder"
                      onClick={(e) => {
                        handleClickSwatchScroll(e);
                      }}
                    ></div>
                  }
                </div>

                <div className="thumbcolorRadius">
                  <div className="fill-color">{t("Color")}</div>
                </div>
              </div>
              <div className="thumbRadius">
                <p>{t("Radius")}</p>
                <TextField
                  title=""
                  className="standard-basic"
                  id="trackRadius"
                  onChange={handleScrollDimensions}
                  onBlur={(e) => handleScrollDimensions(e, "blur")}
                  value={
                    isNaN(scrollDimensions.trackRadius)
                      ? 0
                      : Math.round(scrollDimensions?.trackRadius)
                  }
                  name="trackRadius"
                  size="small"
                  inputProps={{
                    style: {
                      width: "40px",
                      position: "relative",
                      bottom: "6px",
                      height: "20px",
                      // marginTop: "-14px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                      color: "#928989",
                      textAlign: "center",
                    },
                  }}
                />
              </div>
            </div>
            <hr className="hrTag" />
            <div>
              <div className="trackScroll">{t("ARROW")}</div>
              <div className="arrowScroll">
                <div className="directionscroll">Up</div>
                <div className="scrollArrowUpload">
                  {selectedComponent?.componentStyles?.scrollUpArrowbg !==
                  "" ? (
                    <>
                      <img
                        src={
                          selectedComponent?.componentStyles?.scrollUpArrowbg
                        }
                        className="uploadArrowscrollimg"
                        alt="UploadScrollImage"
                      />
                      <span
                        class="deleteArrow"
                        onClick={(e) =>
                          deleteUpArrowBg(
                            selectedComponent?.componentStyles?.scrollUpArrowbg
                          )
                        }
                      >
                        Delete
                      </span>
                    </>
                  ) : (
                    <>
                      <Dropzone
                        ref={dropzoneReference}
                        multiple={true}
                        accept=".jpeg,.png,.jpg,.gif,.svg"
                        onDrop={(accepted, rejected) =>
                          onDropUpArrowImage(accepted, rejected)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} style={{ marginTop: "6px" }}>
                            <input {...getInputProps()} />
                            <img
                              src={iconupload}
                              className="uploadArrowscroll"
                              alt="UploadScrollImage"
                            />

                            <span className="image-textNewscroll">
                              Drag & drop image here
                            </span>
                            <div>
                              <span className="ordiv">Or</span>
                              <span className="image-textNew2scroll">
                                {" "}
                                click to browse
                              </span>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}
                </div>
              </div>
              <div className="arrowScroll">
                <div className="directionscroll">Down</div>
                <div className="scrollArrowUpload downArrow">
                  {selectedComponent?.componentStyles?.scrollDownArrowbg !==
                  "" ? (
                    <>
                      <img
                        src={
                          selectedComponent?.componentStyles?.scrollDownArrowbg
                        }
                        className="uploadArrowscrollimg"
                        alt="UploadScrollImage"
                      />
                      <span class="deleteArrow" onClick={deleteDownArrowBg}>
                        Delete
                      </span>
                    </>
                  ) : (
                    <>
                      <Dropzone
                        ref={dropzoneReference}
                        multiple={true}
                        accept=".jpeg,.png,.jpg,.gif,.svg"
                        onDrop={(accepted, rejected) =>
                          onDropDownArrowImage(accepted, rejected)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} style={{ marginTop: "6px" }}>
                            <input {...getInputProps()} />
                            <img
                              src={iconupload}
                              className="uploadArrowdown"
                              alt="UploadScrollImage"
                            />
                            <span className="image-textNewscroll">
                              Drag & drop image here
                            </span>
                            <div>
                              <span className="ordiv">Or</span>
                              <span className="image-textNew2scroll">
                                {" "}
                                click to browse
                              </span>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </>
                  )}
                </div>
              </div>
              <div className="dimensionFieldsWH" style={{ marginLeft: "-9px" }}>
                <div
                  className="widthAndHeightScroll "
                  //style={{ marginLeft: "-9px" }}
                >
                  <>
                    {scrollDimensionData.dimensions.dimension.map(
                      (dimensions, id) => {
                        return (
                          <div key={id} className="height-width">
                            <p>{dimensions}</p>
                            <TextField
                              title=""
                              className="standard-basic"
                              id={`${dimensions}Dimension`}
                              onChange={handleScrollDimensions}
                              onBlur={(e) => handleScrollDimensions(e, "blur")}
                              value={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? isNaN(scrollDimensions?.arrowWidth)
                                    ? 0
                                    : Math.round(scrollDimensions?.arrowWidth)
                                  : isNaN(scrollDimensions?.arrowHeight)
                                  ? 0
                                  : Math.round(scrollDimensions.arrowHeight)
                              }
                              name={
                                `${dimensions}Dimension` === "WidthDimension"
                                  ? "arrowWidth"
                                  : "arrowHeight"
                              }
                              size="small"
                              inputProps={{
                                style: {
                                  width: "40px",
                                  position: "relative",
                                  bottom: "6px",
                                  height: "20px",
                                  marginBottom: "-15px",
                                  fontSize: "11px",
                                  color: "#928989",
                                  textAlign: "center",
                                },
                              }}
                            />
                          </div>
                        );
                      }
                    )}
                  </>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <hr className="hr" />
    </>
  );
}
