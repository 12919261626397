import { putSaveAssets } from "../../services/apis";
import { toast } from "react-toastify";
import React from "react";
import { useHistory } from "react-router-dom";

import { TEXT, IMAGE, BUTTON, SHAPE } from "../../Uitls/ComponentType";

const getDOM = (val, animationObj) => {
  let collectComponentInfo = {
    componentType: val.componentType,
    atomId: "",
    atomURL: "",
    src: "",
    components: {
      ...val.componentStyles,
      keyFrames: val.keyFrames,
      animationObj: animationObj,
    },
  };
  switch (val.componentType) {
    case BUTTON:

    case TEXT:
      collectComponentInfo.components.froalaText = val.froalaText;
      collectComponentInfo.components.textComponentType = val.textComponentType;
      return collectComponentInfo;

    case IMAGE:
      return collectComponentInfo;

    case SHAPE:
      collectComponentInfo.components.textComponentType = val.textComponentType;
      collectComponentInfo.components.src =
        val.triImage || val.hexImage || val.pentImage;

      return collectComponentInfo;

    /*       case default :
        return collectComponentInfo; */
  }
};

export function saveAssets({
  layerCompData,
  assetId,
  animationObject,
  setLayerCompData,
  setundoFlag,
  bannerChanged,
  refBannerChanged,
  setBannerChanged,
  setRefreshed,
  origin,
  bannerName,
  setShowBannerName,
}) {
  // const history = useHistory();
  let finalAssetId = "";
  let assetpagesComponents = [];
  let finalObject = {
    id: "",
    // canvasDimension: {
    //   backgroundColor: canvasDimension?.backgroundColor,
    // }, //IMC-3450(patch call is must for canvasproperties?)
    assetpages: [
      {
        pageIndex: "0",
        pageName: "bannerPAge1",
        assetpagecomponent: assetpagesComponents,
        // fileSizeExceeds: fileSizeExceeds,
      },
    ],
  };

  layerCompData &&
    layerCompData.map((val, index) => {
      // console.log(animationObject[val.id], "animationObject");
      let constructDom = getDOM(val, animationObject[val.id]);
      assetpagesComponents.push(constructDom);
    });

  finalObject.id = "";
  finalAssetId = assetId;
  let msg = saveComponents(
    layerCompData,
    finalAssetId,
    finalObject,
    setLayerCompData,
    setundoFlag,
    bannerChanged,
    refBannerChanged,

    setBannerChanged,
    setRefreshed,
    origin,
    bannerName,
    setShowBannerName
  );
  return msg;
}

function saveComponents(
  layerCompData,
  finalAssetId,
  finalObject,
  setLayerCompData,
  setundoFlag,
  bannerChanged,
  refBannerChanged,

  setBannerChanged,
  setRefreshed,
  origin,
  bannerName,
  setShowBannerName
) {
  // console.log(finalObject, "finalObject")
  return new Promise((resolve) => {
    putSaveAssets(finalAssetId, finalObject, (response, err, errorCode) => {
      // console.log(finalObject, "finalObjectsave");
      if (err) {
        if (origin !== "backIcon")
          toast.error(<div className="notificationText">Asset not saved</div>);
        // setundoFlag(false);
        setBannerChanged(true);
        refBannerChanged.current = true;
        // setRefreshed(false);
      } else {
        // let tempLayerCompData = JSON.parse(JSON.stringify(layerCompData));
        // layerCompData.forEach((comp) => {
        //   comp.componentStyles.layerNameSaved = comp.componentStyles.layerName;
        // });// 1634 using dom observer
        refBannerChanged.current = false;
        setShowBannerName(bannerName);
        // setundoFlag(true);
        setBannerChanged(false);

        // setRefreshed(true);
        // setLayerCompData(tempLayerCompData);
        if (origin !== "backIcon")
          toast.success(
            <div className="notificationText">Asset saved successfully.</div>
          );
      }
    });
  });
}
