import React, { useRef, useEffect } from "react";

function Canvas(props) {
  let canvasRef = useRef(null);

  useEffect(() => {
    let canvas = canvasRef.current;

    let propObj = {};
    propObj.AXIS_ORIGIN = { x: 0, y: canvas.height - 0 };
    propObj.TEXT_FONTSIZE = 10;
    propObj.TOTAL_DURATION = props.totalDuration.divisions;
    propObj.SECONDS_DIVISION = 5;
    propObj.HORIZONTAL_TICK_SPACING =
      (canvas.width - propObj.TEXT_FONTSIZE) /
      propObj.TOTAL_DURATION /
      propObj.SECONDS_DIVISION;
    propObj.NUM_HORIZONTAL_TICKS =
      propObj.TOTAL_DURATION * propObj.SECONDS_DIVISION;
    propObj.TICKS_LINEWIDTH = 0.5;

    const context = canvasRef.current.getContext("2d");
    drawHorizontalAxisTicks();

    function drawHorizontalAxisTicks() {
      let j = 1;
      let moveText = 0;
      context.lineWidth = 0.4;
      for (var i = 0; i <= propObj.NUM_HORIZONTAL_TICKS; ++i) {
        context.beginPath();
        if (i !== 0 && i % 5 === 0) {
          context.moveTo(0 + propObj.HORIZONTAL_TICK_SPACING * i, 45);
          context.lineTo(propObj.HORIZONTAL_TICK_SPACING * i, 15);
          context.textAlign = "left";
          if (j > 9) moveText = 5;
          else moveText = 2;
          context.fillText(
            j++,
            propObj.HORIZONTAL_TICK_SPACING * i - moveText,
            12
          );
        } else {
          context.moveTo(
            propObj.AXIS_ORIGIN.x + i * propObj.HORIZONTAL_TICK_SPACING,
            45
          );
          context.lineTo(
            propObj.AXIS_ORIGIN.x + i * propObj.HORIZONTAL_TICK_SPACING,
            35
          );
        }
        context.stroke();
      }
    }
  }, [props.totalDuration.divisions]);

  return (
    <div className="canvas-division">
      <canvas
        width={props.totalDuration.width + "px"}
        height="49px"
        margin-top="100px"
        ref={canvasRef}
        className="ruler"
      ></canvas>
    </div>
  );
}

export default Canvas;
