import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { DebounceInput } from "react-debounce-input";

import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField, InputAdornment } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Slider from "@material-ui/core/Slider";
import { toast } from "react-toastify";
export default function Transform({
  showComponentType,
  handleDimensions,
  updatedValue,
  dimensionsData,
  checkedAspect,
  handleBlur,
  selectedComponent,

  setSelectedComponent,
  layerCompData,
  setUpdatedValue,
  toggleAnimatedBanner,
}) {
  // let locked = selectedComponent?.componentStyles?.lockBoolean;
  let locked = false;
  if (
    selectedComponent.componentType !== undefined &&
    layerCompData.length > 0 &&
    selectedComponent.componentType !== "default"
  )
    locked =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles?.lockBoolean;

  const { t } = useTranslation();
  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: "flex",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
      background: selectedComponent?.componentStyles?.aspectLock ? "white" : "",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);
  // const [checkedAspect, setCheckedAspect] = React.useState(true);
  const ToggleAspectLock = (e) => {
    let tempSlected = { ...selectedComponent };
    tempSlected.componentStyles.aspectLock = e.target.checked;
    setSelectedComponent(tempSlected);
  };

  const handleChangeCompOpacity = (e, newValue) => {
    let updatedOpacity = { ...updatedValue };
    updatedOpacity.componentStyles.compOpacity = newValue;
    setUpdatedValue(updatedOpacity);
    let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.compOpacity =
      updatedOpacity?.componentStyles?.compOpacity;
    setSelectedComponent(tempSelected);
  };
  const handleInputOpacity = (e) => {
    let updatedOpacity = { ...updatedValue };
    if (e.target.value > 100) {
      toast.error(
        <div className="notificationText">
          Opacity value can not be more than 100%
        </div>
      );
      updatedOpacity.componentStyles.compOpacity = 100;
    } else {
      updatedOpacity.componentStyles.compOpacity = isNaN(e.target.value)
        ? 0
        : Math.round(e.target.value);
      setUpdatedValue(updatedOpacity);
      let tempSelected = { ...selectedComponent };
      tempSelected.componentStyles.compOpacity =
        updatedOpacity?.componentStyles?.compOpacity;
      setSelectedComponent(tempSelected);
    }
  };
  const handleBlurOpacity = () => {
    let updatedOpacity = { ...updatedValue };
    if (updatedValue.componentStyles.compOpacity < 0) {
      updatedOpacity.componentStyles.compOpacity = 0;
      setUpdatedValue(updatedOpacity);
    } else if (updatedValue.componentStyles.compOpacity > 100) {
      updatedOpacity.componentStyles.compOpacity = 100;
      setUpdatedValue(updatedOpacity);
    }
    let tempSelected = { ...selectedComponent };
    tempSelected.componentStyles.compOpacity =
      updatedOpacity?.componentStyles?.compOpacity;
    setSelectedComponent(tempSelected);
  };

  const validateNumbers = (e) => {
    if (isNaN(e.target.value)) return false;
  };
  return (
    <div className="textTransform">
      {/* <div className="textSetting">{showComponentType}</div> */}
      <div className="transformProps">
        <div className="transformText">
          {showComponentType === "Image" || showComponentType === "Shape"
            ? "Transform"
            : ""}
        </div>
        <div className="aspectLock">
          {t("editorRightPanel.propertiesTabcontent.aspectLock")}
          <AntSwitch
            checked={selectedComponent?.componentStyles?.aspectLock}
            onChange={(e) => {
              !locked && ToggleAspectLock(e);
            }}
          />
        </div>
      </div>
      <div>
        <div className="properties-textfield">
          <div className="dimensionFieldsWH">
            <div className="widthAndHeight">
              {dimensionsData.dimensions.width === "true" &&
              dimensionsData.dimensions.xPosition === "true" ? (
                <>
                  {dimensionsData.dimensions.dimension.map((dimensions, id) => {
                    return (
                      <div key={id} className="height-width">
                        <p>{dimensions}</p>
                        <TextField
                          // debounceTimeout={500}
                          // element={TextField}
                          // type="number"
                          autoComplete="off"
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          id={`${dimensions}Dimension`}
                          // onKeyDown={validateNumbers}
                          value={
                            `${dimensions}Dimension` === "WDimension"
                              ? Math.round(updatedValue?.componentStyles?.width)
                              : Math.round(
                                  updatedValue?.componentStyles?.height
                                )
                          }
                          // onClick={() => document.inputDiv.blur()}
                          onChange={(e) => handleDimensions(e)}
                          onKeyDown={(e) => handleDimensions(e)}
                          onBlur={(e) => handleDimensions(e, "blur")}
                          name={
                            `${dimensions}Dimension` === "WDimension"
                              ? "width"
                              : "height"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              // marginTop: "-14px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        />
                        {/* <TextField
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          id={`${dimensions}Dimension`}
                          value={
                            `${dimensions}Dimension` === "WDimension"
                              ? isNaN(theUpdatedValue?.componentStyles?.width)
                                ? 0
                                : Math.round(
                                    theUpdatedValue?.componentStyles?.width
                                  )
                              : isNaN(theUpdatedValue?.componentStyles?.height)
                              ? 0
                              : Math.round(
                                  theUpdatedValue?.componentStyles?.height
                                )
                          }
                          // onChange={(e) => debounced(e.target, e.target.value)}
                          // onChange={handleDimensions}
                          name={
                            `${dimensions}Dimension` === "WDimension"
                              ? "width"
                              : "height"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              // marginTop: "-14px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        /> */}
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          <div className="dimensionFieldsXY">
            <div className="positions">
              {dimensionsData.dimensions.height === "true" &&
              dimensionsData.dimensions.yPosition === "true" ? (
                <>
                  {dimensionsData.dimensions.positions.map((dimensions, id) => {
                    return (
                      <div className="xy-positionValues" key={id}>
                        <p>{dimensions}</p>
                        <TextField
                          // debounceTimeout={500}
                          // element={TextField}
                          autoComplete="off"
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          // type="number"
                          id={`${dimensions}Position`}
                          value={
                            `${dimensions}Position` === "XPosition"
                              ? updatedValue?.componentStyles?.left
                              : updatedValue?.componentStyles?.top
                          }
                          // onBlur={handleBlur}
                          onChange={(e) => handleDimensions(e)}
                          // onKeyDown={(e) => handleDimensions(e)}
                          onBlur={(e) => handleDimensions(e, "blur")}
                          name={
                            `${dimensions}Dimension` === "XDimension"
                              ? "left"
                              : "top"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        />

                        {/* <TextField
                          title=""
                          disabled={locked}
                          className="standard-basic"
                          type="number"
                          id={`${dimensions}Position`}
                          value={
                            `${dimensions}Position` === "XPosition"
                              ? updatedValue?.componentStyles?.left
                              : updatedValue?.componentStyles?.top
                          }
                          onBlur={handleBlur}
                          onChange={handleDimensions}
                          name={
                            `${dimensions}Dimension` === "XDimension"
                              ? "left"
                              : "top"
                          }
                          size="small"
                          inputProps={{
                            style: {
                              width: "40px",
                              position: "relative",
                              bottom: "6px",
                              height: "20px",
                              marginBottom: "-15px",
                              fontSize: "11px",
                              color: "#928989",
                              textAlign: "center",
                            },
                          }}
                        /> */}
                      </div>
                    );
                  })}
                </>
              ) : null}
            </div>
          </div>
          {(selectedComponent?.componentType === "Image" ||
            selectedComponent?.componentType === "Shape") && (
            <div>
              {!toggleAnimatedBanner ? (
                <div className="opacity-values">
                  <p>Opacity</p>
                  <TextField
                    //element={TextField}
                    //debounceTimeout={500}
                    title=""
                    disabled={locked}
                    className="standard-basic"
                    // type="number"
                    id="opacity"
                    value={updatedValue?.componentStyles?.compOpacity}
                    onBlur={handleBlurOpacity}
                    onChange={handleInputOpacity}
                    name="opacity"
                    size="small"
                    inputProps={{
                      style: {
                        width: "40px",
                        position: "relative",
                        bottom: "6px",
                        height: "20px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                        color: "#928989",
                        textAlign: "center",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className="opacityPercentage2"
                        >
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Slider
                    //value={updatedValue?.componentStyles?.compOpacity}
                    value={
                      isNaN(updatedValue?.componentStyles?.compOpacity)
                        ? 0
                        : Math.round(updatedValue?.componentStyles?.compOpacity)
                    }
                    onChange={handleChangeCompOpacity}
                    aria-labelledby="continuous-slider"
                    defaultValue={50}
                  />
                </div>
              ) : null}{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
