import React, { useContext, useState } from "react";
import "../../styles/pages/Editor.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import InfoIcon from "../../images/iconinfo.svg";
import BlueSquareInfoIcon from "../../images/BlueSquareInfoIcon.svg";

import PDFImage from "../../images/PDFImage.svg";
import HtmlImage from "../../images/HtmlImage.svg";

import ApproveIcon from "../../images/ApproveIcon.svg";
import VeevaErrorIcon from "../../images/VeevaErrorIcon.svg";
import ClearIcon from "../../images/ClearIcon.svg";

//import NewIcon from "../../images/NewIcon.svg";
import SaveIcon from "../../images/Saveblue.svg";
import CodeIcon from "../../images/codeBlue.svg";
import ExportIcon from "../../images/Upload.svg";
//import MenuIcon from "../../images/MenuIcon.svg";
//import AnchorIcon from "../../images/icon_anchor.svg";
//import Lockbanner2 from "../../images/Lockbanner2.svg";
import UndoIcon from "../../images/redoBlue.svg";
import RedoIcon from "../../images/UndoBlue.svg";
import PreviewIcon from "../../images/Playblue.svg";
import Button from "@material-ui/core/Button";
import PublishIcon from "../../images/PublishIcon.svg";
import { downLoad } from "./DownloadHTML";
import { downLoadAnimation } from "./AnimationDownloadHtml";
import { downLoadPdf } from "./PDFDownload";
import exportHTML from "./HtmlExport";
import { saveAssets } from "./saveAssets";
import backArrow from "../../images/backArrow.svg";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
// import ErrorIcon from "../../images/ErrorIcon.svg";
// import PDFIcon from "../../images/PDFIcon.svg";
//import greentick from "../../images/greentick.svg";
import { Slide, Zoom, Flip, Bounce } from "react-toastify";

import {
  sendExport,
  getExport,
  browseImages,
  imagePatch,
  fileSizeValidation,
  fileSizeValidationAsync,
  RVCVFile,
  veevaIntegration,
  //  exportAsJpeg,

  //animationObject,
  //getImage,
  getbannerandTemplateDetails,
} from "../../services/apis";
import Tooltip from "@material-ui/core/Tooltip";

import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import ExportModal from "../BannerManager/ExportModal.js";
import { CircularProgress } from "@material-ui/core";
import ValueContext from "../../ContextAPi/appContext";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import {
  modifyAsset,
  preview,
  lockBanners,
  getVeevaApprovedStatus,
} from "../../services/apis";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import PreviewModal from "./Preview";
import RVCV from "./RVCV";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { nameValidation } from "../../Uitls/Util.js";
import InfoDetailsDialog from "../BannerManager/InfoDetailsDialog";

export default function EditorHeader({
  setDamsId,
  getFroalaInstance,
  canvasDimRightPanel,
  objPrev,
  eleDimforLayers,
  setEleDimforLayers,
  layerCompData,
  canvasDimension,
  bgColorCanvas,
  animationObject,
  selectedComponent,
  popUpOptions,
  setLayerCompData,
  toggleAnimatedBanner,
  handleOnUndo,
  addedActionsList,
  refreshed,
  layerLengthAtRefresh,
  showAssettype,
  setundoFlag,
  addedCanvasActionsList,
  handleCanvasUndo,
  listForReDo,
  handleRedo,
  undoCount,
  playPause,
  bannerChanged,
  refBannerChanged,
  setBannerChanged,
  setRefreshed,
  showBannertype,
  showBannerName,
  linkedModulesType,
  handleZoomIn,
  handleZoomOut,
  setShowBannerName,
  setMLRstatus,
}) {
  // const [fileSizeExceeds, setFileSizeExceeds] = useState(false);
  const { t } = useTranslation();
  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };
  const appData = useContext(ValueContext);
  // const [eventData, setEventData] = useState("error");
  //let sizeValidate = false;
  const [showExport, setExport] = useState(false);
  const [showPreview, setPreView] = useState(false);

  const [loading, setLoading] = useState(false);
  const [savedObjForPatch, setSavedObjForPatch] = useState({});
  const [previewURl, setpreviewURl] = useState({});
  const [checkExportType, setCheckExportType] = useState({
    PDF: false,
    PNG: false,
    JPG: false,
    HTML: false,
    RVCVFILE: false,
  });
  const [rvcvTypes, setRvcvTypes] = React.useState({
    value2X: false,
    value3X: false,
  });
  const [pdfHtmlEnabled, setPdfHtmlEnabled] = useState({
    pdfEnabled: true,
    htmlEnabled: false,
  });

  const headerLeftData = [
    // {
    //   img: <img src={NewIcon} alt="" className="img-fluid"></img>,
    //   text: <p>{t("editorHeader.new")}</p>,
    // },
    // {
    //   img: <img src={OpenIcon} alt="" className="img-fluid"></img>,
    //   text: <p>{t("editorHeader.open")}</p>,
    // },
    {
      img: (
        <img
          id="saveImage"
          src={SaveIcon}
          alt=""
          name="save"
          className="img-fluid"
          onClick={(e) => handlefunctionality(e)}
        ></img>
      ),
      text: <p>{t("editorHeader.save")}</p>,
    },
    // {
    //   img: <img src={CodeIcon} alt="" className="img-fluid imgpadding"></img>,
    //   text: <p>{t("editorHeader.code")}</p>,
    // },
    {
      img: (
        <img
          src={ExportIcon}
          alt=""
          name="export"
          className="img-fluid"
          onClick={(e) => handlefunctionality(e)}
        ></img>
      ),
      text: <p>{t("editorHeader.export")}</p>,
    },
  ];
  const [pdf, setPdf] = useState(true);
  const [anmObjForVeevaHtml, setAnmObjForVeevaHtml] = useState({});
  const [openInfoDetails, setOpenInfoDetails] = useState(false);

  const [bannerTemplateDetails, setBannerTemplateDetails] = useState({});
  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }

  function getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  async function thumbnail() {
    const formData = new FormData();
    let finalDOMObj = {};
    // if (eleDimforLayers?.length > 0) {
    //   finalDOMObj = downLoadAnimation(getFroalaInstance, appData.assetInfo.assetId, canvasDimRightPanel, eleDimforLayers);
    //   console.log('finalDOMObj', finalDOMObj)
    // } else {

    finalDOMObj = downLoad({
      layerCompData: layerCompData,
      assetId: appData.assetInfo.assetId,
      canvasDimension: canvasDimension,
      bgColorCanvas: bgColorCanvas,
      animationObject: {},
      flag: true,
    });
    // }
    if (finalDOMObj === 0) {
      setLoading(false);
      return;
    }

    //console.log(finalDOMObj, "finalDOMObj");
    let DOMObj = formDOMObj(finalDOMObj);
    let getstring = await fetch("https://test-imageconvert.indegene.com/png", {
      method: "POST",
      body: DOMObj.html,
    });

    let cv = await getstring.json();
    var img = new Image();
    if (cv.image) {
      const bannerName = getRandomString(9);
      let rep = dataURItoBlob(cv.image);

      var file = new File([rep], `${bannerName}.png`, { type: "image/png" });
      formData.append("fileName", file);
      browseImages(formData, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
        } else {
          if (response.data.discription) {
            patchCallForThumbNail(response.data.discription);
          }
        }
      });
    } else {
      return;
    }
  }

  const patchCallForThumbNail = (url) => {
    imagePatch(
      appData.assetInfo.assetId,
      {
        thumbNail: url,
      },
      (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
        } else {
        }
      }
    );
  };
  let animationFinalObj = [];
  // const formAnimationObject = (obj) => {
  //   let xPosition = document
  //     .getElementById("canvas-page")
  //     .getBoundingClientRect();
  //   console.log("xPosition", xPosition);
  //   let retBoundingClientReact = document
  //     .getElementById(obj.parentId)
  //     .getBoundingClientRect();
  //   let animationTypes = [
  //     "animationLefttoright",
  //     "animationRighttoleft",
  //     "animationToptobottom",
  //     "animationBottomtotop",
  //     "animationFadeout",
  //     "animationFadein",
  //     "animationAxialrotation",
  //     "animationZoomin",
  //     "animationZoomout",
  //     "animationSlideup",
  //     "animationSlidedown",
  //   ];
  //   let aryOfComps = animationTypes.map((animationType) => {
  //     if (obj.hasOwnProperty(animationType) && obj[animationType].end > 0) {
  //       let tempObj = {};
  //       tempObj.api = "to";
  //       tempObj.atoms = [];
  //       tempObj.atoms[0] = obj.id;
  //       tempObj.op = {};
  //       tempObj.duration = obj[animationType].duration;
  //       tempObj.op.delay = obj[animationType].delay;
  //       if (
  //         !animationType.includes("Fade") &&
  //         !animationType.includes("Slide")
  //       ) {
  //         tempObj.op.opacity = 1;
  //         tempObj.op.scale = 1;
  //       }
  //       switch (animationType) {
  //         case "animationLefttoright":
  //           tempObj.op.right = Math.round(
  //             xPosition.right - retBoundingClientReact.right
  //           );
  //           if (obj[animationType].top)
  //             tempObj.op.top = document.getElementById(obj.parentId).style.top;
  //           break;
  //         case "animationRighttoleft":
  //           tempObj.op.left = Math.round(
  //             retBoundingClientReact.left - xPosition.left
  //           );
  //           if (obj[animationType].top)
  //             tempObj.op.top = document.getElementById(obj.parentId).style.top;
  //           break;
  //         case "animationToptobottom":
  //           tempObj.op.bottom = Math.round(
  //             xPosition.bottom - retBoundingClientReact.bottom
  //           );
  //           if (obj[animationType].left)
  //             tempObj.op.left = document.getElementById(
  //               obj.parentId
  //             ).style.left;
  //           break;
  //         case "animationBottomtotop":
  //           tempObj.op.top = Math.round(
  //             retBoundingClientReact.top - xPosition.top
  //           );
  //           if (obj[animationType].left)
  //             tempObj.op.left = document.getElementById(
  //               obj.parentId
  //             ).style.left;
  //           break;
  //         case "animationFadeout":
  //           tempObj.op.opacity = obj[animationType].opacity
  //             ? obj[animationType].opacity
  //             : 0;
  //           break;
  //         case "animationFadein":
  //           tempObj.op.opacity = obj[animationType].opacity
  //             ? obj[animationType].opacity
  //             : 1; //obj[animationType].opacity;
  //           break;
  //         case "animationAxialrotation":
  //           tempObj.op.rotation = obj[animationType].rotation;
  //           if (obj[animationType].x) {
  //             tempObj.op.left = retBoundingClientReact.left - xPosition.left;
  //           }
  //           if (obj[animationType]["y"]) {
  //             tempObj.op.top = retBoundingClientReact.top - xPosition.top;
  //           }
  //           break;
  //         case "animationZoomin":
  //           tempObj.op.scale = 1;
  //           tempObj.op.zIndex = obj[animationType].zIndex;
  //           break;
  //         case "animationZoomout":
  //           tempObj.op.scale = 1;
  //           tempObj.op.zIndex = obj[animationType].zIndex;
  //           break;
  //         case "animationSlideup":
  //           tempObj.op.top = Math.round(
  //             retBoundingClientReact.top - xPosition.top
  //           );
  //           tempObj.op.height = retBoundingClientReact.height; //obj[animationType].height;
  //           tempObj.op.opacity = 1; //obj[animationType].opacity;
  //           break;
  //         case "animationSlidedown":
  //           tempObj.op.top = Math.round(
  //             retBoundingClientReact.top - xPosition.top
  //           );
  //           tempObj.op.height = retBoundingClientReact.height;
  //           tempObj.op.opacity = 1; //obj[animationType].opacity;
  //           break;
  //         default:
  //           break;
  //       }
  //       if (Object.keys(tempObj).length > 0) {
  //         return tempObj;
  //       }
  //     }
  //   });
  //   return aryOfComps;
  // };
  const exportType = async (type, veeva) => {
    const formData = new FormData();
    let finalDOMObj = {};
    if (layerCompData.length === 0) {
      toast.error(
        <div className="notificationText">
          Please drag and drop something on the canvas.
        </div>
      );
      setLoading(false);
      return;
    }
    // let animation = false;
    // let eleDimforLayersTemp = [...layerCompData];
    animationFinalObj = [];
    let animationObjectNew = JSON.parse(JSON.stringify(animationObject || []));
    Object.values(animationObjectNew).forEach((obj) => {
      if (obj?.animation.length > 0 && obj.animation[0].delay !== 0) {
        let tempObj = obj.animation[0];
        tempObj.delay = 0;
        tempObj.duration = tempObj.end;
        let firstObject = {
          ...tempObj,
          rotation: 0,
          left: 0,
          top: 0,
          width: tempObj.width,
          height: tempObj.height,
          transform: 1,
          delay: 0,
          duration: 0,
          ease: "NONE",
          end: 0,
          opacity: 1,
        };
        obj.animation.splice(0, 0, firstObject);
        obj.animation[1] = tempObj;
      }
    });

    Object.keys(animationObjectNew).forEach((obj) => {
      animationObjectNew[obj].animation.forEach((eachAnimation) => {
        animationFinalObj.push({
          api: "to",
          atoms: [obj],
          duration: eachAnimation.duration,
          op: {
            delay: eachAnimation.delay,
            opacity: eachAnimation.opacity,
            ease: eachAnimation.ease,
            rotation: eachAnimation.rotation,
            scale: eachAnimation.transform,
            left: popUpOptions?.skewAnimation === true ? "" : eachAnimation.x,
            top: popUpOptions?.skewAnimation === true ? "" : eachAnimation.y,
            width: eachAnimation.width,
            height: eachAnimation.height,
          },
        });
      });
    });
    if (checkExportType.RVCVFILE === true) {
      setLoading(false);
      toast.success(
        <div className="notificationText">
          Please click on the link sent to you via email to download the RVCV
          images.
        </div>
      );
      finalDOMObj = downLoad({
        layerCompData: layerCompData,
        assetId: appData.assetInfo.assetId,
        canvasDimension: canvasDimension,
        bgColorCanvas: bgColorCanvas,
        animationObject: animationObjectNew,
        flag: true,
        popUpOptions: popUpOptions,
        toggleAnimatedBanner: toggleAnimatedBanner,
      });
      //  console.log(rvcvTypes, "rvcvTypes");
      let DOMObj = formDOMObj(finalDOMObj, "rvcv");

      let obj = {
        page: DOMObj.html,
        rvcv:
          rvcvTypes.value2X === true ? 2 : rvcvTypes.value3X === true ? 3 : 1,
        name: appData.assetInfo.assetName,
        emailID: localStorage.getItem("RVCV-email"),
        currentUserName: localStorage.getItem("currentUserName"),
      };
      // let PreviewDOM = {
      //   html: DOMObj.html,
      //   fileName: appData.assetInfo.assetName,
      // };

      // preview(PreviewDOM, (response, err, errorCode) => {
      //   if (err) {
      //     const errCoponent = ErrComponentToRender(errorCode);
      //   } else {
      //     if (response.data) {
      //       console.log(response.data, "response.data");
      //       setpreviewURl(response.data);
      //       setPreView(true);
      //     }
      //   }
      // }); https://test-imageconvert.indegene.com/RVCV
      //http://localhost:8080/RVCV
      //return ;
      let getstring = fetch("https://test-imageconvert.indegene.com/RVCV", {
        method: "POST",
        body: JSON.stringify(obj),
      });

      let cv = await getstring;
      if (cv.status === 200) {
      } else {
        const errCoponent = ErrComponentToRender(cv.status);
        // toast.error(errCoponent);
        setLoading(false);
      }
    }

    if (finalDOMObj === 0) {
      setLoading(false);
      return;
    }

    Object.keys(checkExportType).forEach(async (key) => {
      if (key === "HTML" && checkExportType[key] === true) {
        let finalDOMObj = downLoad({
          layerCompData: layerCompData,
          assetId: appData.assetInfo.assetId,
          canvasDimension: canvasDimension,
          bgColorCanvas: bgColorCanvas,
          animationObject: checkExportType.PDF ? {} : animationObjectNew,
          flag:
            checkExportType.PNG === true || checkExportType.JPEG === true
              ? true
              : false,
          popUpOptions: popUpOptions,
          toggleAnimatedBanner: toggleAnimatedBanner,
        });
        let DOMObjHTML = formDOMObj(finalDOMObj);
        getExport(
          randomNumber,
          encodeURIComponent(appData.assetInfo.assetName),
          (response, err, errorCode) => {
            if (err) {
              const errCoponent = ErrComponentToRender(errorCode);
            } else {
              console.log(response);
            }
          }
        );
        htmlDownload(DOMObjHTML);
      } else if (key === "JPG" && checkExportType[key] === true) {
        finalDOMObj = downLoad({
          layerCompData: layerCompData,
          assetId: appData.assetInfo.assetId,
          canvasDimension: canvasDimension,
          bgColorCanvas: bgColorCanvas,
          animationObject: checkExportType.PDF ? {} : animationObjectNew,
          flag:
            checkExportType.PNG === true || checkExportType.JPG === true
              ? true
              : false,
          popUpOptions: popUpOptions,
          toggleAnimatedBanner: toggleAnimatedBanner,
        });
        let DOMObj = formDOMObj(finalDOMObj);
        // http://imcassetconversion-dev.ap-south-1.elasticbeanstalk.com/ http://15.206.78.2/'
        let getstring = await fetch("https://test-imageconvert.indegene.com/", {
          method: "POST",
          body: DOMObj.html,
        });
        if (getstring) {
          let cv = await getstring.json();
          if (cv) {
            if (cv.image) {
              let rep = dataURItoBlob(cv.image);
              var file = new File(
                [rep],
                `${appData.assetInfo.assetName.trim()}.jpg`,
                { type: "image/jpg" }
              );
              const formDatatype = new FormData();
              formDatatype.append("fileName", file);
              pngJpegPdfDownload(key, formDatatype);
            } else {
              toast.error(
                <div className="notificationText">
                  {t("Failed to download JPEG the file")}
                </div>
              );
              setLoading(false);
            }
          }
        }
      } else if (key === "PNG" && checkExportType[key] === true) {
        finalDOMObj = downLoad({
          layerCompData: layerCompData,
          assetId: appData.assetInfo.assetId,
          canvasDimension: canvasDimension,
          bgColorCanvas: bgColorCanvas,
          animationObject: checkExportType.PDF ? {} : animationObjectNew,
          flag:
            checkExportType.PNG === true || checkExportType.JPG === true
              ? true
              : false,
          popUpOptions: popUpOptions,
          toggleAnimatedBanner: toggleAnimatedBanner,
        });
        let DOMObj = formDOMObj(finalDOMObj);
        let getstring = await fetch(
          "https://test-imageconvert.indegene.com/png",
          {
            method: "POST",
            body: DOMObj.html,
          }
        );

        let cv = await getstring.json();
        // var img = new Image();
        if (cv.image) {
          let rep = dataURItoBlob(cv.image);

          var file = new File(
            [rep],
            `${appData.assetInfo.assetName.trim()}.png`,
            { type: "image/png" }
          );
          const formDatatype = new FormData();
          formDatatype.append("fileName", file);
          pngJpegPdfDownload(key, formDatatype);
        } else {
          toast.error(
            <div className="notificationText">
              {t("Failed to download the PNG file")}
            </div>
          );
          setLoading(false);
          return;
        }
      } else if (key === "PDF" && checkExportType[key] === true) {
        let finalPdf = downLoadPdf(
          layerCompData,
          appData.assetInfo.assetId,
          canvasDimension,
          bgColorCanvas,
          // animationObject
          {}
        );
        const formDatatype = new FormData();
        let DOMObj = formDOMObj(finalPdf);

        let y = await fetch("https://test-imageconvert.indegene.com/pdf", {
          method: "POST",

          body: DOMObj.html,
        });
        let cv = await y.blob();
        if (y.status === 200) {
          var blob = new Blob([cv], { type: "application/pdf" });

          var file = new File(
            [blob],
            `${appData.assetInfo.assetName.trim()}.pdf`,
            {
              type: "application/pdf",
            }
          );
          if (veeva === "veeva") {
            formDatatype.append("pdfFile", file);
            formDatatype.append("fileName", appData.assetInfo.assetName);
            return formDatatype;
          }
          formDatatype.append("fileName", file);

          pngJpegPdfDownload(key, formDatatype);
        } else {
          setLoading(false);
          toast.error(
            <div className="notificationText">
              {t("Failed to download the PDF file")}
            </div>
          );
        }
        //  FileSaver.saveAs(getPDF, `${appData.assetInfo.assetName}.${type}`);
        //console.log("here", getPDF, token);
      }
    });

    setCheckExportType({
      ...checkExportType,
      PDF: false,
      HTML: false,
      PNG: false,
      JPG: false,
      RVCVFILE: false,
    });
    setRvcvTypes({
      ...rvcvTypes,
      value2X: false,
      value3X: false,
    });
  };

  const pngJpegPdfDownload = (type, formData) => {
    browseImages(formData, (response, err, errorCode) => {
      if (err) {
        ErrComponentToRender(errorCode);
        setLoading(false);
      } else {
        if (response.data.discription) {
          if (type !== "PDF") patchCallForThumbNail(response.data.discription);

          FileSaver.saveAs(
            response.data.discription,
            `${appData.assetInfo.assetName}.${type}`
          );
          setLoading(false);
        }
      }
    });
  };
  function htmlDownload(DOMObj) {
    sendExport(DOMObj, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);

        toast.error(errCoponent);
      } else {
        getExport(
          randomNumber,
          encodeURIComponent(appData.assetInfo.assetName),
          (response, err, errorCode) => {
            if (err) {
              const errCoponent = ErrComponentToRender(errorCode);
            } else {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
              );
              const link = document.createElement("a");

              link.href = downloadUrl;

              link.setAttribute(
                "download",
                appData.assetInfo.assetName + "HTML.zip"
              ); //any other extension

              document.body.appendChild(link);

              link.click();

              link.remove();
            }
          }
        );
      }
    });

    setLoading(false);
  }
  const patchCall = (bannerSize, origin) => {
    let obj = {};
    if (appData?.genericInfo?.rightUpdaedName === true)
      obj.assetName = appData.assetInfo.assetName.trim();
    obj.campaignId = appData.campaignId;
    obj.brandId = appData.brandId;
    obj.campaign = appData?.genericInfo?.campaign;
    obj.marketName = appData?.genericInfo?.market;
    obj.marketCode = appData.marketCode;
    //  obj.tagName = appData?.genericInfo?.tagName;
    obj.height = canvasDimension.height;
    obj.width = canvasDimension.width;

    obj.attributes = {
      customTemp: appData?.customTempSize,
      backgroundColor: bgColorCanvas,
      fps: canvasDimension.fps,
      globalLink: canvasDimension.globalLink,
      checkGloballink: canvasDimension.checkGloballink,
      checkLoops: canvasDimension.checkLoops,
      loopAnimation: canvasDimension.loopAnimation,
      bannerSize: bannerSize || canvasDimension.bannerSize,
      canvasScale: appData?.genericInfo?.canvasScale,
      custom: canvasDimension.custom,
      searchExpression: appData?.genericInfo?.linkedData?.currentLink
        ? appData?.genericInfo?.searchedFields
        : appData?.genericInfo?.searchFieldsTobeSaved,
      linkedModules: { linkedModules: linkedModulesType },
    };
    console.log("appData.searchFields saved", obj.attributes.searchExpression);
    var tempObj =
      Object.keys(savedObjForPatch).length === 0 ? objPrev : savedObjForPatch;
    var infoPatchedObj = { ...obj };
    for (let x in tempObj) {
      if (tempObj[x] === obj[x]) {
        delete obj[x];
      }
    }

    if (Object.keys(obj).length !== 0) {
      return new Promise((resolve) => {
        modifyAsset(
          appData.assetInfo.assetId,
          obj,
          (response, err, errorCode) => {
            if (err) {
              const errCoponent = errComponentToRender(errorCode);
              // categoriesErr(errCoponent);
              if (
                err.response?.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS" &&
                origin !== "backIcon" &&
                origin !== "save"
              ) {
                toast.error(
                  <div className="notificationText">
                    {t("apiErrors.errSaveBannerExist")}
                  </div>
                );
              } else {
                if (origin !== "backIcon" && origin !== "save")
                  // toast.error(<div className="notificationText">{t("apiErrors.err500")}</div>)
                  categoriesErr(errCoponent);
              }
              resolve("false");
            } else {
              setSavedObjForPatch(infoPatchedObj);

              resolve("true");
              appData.updateGenericInfo({
                ...appData?.genericInfo,
                rightUpdaed: false,
                rightUpdaedName: false,
              });
            }
          }
        );
      });
    } else
      appData.updateGenericInfo({
        ...appData?.genericInfo,
        rightUpdaed: false,
        rightUpdaedName: false,
      }); //  }
  };

  const renderTheFontLink = (fontFamily) => {
    if (fontFamily.startsWith(" ")) fontFamily = fontFamily.substring(1);
    let tempFont = fontFamily?.split(" ").join("+");
    let url = `https://fonts.googleapis.com/css2?family=${tempFont}:wght@100;200;300;400;500;600;700;800;900&display=swap`;
    var link = document.createElement("link");
    link.href = url;
    link.rel = "stylesheet";
    link.type = "text/css";
    document.getElementsByTagName("head")[0].appendChild(link);
    return `<link rel=${"stylesheet"} href= "${url}">`;
  };
  var stringToHTML = function (str) {
    var dom = document.createElement("div");

    dom.innerHTML = str;

    return dom;
  };
  const renderAppliedFonts = (response, fromExport) => {
    const myFontSpanTags = [];
    let fontsLink = "";

    response.forEach((layer) => {
      if (layer.componentType === "Button" || layer.componentType === "Text") {
        fontsLink = renderTheFontLink(layer.componentStyles.fontFamily);
        // fontsLink = renderTheFontLink("Abeezee");
        myFontSpanTags.push(stringToHTML(layer.froalaText));
      }
    });

    if (myFontSpanTags.length < 1) return;
    myFontSpanTags.forEach((myFontSpanTag) => {
      let compFamiliesLength =
        myFontSpanTag.getElementsByTagName("span").length;
      let params = [];
      for (let i = 0; i < compFamiliesLength; i++) {
        params[i] = myFontSpanTag
          .getElementsByTagName("span")
          [i].style.cssText.replaceAll(";", ",");
      }
      let compFamilies = params.map((param) => {
        let jsonStrig = "[{";
        let items = param.split(",");

        // items[0] = items[0].replace(/["/]+/g, "");
        for (let i = 0; i < items.length; i++) {
          items[i] = items[i].includes("font-family")
            ? items[i].replace(/["/]+/g, "")
            : items[i];
          if (items[i].startsWith(" ")) items[i] = items[i].substring(1);
          let current = items[i].split(":");

          jsonStrig += '"' + current[0] + '":"' + current[1] + '",';
        }

        jsonStrig = jsonStrig.substr(0, jsonStrig.length - 1);

        jsonStrig += "}]";
        return JSON.parse(jsonStrig)[0];
      });
      compFamilies.forEach((family) => {
        if (family.hasOwnProperty("font-family")) {
          let fontFamily = family["font-family"];
          if (fontFamily.startsWith(" ")) fontFamily = fontFamily.substring(1);
          let tempFont = fontFamily?.split(" ").join("+");
          // tempFont.lastIndexOf("&");
          let position = fontsLink.lastIndexOf("&");
          fontsLink = [
            "Arial",
            "Georgia",
            "Tahoma",
            "Times New Roman",
            "Verdana",
          ].includes(tempFont)
            ? fontsLink
            : [
                fontsLink.slice(0, position + 1),
                `family=${tempFont}:wght@100;200;300;400;500;600;700;800;900&`,
                fontsLink.slice(position),
              ].join("");
        }
        // fontsLink += `${renderTheFontLink(family["font-family"])} \n`;
      });
    });
    return fontsLink;
  };

  function handlefunctionality(e, origin) {
    e.preventDefault();
    if (!nameValidation(appData.assetInfo.assetName, showAssettype)) {
      return;
    }
    let DOMObj = handlePreview("save");
    const { name } = e.target;
    fileSizeValidation(DOMObj, async (response, err, errorCode) => {
      if (err) {
        if (
          err.response?.data?.errorCode === "ERR_EXCEED_MAX_UPLOAD_SIZE_200KB"
        ) {
          //File Size Exceed More than 200 KB
          console.log("patchCall(200)");
          patchCall(200);
          if (origin !== "backIcon") {
            toast.error(
              <div className="notificationText">
                {t("Warning - Banner size exceeds 200 KB!")}
              </div>
              // { hideProgressBar: true, closeButton: false }
            );
          }
        } else {
          toast.error(
            <div className="notificationText">
              {t("err.response?.data?.errorCode")}
            </div>
            // { hideProgressBar: true, closeButton: false }
          );
        }
        // setLoading(false);
        // return;
      } else {
        patchCall(0, origin);
      }
      if (name === "save" || origin === "backIcon") {
        if (origin === "backIcon") {
          appData.setActiveCards([]);
        }
        if (
          selectedComponent?.componentStyles?.layerName !== "" ||
          selectedComponent?.componentStyles?.layerName?.length !== 0
        ) {
          let result = "save";
          if (
            appData?.genericInfo?.rightUpdaed === true ||
            appData?.genericInfo?.rightUpdaedName === true
          ) {
            result = await patchCall("", "save");
          }
          if (result === "save" || result === "true") {
            saveAssets({
              layerCompData,
              assetId: appData.assetInfo.assetId,
              animationObject,
              setLayerCompData,
              setundoFlag,
              bannerChanged,
              refBannerChanged,
              setBannerChanged,
              origin,
              bannerName: appData.assetInfo.assetName,
              setShowBannerName,
            });
            thumbnail();
          }

          // } else {
          //   toast.error(
          //     <div className="notificationText">
          //       {t("apiErrors.errlayernameSpaces")}
          //     </div>
          //   );
          // }
        }
        // else {
        //   toast.error(
        //     <div className="notificationText">
        //       {t("apiErrors.errlayerNameEmpty")}
        //     </div>
        //   );
        // }
        // });
      } else if (name === "export") {
        setLoading(true);

        setExport(true);
        setCheckExportType({
          ...checkExportType,
          PDF: false,
          HTML: false,
          PNG: false,
          JPG: false,
          RVCVFILE: false,
        });
        setRvcvTypes({
          ...rvcvTypes,
          value2X: false,
          value3X: false,
        });
      }
    });
  }
  let randomNumber = new Date().getTime();
  function formDOMObj(finalDOMObj, rvcv) {
    let animationFinalObjToString = JSON.stringify(animationFinalObj);
    let completeObj = JSON.stringify(finalDOMObj.trackHeight);
    let animationObjecStringified = JSON.stringify(animationObject);
    let layerLink = "";
    let downloadType = checkExportType.PDF;
    let hrefUrl =
      canvasDimension.checkGloballink === true &&
      canvasDimension.checkGloballink !== undefined
        ? canvasDimension.globalLink
        : "javascript:void(0)";

    let typeDiff =
      downloadType === true
        ? `<a
        id="canvas-page"
        href= '${hrefUrl}'
      >
      ${finalDOMObj.DomObj}
      </a>`
        : `<div
          id="canvas-page"
          data-global="${canvasDimension.globalLink}"
          data-link="${canvasDimension.checkGloballink}"
          onClick="enableTxt(this)"
        >
          ${finalDOMObj.DomObj}

        </div>`;

    //Logic for Expand and close

    let expandClose =
      canvasDimension.width < 310
        ? `function expandBanner(id) {

             var foundEle = completeObj.find(function (element) {
                             return element.id ===id ;
                           });

          let expanHeight = document.getElementById("expand"+id).clientHeight||0;
          let heightTracK = ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4;

          gsap.to('#'+'expand'+id, 0, {display:'none' });
          gsap.to('#'+'close'+id, 0, {display:'block' });
          gsap.to('#'+id, 0.5, {top:0,height: ${canvasDimension.height}});
          gsap.to('#'+id+'ISIInfo', 0.5, {height: (${canvasDimension.height}-expanHeight)});
          gsap.to('#'+id+'viewport', 0.5, {top:0,height: (${canvasDimension.height}-expanHeight) });
         
          gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
          gsap.to('#'+id+'track', 0.5, {height:heightTracK, onUpdate:function test (){updateTiny(id,heightTracK);}});
      
      }
      function  closeBanner(id){

        let topLeft={};    
        if(animationObjecStringified !== "{}"){  
          for( let x in animationObjecStringified){   
          if(x===id && animationObjecStringified[x].animation.length > 0 ){    
        
         topLeft=animationObjecStringified[x].animation[animationObjecStringified[x].animation.length-1];} 
           }  
        }
          var foundEle = completeObj.find(function (element) {
            return element.id ===id ;
          });
          console.log(foundEle. trackHeight,"foundElefoundEle")
          gsap.to('#'+'expand'+id, 0, {display:'block'});
          gsap.to('#'+'close'+id, 0, {display:'none'});
          gsap.to('#'+id, 0.5, {top:foundEle.compTop,height: foundEle.compHeight});
          gsap.to('#'+id, 0.5, {top:Object.values(topLeft).length ? topLeft.y : foundEle.compTop,height: foundEle.compHeight});  
          gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight});
          gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });
        
          gsap.to('#scrollbar'+id, 0.5, {height:
            foundEle.compHeight -
            foundEle.arrowHeight * 2 -
            (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
          });
          console.log(foundEle)
        gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onComplete:function test (){updateTiny(id,foundEle.trackHeight);}});

        }
        
        function updateTiny(id, heightTracK){

          let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
           tinyscrollbar.update(0, heightTracK);
        }
        `
        : `function expandBanner(id) {

          var foundEle = completeObj.find(function (element) {
                          return element.id ===id ;
                        });

       let expanHeight = document.getElementById("expand"+id).clientHeight||0;
       let heightTracK =( ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4);

       gsap.to('#'+'expand'+id, 0, {display:'none' });
       gsap.to('#'+'close'+id, 0, {display:'block' });
       gsap.to('#'+id, 0.5, {left:0, top:0,height: ${canvasDimension.height} , width: ${canvasDimension.width}});
       gsap.to('#'+id+'ISIInfo', 0.5, {left:0, height: (${canvasDimension.height}-expanHeight), width: ${canvasDimension.width}});
      
       gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
       gsap.to("#"+id+"track",0.5,{height:heightTracK,onComplete: function test(){updateTiny(id,heightTracK);}});
   
   }

   function  closeBanner(id){

       var foundEle = completeObj.find(function (element) {
         return element.id ===id ;
       });
       console.log(foundEle,"foundElefoundEle")
       gsap.to('#'+'expand'+id, 0, {display:'block'});
       gsap.to('#'+'close'+id, 0, {display:'none'});
       gsap.to('#'+id, 0.5, {top:foundEle.compTop, left:foundEle.compLeft,height: foundEle.compHeight, width:foundEle.compWidth});
       gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight, width: foundEle.compWidth}  );
       gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });
     
       gsap.to('#scrollbar'+id, 0.5, {height:
         foundEle.compHeight -
         foundEle.arrowHeight * 2 -
         (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
       });
      console.log(foundEle)
     gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onUpdate:function test (){updateTiny(id,foundEle.trackHeight);}});

     }
     
     function updateTiny(id, heightTracK){
       let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
        tinyscrollbar.update(0, heightTracK);
     }
     `;

    layerLink = renderAppliedFonts(layerCompData, true) || "";

    let DOMObj = {
      html:
        rvcv === "rvcv"
          ? RVCV({
              canvasDimension,
              finalDOMObj,
              animationFinalObjToString,
              layerLink,
              completeObj,
              typeDiff,
            })
          : exportHTML({
              canvasDimension,
              finalDOMObj,
              animationFinalObjToString,
              layerLink,
              completeObj,
              typeDiff,
              expandClose,
              animationObjecStringified,
            }),

      cssObj: {
        fileName: "Styles",
        content: "",
      },
      js: "",
      atomId: finalDOMObj.atomId,
      atomUrl: finalDOMObj.atomUrl,
      img: finalDOMObj.img,
      id: randomNumber,
      fileName: appData.assetInfo.assetName.trim(), //put assetname here
    };
    return DOMObj;
  }
  function handlePreview(save) {
    let finalDOMObj = {};

    animationFinalObj = [];
    let animationObjectNew = JSON.parse(JSON.stringify(animationObject || []));

    Object.values(animationObjectNew).forEach((obj, index) => {
      if (obj?.animation.length > 0 && obj?.animation[0]?.delay !== 0) {
        let tempObj = obj.animation[0];
        tempObj.delay = 0;
        tempObj.duration = tempObj.end;
        let firstObject = {
          ...tempObj,
          rotation: 0,
          left: 0,
          top: 0,
          width: tempObj.width,
          height: tempObj.height,
          transform: 1,
          delay: 0,
          duration: 0,
          ease: "NONE",
          end: 0,
          opacity: tempObj.hasOwnProperty("hidden") ? 0 : 1,
          skewAnimation: false,
        };
        obj.animation.splice(0, 0, firstObject);
        obj.animation[1] = tempObj;
      }
    });

    Object.keys(animationObjectNew).forEach((obj) => {
      animationObjectNew[obj].animation.forEach((eachAnimation) => {
        animationFinalObj.push({
          api: "to",
          atoms: [obj],
          duration: eachAnimation.duration,
          op: {
            delay: eachAnimation.delay,
            opacity: eachAnimation.opacity,
            ease: eachAnimation.ease,
            rotation: eachAnimation.rotation,
            scale: eachAnimation.transform,
            left: popUpOptions?.skewAnimation === true ? "" : eachAnimation.x,
            top: popUpOptions?.skewAnimation === true ? "" : eachAnimation.y,
            width: eachAnimation.width,
            height: eachAnimation.height,
            // width:
            //   popUpOptions?.skewAnimation === true
            //     ? (animationObject.animation[selectedComponent.id].length ===
            //         1 &&
            //         animationObjectNew.animation[selectedComponent.id]
            //           .length === 2) !== true
            //       ? 0
            //       : ""
            //     : "",
          },
        });
      });
    });

    finalDOMObj = downLoad({
      layerCompData: layerCompData,
      assetId: appData.assetInfo.assetId,
      canvasDimension: canvasDimension,
      bgColorCanvas: bgColorCanvas,
      animationObject: animationObjectNew,
      flag: true,
      popUpOptions: popUpOptions,
      toggleAnimatedBanner: toggleAnimatedBanner,
    });
    let DOMObj = formDOMObj(finalDOMObj);
    let PreviewDOM = {
      html: DOMObj.html,
      fileName: appData.assetInfo.assetName,
    };
    if (save === "save") {
      return DOMObj;
    }
    fileSizeValidation(DOMObj, async (response, err, errorCode) => {
      if (err) {
        if (
          err.response?.data?.errorCode === "ERR_EXCEED_MAX_UPLOAD_SIZE_200KB"
        )
          // File Size Exceed More than 200 KB
          toast.error(
            <div className="notificationText">
              {t("Warning - Banner size exceeds 200 KB!")}
            </div>
            // { hideProgressBar: true, closeButton: false }
          );
        setLoading(false);
        // setFileSizeExceeds(true);
        // return;
      } else {
        // setFileSizeExceeds(false);
      }
      preview(PreviewDOM, (response, err, errorCode) => {
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
        } else {
          if (response.data) {
            setpreviewURl(response.data);
            setPreView(true);
          }
        }
      });
    });
  }
  let enableUndo = false;
  let heilightUndo = false;

  if (addedActionsList[0]?.layerCompData.length)
    enableUndo = !refreshed
      ? addedActionsList.length && addedActionsList[0].layerCompData.length
      : //  JSON.stringify(layerLengthAtRefresh) !==
        //     JSON.stringify(
        //       addedActionsList[addedActionsList.length - 1].layerCompData
        //     ) ||
        addedActionsList.length > 1;
  // console.log("enableUndo", enableUndo);

  const [openPublishDropdown, setOpenPublishDropdown] = React.useState(null);
  let open = Boolean(openPublishDropdown);
  const handlePublishDropdown = (event) => {
    //fetching mlr ststus
    const eventCurrentTarget = event.currentTarget;
    setLoading(true);
    getVeevaApprovedStatus(
      appData.assetInfo.assetId,
      async (response, err, errorCode) => {
        let data = await response?.data;
        if (err) {
          setLoading(false);
          toast.error(
            <div className="toastHeading">
              {t(`MLR Status fetch failed. Please try again`)}
            </div>
          );
        } else {
          setPdfHtmlEnabled({
            htmlEnabled: data?.isHtmlEnable,
            pdfEnabled: data?.isPdfEnable,
          });
          setLoading(false);
          setOpenPublishDropdown(eventCurrentTarget);
        }
      }
    );
  };
  const handleClose = () => {
    setOpenPublishDropdown(null);
  };
  const amPmConverter = (timeString) => {
    const date = new Date();
    let day = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()];
    let month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][date.getMonth()];
    let year = date.getFullYear();
    let time = timeString.split(" ")[1];
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    hour =
      hour > 12
        ? "0" + (hour - 12) + ":" + min + "pm"
        : hour + ":" + min + `${hour === "12" ? "pm" : "am"}`;
    return hour + "," + " " + day + "." + " " + month + " " + year;
  };

  const handleVeevaIntegration = async (e, type, obj) => {
    handleClose();
    setLoading(true);
    const formDatatype = new FormData();
    let htmlObj = {};

    if (type === "pdf") {
      handlefunctionality(e, "backIcon");

      let finalPdf = downLoadPdf(
        layerCompData,
        appData.assetInfo.assetId,
        canvasDimension,
        bgColorCanvas,
        // animationObject
        {}
      );
      let DOMObj = formDOMObj(finalPdf);

      let y = await fetch("https://test-imageconvert.indegene.com/pdf", {
        method: "POST",
        body: DOMObj.html,
      });
      let cv = await y.blob();
      if (y.status === 200) {
        var blob = new Blob([cv], { type: "application/pdf" });

        var file = new File(
          [blob],
          `${appData.assetInfo.assetName.trim()}.pdf`,
          {
            type: "application/pdf",
          }
        );
        formDatatype.append("pdfFile", file);
        formDatatype.append("fileName", appData.assetInfo.assetName);
        formDatatype.append("fileType", "Pdf");
        formDatatype.append("fileType", "Pdf");
      }
      // setMLRstatus();
    } else if (type === "html") {
      htmlObj = {
        fileType: "Html",
        html: obj.html,
        htmlFileName: obj.fileName,
        images: obj.img,
      };
    }
    veevaIntegration(
      type === "pdf" ? formDatatype : htmlObj,
      type,
      appData.assetInfo.assetId,
      (response, err, errorCode) => {
        if (err) {
          setLoading(false);
          toast.error(
            <div className="parentContainer">
              <img
                src={VeevaErrorIcon}
                className="approveIcon"
                alt="Error Icon"
              />
              <div className="pb-1 messageContainer">
                <div className="toastHeading">
                  {t(`DAMS Submission Unsuccessful`)}
                </div>
                <div className="row pleaseTryAgainVeeva">
                  {"Please try again"}
                </div>
              </div>
              <img src={ClearIcon} alt="" className="approveIcon" />
            </div>,
            {
              hideProgressBar: false,
              className: "toastMessageError",
              position: "top-right",
              transition: Slide,
              autoClose: 2000,
            }
          );
        } else {
          setLoading(false);
          let time = response.data.submittedOn.split(" ")[1].substring(0, 5);
          let name = localStorage.getItem("currentUserName");
          // .concat(" " + localStorage.getItem("currentUserFamilyName"));
          // .replaceAll(":", "/");
          const date = new Date();

          toast.success(
            <div className="parentContainer">
              <img
                src={ApproveIcon}
                className="approveIcon"
                alt="Approve Icon"
              />
              <div className="pb-1 messageContainer">
                <div className="toastHeading">
                  {t(`DAMS Submission Successful`)}
                </div>
                <div
                  className={`fileType ${
                    response.data.fileType === "Html" ? "widthExtra" : ""
                  }`}
                >
                  <div className="veevaSuccessLHS">File type</div>
                  <div
                    className={` typeImageAndTag ${
                      response.data.fileType === "Html" ? "addWidth" : ""
                    }`}
                  >
                    <img
                      src={
                        response.data.fileType === "Pdf" ? PDFImage : HtmlImage
                      }
                      className="imageSize"
                      alt="PDFImage"
                    />
                    <span className="HTMLText">
                      {response.data.fileType === "Pdf" ? "PDF" : "HTML"}
                    </span>
                  </div>
                </div>
                <div className="row notificationText1">
                  <div className="col-md-4 veevaSuccessLHS">DAMS ID</div>
                  <div className="col-md-8 veevaSuccessRHS ">
                    {" "}
                    {response.data.veevaDocNumber}
                  </div>
                </div>
                <div className="row notificationText1">
                  <div className="col-md-4 veevaSuccessLHS">Submitted by</div>
                  <div className="col-md-8 veevaSuccessRHS"> {name}</div>
                  {/*  <button className="close-button1" style={{ outline: "none" }}>
                   Close
          </button>*/}
                </div>
                <div className="row notificationText1">
                  <div className="col-md-4 veevaSuccessLHS">Submitted on</div>
                  <div className="col-md-8 veevaSuccessRHS">
                    {" "}
                    {amPmConverter(response.data.submittedOn)}
                  </div>
                </div>

                {/* <div className="notificationText1">
                Veeva ID : {response.data.docId}
              </div>
              <div className="notificationText1">Submitted at : {time}</div>
              <div className="notificationText1">
                Submitted by : {localStorage.getItem("currentUserName")}
              </div>
             */}
              </div>
              <img src={ClearIcon} alt="" className="approveIcon" />
            </div>,
            {
              hideProgressBar: false,
              className: "toastMessage",
              position: "top-right",
              autoClose: 2000,
              transition: Slide,
            }
          );
          setDamsId(response.data?.veevaDocNumber);
          setMLRstatus(response?.data?.reviewStatus);
        }
      }
    );
  };

  const history = useHistory();

  const publishHTML = (e) => {
    animationFinalObj = [];
    let animationObjectNew = JSON.parse(JSON.stringify(animationObject || []));
    Object.values(animationObjectNew).forEach((obj) => {
      if (obj?.animation.length > 0 && obj.animation[0].delay !== 0) {
        let tempObj = obj.animation[0];
        tempObj.delay = 0;
        tempObj.duration = tempObj.end;
        let firstObject = {
          ...tempObj,
          rotation: 0,
          left: 0,
          top: 0,
          width: tempObj.width,
          height: tempObj.height,
          transform: 1,
          delay: 0,
          duration: 0,
          ease: "NONE",
          end: 0,
          opacity: 1,
        };
        obj.animation.splice(0, 0, firstObject);
        obj.animation[1] = tempObj;
      }
    });

    Object.keys(animationObjectNew).forEach((obj) => {
      animationObjectNew[obj].animation.forEach((eachAnimation) => {
        animationFinalObj.push({
          api: "to",
          atoms: [obj],
          duration: eachAnimation.duration,
          op: {
            delay: eachAnimation.delay,
            opacity: eachAnimation.opacity,
            ease: eachAnimation.ease,
            rotation: eachAnimation.rotation,
            scale: eachAnimation.transform,
            left: popUpOptions?.skewAnimation === true ? "" : eachAnimation.x,
            top: popUpOptions?.skewAnimation === true ? "" : eachAnimation.y,
            width: eachAnimation.width,
            height: eachAnimation.height,
          },
        });
      });
    });
    let finalDOMObj = downLoad({
      layerCompData: layerCompData,
      assetId: appData.assetInfo.assetId,
      canvasDimension: canvasDimension,
      bgColorCanvas: bgColorCanvas,
      animationObject: checkExportType.PDF ? {} : animationObjectNew,
      flag:
        checkExportType.PNG === true || checkExportType?.JPEG === true
          ? true
          : false,
      popUpOptions: popUpOptions,
      toggleAnimatedBanner: toggleAnimatedBanner,
    });
    let DOMObjHTML = formDOMObj(finalDOMObj);
    fileSizeValidation(DOMObjHTML, async (response, err, errorCode) => {
      if (err) {
        if (
          err.response?.data?.errorCode === "ERR_EXCEED_MAX_UPLOAD_SIZE_200KB"
        )
          // File Size Exceed More than 200 KB
          toast.error(
            <div className="notificationText">
              {t("Warning - Banner size exceeds 200 KB!")}
            </div>
            // { hideProgressBar: true, closeButton: false }
          );
        // setLoading(false);
        // setFileSizeExceeds(true);
        // return;
      } else {
        // setFileSizeExceeds(false);
      }
    });
    handleVeevaIntegration(e, "html", DOMObjHTML);
  };

  const handleGobackButton = () => {
    history.push({ pathname: "/landingPage" });
  };

  const openInfoDialog = () => {
    setLoading(true);
    getbannerandTemplateDetails(
      appData.assetInfo.assetId,
      async (response, err, errorCode) => {
        const data = await response.data;
        if (err) {
          toast.error(
            <div className="notificationText">
              {t("Failed to fetch the details.!")}
            </div>
          );
          setLoading(false);
        } else {
          setLoading(false);
          setBannerTemplateDetails(response?.data);
          setOpenInfoDetails(true);
        }
      }
    );
  };

  return (
    <>
      <div
        className="container-fluid editorTempheader"
        style={{ pointerEvents: !playPause.animationCompleted && "none" }}
      >
        <div className="row editHeader">
          <div className="col-md-2 d-flex tempHeaderLeft">
            <div className="contstud-title">
              <div className=" d-flex editorHeading">
                <div className=" d-flex editorTempHeading">
                  <img
                    src={backArrow}
                    alt=""
                    className="backIcon"
                    onClick={(e) => {
                      if (
                        !nameValidation(
                          appData.assetInfo.assetName,
                          showAssettype
                        )
                      )
                        return;
                      handlefunctionality(e, "backIcon");
                      appData.setBanner(
                        showAssettype === "Banner" ? true : false
                      );
                      history.goBack();
                    }}
                  />
                  <p className="templateheading">
                    {showAssettype}{" "}
                    <span className="bannerTypetext">| {showBannertype}</span>
                  </p>

                  <br />
                  {showAssettype === "Template" ? (
                    <div
                      className={
                        showBannertype === "Animated"
                          ? "tempNameAnimated"
                          : "tempName"
                      }
                    >
                      <Tooltip title={showBannerName}>
                        <h6 className="editorTemp-name">{showBannerName}</h6>
                      </Tooltip>
                    </div>
                  ) : (
                    <div
                      className={
                        showBannertype === "Animated"
                          ? "bnrNameAnimated"
                          : "tempNamebanner"
                      }
                    >
                      <Tooltip title={showBannerName}>
                        <h6 className="editorTemp-name">{showBannerName}</h6>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="vl2"></div>
          <div className="col-md-2 editorHeaderTempRight">
            <div
              className="align-items-center  editorNavIcons"
              style={{ marginLeft: "-26px" }}
            >
              <ul className="d-flex  mb-0 headerLeftdata">
                {headerLeftData.map((data, index) => {
                  return (
                    <li className="editorList" key={index + "headerLeftData"}>
                      <div className="img-with-text">
                        <div className="headerIconBlock">{data.img}</div>
                        {data.text}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-3 editorTempheaderRight">
            <div className="d-flex align-items-center justify-content-end  editorNavIcons">
              <ul className="d-flex mb-0 undoicon">
                <li className="mt-1 mb-1">
                  <div
                    // className="img-with-text editorRightImage"
                    className={
                      //undoCount < 10 &&
                      enableUndo || addedCanvasActionsList.length > 1
                        ? "img-fluid "
                        : "img-fluid  OpacityLess"
                    }
                    // onClick={
                    //   enableUndo
                    //     ? //&& undoCount < 10
                    //       handleOnUndo
                    //     : addedCanvasActionsList.length > 1 &&
                    //       // undoCount < 10 &&
                    //       handleCanvasUndo
                    // }
                  >
                    <img
                      src={UndoIcon}
                      alt="undoIcon"
                      name="undoIcon"
                      //style={{ height: }}
                      className="undoIcon undo"
                      // onClick={enableUndo && handleOnUndo}
                    ></img>
                  </div>
                </li>
                {/* <div className="vl"></div> */}
                <li className="linkBorder ">
                  <div
                    // className="img-with-text editorRightImage"
                    className={
                      listForReDo.length > 0
                        ? "img-fluid "
                        : "img-fluid  OpacityLess"
                    }
                    // onClick={listForReDo.length > 0 && handleRedo}
                  >
                    <div className="headerIconBlock2">
                      <img
                        src={RedoIcon}
                        alt=""
                        name="undoIcon"
                        className="undoIcon"
                        // onClick={enableUndo && handleOnUndo}
                      ></img>
                    </div>
                  </div>
                </li>
                {/* <div className="vl2 "></div> */}
                <li className="linkBorderzoom">
                  <div className="zoomDiv">
                    <div className="d-flex zoomInOut">
                      <div className="zoomOut" onClick={handleZoomOut}>
                        <RemoveIcon />
                      </div>

                      <div className="zoomPercentage">
                        {canvasDimension.zoomValue}%
                      </div>
                      <div className="zoomIn" onClick={handleZoomIn}>
                        <AddIcon />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <ul className="rightTopThreeIcons">
                    <li>
                      <div
                        className="img-with-text text-center editorRightImage"
                        onClick={() => handlePreview()}
                      >
                        <div className="headerIconBlock2">
                          <img
                            src={PreviewIcon}
                            alt=""
                            className="img-fluid mb-0 previewIcon"
                          ></img>
                        </div>
                        <div className="headerTextTempData2">
                          <p>{t("editorHeader.preview")}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div
                        className="img-with-text text-center editorRightImage"
                        onClick={() => openInfoDialog()}
                      >
                        <div className="headerIconBlock2">
                          <img
                            src={BlueSquareInfoIcon}
                            alt=""
                            className="img-fluid mb-0 previewIcon"
                          ></img>
                        </div>
                        <div className="headerTextTempData2">
                          <p>{t("Info")}</p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        type="button"
                        className="publishvvpmBtn editorRightImage"
                        onClick={handlePublishDropdown}
                        // onClick={handleVeevaIntegration}
                      >
                        {t("PUBLISH ")}
                        {open ? (
                          <img
                            src={PublishIcon}
                            alt=""
                            className="publishIcon "
                            style={{ transform: "rotateX(180deg)" }}
                          ></img>
                        ) : (
                          <img
                            src={PublishIcon}
                            alt=""
                            className="publishIcon"
                          ></img>
                        )}
                      </Button>
                      <Menu
                        id="basic-menu"
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorEl={openPublishDropdown}
                        open={open}
                        onClose={handleClose}
                        class="dropdownPublish"
                        PaperProps={{
                          style: {
                            position: "absolute",
                            //  right: "28px",
                            width: "180px",
                            height: "auto",

                            borderRadius: "4px",
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
                            backgroundColor: "#fff",
                          },
                        }}
                        //   TransitionComponent={Fade}
                      >
                        <MenuItem
                          disabled={!pdfHtmlEnabled.pdfEnabled}
                          onClick={(e) => handleVeevaIntegration(e, "pdf", {})}
                          className={pdfHtmlEnabled.pdfEnabled && "enable"}
                        >
                          {t("PDF")}
                        </MenuItem>
                        {!pdfHtmlEnabled.pdfEnabled && (
                          <div className="d-flex htmlInfo">
                            <img
                              src={InfoIcon}
                              alt=""
                              className="img-fluid htmlinfoIcon"
                            />
                            {t(`Publish disable for approved ${showAssettype}`)}
                          </div>
                        )}
                        <MenuItem
                          disabled={!pdfHtmlEnabled.htmlEnabled}
                          className={pdfHtmlEnabled.htmlEnabled && "enable"}
                          onClick={(e) => publishHTML(e)}
                        >
                          {t("HTML")}
                        </MenuItem>
                        {""}
                        {!pdfHtmlEnabled.htmlEnabled && (
                          <div className="d-flex htmlInfo">
                            <img
                              src={InfoIcon}
                              alt=""
                              className="img-fluid htmlinfoIcon"
                            />
                            {!pdfHtmlEnabled.htmlEnabled
                              ? pdfHtmlEnabled.pdfEnabled
                                ? t("Will be activated after MLR approval")
                                : t(
                                    `Publish disable for approved ${showAssettype}`
                                  )
                              : ""}
                          </div>
                        )}
                        <MenuItem disabled>{t("Google Click Studio")}</MenuItem>
                      </Menu>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {openInfoDetails && (
          <InfoDetailsDialog
            setOpenInfoDetails={setOpenInfoDetails}
            showAssettype={showAssettype}
            toggleAnimatedBanner={toggleAnimatedBanner}
            bannerTemplateDetails={bannerTemplateDetails}
            setLoading={setLoading}
            loading={loading}
            patchCall={patchCall}
            setShowBannerName={setShowBannerName}
          />
        )}
        {showExport && (
          <ExportModal
            showBasedOn={showExport}
            setLoading={setLoading}
            setExport={setExport}
            exportType={exportType}
            checkExportType={checkExportType}
            setCheckExportType={setCheckExportType}
            rvcvTypes={rvcvTypes}
            setRvcvTypes={setRvcvTypes}
            toggleAnimatedBanner={toggleAnimatedBanner}
          />
        )}
        {loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress size="30px" className="customLoader" />{" "}
          </>
        )}
        {showPreview && (
          <PreviewModal
            previewURl={previewURl}
            setPreview={setPreView}
            showPreview={showPreview}
            setLoading={setLoading}
          />
        )}
      </div>
    </>
  );
}
