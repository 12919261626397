import React, { useState, useContext, useEffect, useRef } from "react";

import { Dialog, Divider } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import copyIcon from "../../images/copyIcon.svg";
import refreshMLR from "../../images/TopRightCardIcons/refreshMLR.svg";
import editIcon from "../../images/editIcon.svg";
import { useTranslation } from "react-i18next";
import errComponentToRender from "../../Uitls/apiErrorHandling";
import Tooltip from "@material-ui/core/Tooltip";
import { TextField } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

import "../../styles/pages/Editor.scss";
import DropDownWithLabelTemplate from "../../components/DropDownWithLabelTemplate.js";
import DropDownWithSearchTemplate from "../../components/DropDownWithSearchTemplate.js";

import ValueContext from "../../ContextAPi/appContext";
import { toast } from "react-toastify";

import "./InfoDetailsDialog.scss";

import {
  convertTimestamp,
  showCreatedOntime,
  formatNumber,
  nameValidation,
  inlineNameValidation,
} from "../../Uitls/Util.js";

import {
  getBrandsAndCampaigns,
  getNewBrandsAndcampaigns,
  // getSaveAssets,
  // searchModules
  getCognitoSession,
  getCognitoIndentityCredentials,
  getMLRstatus,
  getbannerandTemplateDetails,
  getVeevaApprovedStatus,
  modifyAsset,
} from "../../services/apis";
import { isoCountryTableName, awsRegion } from "../../Uitls/Util";
import CustomDropDownInfoModal from "./CustomDropDownInfoModal";

function InfoDetailsDialog(props) {
  console.log(props, "Propsss");
  const { t } = useTranslation();
  const [marketDataSource, setMarketDataSource] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState(undefined);
  const [genericInfo, setGenericInfo] = useState({
    brand: "",
    campaign: "",
    market: "",
    //tagName: "",
    assetName: "",
    campaignId: "",
    marketCode: "",
  });
  const [brandsAndCampaigns, setBrandsAndCampaigns] = useState([]);
  const [edit, setEdit] = useState(false);

  const [mLRLatestStatus, setMLRLatestStatus] = useState(
    props.bannerTemplateDetails.reviewStatus
  );
  const [tobeUpdated, setTobeUpdated] = useState({
    assetName: false,
    market: false,
    campaign: false,
  });
  const [modified, setModified] = useState({
    modifiedBy: props.bannerTemplateDetails.updatedByName,
    modifiedOn: props.bannerTemplateDetails.updatedDateTime,
  });

  const appData = useContext(ValueContext);

  const categoriesErr = (value) => {
    toast.error(value, { position: toast.POSITION.TOP_RIGHT });
  };

  const updateNow = (nameRet, fieldName) => {
    let otherField = false;
    for (let x in tobeUpdated) {
      if (x !== fieldName) {
        if (tobeUpdated[x] !== false) otherField = true;
      }
    }
    setCanUpdate(otherField ? true : nameRet.valid);
  };
  const updateDetails = () => {
    props.setLoading(true);
    setTobeUpdated({
      assetName: false,
      market: false,
      campaign: false,
    });
    setCanUpdate(false);
    let objTobeUpdated = {};
    // tobeUpdated.forEach((field) => {
    //   objTobeUpdated[field] = genericInfo[field];
    // });
    for (let x in tobeUpdated) {
      if (tobeUpdated[x]) {
        if (x !== "market") objTobeUpdated[x] = genericInfo[x];
        if (x === "market") {
          objTobeUpdated["marketName"] = genericInfo["market"];
          objTobeUpdated["marketCode"] = genericInfo["marketCode"];
        }
        if (x === "campaign")
          objTobeUpdated["campaignId"] = genericInfo["campaignId"];
      }
    }

    modifyAsset(
      appData.assetInfo.assetId,
      objTobeUpdated,
      (response, err, errorCode) => {
        if (err) {
          const errCoponent = errComponentToRender(errorCode);
          if (err.response?.data?.errorCode === "ERR_CS_ASSETNAME_EXISTS") {
            toast.error(
              <div className="notificationText">
                {t("apiErrors.errSaveBannerExist")}
              </div>
            );
          } else {
            // toast.error(<div className="notificationText">{t("apiErrors.err500")}</div>)
            categoriesErr(errCoponent);
          }
          props.setLoading(false);
        } else {
          toast.success(
            <div className="notificationText">
              {t("Detail updated successfully")}
            </div>
          );
          appData.setAssetInfo({
            ...appData.assetInfo,
            assetName: assetNameEdit,
          });
          props.setShowBannerName(assetNameEdit);
          appData.updateGenericInfo({
            ...appData.genericInfo,
            ...objTobeUpdated,
          });
          if (objTobeUpdated?.marketCode)
            appData.setMarketCode(genericInfo.marketCode);
          props.setLoading(false);
          setModified({
            modifiedBy: response.data.updatedByName,
            modifiedOn: response.data.updatedDateTime,
          });
        }
      }
    );
  };
  var dynamo = require("dynamodb");

  useEffect(() => {
    fetchBrandsAndCampaigns();
    fetchMarketData();
    getCampaignListToPopulate(appData?.genericInfo?.brand);
    appData?.genericInfo?.brand &&
      setGenericInfo({
        ...genericInfo,
        campaign: appData?.genericInfo?.campaign,
      });
  }, []);

  React.useEffect(() => {
    setAssetNameEdit(appData.assetInfo?.assetName);
    setGenericInfo({
      ...genericInfo,
      campaign: appData?.genericInfo?.campaign,
    });
  }, [appData?.genericInfo]);

  const fetchBrandsAndCampaigns = () => {
    props.setLoading(true);
    getNewBrandsAndcampaigns((response, err, errCode) => {
      if (err) {
        const errCoponent = errComponentToRender(errCode);
        toast.error(errCoponent);
      } else {
        //parse and save
        const data = response.data;
        if (data && data.length > 0) {
          setBrandsAndCampaigns(data);
        }
      }
    });
  };
  const getCampaignListToPopulate = (brand) => {
    if (brand && brand !== "— Not Brand Specific —") {
      const campaigns = brandsAndCampaigns?.filter((object) => {
        return object.brandName === brand;
      })[0]?.campaigns;

      if (campaigns && campaigns.length > 0) {
        const desiredList = campaigns?.map((campaign) => {
          return campaign["campaignName"];
        });
        if (desiredList && desiredList.length > 0) {
          return desiredList;
        }
      }
    }

    return [];
  };
  const handleChangeInfoData = (e, origin) => {
    const { name, value } =
      origin === "custom" ? { value: e, name: "campaign" } : e.target;
    setGenericInfo({ ...genericInfo, [name]: value });
    let brandId = "";
    if (brandsAndCampaigns) {
      brandsAndCampaigns.map((brand) => {
        if (brand.brandName === value) {
          // appData.setBrandId(brand.brandId);
          brandId = brand.brandId;

          return;
        }
        brand.campaigns.map((campaign) => {
          if (campaign.campaignName === value)
            setGenericInfo({
              ...genericInfo,
              brandId: brand.brandId,
              [name]: value,
              campaignId: campaign.campaignId,
            });

          // appData.setCampaignId(campaign.campaignId);

          return;
        });
      });
      if (value !== appData.genericInfo.campaign) {
        setTobeUpdated({ ...tobeUpdated, campaign: true });
        updateNow({ valid: true }, "campaign");
      } else {
        setTobeUpdated({ ...tobeUpdated, campaign: false });
        updateNow({ valid: false }, "campaign");
      }

      // setTobeUpdated([...tobeUpdated, "campaign", "campaignId", "brandId"]);
    }
    // let obj = { ...appData?.genericInfo, [name]: value, rightUpdaed: true };
    // appData.updateGenericInfo(obj);
  };

  const fetchMarketData = () => {
    props.setLoading(true);
    getCognitoSession((session) => {
      if (session) {
        getCognitoIndentityCredentials(session, dynamo.AWS, (credentials) => {
          if (!credentials) {
            return;
          }
          var params = {
            TableName: isoCountryTableName,
          };
          var docClient = new dynamo.AWS.DynamoDB.DocumentClient({
            region: awsRegion,
          });
          docClient.scan(params, function (err, data) {
            if (data) {
              let marketData = data.Items;
              if (marketData && marketData.length > 0) {
                marketData = [...marketData].map((market) => {
                  let object = {
                    code: market.countryCode,
                    shortName: market.ShortName,
                    name: market.Name,
                  };
                  object["displayName"] = `${object.code} - ${object.name}`;
                  return object;
                });
                if (marketData && marketData.length > 0) {
                  marketData = [...marketData].sort((marketOne, marketTwo) => {
                    if (marketOne.name < marketTwo.name) {
                      return -1;
                    }
                    if (marketOne.name > marketTwo.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setMarketDataSource(marketData);

                  props.setLoading(false);
                }
              }
            }
          });
        });
      }
    });
  };

  const handleMarketChange = (market) => {
    if (market === "clearIcon") return;
    if (market) {
      setSelectedMarket(market);
      setGenericInfo({
        ...genericInfo,
        market: market.name,
        marketCode: market.code,
      });
      if (market.name !== appData.genericInfo.market) {
        setTobeUpdated({ ...tobeUpdated, market: true });
        updateNow({ valid: true }, "market");
      } else {
        setTobeUpdated({ ...tobeUpdated, market: false });
        updateNow({ valid: false }, "market");
      }
      // appData.updateGenericInfo({
      //   ...appData?.genericInfo,
      //   market: market.name,
      //   rightUpdaed: true,
      // });
      // appData.setMarketCode(market.code);
    }
  };

  const [assetNameEdit, setAssetNameEdit] = useState(
    appData.assetInfo.assetName
  );
  const [inValidInput, setInValidInput] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setAssetNameEdit(appData.assetInfo.assetName);
    appData.updateGenericInfo({ ...appData?.genericInfo, rightUpdaed: false });
  }, []);

  useEffect(() => {
    getLatestMLRStatus("useEffect");
  }, []);

  // const handleTagChange = (e) => {
  //   const { name, value } = e.target;
  //   let obj = appData?.genericInfo;
  //   //let obj= appData && appData?.genericInfo;
  //   obj["tagName"] = value;
  //   appData.updateGenericInfo({
  //     ...appData?.genericInfo,
  //     [name]: value,
  //     rightUpdaed: true,
  //   });
  // };

  const handleChangeInfoDataBlur = (e) => {
    setInValidInput(false);
    if (inValidInput || appData.assetInfo.assetName === assetNameEdit.trim()) {
      setAssetNameEdit(appData.assetInfo.assetName);
      setTobeUpdated({ ...tobeUpdated, assetName: false });
      updateNow(
        {
          valid: false,
        },
        "assetName"
      );
      return;
    }
    setAssetNameEdit(assetNameEdit.trim());
    setGenericInfo({
      ...genericInfo,
      assetName: assetNameEdit.trim(),
    });
    setTobeUpdated({ ...tobeUpdated, assetName: true });
  };

  const handleEnterKeypress = (event) => {
    if (event.key === "Enter") {
      setEdit(false);
      handleChangeInfoDataBlur(event);
      return;
    }
  };

  const handleInputChange = (event) => {
    setTobeUpdated({ ...tobeUpdated, assetName: true });
    let retValue = inlineNameValidation(event.target.value);
    setErrorMessage(
      !retValue?.valid && retValue?.message !== "Not allowed"
        ? retValue?.message
        : ""
    );
    if (retValue?.message === "Not allowed") return;
    if (retValue?.message?.includes("length")) {
      setInValidInput(!retValue.valid);
      return;
    }
    updateNow(retValue, "assetName");
    setInValidInput(!retValue.valid);

    setAssetNameEdit(event.target.value);
  };
  const dropdownWidth = useRef(null);

  const getSelectedMarket = () => {
    if (!selectedMarket && appData && appData?.genericInfo?.market) {
      const countryName = appData?.genericInfo?.market;
      const countryCode = appData.marketCode;
      const displayName = `${countryCode} - ${countryName}`;
      return { name: countryName, code: countryCode, displayName: displayName };
    }
    return selectedMarket;
  };
  const copyToClipBoard = () => {
    navigator.clipboard.writeText(window.location.href);
  };
  const getLatestMLRStatus = (origin) => {
    if (origin !== "useEffect") props.setLoading(true);
    getMLRstatus(
      appData.assetInfo.assetId,
      async (response, err, errorCode) => {
        if (err) {
          // const errCoponent = errComponentToRender(errorCode);
          if (origin !== "useEffect") props.setLoading(false);
        } else {
          const data = await response.data;
          setMLRLatestStatus(data?.reviewStatus || "Not Submitted");
          if (origin !== "useEffect") props.setLoading(false);
        }
      }
    );
  };
  return (
    <div className="blurryBackground">
      <Dialog
        disableBackdropClick
        open={true}
        className={"infoDialog"}
        aria-labelledby="form-dialog-title"
      >
        {props.loading && (
          <>
            <div className="modal-backdrop fade show"> </div>
            <CircularProgress
              size="30px"
              className="customLoadermodules"
            />{" "}
          </>
        )}
        <DialogContent>
          <div className="dialogInfoContent">
            <div className="leftBox">
              <div className="innerLeftBox">
                <div className="infoheadingLeft">
                  {props.showAssettype === "Banner"
                    ? "Banner Information"
                    : "Template Information"}
                </div>

                <div className="BannerInfoRow">
                  <div className={`BannerInfoLHS`}>Name</div>
                  <div className={`BannerInfoRHS ${edit && "goUp"}`}>
                    {!edit && (
                      <img
                        style={{ cursor: "pointer" }}
                        src={editIcon}
                        alt={"Edit Icon"}
                        onClick={() => setEdit(true)}
                      />
                    )}
                    <Tooltip
                      title={assetNameEdit.length >= 20 ? assetNameEdit : ""}
                      placement="top"
                    >
                      <div className="RHSSecVal">
                        {edit ? (
                          <>
                            <Tooltip
                              title={
                                assetNameEdit.length >= 20 ? assetNameEdit : ""
                              }
                              placement="top"
                            >
                              <TextField
                                variant="standard"
                                name={
                                  props.showAssettype === "Banner"
                                    ? "bannerName"
                                    : "templateName"
                                }
                                // className="bannerNameTextfield"
                                inputProps={{
                                  // autoComplete: 'off', // disable autocomplete and autofill
                                  style: {
                                    fontSize: "12px",
                                    paddingLeft: "11px",
                                    paddingRight: "10px",
                                    marginTop: "-5px",
                                    height: "32px",
                                    fontFamily: "Nunito Sans",
                                    color: "#231f20",
                                    fontWeight: 600,
                                    display: "block",
                                    textOverflow: "ellipsis",
                                  },
                                }}
                                className={`custom-textInput ${
                                  inValidInput && "borderRed"
                                }`}
                                value={assetNameEdit}
                                onChange={handleInputChange}
                                onKeyUp={handleEnterKeypress}
                                onBlur={(e) => {
                                  setEdit(false);
                                  handleChangeInfoDataBlur(e);
                                }}
                                fullWidth={true}
                                autoComplete="off"
                              ></TextField>
                            </Tooltip>
                            {inValidInput && (
                              <div className="errormessage">{errorMessage}</div>
                            )}
                          </>
                        ) : (
                          assetNameEdit
                        )}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="BannerInfoRow">
                  <div className="BannerInfoLHS">Type</div>
                  <div className="BannerInfoRHS">
                    <div className="RHSSecVal">
                      {props.toggleAnimatedBanner ? "Animated" : "Static"}
                    </div>
                  </div>
                </div>
                <div className="BannerInfoRow">
                  <div className="BannerInfoLHS">Brand</div>
                  <div className="BannerInfoRHS">
                    <div className="RHSSecVal">
                      {appData?.genericInfo?.brand}
                    </div>
                  </div>
                </div>
                <div className="BannerInfoRow">
                  <div className="BannerInfoLHS">Banner Link</div>
                  <div className="BannerInfoRHS height">
                    <img
                      className="copyIconBlue"
                      src={copyIcon}
                      alt="copy Icon"
                      // style={{ marginTop: "-14px" }}
                      onClick={copyToClipBoard}
                    />
                    <Tooltip title={window.location.href} placement="top">
                      <div className="RHSSecVal textColor">
                        {window.location.href}
                      </div>
                    </Tooltip>
                  </div>
                </div>
                <div className="BannerInfoRow">
                  <div className="BannerInfoLHS">MLR Status</div>
                  <div className="BannerInfoRHS">
                    <img
                      src={refreshMLR}
                      alt={"Refresh MLR"}
                      width="10"
                      height="15"
                      onClick={getLatestMLRStatus}
                      style={{ cursor: "pointer" }}
                    />
                    <div className="RHSSecVal">
                      {mLRLatestStatus || "Not Submitted"}
                    </div>
                  </div>
                </div>
                <div className="BannerInfoRow ">
                  <div className="BannerInfoLHS">Campaign</div>
                  <div className="BannerInfoRHS" style={{ height: "18px" }}>
                    <div
                      className="newInputFieldTemp1 brandNm"
                      // ref={dropdownWidth}
                    >
                      {appData?.genericInfo?.brand ===
                      "— Not Brand Specific —" ? (
                        "-"
                      ) : (
                        <CustomDropDownInfoModal
                          options={{
                            // value: appData?.genericInfo?.campaign, //t("uploadAssetLabel.brand"),
                            value: {
                              displayName: genericInfo?.campaign,
                            }, //t("uploadAssetLabel.brand"),
                            name: "campaign",
                            label: "Campaign",
                            items: getCampaignListToPopulate(
                              appData?.genericInfo?.brand
                            ),
                            handleChange: handleChangeInfoData,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="BannerInfoRow newHight">
                  <div className="BannerInfoLHS">Market</div>
                  <div className="BannerInfoRHS">
                    <div
                      className="newInputFieldTemp1 brandNm"
                      // ref={dropdownWidth}
                    >
                      <CustomDropDownInfoModal
                        options={{
                          value: getSelectedMarket(),
                          name: "market",
                          label: "Market",
                          items: marketDataSource,
                          handleChange: handleMarketChange,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="lastBoxRow">
                  <div className="BannerInfoRow">
                    <div>Created by</div>
                    <div className="lastBoxRHS">
                      <div>{props.bannerTemplateDetails.createdByName}</div>
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Created on</div>
                    <div className="lastBoxRHS">
                      <div>
                        {showCreatedOntime(
                          props.bannerTemplateDetails.createdDateTime
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Last modified by</div>
                    <div className="lastBoxRHS">
                      <div>{modified.modifiedBy}</div>
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Last modified on</div>
                    <div className="lastBoxRHS">
                      <div>{convertTimestamp(modified.modifiedOn)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rightBox"
              style={{
                height: props.showAssettype !== "Banner" ? "109px" : "247px",
              }}
            >
              <div className="infoheadingLeft">
                {props.showAssettype === "Banner"
                  ? "Source Template Information"
                  : "Other Information"}
              </div>
              {props.showAssettype === "Banner" ? (
                <>
                  <div className="BannerInfoRow">
                    <div>Name</div>
                    <div className={"rightBoxRHS"}>
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.assetName || "-"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Brand</div>
                    <div className="rightBoxRHS">
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.brandName || "-"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Market</div>
                    <div className="rightBoxRHS">
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.marketName || "-"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Campaign</div>
                    <div className="rightBoxRHS">
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.campaign || "-"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Created by</div>
                    <div className="rightBoxRHS">
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.cratedByName || "-"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>Created on</div>
                    <div className="rightBoxRHS">
                      {props?.bannerTemplateDetails?.sourceTemplateInfo
                        ?.createdDateTime
                        ? showCreatedOntime(
                            props?.bannerTemplateDetails?.sourceTemplateInfo
                              ?.createdDateTime
                          )
                        : "-"}
                    </div>
                  </div>
                </>
              ) : (
                <div className="templateDetails">
                  <div className="BannerInfoRow">
                    <div>Banner created</div>
                    <div
                      className={`rightBoxRHS ${
                        props.showAssettype === "Template" && "smallBox"
                      }`}
                    >
                      {props?.bannerTemplateDetails?.isBannerCreated
                        ? "Yes"
                        : "No"}
                    </div>
                  </div>
                  <div className="BannerInfoRow">
                    <div>No. of Banner created</div>
                    <div
                      className={`rightBoxRHS ${
                        props.showAssettype === "Template" && "smallBox"
                      }`}
                    >
                      {props?.bannerTemplateDetails?.bannerCount || "-"}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <Divider className="DialogFooterLine"></Divider>
        <DialogActions className="infoSDialog">
          <div className="footerActions">
            <Button
              className="closeButtn"
              onClick={() => props.setOpenInfoDetails(false)}
            >
              Close
            </Button>
            <Button
              disabled={!canUpdate}
              className={canUpdate ? "updateButton" : "disbledUpdate"}
              onClick={() => {
                canUpdate && updateDetails();
              }}
            >
              Update
            </Button>
          </div>
          <Divider className="DialogFooterLine"></Divider>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default InfoDetailsDialog;
