import React, { useState, useEffect } from "react";
import { InputLabel } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import "../styles/components/DropDownWithLabel.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import CheckIcon from "@material-ui/icons/Check";
//import { Tooltip } from "bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
const iconComponent = (props) => {
  return <ExpandMoreIcon className={props.className} />;
};

const CampaignDropdownWithLabel = (props) => {
  const [options, setOptions] = useState([]);
  const [animationCombination, setAnimationCombination] = useState({
    "Left-to-right": [
      "Left-to-right",
      "Right-to-left",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Right-to-left": [
      "Left-to-right",
      "Right-to-left",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Top-to-bottom": [
      "Top-to-bottom",
      "Bottom-to-top",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Bottom-to-top": [
      "Top-to-bottom",
      "Bottom-to-top",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Slide-up": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
    ],
    "Slide-down": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
    ],
    "Zoom-in": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Zoom-out": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
      "Slide-up",
      "Slide-down",
    ],
    "Fade-in": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
      "Fade-in",
      "Slide-up",
      "Slide-down",
    ],
    "Fade-out": [
      "Left-to-right",
      "Right-to-left",
      "Top-to-bottom",
      "Bottom-to-top",
      "Slide-up",
      "Slide-down",
      "Zoom-in",
      "Zoom-out",
      "Fade-out",
      "Slide-up",
      "Slide-down",
    ],
    "Axial-rotation": [
      "Zoom-in",
      "Zoom-out",
      "Axial-rotation",
      "Slide-up",
      "Slide-down",
    ],
    filtered: [],
  });
  useEffect(() => {
    setOptions(props.options);
  }, [props]);

  useEffect(() => {
    let filtered = [];
    if (props.tick?.length > 0) {
      let selectedAnimations = props.tick?.filter(
        (animation) => animation !== undefined
      );
      let mixed = [];
      if (selectedAnimations.length === 1) {
        filtered = animationCombination[selectedAnimations[0].animationType];
      } else if (selectedAnimations.length > 0) {
        selectedAnimations.forEach((animation) => {
          for (let property in animationCombination) {
            if (property === animation.animationType)
              mixed = [...animationCombination[property], ...mixed];
          }
        });
        // mixed = [...animationCombination.filtered, ...animationCombination[selectedAnimations[selectedAnimations.length -1].animationType]];
        filtered = [...new Set(mixed)];
      }
      setAnimationCombination({ ...animationCombination, filtered });
    } else if (animationCombination.filtered.length > 0) {
      filtered = [];
      setAnimationCombination({ ...animationCombination, filtered });
    }
  }, [props.tick]);

  const handleDisable = (optionName) => {
    // const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false;
    // setDisabled(prevState=>{ prevState === true ? true :disabled});
  };

  const getValue = () => {
    if (
      options &&
      options.items &&
      options.items.length > 0 &&
      options.value &&
      options.items.indexOf(options.value) !== -1
    ) {
      return options.value;
    }
    return "";
  };
  console.log("options", options.name);
  return (
    <FormControl
      className="drop-down-with-label noWrapHidden"
      variant="outlined"
      fullWidth={true}
    >
      <InputLabel htmlFor={options.label}>{options.label}</InputLabel>
      {options.name === "campaign" ? (
        <Tooltip
          title={options?.value?.length >= 10 ? getValue() : ""}
          placement="top"
        >
          <Select
            value={getValue()}
            onOpen={options.onOpen}
            onChange={options.handleChange}
            disabled={options.disabled}
            label={options.label}
            name={options.name}
            id={options.name}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              PaperProps: {
                style: {
                  maxWidth: props.menuWidth,
                },
              },
              getContentAnchorEl: null,
            }}
            IconComponent={iconComponent}
            fullWidth={true}
          >
            {options.items &&
              options.items.length > 0 &&
              options.items.map((item, index) => {
                {
                  /* const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false; */
                }
                return (
                  <MenuItem
                    disabled={
                      animationCombination.filtered.length > 0 &&
                      animationCombination.filtered.some(
                        (type) => type === item
                      )
                        ? true
                        : false
                    }
                    value={item}
                    id={item}
                    key={item}
                  >
                    {/*   <div className="menuItemAnimation"> */}
                    {item}{" "}
                    {props.tick?.some(
                      (animation) =>
                        animation !== undefined &&
                        animation.animationType === item
                    ) ? (
                      <CheckIcon style={{ color: "#00b10a", fontSize: 15 }} />
                    ) : (
                      ""
                    )}
                    {/*   </div> */}
                  </MenuItem>
                );
              })}
          </Select>
        </Tooltip>
      ) : (
        <Select
          value={getValue()}
          onOpen={options.onOpen}
          onChange={options.handleChange}
          disabled={options.disabled}
          label={options.label}
          name={options.name}
          id={options.name}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxWidth: props.menuWidth,
              },
            },
            getContentAnchorEl: null,
          }}
          IconComponent={iconComponent}
          fullWidth={true}
        >
          {options.items &&
            options.items.length > 0 &&
            options.items.map((item, index) => {
              {
                /* const disabled = (props.tick?.length >0 && props.tick?.some( animation=> animation !== undefined && animation.animationType === options.value  ) && !animationCombination[options.value]?.includes(item) )? true: false; */
              }
              return (
                <MenuItem
                  disabled={
                    animationCombination.filtered.length > 0 &&
                    animationCombination.filtered.some((type) => type === item)
                      ? true
                      : false
                  }
                  value={item}
                  id={item}
                  key={item}
                  className={
                    item === "— Not Brand Specific —" ? "notbranded" : ""
                  }
                >
                  {/*   <div className="menuItemAnimation"> */}
                  {item}{" "}
                  {props.tick?.some(
                    (animation) =>
                      animation !== undefined &&
                      animation.animationType === item
                  ) ? (
                    <CheckIcon style={{ color: "#00b10a", fontSize: 15 }} />
                  ) : (
                    ""
                  )}
                  {/*   </div> */}
                </MenuItem>
              );
            })}
        </Select>
      )}
    </FormControl>
  );
};

export default CampaignDropdownWithLabel;
