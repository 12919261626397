import React from "react";
import RecentlyViewedCard from "../BannerManager/RecentlyViewedCard";
import "../../styles/pages/Banners/BannerManager.scss";

export default React.memo(function GotoRecentlyViewed(props) {
  console.log("props++", props);
  return (
    <>
      {props.responseData.map((recentlyViewedData, index) => {
        return (
          <div className="col-md-3 recentlyCardsCol">
            <RecentlyViewedCard
              recentlyViewedData={recentlyViewedData}
              search={props.search}
              createdByMe={props.createdByMe}
              // getRecentlyViewedBanner={props.getRecentlyViewedBanner}
              removeSearchresultCard={props.removeSearchresultCard}
              setLoading={props.setLoading}
              generateRVCV={props.generateRVCV}
              disableProceedbut={props.disableProceedbut}
              setDisableProceedbut={props.setDisableProceedbut}
              selectMultipleBanner={props.selectMultipleBanner}
              setSelectMultipleBanner={props.setSelectMultipleBanner}
              arrcheckboxSelection={props.arrcheckboxSelection}
              setArrcheckboxSelection={props.setArrcheckboxSelection}
              indexResponseData={index}
              responseData={props.responseData}
              genericInfo={props.genericInfo}
              setGenericInfo={props.setGenericInfo}
              isAnimatedBanner={props.isAnimatedBanner}
              hide3dotIcon={props.hide3dotIcon}
              fetchAllTemplates={props.fetchAllTemplates}
              lockUnlockBannerAtFE={props.lockUnlockBannerAtFE}
            />
          </div>
        );
      })}
    </>
  );
});

// export default GotoRecentlyViewed
