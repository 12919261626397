import RVCV from "../Editor/RVCV";
import exportHTML from "../Editor/HtmlExport";

export default function FormDOMObj({
  finalDOMObj,
  type,
  animationObj,
  checkExportType,
  canvasDimension,
}) {
  let animationFinalObjToString = JSON.stringify(animationObj);
  let completeObj = JSON.stringify(finalDOMObj.trackHeight);
  let layerLink = "";
  let downloadType = checkExportType;
  let hrefUrl =
    canvasDimension.checkGloballink === true &&
    canvasDimension.checkGloballink !== undefined
      ? canvasDimension.globalLink
      : "javascript:void(0)";

  let typeDiff =
    downloadType === true
      ? `<a
        id="canvas-page"
        href= '${hrefUrl}'
      >
      ${finalDOMObj.DomObj}
      </a>`
      : `<div
          id="canvas-page"
          data-global="${canvasDimension.globalLink}"
          data-link="${canvasDimension.checkGloballink}"
          onClick="enableTxt(this)"
        >

          ${finalDOMObj.DomObj}

        </div>`;
  // layerCompData.forEach(
  //   (layer) =>
  //     (layerLink += `${renderAppliedFonts(layer.froalaText, true)} \n`)
  // );

  let expandClose =
    canvasDimension.width < 310
      ? `function expandBanner(id) {

           var foundEle = completeObj.find(function (element) {
                           return element.id ===id ;
                         });

        let expanHeight = document.getElementById("expand"+id).clientHeight||0;
        let heightTracK = ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4;

        gsap.to('#'+'expand'+id, 0, {display:'none' });
        gsap.to('#'+'close'+id, 0, {display:'block' });
        gsap.to('#'+id, 0.5, {top:0,height: ${canvasDimension.height}});
        gsap.to('#'+id+'ISIInfo', 0.5, {height: (${canvasDimension.height}-expanHeight)});
        gsap.to('#'+id+'viewport', 0.5, {top:0,height: (${canvasDimension.height}-expanHeight) });

        gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
        gsap.to('#'+id+'track', 0.5, {height:heightTracK, onUpdate:function test (){updateTiny(id,heightTracK);}});

    }
    function  closeBanner(id){
        var foundEle = completeObj.find(function (element) {
          return element.id ===id ;
        });
        console.log(foundEle. trackHeight,"foundElefoundEle")
        gsap.to('#'+'expand'+id, 0, {display:'block'});
        gsap.to('#'+'close'+id, 0, {display:'none'});
        gsap.to('#'+id, 0.5, {top:foundEle.compTop,height: foundEle.compHeight});
        gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight});
        gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });

        gsap.to('#scrollbar'+id, 0.5, {height:
          foundEle.compHeight -
          foundEle.arrowHeight * 2 -
          (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
        });
        console.log(foundEle)
      gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onComplete:function test (){updateTiny(id,foundEle.trackHeight);}});

      }

      function updateTiny(id, heightTracK){

        let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
         tinyscrollbar.update(0, heightTracK);
      }
      `
      : `function expandBanner(id) {

        var foundEle = completeObj.find(function (element) {
                        return element.id ===id ;
                      });

     let expanHeight = document.getElementById("expand"+id).clientHeight||0;
     let heightTracK =( ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4);

     gsap.to('#'+'expand'+id, 0, {display:'none' });
     gsap.to('#'+'close'+id, 0, {display:'block' });
     gsap.to('#'+id, 0.5, {left:0, top:0,height: ${canvasDimension.height} , width: ${canvasDimension.width}});
     gsap.to('#'+id+'ISIInfo', 0.5, {left:0, height: (${canvasDimension.height}-expanHeight), width: ${canvasDimension.width}});

     gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
     gsap.to("#"+id+"track",0.5,{height:heightTracK,onComplete: function test(){updateTiny(id,heightTracK);}});

 }

 function  closeBanner(id){
     var foundEle = completeObj.find(function (element) {
       return element.id ===id ;
     });
     console.log(foundEle,"foundElefoundEle")
     gsap.to('#'+'expand'+id, 0, {display:'block'});
     gsap.to('#'+'close'+id, 0, {display:'none'});
     gsap.to('#'+id, 0.5, {top:foundEle.compTop, left:foundEle.compLeft,height: foundEle.compHeight, width:foundEle.compWidth});
     gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight, width: foundEle.compWidth}  );
     gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });

     gsap.to('#scrollbar'+id, 0.5, {height:
       foundEle.compHeight -
       foundEle.arrowHeight * 2 -
       (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
     });
    console.log(foundEle)
   gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onUpdate:function test (){updateTiny(id,foundEle.trackHeight);}});

   }

   function updateTiny(id, heightTracK){
     let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
      tinyscrollbar.update(0, heightTracK);
   }
   `;

  layerLink = "";

  let DOMObj = {
    html:
      type === "rvcv"
        ? RVCV({
            canvasDimension,
            finalDOMObj,
            animationFinalObjToString,
            layerLink,
            completeObj,
            typeDiff,
          })
        : exportHTML({
            canvasDimension,
            finalDOMObj,
            animationFinalObjToString,
            layerLink,
            completeObj,
            typeDiff,
            expandClose,
          }),

    cssObj: {
      fileName: "Styles",
      content: "",
    },
    js: "",
    atomId: finalDOMObj.atomId,
    atomUrl: finalDOMObj.atomUrl,
    img: finalDOMObj.img,
    id: new Date().getTime(),
    fileName: canvasDimension.assetName.trim(),
  };
  return DOMObj;
}
