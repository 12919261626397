import React from "react";
import { useTranslation } from "react-i18next";
import "../../styles/components/RightPanel.scss";
import { TextField } from "@material-ui/core";
import rad1 from "../../images/rad1.svg";
import rad2 from "../../images/rad2.svg";
import rad2Grey from "../../images/rad2Grey.svg";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import DownwardArrow from "../../images/DownwardArrow.svg";
import SideArrow from "../../images/SideArrow.svg";
import InfoIcon from "../../images/iconinfo.svg";
export default function ButtonAndShape({
  selectedComponent,
  styles,
  handleClickSwatch,
  displayColorPicker,
  ColorPicker,
  handleClose,
  handleButtonColorChange,
  buttonColor,
  setDisplayColorPicker,
  handleClickSwatchBorder,
  refBorder,
  handleCloseBorder,
  handleBorderColorChange,
  borderColor,
  setDisplayColorPickerBorder,
  changeBorderStyle,
  borderStyle,
  borderStyles,
  borderWidth,
  changeBorderWidth,
  refButtonColor,
  displayColorPickerBorder,
  paddingTRBL,
  radiusTRBL,
  commonPadding,
  commonRadius,
  handlePadding,
  handleRadius,
  changeRadius,
  changePadding,
  handleChange,
  expanded,
  layerCompData,
}) {
  const { t } = useTranslation();
  // let locked = selectedComponent?.componentStyles?.lockBoolean;

  let locked = false;
  if (
    selectedComponent.componentType !== undefined &&
    layerCompData.length > 0 &&
    selectedComponent.componentType !== "default"
  )
    locked =
      layerCompData[
        layerCompData.findIndex(
          (selComp) => selComp.id === selectedComponent.id
        )
      ]?.componentStyles?.lockBoolean;

  return (
    <>
      <hr className="hrtag" />
      {/*       <div className="textProps">
        <div className="text">IMAGE</div>
      </div> */}
      <div className="accordion2 customAccordIcons1">
        <Accordion
          //  expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={
              expanded.panel5 === true ? (
                <img src={DownwardArrow} alt="" className="img-fluid" />
              ) : (
                <img src={SideArrow} alt="" className="img-fluid" />
              )
            }
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography className="accord2-summary">
              <div className="textProps">
                <div className="text">BORDER</div>
                {/* <img
                  src={InfoIcon}
                  alt=""
                  className="img-fluid pl-1 infoIcon2"
                /> */}
              </div>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="borderFll">
              <div className="bg-fill">
                <div className="swatch">
                  {
                    <div
                      style={styles.borderColor}
                      className="swatchBorder"
                      onClick={() => {
                        !locked && handleClickSwatchBorder();
                      }}
                    ></div>
                  }
                </div>
                {/* <div>
                  {!locked && displayColorPickerBorder ? (
                    <div ref={refBorder}>
                      <ColorPicker
                        id="textColor"
                        handleClose={handleCloseBorder}
                        handleColorChange={(e) => handleBorderColorChange(e)}
                        name="borderColor"
                        color={borderColor ? borderColor : ""}
                        borderColor={borderColor ? borderColor : ""}
                        setDisplayColorPickerBorder={
                          setDisplayColorPickerBorder
                        }
                      />
                    </div>
                  ) : null}
                </div> */}
                <div className="fill-color">
                  {t("editorRightPanel.propertiesTabcontent.border")}
                </div>
              </div>
              <div className="borderSizeType">
                <div className="fontSizeItems">
                  <p>Size</p>
                  <TextField
                    disabled={locked}
                    className="textFieldCustom"
                    value={borderWidth}
                    onChange={changeBorderWidth}
                    size="small"
                    inputProps={{
                      style: {
                        width: "20px",
                        marginTop: "-14px",
                        marginBottom: "-15px",
                        fontSize: "11px",
                        color: "#928989",
                        textAlign: "center",
                      },
                    }}
                  />{" "}
                </div>
                <div className="fontWeightItems select_boxbordersize ">
                  <select
                    disabled={locked}
                    className="borderDropdown"
                    onChange={changeBorderStyle}
                    value={borderStyle}
                  >
                    {borderStyles.map((borderstyleItem, id) => {
                      return (
                        <option key={id} value={borderstyleItem}>
                          {borderstyleItem}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <p className="radiusLabel">Radius</p>
              <div
                className={
                  commonRadius ? "padRadEach paddingRad" : "paddingRad"
                }
              >
                <img
                  src={rad1}
                  alt="PaddingBox"
                  className="image-fluid"
                  onClick={() => {
                    !locked && handleRadius("each");
                  }}
                />
                <img
                  src={commonRadius === true ? rad2 : rad2Grey}
                  alt="PaddingEach"
                  onClick={() => {
                    !locked && handleRadius("common");
                  }}
                  className="pl-2"
                />
                <TextField
                  disabled={locked}
                  className="textFieldCustom"
                  name="borderTopLeftRadius"
                  value={radiusTRBL.borderTopLeftRadius}
                  onChange={changeRadius}
                  onBlur={(e) => changeRadius(e, "blur")}
                  size="small"
                  inputProps={{
                    style: {
                      width: "20px",
                      marginTop: "-14px",
                      marginBottom: "-15px",
                      fontSize: "11px",
                      color: "#928989",
                      textAlign: "center",
                    },
                  }}
                />{" "}
                {!commonRadius && (
                  <>
                    <TextField
                      disabled={locked}
                      className="textFieldCustom"
                      name="borderTopRightRadius"
                      value={radiusTRBL.borderTopRightRadius}
                      onChange={changeRadius}
                      onBlur={(e) => changeRadius(e, "blur")}
                      size="small"
                      inputProps={{
                        style: {
                          width: "20px",
                          marginTop: "-14px",
                          marginBottom: "-15px",
                          fontSize: "11px",
                          color: "#928989",
                          textAlign: "center",
                        },
                      }}
                    />{" "}
                    <TextField
                      disabled={locked}
                      className="textFieldCustom"
                      name="borderBottomLeftRadius"
                      value={radiusTRBL.borderBottomLeftRadius}
                      onChange={changeRadius}
                      onBlur={(e) => changeRadius(e, "blur")}
                      size="small"
                      inputProps={{
                        style: {
                          width: "20px",
                          marginTop: "-14px",
                          marginBottom: "-15px",
                          fontSize: "11px",
                          color: "#928989",
                          textAlign: "center",
                        },
                      }}
                    />{" "}
                    <TextField
                      disabled={locked}
                      className="textFieldCustom"
                      name="borderBottomRightRadius"
                      value={radiusTRBL.borderBottomRightRadius}
                      onChange={changeRadius}
                      onBlur={(e) => changeRadius(e, "blur")}
                      size="small"
                      inputProps={{
                        style: {
                          width: "20px",
                          marginTop: "-14px",
                          marginBottom: "-15px",
                          fontSize: "11px",
                          color: "#928989",
                          textAlign: "center",
                        },
                      }}
                    />{" "}
                  </>
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <hr className="hr" />
    </>
  );
}
