export const fontFamilyItems = [
  {
    fontFamilyItem: "Nunito Sans",
  },
  {
    fontFamilyItem: "Georgia",
  },

  {
    fontFamilyItem: "Tahoma",
  },
  {
    fontFamilyItem: "Times New Roman",
  },
  {
    fontFamilyItem: "Verdana",
  },
];

export const fontWeightItems = [
  {
    fontWeightItem: "Normal",
  },
  {
    fontWeightItem: "Bold",
  },
];
export const fontSizeItems = [
  {
    fontSizeItem: 9,
  },
  {
    fontSizeItem: 10,
  },
  {
    fontSizeItem: 12,
  },
  {
    fontSizeItem: 14,
  },
  {
    fontSizeItem: 18,
  },
  {
    fontSizeItem: 24,
  },
  {
    fontSizeItem: 29,
  },
  {
    fontSizeItem: 32,
  },
  {
    fontSizeItem: 50,
  },
];
export const letterSpacingItems = [
  {
    letterSpacingItem: 0.2,
  },
  {
    letterSpacingItem: 1,
  },
  {
    letterSpacingItem: 2,
  },
  {
    letterSpacingItem: 3,
  },
  {
    letterSpacingItem: 4,
  },
];
export const lineHeightItems = [
  {
    lineHeightItem: 1,
  },
  {
    lineHeightItem: 1.25,
  },
  {
    lineHeightItem: 2,
  },
  {
    lineHeightItem: 3,
  },
  {
    lineHeightItem: 4,
  },
  {
    lineHeightItem: 5,
  },
];
export const borderStyles = [
  "Solid",
  "Dotted",
  "Dashed",
  "Double",
  "Groove",
  "Ridge",
  "Inset",
  "Outset",
  "None",
  "Hidden",
];
export const bannerSizes = [
  "300X250",
  "300X600",
  "160X600",
  "728X90",
  "970X90",
  "300X50",
  "320X50",
  //"180X60",
];
export const templateSizes = [
  //"180X160",
  "300X250",
  "300X600",
  "160X600",
  "728X90",
  "970X90",
  "300X50",
  "320X50",
];
