import React, { useContext, useState, useEffect } from "react";
import "../../styles/pages/Editor.scss";
import {
  sendExport,
  getExport,
  browseImages,
  RVCVFile,
} from "../../services/apis";
import exportHTML from "../Editor/HtmlExport";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";

import ValueContext from "../../ContextAPi/appContext";
import { toast } from "react-toastify";
import FileSaver from "file-saver";

import { downLoad } from "../Editor/DownloadHTML";

import { downLoadPdf } from "../Editor/PDFDownload";
import RVCV from "../Editor/RVCV";
import { useTranslation } from "react-i18next";
export default function EditorHeader({
  type,
  setType,
  setLoading,
  canvasDimension,
  canvasDom,
  animationObj,
  fontLink,
  checkExportType,
  setCheckExportType,
  rvcvTypes,
  setRvcvTypes,
}) {
  const appData = useContext(ValueContext);
  const t = useTranslation();
  useEffect(() => {
    exportType(type);
  }, []);

  function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
  }
  const exportType = async (type) => {
    //const formData = new FormData();
    let finalDOMObj = downLoad({
      layerCompData: canvasDom,
      assetId: appData.assetInfo.assetId,
      canvasDimension: canvasDimension,
      bgColorCanvas: canvasDimension.bgColorCanvas,
      animationObject: {},
      flag:
        checkExportType.PNG === true || checkExportType.JPG === true
          ? true
          : false,
    });

    //downLoadExport(appData.assetInfo.assetId);

    // let ImageHtml = "<html><head><style>" + finalDOMObj.CssCollect + "</style></head> <body><div class='canvas-page'>" + finalDOMObj.DomObj + "</div></body></html>";

    let DOMObj = formDOMObj(finalDOMObj);
    Object.keys(checkExportType).forEach(async (key) => {
      if (key === "HTML" && checkExportType[key] === true) {
        finalDOMObj = downLoad({
          layerCompData: canvasDom,
          assetId: appData.assetInfo.assetId,
          canvasDimension: canvasDimension,
          bgColorCanvas: canvasDimension.bgColorCanvas,
          animationObject: animationObj,
          flag:
            checkExportType.PNG === true || checkExportType.JPG === true
              ? true
              : false,
        });
        htmlDownload(DOMObj);
      } else if (key === "JPG" && checkExportType[key] === true) {
        // http://imcassetconversion-dev.ap-south-1.elasticbeanstalk.com/'
        let getstring = await fetch("https://test-imageconvert.indegene.com/", {
          method: "POST",
          body: DOMObj.html,
        });

        let cv = await getstring.json();
        //  console.log(cv, "check Image")
        var img = new Image();
        if (cv.image) {
          let rep = dataURItoBlob(cv.image);

          var file = new File([rep], `${appData.assetInfo.assetName}.jpg`, {
            type: "image/jpg",
          });
          const formDatatype = new FormData();
          formDatatype.append("fileName", file);
          pngJpegPdfDownload(key, formDatatype);
        } else {
          setLoading(false);
          toast.error(
            <div className="notificationText">
              Failed to download the JPEG file
            </div>
          );
          return;
        }
      } else if (key === "PNG" && checkExportType[key] === true) {
        // http://imcassetconversion-dev.ap-south-1.elasticbeanstalk.com/'
        let getstring = await fetch(
          "https://test-imageconvert.indegene.com/png",
          {
            method: "POST",
            body: DOMObj.html,
          }
        );

        let cv = await getstring.json();
        var img = new Image();
        if (cv.image) {
          let rep = dataURItoBlob(cv.image);

          var file = new File([rep], `${appData.assetInfo.assetName}.png`, {
            type: "image/png",
          });
          const formDatatype = new FormData();
          formDatatype.append("fileName", file);
          pngJpegPdfDownload(key, formDatatype);
        } else {
          setLoading(false);
          toast.error(
            <div className="notificationText">
              Failed to download the PNG file
            </div>
          );
          return;
        }
      } else if (key === "PDF" && checkExportType[key] === true) {
        let finalPdf = downLoadPdf(
          canvasDom,
          appData.assetInfo.assetId,
          canvasDimension,
          canvasDimension.bgColorCanvas,
          {}
        );
        const formDatatype = new FormData();
        DOMObj = formDOMObj(finalPdf);
        let y = await fetch("https://test-imageconvert.indegene.com/pdf", {
          method: "POST",

          body: DOMObj.html,
        });
        let cv = await y.blob();
        if (y.status === 200) {
          var blob = new Blob([cv], { type: "application/pdf" });

          var file = new File([blob], `${appData.assetInfo.assetName}.pdf`, {
            type: "application/pdf",
          });
          formDatatype.append("fileName", file);

          pngJpegPdfDownload("pdf", formDatatype);
        } else {
          setLoading(false);

          toast.error(
            <div className="notificationText">
              Failed to download the PDF file
            </div>
          );
          return;
        }
      } else if (key === "RVCVFILE" && checkExportType[key] === true) {
        setLoading(false);
        toast.success(
          <div className="notificationText">
            Please click on the link sent to you via email to download the RVCV
            images.
          </div>
        );
        finalDOMObj = downLoad({
          layerCompData: canvasDom,
          assetId: appData.assetInfo.assetId,
          canvasDimension: canvasDimension,
          bgColorCanvas: canvasDimension.bgColorCanvas,
          animationObject: animationObj,
          flag: true,
        });

        let DOMObj = formDOMObj(finalDOMObj, "rvcv");
        let obj = {
          page: DOMObj.html,
          rvcv:
            rvcvTypes.value2X === true ? 2 : rvcvTypes.value3X === true ? 3 : 1,
          name: appData.assetInfo.assetName,
          emailID: localStorage.getItem("RVCV-email"),
          currentUserName: localStorage.getItem("currentUserName"),
        };

        let getstring = await fetch(
          "https://test-imageconvert.indegene.com/RVCV",
          {
            method: "POST",
            body: JSON.stringify(obj),
          }
        );

        let cv = await getstring;

        if (cv.status === 200) {
        } else {
          const errCoponent = ErrComponentToRender(cv.status);
          toast.error(errCoponent);
          setLoading(false);
        }
      }
    });

    setType(false);
    setCheckExportType({
      ...checkExportType,
      PDF: false,
      HTML: false,
      PNG: false,
      JPG: false,
      RVCVFILE: false,
    });
    setRvcvTypes({
      ...rvcvTypes,
      value2X: false,
      value3X: false,
    });
  };
  const pngJpegPdfDownload = (type, formData) => {
    browseImages(formData, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);
        setLoading(false);
      } else {
        if (response.data.discription) {
          FileSaver.saveAs(
            response.data.discription,
            `${appData.assetInfo.assetName}.${type}`
          );
          setLoading(false);
        }
      }
    });
  };
  function htmlDownload(DOMObj) {
    sendExport(DOMObj, (response, err, errorCode) => {
      if (err) {
        const errCoponent = ErrComponentToRender(errorCode);

        toast(errCoponent);
      } else {
        getExport(
          randomNumber,
          appData.assetInfo.assetName,
          (response, err, errorCode) => {
            if (err) {
              const errCoponent = ErrComponentToRender(errorCode);
            } else {
              const downloadUrl = window.URL.createObjectURL(
                new Blob([response.data])
              );

              const link = document.createElement("a");

              link.href = downloadUrl;

              link.setAttribute(
                "download",
                appData.assetInfo.assetName + ".zip"
              ); //any other extension

              document.body.appendChild(link);

              link.click();

              link.remove();
            }
          }
        );
      }
    });
    setLoading(false);
  }

  let randomNumber = new Date().getTime();
  function formDOMObj(finalDOMObj, rvcv) {
    let animationFinalObjToString = JSON.stringify(animationObj);
    let completeObj = JSON.stringify(finalDOMObj.trackHeight);
    let layerLink = "";
    let downloadType = checkExportType.PDF;
    let hrefUrl =
      canvasDimension.checkGloballink === true &&
      canvasDimension.checkGloballink !== undefined
        ? canvasDimension.globalLink
        : "javascript:void(0)";

    let typeDiff =
      downloadType === true
        ? `<a
        id="canvas-page"
        href= '${hrefUrl}'
      >
      ${finalDOMObj.DomObj}
      </a>`
        : `<div
          id="canvas-page"
          data-global="${canvasDimension.globalLink}"
          data-link="${canvasDimension.checkGloballink}"
          onClick="enableTxt(this)"
        >

          ${finalDOMObj.DomObj}

        </div>`;
    // layerCompData.forEach(
    //   (layer) =>
    //     (layerLink += `${renderAppliedFonts(layer.froalaText, true)} \n`)
    // );

    let expandClose =
      canvasDimension.width < 310
        ? `function expandBanner(id) {

           var foundEle = completeObj.find(function (element) {
                           return element.id ===id ;
                         });

        let expanHeight = document.getElementById("expand"+id).clientHeight||0;
        let heightTracK = ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4;

        gsap.to('#'+'expand'+id, 0, {display:'none' });
        gsap.to('#'+'close'+id, 0, {display:'block' });
        gsap.to('#'+id, 0.5, {top:0,height: ${canvasDimension.height}});
        gsap.to('#'+id+'ISIInfo', 0.5, {height: (${canvasDimension.height}-expanHeight)});
        gsap.to('#'+id+'viewport', 0.5, {top:0,height: (${canvasDimension.height}-expanHeight) });
       
        gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
        gsap.to('#'+id+'track', 0.5, {height:heightTracK, onUpdate:function test (){updateTiny(id,heightTracK);}});
    
    }
    function  closeBanner(id){
        var foundEle = completeObj.find(function (element) {
          return element.id ===id ;
        });
        console.log(foundEle. trackHeight,"foundElefoundEle")
        gsap.to('#'+'expand'+id, 0, {display:'block'});
        gsap.to('#'+'close'+id, 0, {display:'none'});
        gsap.to('#'+id, 0.5, {top:foundEle.compTop,height: foundEle.compHeight});
        gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight});
        gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });
      
        gsap.to('#scrollbar'+id, 0.5, {height:
          foundEle.compHeight -
          foundEle.arrowHeight * 2 -
          (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
        });
        console.log(foundEle)
      gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onComplete:function test (){updateTiny(id,foundEle.trackHeight);}});

      }
      
      function updateTiny(id, heightTracK){

        let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
         tinyscrollbar.update(0, heightTracK);
      }
      `
        : `function expandBanner(id) {

        var foundEle = completeObj.find(function (element) {
                        return element.id ===id ;
                      });

     let expanHeight = document.getElementById("expand"+id).clientHeight||0;
     let heightTracK =( ${canvasDimension.height}-expanHeight-foundEle.arrowHeight*2-4);

     gsap.to('#'+'expand'+id, 0, {display:'none' });
     gsap.to('#'+'close'+id, 0, {display:'block' });
     gsap.to('#'+id, 0.5, {left:0, top:0,height: ${canvasDimension.height} , width: ${canvasDimension.width}});
     gsap.to('#'+id+'ISIInfo', 0.5, {left:0, height: (${canvasDimension.height}-expanHeight), width: ${canvasDimension.width}});
    
     gsap.to('#scrollbar'+id, 0.5, {height:heightTracK});
     gsap.to("#"+id+"track",0.5,{height:heightTracK,onComplete: function test(){updateTiny(id,heightTracK);}});
 
 }

 function  closeBanner(id){
     var foundEle = completeObj.find(function (element) {
       return element.id ===id ;
     });
     console.log(foundEle,"foundElefoundEle")
     gsap.to('#'+'expand'+id, 0, {display:'block'});
     gsap.to('#'+'close'+id, 0, {display:'none'});
     gsap.to('#'+id, 0.5, {top:foundEle.compTop, left:foundEle.compLeft,height: foundEle.compHeight, width:foundEle.compWidth});
     gsap.to('#'+id+'ISIInfo', 0.5, {height: foundEle.compHeight + (foundEle.arrowHeight === 0 ? 0 : 2)-foundEle.expandHeight, width: foundEle.compWidth}  );
     gsap.to('#'+id+'viewport', 0.5, {top:0,height: foundEle.compHeight });
   
     gsap.to('#scrollbar'+id, 0.5, {height:
       foundEle.compHeight -
       foundEle.arrowHeight * 2 -
       (foundEle.arrowHeight == 0 ? 0 : 2)-foundEle.expandHeight
     });
    console.log(foundEle)
   gsap.to('#'+id+'track', 0.5, {height:foundEle.trackHeight, onUpdate:function test (){updateTiny(id,foundEle.trackHeight);}});

   }
   
   function updateTiny(id, heightTracK){
     let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");
      tinyscrollbar.update(0, heightTracK);
   }
   `;

    layerLink = fontLink;

    let DOMObj = {
      html:
        rvcv === "rvcv"
          ? RVCV({
              canvasDimension,
              finalDOMObj,
              animationFinalObjToString,
              layerLink,
              completeObj,
              typeDiff,
            })
          : exportHTML({
              canvasDimension,
              finalDOMObj,
              animationFinalObjToString,
              layerLink,
              completeObj,
              typeDiff,
              expandClose,
            }),

      cssObj: {
        fileName: "Styles",
        content: "",
      },
      js: "",
      atomId: finalDOMObj.atomId,
      atomUrl: finalDOMObj.atomUrl,
      img: finalDOMObj.img,
      id: randomNumber,
      fileName: appData.assetInfo.assetName.trim(), //put assetname here
    };
    return DOMObj;
  }
  // function formDOMObj(finalDOMObj) {
  //   let animationFinalObjToString = JSON.stringify(animationObj);
  //   let completeObj = JSON.stringify(finalDOMObj.trackHeight);
  //   let downloadType = checkExportType.PDF;

  //   let hrefUrl =
  //     canvasDimension.checkGloballink === true &&
  //     canvasDimension.checkGloballink !== undefined
  //       ? canvasDimension.globalLink
  //       : "javascript:void(0)";

  //   let typeDiff =
  //     downloadType === true
  //       ? `<a
  //         id="canvas-page"
  //         href= '${hrefUrl}'
  //       >
  //       ${finalDOMObj.DomObj}
  //       </a>`
  //       : `<div
  //         id="canvas-page"
  //         data-global="${canvasDimension.globalLink}"
  //         data-link="${canvasDimension.checkGloballink}"
  //         onClick="enableTxt(this)"
  //       >

  //         ${finalDOMObj.DomObj}

  //       </div>`;
  //   let DOMObj = {
  //     html: `<html><head>
  //         <meta charset="utf-8">

  //         <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  //         <meta name="format-detection" content="telephone=no" />
  //         <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
  //         <meta name="ad.size" content="width=${
  //           canvasDimension?.width
  //         },height=${canvasDimension?.height}"/>
  //        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
  //         <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css">
  //         <script src="https://s0.2mdn.net/ads/studio/Enabler.js"></script>
  //         <script type="text/javascript" src="https://code.jquery.com/jquery-latest.js" ></script>
  //         <script  type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.20.3/TweenMax.min.js"></script>
  //         <script type="text/javascript" src="https://baijs.com/tinyscrollbar/js/jquery.tinyscrollbar.js" ></script>

  //       <style>
  //       * {
  //       -moz-box-sizing: border-box;
  //       -webkit-box-sizing: border-box;
  //        box-sizing: border-box;
  //        }
  //        body{
  //          padding:0;
  //          margin:0;
  //        }
  //       ${finalDOMObj.CssCollect}
  //       .customScrollBar {
  //         padding-bottom:50px;
  //       }
  //       .customScrollBar::-webkit-scrollbar {
  //         background-color: $imc-white-color;
  //       }
  //       p{
  //         margin: 0 0 0px !important;
  //       }
  //       .customScrollBar::-webkit-scrollbar {
  //         width: 6px;
  //         height: 6px;
  //       }

  //       .customScrollBar::-webkit-scrollbar-thumb {
  //         border-radius: 5px;
  //         box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //         -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  //         background: rgba(0, 0, 0, 0.2);
  //       }

  //       .custonScrollBar::-webkit-scrollbar {
  //         background-color: $imc-white-color;
  //       }

  //       .disable .thumb {
  //         display:none;
  //         }

  //       </style>
  //       </head>

  //       <body class="customScrollBar">${typeDiff}
  //       <script>
  //       var arrObject=${animationFinalObjToString}
  //       var fps =${canvasDimension.fps}
  //       var tempGpsa = [];
  //       var count = 0;
  //       var clearTimer = [];
  //       var MaxDuration = [];
  //       var collectID = [];
  //       var loopAnimation =${
  //         canvasDimension.checkLoops ? canvasDimension.loopAnimation : 0
  //       }
  //       var set = true;
  //       let maxIndex = -1;

  //       function enableTxt(item) {
  //         var urltestGlobal= $('#canvas-page').data("global");
  //         var urltextGlobalCheck=$('#canvas-page').data("link");
  //         if(urltextGlobalCheck && urltextGlobalCheck !=="undefined"){
  //           window.open(urltestGlobal)
  //         }else{
  //           var urltest = $(item).data("url");
  //           if(urltest!==undefined && urltest !=="http://" ){
  //           window.open(urltest);
  //           }else{
  //             return;
  //           }
  //         }
  //       }

  //       function getElements(elem) {
  //         let element = [];
  //         for (let i = 0; i < elem.length; i++) {
  //           element.push(document.getElementById(elem[i]));
  //           console.log("in Test");
  //         }
  //         return element;
  //       }

  //       function animationRepeat(AryObj) {
  //         var masterTimeLine = new TimelineMax({ repeat:${
  //           canvasDimension.checkLoops === true
  //             ? canvasDimension.loopAnimation
  //             : 0
  //         }});
  //             gsap.ticker.fps(fps);

  //             if (loopAnimation && set===true){
  //               Array.prototype.max = function() {
  //                 return Math.max.apply(null, this);
  //               };
  //               for (let j = 0; j < AryObj.length; j++) {
  //                 let obj = AryObj[j];
  //                 MaxDuration.push(obj.duration+obj.op.delay);
  //               }

  //               const max = MaxDuration.max();
  //               maxIndex = MaxDuration.indexOf(max);
  //                   }
  //       let i = 0;
  //         for ( i = 0; i < AryObj.length; i++) {
  //           var obj = AryObj[i];
  //           var funcName = obj.api;
  //           let value = getElements(obj.atoms);
  //           if (value[0]) {
  //             let  createGPSA = gsap.timeline();
  //             if (maxIndex=== i && set===true){
  //                  let newObj = obj.op;
  //                 newObj.onComplete = function(){
  //                     setTimeout(loopClear,100);
  //                  }
  //                    tempGpsa.push(createGPSA.to([value], obj.duration, newObj));
  //                    set = false;
  //             }else{

  //                tempGpsa.push(createGPSA.to([value], obj.duration, obj.op));
  //                MaxDuration.push(createGPSA.totalDuration())
  //             }
  //                console.log(value[0],"value0000")
  //                collectID.push("#"+obj.atoms)
  //             }
  //         }
  //         // if (loopAnimation && set){
  //         // Array.prototype.max = function() {
  //         //   return Math.max.apply(null, this);
  //         // };
  //         // const max = MaxDuration.max();
  //         // const index = MaxDuration.indexOf(max);
  //         // set = false
  //         //  let objMax = arrObject[index];
  //         //   let funcNameMax = objMax.api;
  //         //   let Maxvalue = getElements(objMax.atoms);
  //         //   if (Maxvalue[0]) {
  //         //     let  createGPSA = gsap.timeline();
  //         //     let newObj = objMax.op;
  //         //     newObj.onComplete = function(){
  //         //       setTimeout(loopClear,100);
  //         //       }
  //         //         tempGpsa.push(createGPSA.to([Maxvalue], objMax.duration, newObj));
  //         //       }
  //         //       }
  //         for (let i=0; i <completeObj.length; i++) {
  //           scrollTrap(completeObj[i].id);
  //           if (completeObj[i].autoScroll){
  //           let autoScroll = setInterval(function() {ControlThumb(completeObj[i].id)}, 90);
  //           clearTimer[completeObj[i].id]= autoScroll;
  //           }
  //        }
  //       }
  //       function loopClear(){
  //         if (loopAnimation > 0){
  //        $(collectID.join()).removeAttr("style");
  //         setTimeout(function(){animationRepeat(arrObject);},100);

  //         loopAnimation=loopAnimation-1;
  //         }
  //       }

  //       function stopAndStart(id) {
  //            clearInterval(clearTimer[id]);

  //           setTimeout(function () {
  //             let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");

  //             tinyscrollbar.update(0);
  //           }, 1000);
  //          }

  //       function ControlThumb(id) {
  //         var scrollThumbCurrPos = 0;
  //            var scrollThumbMaxPos = 119; // top position value of thumb div when collapse
  //            var scrollThumbMinPos = 0;
  //           let  scrollTrackHeight =0;
  //            $("#"+id+"thumb","#"+id+"track","#"+id+"viewport", "#"+id+"overview").bind("click", function () {
  //             clearInterval(clearTimer[id]);
  //            });

  //            if (
  //              $("#"+id+"overview").position().top <= parseInt($("#"+id+"viewport").height()) - parseInt($("#"+id+"overview").height())
  //            ) {
  //             stopAndStart(id);
  //            $("#"+id+"scrollbar").addClass(id+"activeScroller");
  //            } else {
  //              scrollTrackHeight = $("#"+id+"track").height();
  //              $("#"+id+"scrollbar").addClass(id+"activeScroller");
  //              scrollThumbCurrPos = parseFloat($("#"+id+"thumb").css("top")); //$("#thumb").position().top;
  //              var maxOverviewTop =
  //                $("#"+id+"overview").height() - $("#"+id+"viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
  //              if (scrollTrackHeight > 100) {
  //                scrollThumbMaxPos = 208.5; // top position value of thumb div when expand
  //              } else {
  //                scrollThumbMaxPos = 50; // top position value of thumb div when collapse
  //              }
  //              scrollThumbCurrPos = scrollThumbCurrPos - 5;
  //              scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
  //              if (scrollThumbCurrPos < scrollThumbMaxPos) {
  //                var currContentTop = Math.floor($("#"+id+"overview").position().top);

  //                if (currContentTop <= 0) {
  //                  currContentTop = currContentTop - 1;

  //                  currContentTop = currContentTop * -1;
  //                }

  //                currContentTop = Math.min(currContentTop, maxOverviewTop);

  //                let tinyscrollbar = $("#"+id+"ISIInfo").data("plugin_tinyscrollbar");

  //                tinyscrollbar.update(currContentTop);
  //              }
  //            }
  //          }
  //       function scrollTrap(id) {
  //         $("#"+id+'ISIInfo').tinyscrollbar();
  //         var scrollTrackHeight = $("#"+id+'track').height();
  //         var scrollThumbCurrPos = 0;
  //         var scrollThumbMaxPos = 119; // top position value of thumb div when collapse
  //         var scrollThumbMinPos = 0;

  //         $("#"+id+"arrowBottom").bind("click", function () {
  //           scrollTrackHeight = $("#"+id+"track").height();

  //           scrollThumbCurrPos = parseFloat($("#"+id+"thumb").css("top")); //$("#thumb").position().top;
  //           var maxOverviewTop =
  //             $("#"+id+"overview").height() - $("#"+id+"viewport").height() + 0; //  top position value of overview div when full scroll down by mousewheel or drag but not clicking bottomArrow
  //           if (scrollTrackHeight > 97) {
  //             scrollThumbMaxPos = 533; // top position value of thumb div when expand
  //           } else {
  //             scrollThumbMaxPos = 45; // top position value of thumb div when collapse
  //           }
  //           scrollThumbCurrPos = scrollThumbCurrPos + 5;
  //           scrollThumbCurrPos = Math.min(scrollThumbCurrPos, scrollThumbMaxPos);
  //           //if(scrollThumbCurrPos<=scrollThumbMaxPos){
  //           var currContentTop = $("#"+id+"overview").position().top;
  //           if (currContentTop <= 0) {
  //             currContentTop = currContentTop - 10;
  //             currContentTop = currContentTop * -1;
  //           }
  //           currContentTop = Math.min(currContentTop, maxOverviewTop);
  //           //console.log(currContentTop);
  //           if (currContentTop < 4635) {
  //             var scroll = $("#"+id+"ISIInfo").tinyscrollbar();

  //             $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
  //             //  $("#ISIInfo").tinyscrollbar_update(currContentTop);
  //           }
  //           //}
  //         });
  //         var scrollInterval = null;
  //         $("#"+id+"arrowBottom" , "#"+id+"arrowTop").bind("mousedown", function (e) {
  //           if ($(e.target).hasClass("bottom_arrow"))
  //             scrollInterval = setInterval(function () {
  //               $(".bottom_arrow").trigger("click");
  //             }, 100);
  //           else
  //             scrollInterval = setInterval(function () {
  //               $(".top_arrow").trigger("click");
  //             }, 100);
  //         });

  //         $("#"+id+"arrowBottom" , "#"+id+"arrowTop").bind("mouseup mousemove", function (e) {
  //           clearInterval(scrollInterval);
  //         });
  //         $("#"+id+"arrowTop").bind("click", function () {
  //           scrollTrackHeight = $("#"+id+"track").height();
  //           scrollThumbCurrPos = $("#"+id+"thumb").position().top;
  //           scrollThumbCurrPos = scrollThumbCurrPos - 5;
  //           if (
  //             scrollThumbCurrPos < scrollThumbMinPos &&
  //             $("#"+id+"thumb").position().top >= scrollThumbMinPos
  //           )
  //             scrollThumbCurrPos = $("#"+id+"thumb").position().top;
  //           if (scrollThumbCurrPos >= scrollThumbMinPos) {
  //             var currContentTop = $("#"+id+"overview").position().top;

  //             if (currContentTop < 0) {
  //               currContentTop = currContentTop + 10;
  //               currContentTop = currContentTop * -1;
  //               currContentTop = Math.max(currContentTop, 0);
  //             } else currContentTop = 0;
  //             //console.log(currContentTop);
  //             var scroll = $("#"+id+'ISIInfo').tinyscrollbar();

  //             $(scroll).data().plugin_tinyscrollbar.update(currContentTop);
  //             // $("#ISIInfo").tinyscrollbar_update(currContentTop);
  //           }
  //         });
  //       }
  //       function pageLoadedHandler() {
  //         animationRepeat(arrObject);
  //       }

  //       window.onload = function () {
  //         if (Enabler.isPageLoaded()) {
  //           pageLoadedHandler();
  //         } else {
  //           Enabler.addEventListener(
  //             studio.events.StudioEvent.PAGE_LOADED,
  //             pageLoadedHandler
  //           );
  //         }
  //       };
  //       let completeObj = ${completeObj};

  //       for (let i=0; i <completeObj.length; i++) {
  //       $("#"+completeObj[i].id+'ISIInfo').tinyscrollbar({ trackSize: completeObj[i].trackHeight , thumbSize :completeObj[i].thumbHeight, thumbSizeMin:2});
  //       }
  //     </script>
  //     <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/3.2.4/gsap.min.js"></script>

  //     <script src="https://cdnjs.cloudflare.com/ajax/libs/gsap/1.10.2/plugins/CSSPlugin.min.js"></script>
  //       </body>
  //       </html>
  //       `,
  //     cssObj: {
  //       fileName: "Styles",
  //       content: "",
  //     },
  //     js: "",
  //     atomId: finalDOMObj.atomId,
  //     atomUrl: finalDOMObj.atomUrl,
  //     img: finalDOMObj.img,
  //     id: randomNumber,
  //     fileName: appData.assetInfo.assetName.trim(), //put assetname here
  //   };
  //   return DOMObj;
  // }

  return <></>;
}
