import React, { useState, useContext } from "react";
import SideBarLeftPanel from "./SideBarLeftPanel";
import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import { getSaveAssets } from "../../services/apis";
import ValueContext from "../../ContextAPi/appContext";
import { toast } from "react-toastify";
const LeftPanel = (props) => {
  const {
    getEditSaveAssets,
    bannerId,
    setModules,
    modules,
    showAssettype,
    openTextWindow,
    setOpenTextWindow,
    getLayerDetails,
    layerCompData,
    setLayerCompData,
    openShapesWindow,
    setOpenShapesWindow,
    setOpenTimeLine,
    openTimeLine,
    setOpenModuleWindow,
    openModuleWindow,
    sendAtomDetails2Canvas,
    setOpenImageWindow,
    openImageWindow,
    selectedComponent,
    setSelectedComponent,
    layerId,
    imageOnCanvas,
    toggleAnimatedBanner,
    playPause,
    linkedModulesType,
    setLinkedModulesType,
    loadingModules,
    setLoadingModules,
    setVeevaModuleCardData,
    veevaModuleCardData,
    playPauseRef,
    toggleHideComp,
    toggleLockComp,
    setMultiSelectedComps,
  } = props;
  const [linkImages, setLinkImages] = useState([]);
  const appData = useContext(ValueContext);
  const [imageCount, setImageCount] = useState(0);

  const getLinkImages = () => {
    getSaveAssets(
      appData?.assetInfo.assetId,
      async (response, err, errorCode) => {
        const data = await response.data;
        if (err) {
          const errCoponent = ErrComponentToRender(errorCode);
          toast.error(<div className="notificationText">{errCoponent}</div>);
        } else {
          // data.assetLibrary.filter((element) => element.imageType === "Upload");
          //    setImageCount(data.assetLibrary.length);
          setLinkImages(data.assetLibrary);
        }
      }
    );
  };
  return (
    <>
      <SideBarLeftPanel
        playPauseRef={playPauseRef}
        openTimeLine={openTimeLine}
        showAssettype={showAssettype}
        openTextWindow={openTextWindow}
        setOpenTextWindow={setOpenTextWindow}
        getLayerDetails={getLayerDetails}
        layerCompData={layerCompData}
        openShapesWindow={openShapesWindow}
        setOpenShapesWindow={setOpenShapesWindow}
        setOpenTimeLine={setOpenTimeLine}
        setOpenModuleWindow={setOpenModuleWindow}
        openModuleWindow={openModuleWindow}
        sendAtomDetails2Canvas={sendAtomDetails2Canvas}
        setOpenImageWindow={setOpenImageWindow}
        openImageWindow={openImageWindow}
        selectedComponent={selectedComponent}
        setSelectedComponent={setSelectedComponent}
        layerId={layerId}
        imageOnCanvas={imageOnCanvas}
        linkImages={linkImages}
        setLinkImages={setLinkImages}
        getLinkImages={getLinkImages}
        imageCount={imageCount}
        setImageCount={setImageCount}
        playPause={playPause}
        linkedModulesType={linkedModulesType}
        setLinkedModulesType={setLinkedModulesType}
        loadingModules={loadingModules}
        setLoadingModules={setLoadingModules}
        setVeevaModuleCardData={setVeevaModuleCardData}
        veevaModuleCardData={veevaModuleCardData}
        toggleAnimatedBanner={toggleAnimatedBanner}
        setLayerCompData={setLayerCompData}
        toggleHideComp={toggleHideComp}
        toggleLockComp={toggleLockComp}
        setMultiSelectedComps={setMultiSelectedComps}
        setModules={setModules}
        modules={modules}
      />
    </>
  );
};

export default LeftPanel;
