let showSelectionM;
let Xstart;
let Ystart;
let canvasPageMarginTop = 0;
let canvasPageMarginLeft = 0;
let mouseDown = false;
let selectedObjects = [];

// console.log(
//   "canvasPageMarginLeft, canvasPageMarginTop outside the funs",
//   canvasPageMarginLeft,
//   canvasPageMarginTop
// );
export const drawDiv = (
  event,
  showSelection,
  XstartM,
  YstartM,
  setMultiSelect,
  tempLayer
) => {
  mouseDown = true;
  let dontSelect = true;
  if (tempLayer.current.length < 1) return;

  tempLayer.current.forEach((element) => {
    if (element.componentStyles.hideBoolean !== true) dontSelect = false;
  });

  if (dontSelect) return;
  // let selectionDiv = multiSelect;
  // setMultiSelect()

  // const [multiSelect, setMultiSelect] = useState(selectionDiv);

  // set a variable that indicates the user is drawing a selection
  if (document.getElementById("canvas-page") !== null) {
    // canvasPageMarginTop = parseInt(
    //   window.getComputedStyle(document.getElementById("canvas-page")).marginTop
    // );
    // canvasPageMarginLeft = parseInt(
    //   window.getComputedStyle(document.getElementById("canvas-page")).marginLeft
    // );
  }
  showSelection = true;
  showSelectionM = true;
  // console.log("inside divdrag  showSelection", showSelection);

  // save the position where the mouse click started

  Xstart = event.pageX;
  Ystart = event.pageY;

  // console.log(
  //   "canvasPageMarginLeft, canvasPageMarginTop",
  //   canvasPageMarginLeft,
  //   canvasPageMarginTop
  // );
  let Xxstart =
    event.clientX - event.currentTarget.getBoundingClientRect().left;
  let Yystart =
    event.clientY -
    event.currentTarget.getBoundingClientRect().top +
    canvasPageMarginTop;
  // let selection = multiSelect;
  setMultiSelect({ width: 0, height: 0, left: Xxstart, top: Yystart });

  // document.getElementById("mainCanvasContainer").onmousemove = mousedrag;

  document.addEventListener("onmouseup", closeSelec);
  document.onmouseup = closeSelec;
};

export function mousedrag(
  event,
  tempLayer,
  showSelection,
  XstartM,
  YstartM,

  setMultiSelect,
  setMultiSelectedComps,
  multiSelect
) {
  if (!mouseDown) return;
  if (document.getElementById("canvas-page") !== null) {
    // canvasPageMarginTop = parseInt(
    //   window.getComputedStyle(document.getElementById("canvas-page")).marginTop
    // );
    // canvasPageMarginLeft = parseInt(
    //   window.getComputedStyle(document.getElementById("canvas-page")).marginLeft
    // );
  }
  // if we are making a selection lets keep updating our select box dimensions
  let pos1 = Xstart - event.clientX;
  let pos2 = Ystart - event.clientY;

  let leftValue =
    event.clientX - event.currentTarget.getBoundingClientRect().left;
  let Yvalue = event.clientY - event.currentTarget.getBoundingClientRect().top;
  let dragELement = document.getElementById("drawSelectionDiv");
  // console.log(event.clientX, event.pageX, Xstart, "sssssss", dragELement);
  // console.log(showSelection, leftValue, Yvalue, "sssssss");
  // console.log("inside mousedrag  showSelection", showSelectionM);

  if (showSelectionM === true) {
    // console.log("inside mousedrag  showSelection is true");
    document.getElementById("mainCanvasContainer").onClick = null;

    // console.log("event.pageX, Xstart", event.pageX, Xstart);
    // horizontal selection

    if (event.pageX > Xstart) {
      // left to right
      dragELement.style.width = event.pageX - Xstart + "px";
    } else {
      // right to left
      // console.log("inside rightToLeft");
      dragELement.style.width = Xstart - event.pageX + "px";
      dragELement.style.left =
        event.clientX - event.currentTarget.getBoundingClientRect().left + "px";
      // $("#selection").css({
      //   left: event.pageX,
      //   width: Xstart - event.pageX + "px",
      // });
    }

    // vertical selection
    if (event.pageY > Ystart) {
      // top to bottom
      dragELement.style.height = event.pageY - Ystart + "px";

      // $("#selection").css({
      //   height: event.pageY - Ystart + "px",
      // });
    } else {
      // bottom to top

      dragELement.style.height = Ystart - event.pageY + "px";
      dragELement.style.top =
        event.clientY -
        event.currentTarget.getBoundingClientRect().top +
        canvasPageMarginTop +
        "px";
      // $("#selection").css({
      //   top: event.pageY,
      //   height: Ystart - event.pageY + "px",
      // });
    }
    // horizontal selection
    // if (event.pageX > Xstart) {
    //   // left to right
    //   // console.log(
    //   //   "hereeeee 111, sssssss",
    //   //   event.clientX - event.currentTarget.getBoundingClientRect().left,
    //   //   Xstart
    //   // );
    //   console.log(
    //     event.clientX,
    //     event.pageX,
    //     Xstart,
    //     "sssssss",
    //     event.pageX - Xstart
    //   );

    //   upDiv(event.pageX - Xstart);
    // } else {
    //   // right to left
    //   upDiv(event.pageX, 0);
    //   // setMultiSelect({
    //   //   ...multiSelect,
    //   //   width: event.pageX,
    //   //   // left: Xstart - event.pageX,
    //   // });
    // }

    // // // vertical selection
    // if (event.pageY > Ystart) {
    //   // top to bottom
    //   // console.log(Yvalue, leftValue, "sssssss");
    //   upDiv(event.pageX - Xstart, event.pageX - Xstart);
    //   // setMultiSelect({
    //   //   ...multiSelect,
    //   //   height: event.pageY - Ystart,
    //   // });
    // } else {
    //   upDiv(0, Ystart - event.pageY);
    //   // bottom to top
    //   // setMultiSelect({
    //   //   ...multiSelect,
    //   //   //  top: event.pageY,
    //   //   height: Ystart - event.pageY,
    //   // });
    // }
  }
  // console.log("insidemulti Select", tempLayer);
  let selectedObjs = [];
  tempLayer.current.forEach((obj, index) => {
    tempLayer.current[index].multiSelected = false;
    if (
      overLap(
        dragELement?.getBoundingClientRect(),
        document?.getElementById(obj.id)?.getBoundingClientRect()
        // obj.componentStyles
      )
    ) {
      // console.log("tempLayer.current[index]", tempLayer.current[index]);
      tempLayer.current[index].multiSelected = true;

      selectedObjs.push(obj);
    }
  });
  selectedObjects = selectedObjs;
  setMultiSelectedComps(selectedObjs);
  if (tempLayer.current[tempLayer.current.length - 1]) {
    tempLayer.current[tempLayer.current.length - 1].mulSelected = "yes";
  }
  setMultiSelect({
    ...multiSelect,
    pointerX: event.clientX,
    pointerY: event.clientY,
    timestamp: new Date().getTime(),
  });
  // selectedObjs = new Set([selectedObjs]);
  // console.log("selectedObjects  .....", selectedObjs);
}

export function overLap(domRect1, domRect2) {
  // console.log("hereee", domRect1, domRect2);

  // return (
  //   domRect1.top <= domRect2.bottom &&
  //   // domRect1.left <= domRect2.left &&
  //   domRect1.bottom >= domRect2.top
  //   //  &&
  //   // domRect1.right >= domRect2.right
  // );

  return (
    domRect1.top <= domRect2.top &&
    domRect1.left <= domRect2.left &&
    domRect1.bottom >= domRect2.bottom &&
    domRect1.right >= domRect2.right
  );
}
export const closeSelec = (showSelection) => {
  // console.log("closeSelec");
  mouseDown = false;
  showSelectionM = false;
  document.getElementById("mainCanvasContainer").onmousemove = null;
  document.onmouseup = null;
  //document.getElementById("mainCanvasContainer").onClick = drawDiv;
  document.getElementById("drawSelectionDiv").style.width = 0 + "px";
  document.getElementById("drawSelectionDiv").style.height = 0 + "px";
  if (selectedObjects.length > 0)
    document.getElementById("canvas-page").click("Dom");
  // document
  //   .getElementById("mainCanvasContainer")p

  //   .removeEventListener("onmousemove", mousedrag);
};

export const pasteMultiSelectedComps = (
  count,
  setCount,
  setShowPaste,
  setVisibility,
  layerCompData,
  setLayerCompData,
  copiedComponent,
  countZindex,
  multiSelectedComps,
  setMultiSelectedComps,
  componentType,
  imageOnCanvas,
  setSelectedComponent,
  selectedComponent
) => {
  let localCount = count;
  let localCountZindex = countZindex;
  let copiedObjects = JSON.parse(JSON.stringify(copiedComponent));

  let tempLayerCompData = [];

  let modifiedObjects = copiedObjects.map((element, index) => {
    let elemCSSProp = {},
      compType = "",
      coOrdinates = {},
      constructObj = "";
    elemCSSProp = Object.assign({}, element.componentStyles);
    coOrdinates.x = elemCSSProp.left + 25;
    coOrdinates.y = elemCSSProp.top + 50;
    elemCSSProp.froalaText = element.froalaText;

    // element.multiSelected = false;
    if (element.componentType === "Text" || element.componentType === "Shape") {
      compType = element.textComponentType.addContent;
      elemCSSProp.textComponentType = element.textComponentType;
      elemCSSProp.src =
        element.triImage || element.hexImage || element.pentImage;
      elemCSSProp.layerName =
        element.componentType === "Shape" ? "Shape" : "Text"; // Hack
    } else {
      elemCSSProp.layerName = compType = element.componentType;
    }

    if (compType === "Image") {
      constructObj = imageOnCanvas(
        "fromGetAssets",
        elemCSSProp.background,
        coOrdinates.x,
        coOrdinates.y,
        elemCSSProp.filename,
        elemCSSProp,
        elemCSSProp.width,
        elemCSSProp.height,
        localCount
      );
    } else {
      constructObj = componentType[compType](
        coOrdinates,
        null,
        elemCSSProp,
        localCount
      );
    }

    tempLayerCompData = [...tempLayerCompData, constructObj];
    setLayerCompData([...layerCompData, ...tempLayerCompData]);
    localCount++;

    let tempSelected = { ...constructObj };
    tempSelected.componentStyles.lockBoolean = false;
    setSelectedComponent(tempSelected);
  });

  // copiedObject.keyFrames = [];
  // let getComponent;
  // getComponent =
  //   copiedObject.componentType === "Shape"
  //     ? copiedObject?.textComponentType?.addContent
  //     : copiedObject?.componentType;
  // copiedObject.id = getComponent + localCount;
  // let copiedCompStyles = { ...copiedObject.componentStyles };
  // copiedObject.componentStyles = copiedCompStyles;
  // copiedObject.componentStyles.left = copiedObject.componentStyles.left + 50;
  // copiedObject.componentStyles.top = copiedObject.componentStyles.top + 25;
  // copiedObject.componentStyles.zIndex = localCountZindex;
  // copiedObject.multiSelected = false;

  setCount(localCount);

  setShowPaste(false);
  setVisibility(false);
  setMultiSelectedComps([]);
};
export const deleteMultiSelectedComps = (
  event,
  setOpenTimelinePopup,
  layerCompData,
  setLayerCompData,
  multiSelectedComps,
  setMultiSelectedComps,
  animationObject,
  setAnimationObject,
  setSeconds,
  setMillSeconds,
  setSelectedComponent,
  setVisibility
) => {
  setOpenTimelinePopup({
    popupWindow: false,
    fromUnRedo: false,
  });
  const filteredComponents = layerCompData.filter(
    (item) => !multiSelectedComps.includes(item)
  );
  if (event !== "fromKey" && Object.values(animationObject).length > 0) {
    let tempAnimationObj = {};
    for (let props in animationObject) {
      multiSelectedComps.forEach((selectedComp) => {
        if (props !== selectedComp.id)
          tempAnimationObj[props] = animationObject[props];
      });
    }
    setAnimationObject(tempAnimationObj);
  }
  if (layerCompData.length === 1) {
    setMillSeconds(0);
    setSeconds(0);
  }
  // setCompDeleted(true);
  setSelectedComponent("compdeleted");
  setLayerCompData(filteredComponents);
  setVisibility(false);
  setMultiSelectedComps([]);
};
